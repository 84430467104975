//2025.02.13

export const RoomDataInput = {
    FromDate: 0,
    ToDate: 1,
    FromTime: 2,
    ToTime: 3,
    Group: 4,
    QuestionSetId: 5,
    AccessibleOnEntireDay: 6,
    Date: 7,
    AccessibleOnSingleDayOnly: 8,
    Title: 9,
    UseCustomDuration: 10,
    Remark: 11,
    ExtraUrl: 12,
    Subject: 13,
    RandomQuestionMode: 14,
    RestrictAccessToTimeRangeOnly: 15,
    ForceRetrictedAccess: 16,
    QuizEnded: 17,
    EnableStatisticReport: 18,
    ExcludedFromStatisticReport: 19,
    RoomType: 20,
    Classrooms: 21,

    SearchQsSet_ByGroup: 50,          //standard
    SearchQsSet_BySubject: 51,        //subject
    SearchQsSet_ByName: 52,           //name
    SearchQsSet_MaxQtyShow: 53,       //max result
}

export const RoomSearchBy = {
    None: 0,
    Group: 1,
    Subject: 2,
    Quantity: 3,
    QuestionSet: 4,
}

export const FileExtOptions = [
    { value: '.doc', label: 'Word Document (doc)', name: 'doc', content_type: 'application/msword' },
    { value: '.docx', label: 'Word Document (docx)', name: 'docx', content_type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
    { value: '.txt', label: 'Plain text (txt)', name: 'txt', content_type: 'text/plain' },
    { value: '.rtf', label: 'Rich Text Format (rtf)', name: 'rtf', content_type: 'application/rtf' },
    { value: '.pdf', label: 'PDF (pdf)', name: 'pdf', content_type: 'application/pdf' }
];

export const RoomDataInitValue = {
    AuthorId: 0,
    CenterUserId: 0,
    Date: '',
    DateEnd: '',
    DateStart: '',
    Duration: 0,
    EventCode: '',
    Organizer: '',
    OrganizerIdentity: '',
    OrganizerId: 0,
    QuestionSetUniqueId: '',
    RoomCode: 0,
    RoomId: '',
    RoomTitle: '',
    RoomType: 0,        //0 = basic, 1 = document
    SubjectName: '',
    SubjectId: 0,
    SupportedDocExt: ['.txt', '.rtf', '.doc', '.docx'],     //2021.11.08
    TimeEnd: '23:59:00',
    TimeStart: '00:00:00',
    GroupId: 0,
    Grade: 0,
    Remark: '',
    ExtraUrl: '',   //2021.12.10 for Flipbook
    RandomQuestionMode: false,      //2023.10.06
    RestrictAccessToTimeRangeOnly: false,
    ForceRetrictedAccess: false,
    QuizEnded: false,
    EnableStatisticReport: false,
    ExcludedFromStatisticReport: false,
    DemoTestingChecking: false,

    //etc.
    AccessibleOnSingleDayOnly: false,
    Group: null,
    Subject: null,
    UseCustomDuration: false,
    Duration_Hour: 0,
    Duration_Min: 0,
    Duration_Sec: 0,
    QuestionSet: null,
};

export const Room_SearchQuery_DefaultModal = {
    RoomTypeId: 0,
    RoomCode: '',
    RoomTitle: '',
    GroupId: 0,
    SubjectId: 0,
};