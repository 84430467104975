import React from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import ReactSelect from "react-select";
import * as XLSX from "xlsx";
import moment from 'moment';

import { BasicRolePermissions, CommonState, DefaultCustomPermissionModel_Restricted, GlobalSetting, LayoutScreen, PermissionAccessType, SecretKey } from "../utilities/GlobalSetting";
import { useGlobal } from "../utilities/GlobalVariables";
import { PermissionAccess, CapitalizeJsonKeys, CheckBoolean, CheckNullValue, CheckNumber, CheckObjectBoolean, CheckObjectNullValue, CheckObjectNumber, CheckObjectStringEmpty, CheckStringEmpty, CheckValueNA, CommonStatusMessage, Delay, DelayUntil, DoNothing, GetPropIds, PagingComponents, ConsoleLog, UploadStatusMessage, GetPostParams } from "../utilities/GlobalFunctions";
import { useAppService } from "../services/AppService";
import { Accordion, Button, Modal, ProgressBar, Tab, Tabs } from "react-bootstrap";
import { AlertMode } from "./AlertComponent";
import { Locale } from "../utilities/localization/CustomLocalization";
// import { District, NationalState, SchoolList } from "../utilities/NationalQuizEventSignUpSettings";

const settingTitle = 'Teacher';
// const tableCustomGroupStyleObj = { width: 15, height: 15, };
// const editCustomGroupStyleObj = { width: 20, height: 20, };

// const SearchCondition = {
//     // None: 'none',
//     Name: 'Name',
//     Email: 'Email',
//     Group: 'Group',
//     Subject: 'Subject',
//     Classroom: 'Classroom',
//     SchoolName: 'School Name',
// };

const SearchCondition = {
    None: { Label: 'None', Type: 'None', Id: 'search-none' },

    //Input.
    Name: { Label: 'Name', Type: 'Input', Id: 'search-name-input' },
    Email: { Label: 'Email', Type: 'Input', Id: 'search-email-input' },
    SchoolName: { Label: 'School Name', Type: 'Input', Id: 'search-school-name-input' },

    //Select Multiple.
    Groups: { Label: 'Groups', Type: 'Select', Id: 'search-groups-input' },
    Subjects: { Label: 'Subjects', Type: 'Select', Id: 'search-subjects-input' },
    Classrooms: { Label: 'Classrooms', Type: 'Select', Id: 'search-classrooms-input' },
};

const defaultItemModal = {
    Id: 0,
    UserId: 0,
    CenterUserId: 0,
    AuthorRoleId: 0,
    FirebaseUserId: '',

    Organizers: [],
    CustomPermissions: [],
    IsAdmin: false,
    IsViewer: false,
    IsTeacher: true,

    Name: '',
    Email: '',
    DialingCode: '+60',
    PhoneNumber: '',
    ContactNumber: '',

    ForceResetPassword: false,
    LastPasswordUpdateOnUtc: null,

    LastLoginOnUtc: null,
    IsActive: true,

    Groups: [],
    Subjects: [],
    Classrooms: [],
    ClassroomIds: [],   //2025.01.24
    ClassroomSubjects: [],

    RawPassword: '',
    TeacherType: '',    //2024.07.26

    //2025.01.23
    Deactivated: false,
    DeactivatedByUserId: 0,
    DeactivatedOnUtc: null,
    DeactivatedByUserName: '',
    DeactivatedByUserEmail: '',
};

const ItemProperty = {
    None: 'none',
    CheckedItem: 'checkedItem',

    Id: 'Id',
    Name: 'Name',
    Email: 'Email',
    RawPassword: 'RawPassword',
    DialingCode: 'DialingCode',
    PhoneNumber: 'PhoneNumber',
    ContactNumber: 'ContactNumber',
    Groups: 'Groups',
    Subjects: 'Subjects',
    Classrooms: 'Classrooms',
    ClassroomIds: 'ClassroomIds',       //2025.01.24
    ClassroomSubjects: 'ClassroomSubjects',
    CustomPermissions: 'CustomPermissions',
    TeacherType: 'TeacherType',     //2024.07.26
    UserId: 'UserId',   //2024.09.02

    //2025.01.23
    Deactivated: 'Deactivated',
    DeactivatedByUserId: 'DeactivatedByUserId',
    DeactivatedOnUtc: 'DeactivatedOnUtc',
    DeactivatedByUserName: 'DeactivatedByUserName',
    DeactivatedByUserEmail: 'DeactivatedByUserEmail',
};

//2024.07.26
const TeacherTypeOptions = [
    { id: 0, value: '', label: 'NONE' },
    { id: 1, value: 'class', label: 'CLASS' },
    { id: 2, value: 'subject', label: 'SUBJECT' },
];

//2024.07.30
export const UploadState = {
    None: 'None',
    Converting: 'Converting',
    ConvertFailed: 'ConvertFailed',
    Uploading: 'Uploading',
    UploadFailed: 'UploadFailed',
    Processing: 'Processing',
    Failed: 'Failed',
    Success: 'Success',
    Saving: 'Saving',
    Validation: 'Validation',
};

//2025.02.06
const BulkSetting = {
    TeacherType: 'TeacherType',
    Groups: 'Groups',
    Subjects: 'Subjects',
    CustomPermissions: 'CustomPermissions',
};

export default class ManageTeacherProfileScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
    }

    getInitState = () => ({

        isDevMode: window.location.href.includes('localhost'),
        locale: useGlobal.getState().locale,
        redirect: false,
        redirectLink: '/',
        isLoading: false,
        isProcessing: false,
        SecretKey: SecretKey.Admin,
        isSuperAdmin: false,
        gv: null,

        PA_View: false,
        PA_Search: false,
        PA_Create: false,
        PA_Update: false,
        PA_Delete: false,
        PA_Upload: false,
        PA_Download: false,

        //list.
        List: [],
        TableColumn: 7,
        IsListLoaded: false,
        TotalRows: 0,
        PageIndex: 0,
        PageSize: 10,
        OrderBy: 'Name',
        OrderType: 'ASC',

        //search.
        SearchUserByName: '',
        SearchUserByEmail: '',
        SearchUserBySchoolName: '',
        SearchUserByGroups: [],
        SearchUserBySubjects: [],
        SearchUserByClassrooms: [],
        SearchByConditionModal_Toggle: false,
        SearchUserByCondition: SearchCondition.None,
        SearchByCondition_Processing: false,
        // StudentList: [],
        // IsStudentListLoaded: false,
        // StudentListModal_Toggle: false,
        // SelectedStudentList: [],

        //edit or new.
        EditItemModal_Toggle: false,
        EditItemState: CommonState.None,
        CommonStatus: CommonState.None,
        TargetItemIndex: -1,
        TargetProfile: {},
        CachedTargetProfile: null,
        // GenderOptions: [
        //     { value: Locale("label-gender-male", Lang.English), label: Locale("label-gender-male", this.state.locale) },
        //     { value: Locale("label-gender-female", Lang.English), label: Locale("label-gender-female", this.state.locale) },
        //     { value: Locale("label-gender-other", Lang.English), label: Locale("label-gender-other", this.state.locale) },
        // ],
        schoolListArray: [],
        nationalStateListArray: [],
        showSelectSchoolListOption: false,
        filteredByState_DistrictAreaList: [],
        ToggleRevealTargetPassword: false,
        ToggleRevealTargetEmailEdit: false,

        DialingCodeOptions: [],
        GroupOptions: [],
        TeacherGroupOptions: [],    //2025.02.07
        SubjectOptions: [],
        ClassroomOptions: [],
        HighlightedClassroom: null,
        PermissionDetail_Toggle: false,

        //delete.
        DeleteItemModal_Toggle: false,

        //upload.
        UploadProfileModal_Toggle: false,
        ErrorMessage: '',
        AttachedFile: null,
        UploadStatus: UploadState.None,
        UploadStatusText: '',
        UploadResultModal: null,
        UniqueId: '',
        UploadModal: null,
        SendEmailAfterUpload: false,    //2024.09.26

        //2025.01.21
        DeactivatedItems_Toggle: false,
        // CreateNewItem: false,

        //2025.02.06 === BulkEdit
        BulkEdit_Setting: Object.keys(BulkSetting).map((data, key) => { return { key: data, value: null }; }),
        BulkEdit_Setting_checked: Object.keys(BulkSetting).map(() => { return false; }),
        BulkEdit_Toggle_EditSettingModal: false,
        BulkEdit_Toggle_RemoveSettingModal: false,
        BulkEdit_CheckedItems: [],
        BulkEdit_IsUpdating: false,
    });

    componentWillUnmount = () => { }

    componentDidMount = async () => {
        //#region init.
        window.scrollTo(0, 0);
        useGlobal.getState().setScreen(LayoutScreen.ManageTeacherProfile);
        await useAppService.getState().getGroups(true);
        await useAppService.getState().getSubjects(true);
        await Delay(0);
        this.LoadList_ViaApi();
        useGlobal.getState().setRefreshListCallbackFn(this.LoadList_ViaApi);
        //#endregion
    }

    //#region === Teacher Profile - List ===
    //2024.07.24
    CheckPermissions = async () => {
        const gv = useGlobal.getState();
        const { uid, organizerId } = GetPropIds(gv.user);
        this.setState({
            PA_View: PermissionAccess(LayoutScreen.ManageTeacherProfile, PermissionAccessType.View),
            PA_Search: PermissionAccess(LayoutScreen.ManageTeacherProfile, PermissionAccessType.Search),
            PA_Create: PermissionAccess(LayoutScreen.ManageTeacherProfile, PermissionAccessType.Create),
            PA_Update: PermissionAccess(LayoutScreen.ManageTeacherProfile, PermissionAccessType.Update),
            PA_Delete: PermissionAccess(LayoutScreen.ManageTeacherProfile, PermissionAccessType.Delete),
            PA_Upload: PermissionAccess(LayoutScreen.ManageTeacherProfile, PermissionAccessType.Upload),
            PA_Download: PermissionAccess(LayoutScreen.ManageTeacherProfile, PermissionAccessType.Download),

            PageSize: CheckNumber(localStorage.getItem(`ManageTeacherProfile_List_PageSize_${uid}_${organizerId}`), GlobalSetting.PageSize),
            isSuperAdmin: useGlobal.getState().isSuperAdmin,
            gv: useGlobal.getState(),
        });
        await Delay(0);
    }
    LoadList_ViaApi = async (newSearch = false) => {

        await this.CheckPermissions();    //2024.07.24

        if (this.state.PA_View === false)
            return null;

        this.setState({
            isLoading: true,
            List: [],
            IsListLoaded: false,
            PageIndex: newSearch ? 0 : this.state.PageIndex,
            TargetItemIndex: -1,
        });
        if (newSearch)
            this.ToggleSearchUserByConditionModal();    //2024.07.18
        await Delay(0);
        window.scrollTo(0, 0);

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);

        const url = GlobalSetting.ApiUrl + 'Api/LearningCentre/Organizer/Teacher/Profile/List';

        // let groups_id = [];
        // if (Array.isArray(this.state.SearchUserByGroups)) {
        //     this.state.SearchUserByGroups.map((option, key) => {
        //         return groups_id.push(Number(option.id));
        //     });
        // }
        // let subjects_id = [];
        // if (Array.isArray(this.state.SearchUserBySubjects)) {
        //     this.state.SearchUserBySubjects.map((option, key) => {
        //         return subjects_id.push(Number(option.id));
        //     });
        // }
        // let classrooms_name = [];
        // if (Array.isArray(this.state.SearchUserByClassrooms)) {
        //     console.log('classrooms_name (before) \n' + JSON.stringify(this.state.SearchUserByClassrooms));
        //     this.state.SearchUserByClassrooms.map((option, key) => {
        //         classrooms_name.push(String(option.value));
        //         return null;
        //     });
        //     console.log('classrooms_name (after) \n' + JSON.stringify(classrooms_name));
        // }

        const searchJson = JSON.stringify({
            orderBy: this.state.OrderBy,
            orderType: this.state.OrderType,
            pageIndex: this.state.PageIndex,
            pageSize: this.state.PageSize,

            authorId: authorId,
            organizerId: organizerId,

            schoolName: this.state.SearchUserBySchoolName,     //remain empty.
            userName: this.state.SearchUserByName,
            userEmail: this.state.SearchUserByEmail,
            // userBindedGroupIds: groups_id,
            // userBindedSubjectIds: subjects_id,
            // userBindedClassrooms: classrooms_name,
            userBindedGroupIds: Array.isArray(this.state.SearchUserByGroups) ? this.state.SearchUserByGroups.map((option) => { return Number(option.id); }) : [],
            userBindedSubjectIds: Array.isArray(this.state.SearchUserBySubjects) ? this.state.SearchUserBySubjects.map((option) => { return Number(option.id); }) : [],
            userBindedClassrooms: Array.isArray(this.state.SearchUserByClassrooms) ? this.state.SearchUserByClassrooms.map((option) => { return String(option.value); }) : [],
            isTeacher: true,

            isDeactivated: this.state.DeactivatedItems_Toggle,   //2025.01.22
        });

        if (this.state.isDevMode)
            console.log('LoadList_ViaApi \n ' + url + '\n ' + searchJson);

        let totalRows = 0;
        let _List = [];

        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: searchJson,
            })
            .then(res => res.json())
            .then(data => {
                if (data.success) {
                    if (data.data !== undefined)
                        if (Array.isArray(data.data.data)) {
                            _List = data.data.data;
                            totalRows = CheckObjectNumber(data.data, 'totalCount', _List.length);
                        }
                        else {
                            if (this.state.isDevMode)
                                console.log('Profile List is empty.');
                        }
                }
                else {
                    if (this.state.isDevMode)
                        console.log('Error', 'api - profile - load list (failed)\n' + JSON.stringify(data));
                }
            })
            .catch(error => {
                if (this.state.isDevMode)
                    console.log('Error', 'api - profile - load list (error)\n' + error.message);
            });

        this.setState({
            isLoading: false,
            // List: JSON.parse(JSON.stringify(_List)),
            List: CapitalizeJsonKeys(_List),
            TotalRows: totalRows,
            IsListLoaded: true,
            BulkEdit_CheckedItems: Array.isArray(_List) ? _List.map((data, key) => { return false; }) : [],  //2025.02.06
        }, () => {
            if (this.state.isDevMode) {
                console.log('TotalRows', totalRows);
                console.log('List', JSON.stringify(_List));
            }
        });
    }
    ListComponents = () => {
        let components = [];

        if (this.state.IsListLoaded === false)
            return null;

        if (this.state.List.length === 0)
            return (<tr><td colSpan={this.state.TableColumn} align='center'>- list is empty -</td></tr>);

        this.state.List.map((data, key) => {

            // let subjects = '';
            // if (Array.isArray(data[ItemProperty.Subjects])) {
            //     const t_subjects = data[ItemProperty.Subjects].map((item, key) => {
            //         if (CheckObjectBoolean(item, 'Selected'))
            //             return `<span>${CheckObjectStringEmpty(item, 'Name')}</span>`;
            //         return null;
            //     }).filter(x => x !== null);
            //     if (t_subjects.length > 0)
            //         subjects = t_subjects.join('<br />');
            //     else
            //         subjects = '<li>no subject selected</li>';
            // }

            const fn_classrooms = (isBtnLabel = false) => {
                return Array.isArray(data[ItemProperty.ClassroomSubjects]) ?
                    data[ItemProperty.ClassroomSubjects].map((item, key) => {
                        const content = Array.isArray(item['Subjects']) ?
                            item['Subjects'].map((sub, key) => {
                                if (CheckObjectBoolean(sub, 'Selected'))
                                    return `<li>${CheckObjectStringEmpty(sub, 'Name')}</li>`;
                                return null;
                            }).filter(x => x !== null).join('')
                            : '';
                        if (isBtnLabel)
                            return content === '' ? null : `<span>${CheckObjectStringEmpty(item, 'Classroom')}</span>`;
                        else
                            return content === '' ? null : `<span>${CheckObjectStringEmpty(item, 'Classroom')}</span><ul class='ul-classroom'>${content}</ul>`;
                    }).filter(x => x !== null) : [];
            };

            const html_groups = CheckStringEmpty(Array.isArray(data[ItemProperty.Groups]) ?
                data[ItemProperty.Groups].map((item, key) => {
                    if (CheckObjectBoolean(item, 'Selected'))
                        return `<span>${CheckObjectStringEmpty(item, 'Name')}</span>`;
                    return null;
                }).filter(x => x !== null).join('<br />')
                : '', '-');

            const html_subjects = CheckStringEmpty(Array.isArray(data[ItemProperty.Subjects]) ?
                data[ItemProperty.Subjects].map((item, key) => {
                    if (CheckObjectBoolean(item, 'Selected'))
                        return `<span>${CheckObjectStringEmpty(item, 'Name')}</span>`;
                    return null;
                }).filter(x => x !== null).join('<br />')
                : '', '-');

            const html_classrooms = CheckStringEmpty(fn_classrooms(true).join(', '), '-');

            components.push(<tr key={'tbi_' + key} className={`setting-parant-tr ${this.state.BulkEdit_CheckedItems[key] ? 'tr-selected' : ''}`}>
                <td className="pointer setting-child-td" onClick={() => this.ToggleItemChecked(key)}>
                    <div className="no-td">
                        {this.state.PageIndex + key + 1}
                        <input type='checkbox' className='pointer' checked={this.state.BulkEdit_CheckedItems[key]} readOnly={true}></input>
                    </div>
                </td>
                {/* <td>{this.state.PageIndex + key + 1}</td> */}
                <td className='left'>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>{CheckValueNA(data[ItemProperty.Name])}</span>
                        <span style={{ color: 'red', fontStyle: 'italic', fontWeight: 600, }}>{this.state.DeactivatedItems_Toggle ? '(Deactivated)' : null}</span>
                    </div>
                </td>
                <td className='left'>{CheckValueNA(data[ItemProperty.Email])}</td>
                <td className='left'>{CheckObjectStringEmpty(data, ItemProperty.TeacherType, '-').toUpperCase()}</td>
                <td className='left'><div dangerouslySetInnerHTML={{ __html: html_groups }}></div></td>
                <td className='left'><div dangerouslySetInnerHTML={{ __html: html_subjects }}></div></td>
                <td className='left'>
                    <Button type="button" variant="outline-secondary" style={{ width: '100%', textAlign: 'left' }}
                        onClick={() => {
                            useAppService.getState().setModal(`${CheckValueNA(data[ItemProperty.Name])} (Classroom)`, fn_classrooms().join(''));
                        }}
                        disabled={html_classrooms === '-'}
                    ><div dangerouslySetInnerHTML={{ __html: html_classrooms }}></div></Button>
                </td>
                {/* <td>{CheckValueNA(data[ItemProperty.Groups])}</td> */}
                < td >
                    <button
                        type='button'
                        className='btn btn-primary'
                        onClick={() => this.ToggleEditItemUiModal(key)}
                        disabled={this.state.isProcessing}
                    >{this.state.PA_Update ? 'Edit' : 'View'}</button>
                </td >
            </tr >);
            return null;
        });

        return (components);
    }
    ToggleItemChecked = (index, selectAll = null) => {
        if (selectAll !== null) {
            this.setState({
                BulkEdit_CheckedItems: this.state.List.map((data, key) => { return !selectAll; }),
            });
        }
        else {
            if (index < 0)
                return null;
            let checkedItems = this.state.BulkEdit_CheckedItems;
            checkedItems[index] = !checkedItems[index];
            this.setState({
                BulkEdit_CheckedItems: checkedItems,
            });
        }
    }
    //#region === Paging Components
    CallbackFunctionForPagingComponents_PageSize = (pageSize = GlobalSetting.PageSize) => {
        this.setState({
            PageSize: pageSize < GlobalSetting.PageSize ? GlobalSetting.PageSize : pageSize,
        }, () => {
            const { uid, organizerId } = GetPropIds(useGlobal.getState().user);
            localStorage.setItem(`ManageTeacherProfile_List_PageSize_${uid}_${organizerId}`, this.state.PageSize);
            setTimeout(() => {
                this.LoadList_ViaApi();
            }, 500);
        });
    }
    CallbackFunctionForPagingComponents_PageIndex = (pageIndex = 0) => {
        this.setState({
            PageIndex: pageIndex,
        }, () => {
            setTimeout(() => {
                this.LoadList_ViaApi();
            }, 500);
        });
    }
    //#endregion === Paging Components
    //#endregion

    //#region === Teacher Profile - New/View/Edit
    ToggleCreateItemUiModal = () => {
        this.setState({
            EditItemState: CommonState.New,
        });
        this.ToggleEditItemUiModal(-1, true);
    }
    ToggleEditItemUiModal = async (index = -1, create = false) => {
        if (this.state.isDevMode)
            console.log('ToggleEditItemUiModal ' + index + ' ' + String(create));
        const toggle = !this.state.EditItemModal_Toggle;
        if (toggle) {
            useAppService.getState().setModal('', 'loading setting...', null, AlertMode.Loading);
        }
        else {
            this.setState({
                EditItemModal_Toggle: toggle,
            });
            await Delay(200);
        }
        this.setState({
            // EditItemModal_Toggle: !this.state.EditItemModal_Toggle,
            TargetItemIndex: index,
            ToggleRevealTargetPassword: false,
            ToggleRevealTargetEmailEdit: false,
            HighlightedClassroom: null,
            PermissionDetail_Toggle: false,
        });
        if (toggle) {
            await useAppService.getState().getDialingCodes();
            await useAppService.getState().getGroups(true);
            await useAppService.getState().getClassrooms(true);
            this.setState({
                DialingCodeOptions: useAppService.getState().dialingCodeOptions,
                GroupOptions: useAppService.getState().groupOptions,
                SubjectOptions: useAppService.getState().subjectOptions,
                ClassroomOptions: useAppService.getState().classroomOptions,
            });
        }
        await Delay(0);
        await this.InitEditItemUiModal(create);
        await Delay(200);
        if (toggle) {
            useAppService.getState().setModal();
            if (create === false) {
                this.setState({
                    EditItemState: CommonState.Edit,
                });
            }
            this.setState({
                // EditItemState: create ? CommonState.New : CommonState.Edit,
                EditItemModal_Toggle: toggle,
            });
            await Delay(0);
        }

        // this.setState({
        //     EditItemModal_Toggle: !this.state.EditItemModal_Toggle,
        //     TargetItemIndex: CheckNullValue(index) === null ? -1 : index,
        //     ToggleRevealTargetPassword: false,
        //     ToggleRevealTargetEmailEdit: false,
        // }, async () => {
        //     this.InitEditItemUiModal(create);
        //     if (this.state.EditItemModal_Toggle) {
        //         await useAppService.getState().getDialingCodes();
        //         await useAppService.getState().getClassrooms();
        //         this.setState({
        //             DialingCodeOptions: useAppService.getState().dialingCodeOptions,
        //             SubjectOptions: useAppService.getState().subjectOptions,
        //             ClassroomOptions: useAppService.getState().classroomOptions,
        //         });
        //     }
        // });
    }
    InitEditItemUiModal = async (create = false) => {
        const index = this.state.TargetItemIndex;
        ConsoleLog('InitEditItemUiModal', index);
        if (create) {
            //Create New.
            let targetProfile = JSON.parse(JSON.stringify(defaultItemModal));
            targetProfile = await this.GetCustomPermissions(targetProfile);
            this.setState({
                TargetProfile: targetProfile,
                CachedTargetProfile: JSON.parse(JSON.stringify(targetProfile)),
                ToggleRevealTargetPassword: false,
                ToggleRevealTargetEmailEdit: false,
            });
            await Delay(0);
            // ConsoleLog('InitEditItemUiModal', JSON.stringify(GetTempTarget()));
            ConsoleLog('InitEditItemUiModal (New) \n' + JSON.stringify(this.state.TargetProfile));
            ConsoleLog('InitEditItemUiModal (New) (CustomPermissions) \n' + JSON.stringify(this.state.TargetProfile['CustomPermissions']));
        }
        else {
            if (index === undefined || index === null || index < 0) {
                //Reset.
                this.setState({
                    TargetProfile: null,
                    CachedTargetProfile: null,
                    // EditProfileState: CommonState.None,
                    // CommonStatus: CommonState.None,
                    ToggleRevealTargetPassword: false,
                    ToggleRevealTargetEmailEdit: false,
                    EditItemState: CommonState.None,
                });
            }
            else {
                //Edit.
                let targetProfile = JSON.parse(JSON.stringify(this.state.List[index]));
                if (CheckObjectStringEmpty(targetProfile, 'CustomPermissions') !== '')
                    targetProfile['CustomPermissions'] = JSON.parse(targetProfile['CustomPermissions']);
                // ConsoleLog('InitEditItemUiModal (CustomPermissions) (before) \n' + JSON.stringify(targetProfile['CustomPermissions']));
                targetProfile = await this.GetCustomPermissions(targetProfile);
                // ConsoleLog('InitEditItemUiModal (CustomPermissions) (after) \n' + JSON.stringify(targetProfile['CustomPermissions']));
                targetProfile = await this.Populate_ApiModal_To_ItemModal(targetProfile);
                this.setState({
                    TargetProfile: targetProfile,
                    CachedTargetProfile: JSON.parse(JSON.stringify(targetProfile)),
                    ToggleRevealTargetPassword: false,
                    ToggleRevealTargetEmailEdit: false,
                });
                await Delay(0);
                // ConsoleLog('InitEditItemUiModal', JSON.stringify(GetTempTarget()));
                ConsoleLog('InitEditItemUiModal \n' + JSON.stringify(this.state.TargetProfile));
                ConsoleLog('InitEditItemUiModal (CustomPermissions) \n' + JSON.stringify(this.state.TargetProfile['CustomPermissions']));
            }
        }
    }
    Populate_ApiModal_To_ItemModal = async (targetProfile = null) => {
        if (targetProfile === null)
            return this.state.TargetProfile;

        //#region === populate proper format for list item modal === start ===
        //groups.
        //2024.11.25
        let tmp_groups = [];
        const groupOptions = this.state.GroupOptions;
        const groups = targetProfile[ItemProperty.Groups];
        groupOptions.map((option, key) => {
            if (Array.isArray(groups)) {
                const findIndex = groups.findIndex(x => Number(x.Id) === Number(option.id));
                if (findIndex > -1)
                    if (CheckObjectBoolean(groups[findIndex], 'Selected'))
                        tmp_groups.push(CheckObjectNumber(groups[findIndex], 'Id'));
            }
            return null;
        });
        tmp_groups.sort((a, b) => a - b);
        targetProfile[ItemProperty.Groups] = tmp_groups;    //replace.
        ConsoleLog('Populate_ApiModal_To_ItemModal (Groups) =\n' + JSON.stringify(tmp_groups));
        //subjects.
        let tmp_subjects = [];
        const subjectOptions = this.state.SubjectOptions;
        const subjects = targetProfile[ItemProperty.Subjects];
        subjectOptions.map((option, key) => {
            if (Array.isArray(subjects)) {
                const findIndex = subjects.findIndex(x => Number(x.Id) === Number(option.id));
                if (findIndex > -1)
                    if (CheckObjectBoolean(subjects[findIndex], 'Selected'))
                        tmp_subjects.push(CheckObjectNumber(subjects[findIndex], 'Id'));
            }
            return null;
        });
        tmp_subjects.sort((a, b) => a - b);
        targetProfile[ItemProperty.Subjects] = tmp_subjects;    //replace.
        ConsoleLog('Populate_ApiModal_To_ItemModal (Subjects) =\n' + JSON.stringify(tmp_subjects));
        //classrooms.
        let tmp_classrooms = [];
        let tmp_classroomIds = [];      //2025.01.24
        const classroomOptions = this.state.ClassroomOptions;
        const classrooms = targetProfile[ItemProperty.Classrooms];
        ConsoleLog('Populate_ApiModal_To_ItemModal (ClassroomOptions) (before) =\n' + JSON.stringify(classroomOptions));
        ConsoleLog('Populate_ApiModal_To_ItemModal (Classrooms) (before) =\n' + JSON.stringify(classrooms));
        classroomOptions.map((option, key) => {
            if (Array.isArray(classrooms)) {
                const findIndex = classrooms.findIndex(x => Number(x.Id) === Number(option.id));
                if (findIndex > -1) {
                    if (CheckObjectBoolean(classrooms[findIndex], 'Selected')) {
                        tmp_classroomIds.push(CheckObjectNumber(classrooms[findIndex], 'Id'));      //2025.01.24
                        tmp_classrooms.push(CheckObjectStringEmpty(classrooms[findIndex], 'Name'));
                    }
                }
            }
            return null;
        });
        tmp_classroomIds.sort((a, b) => a - b);
        tmp_classrooms.sort((a, b) => {
            if (a < b) { return -1; }
            if (a > b) { return 1; }
            return 0;
        });
        targetProfile[ItemProperty.ClassroomIds] = tmp_classroomIds;    //new property.     //2025.01.24
        targetProfile[ItemProperty.Classrooms] = tmp_classrooms;        //replace.
        ConsoleLog('Populate_ApiModal_To_ItemModal (ClassroomIds) =\n' + JSON.stringify(tmp_classroomIds));
        ConsoleLog('Populate_ApiModal_To_ItemModal (Classrooms) =\n' + JSON.stringify(tmp_classrooms));
        //classroom subjects.
        let tmp_classroomSubjects = [];
        const classroomSubjects = targetProfile[ItemProperty.ClassroomSubjects];
        classroomOptions.map((option, key) => {
            if (Array.isArray(classroomSubjects)) {
                const findIndex = classroomSubjects.findIndex(x => String(x.Classroom) === String(option.value));
                if (findIndex > -1) {
                    const subjects = classroomSubjects[findIndex]['Subjects'];
                    let tmp_subjects = [];
                    subjectOptions.map((option, key) => {
                        if (Array.isArray(subjects)) {
                            const findIndex = subjects.findIndex(x => Number(x.Id) === Number(option.id));
                            if (findIndex > -1)
                                if (CheckObjectBoolean(subjects[findIndex], 'Selected'))
                                    tmp_subjects.push(CheckObjectNumber(subjects[findIndex], 'Id'));
                        }
                        return null;
                    });
                    tmp_subjects.sort((a, b) => a - b);
                    tmp_classroomSubjects.push({
                        ClassroomId: Number(option.id),     //2025.01.24
                        Classroom: String(option.value),
                        Subjects: tmp_subjects,
                    });
                }
            }
            return null;
        });
        tmp_classroomSubjects.sort(function (a, b) {
            if (a.Classroom < b.Classroom) { return -1; }
            if (a.Classroom > b.Classroom) { return 1; }
            return 0;
        });
        targetProfile[ItemProperty.ClassroomSubjects] = tmp_classroomSubjects;    //replace.
        ConsoleLog('Populate_ApiModal_To_ItemModal (ClassroomSubjects) =\n' + JSON.stringify(tmp_classroomSubjects));
        //#endregion === populate proper format for api modal === end ===

        return targetProfile;
    }
    ResetItemValue = () => {
        // this.InitEditItemUiModal(this.state.TargetItemIndex);
        this.InitEditItemUiModal();
    }
    EditItemComponents = () => {
        const targetProfile = this.state.TargetProfile;
        const editItemState = this.state.EditItemState;
        if (
            targetProfile === null
            || editItemState === CommonState.None || editItemState === CommonState.Processing
            || editItemState === CommonState.Success || editItemState === CommonState.Failed
        ) {
            return null;
        }
        const { Groups, Subjects, Classrooms, ClassroomIds, ClassroomSubjects } = targetProfile;
        // console.log(`EditItemComponents (targetProfile) (Subjects) \n${JSON.stringify(Subjects)}`);
        // console.log(`EditItemComponents (targetProfile) (Classrooms) \n${JSON.stringify(Classrooms)}`);
        // console.log(`EditItemComponents (targetProfile) (ClassroomSubjects) \n${JSON.stringify(ClassroomSubjects)}`);

        let topLeftComponents = [];
        let topRightComponents = [];
        //#region === Top ===

        //#region === Left Components ===

        let infoComponents = [];

        //2024.07.26
        const teacherType = CheckObjectStringEmpty(targetProfile, ItemProperty.TeacherType, TeacherTypeOptions[0].value);
        const findIndex_teacherType = TeacherTypeOptions.findIndex(x => String(x.value) === teacherType);
        infoComponents.push(<div key='teacher-type' className="form-group">
            <label>Teacher Type</label>
            <ReactSelect
                id='teacher-type'
                name='teacher-type'
                classNamePrefix='select'
                options={TeacherTypeOptions}
                defaultValue={findIndex_teacherType < 0 ? '' : TeacherTypeOptions[findIndex_teacherType]}
                onChange={(e) => this.UpdateItemValues(ItemProperty.TeacherType, e.value)}
                theme={theme => ({
                    ...theme,
                    width: 'max-content',
                    colors: {
                        ...theme.colors,
                        neutral50: 'black',  // placeholder color
                    }
                })}
                style={{ width: 50 }}
            />
        </div>);

        //Name.
        infoComponents.push(<div key='profile-name' className="form-group">
            <label>{Locale("full-name", this.state.locale)}</label>
            <input
                name="Name"
                className={"form-control"}
                type="text"
                value={CheckObjectStringEmpty(targetProfile, ItemProperty.Name)}
                placeholder={CheckObjectStringEmpty(targetProfile, 'name', Locale("full-name", this.state.locale))}
                onChange={(e) => this.UpdateItemValues(ItemProperty.Name, e.target.value)}
            />
        </div>);

        //Email. dedicated ui component. with reveal/update email via rest api.
        if (editItemState === CommonState.New) {
            infoComponents.push(<div key='profile-email' className="form-group">
                <label>{Locale("your-email", this.state.locale)}</label>
                <input
                    name="Email"
                    className={"form-control"}
                    type="text"
                    value={CheckObjectStringEmpty(targetProfile, ItemProperty.Email)}
                    placeholder={CheckObjectStringEmpty(targetProfile, ItemProperty.Email, Locale("your-email", this.state.locale))}
                    onChange={(e) => this.UpdateItemValues(ItemProperty.Email, e.target.value)}
                />
            </div>);
        }
        else {
            infoComponents.push(<div key='profile-email' className="form-group">
                <table width='100%'>
                    <tbody>
                        {
                            this.state.ToggleRevealTargetEmailEdit ?
                                <tr>
                                    <td width={250}>
                                        <label>{Locale("your-email", this.state.locale)}</label>
                                        <input
                                            name="Email"
                                            className={"form-control"}
                                            type="text"
                                            onChange={(val) => {
                                                let profile = targetProfile;    //JSON.parse(JSON.stringify(targetProfile));
                                                profile['Email'] = String(val.target.value);
                                                this.setState({ TargetProfile: profile });
                                            }}
                                            value={CheckObjectStringEmpty(targetProfile, ItemProperty.Email)}
                                            placeholder={CheckObjectStringEmpty(targetProfile, ItemProperty.Email, '(Unknown)')}
                                            disabled={!this.state.PA_Update}
                                        />
                                    </td>
                                    <td valign='bottom'>
                                        &nbsp;&nbsp;
                                        <button
                                            type='button'
                                            className='btn btn-secondary'
                                            style={{ width: 85 }}
                                            onClick={() => {
                                                let profile = targetProfile;
                                                const cachedProfile = this.state.CachedTargetProfile;
                                                profile[ItemProperty.Email] = cachedProfile[ItemProperty.Email];
                                                profile[ItemProperty.RawPassword] = cachedProfile[ItemProperty.RawPassword];
                                                this.setState({
                                                    TargetProfile: profile,
                                                    ToggleRevealTargetEmailEdit: false,
                                                    ToggleRevealTargetPassword: false,
                                                });
                                            }}
                                        >Hide</button>
                                        &nbsp;&nbsp;
                                        <button
                                            type='button'
                                            className='btn btn-primary'
                                            style={{ width: 85 }}
                                            onClick={() => this.UpdateItem()}
                                            disabled={!this.state.PA_Update || this.state.isLoading || (CheckObjectNullValue(targetProfile, ItemProperty.Email) === null ? true : false)}
                                        >Update</button>
                                    </td>
                                </tr>
                                :
                                <tr>
                                    <td style={this.state.PA_Update ? { width: 250 } : { width: 'auto' }}>
                                        <label>{Locale("your-email", this.state.locale)}</label>
                                        <input
                                            name="Email"
                                            className={"form-control"}
                                            type="text"
                                            value={
                                                CheckObjectNullValue(this.state.List[this.state.TargetItemIndex], ItemProperty.Email) === null ?
                                                    '' : CheckStringEmpty(this.state.List[this.state.TargetItemIndex][ItemProperty.Email])
                                            }
                                            placeholder={
                                                CheckObjectNullValue(this.state.List[this.state.TargetItemIndex], ItemProperty.Email) === null ?
                                                    '' : CheckStringEmpty(this.state.List[this.state.TargetItemIndex][ItemProperty.Email])
                                            }
                                            disabled={true}
                                        />
                                    </td>
                                    <td valign='bottom' style={this.state.PA_Update ? { display: 'table-cell' } : { display: 'none' }}>
                                        &nbsp;&nbsp;
                                        <button
                                            type='button'
                                            className='btn btn-primary'
                                            style={{ width: 85 }}
                                            onClick={() => {
                                                let profile = targetProfile;
                                                const cachedProfile = this.state.CachedTargetProfile;
                                                profile[ItemProperty.RawPassword] = cachedProfile[ItemProperty.RawPassword];
                                                this.setState({
                                                    TargetProfile: profile,
                                                    ToggleRevealTargetEmailEdit: true,
                                                    ToggleRevealTargetPassword: false,
                                                })
                                            }}
                                        >Edit</button>
                                    </td>
                                </tr>
                        }
                    </tbody>
                </table>
            </div>);
        }

        //Password. dedicated ui component. with reveal/update/send password reset link.
        if (editItemState === CommonState.New) {
            infoComponents.push(<div key='profile-password' className="form-group">
                <label>{Locale("your-password", this.state.locale)}</label>
                <input
                    name="Email"
                    className={"form-control"}
                    type="text"
                    value={CheckObjectStringEmpty(targetProfile, ItemProperty.RawPassword)}
                    placeholder={CheckObjectStringEmpty(targetProfile, 'password', Locale("password-min-req", this.state.locale))}
                    onChange={(e) => this.UpdateItemValues(ItemProperty.RawPassword, e.target.value)}
                />
                <span style={{ color: 'gray', fontSize: 14, paddingLeft: 15 }}>(if password is not fill, will randomly generate a 6 digits password)</span>
            </div>);
        }
        else {
            infoComponents.push(<div key='profile-password' className="form-group">
                <table width='100%'>
                    <tbody>
                        {
                            this.state.ToggleRevealTargetPassword ?
                                <tr>
                                    <td width={250}>
                                        <label>{Locale("your-password", this.state.locale)}</label>
                                        <input
                                            name="Password"
                                            className={"form-control"}
                                            type="text"
                                            onChange={(e) => {
                                                let profile = targetProfile;    //JSON.parse(JSON.stringify(targetProfile));
                                                profile[ItemProperty.RawPassword] = String(e.target.value);
                                                this.setState({ TargetProfile: profile });
                                            }}
                                            value={CheckObjectStringEmpty(targetProfile, ItemProperty.RawPassword)}
                                            placeholder={CheckObjectStringEmpty(targetProfile, ItemProperty.RawPassword, '(Unknown)')}
                                            disabled={!this.state.PA_Update}
                                        />
                                    </td>
                                    <td valign='bottom'>
                                        &nbsp;&nbsp;
                                        <button
                                            type='button'
                                            className='btn btn-secondary'
                                            style={{ width: 85 }}
                                            onClick={() => {
                                                let profile = targetProfile;
                                                const cachedProfile = this.state.CachedTargetProfile;
                                                profile[ItemProperty.RawPassword] = cachedProfile[ItemProperty.RawPassword];
                                                profile[ItemProperty.Email] = cachedProfile[ItemProperty.Email];
                                                this.setState({
                                                    TargetProfile: profile,
                                                    ToggleRevealTargetPassword: false,
                                                    ToggleRevealTargetEmailEdit: false,
                                                });
                                            }}
                                        >Hide</button>
                                        &nbsp;&nbsp;
                                        {
                                            this.state.PA_Update === false ? null :
                                                <button
                                                    type='button'
                                                    className='btn btn-primary'
                                                    style={{ width: 85 }}
                                                    onClick={() => this.UpdateItem()}
                                                    disabled={this.state.isLoading || (CheckObjectNullValue(targetProfile, ItemProperty.RawPassword) === null ? true : false)}
                                                >Update</button>
                                            // CheckObjectNullValue(targetProfile, ItemProperty.RawPassword) === null ?
                                            //     <button
                                            //         type='button'
                                            //         className='btn btn-primary'
                                            //         style={{ width: 85 }}
                                            //         onClick={() => this.SendPasswordResetEmail()}
                                            //     >Reset</button>
                                            //     :
                                            //     <button
                                            //         type='button'
                                            //         className='btn btn-primary'
                                            //         style={{ width: 85 }}
                                            //         onClick={() => this.UpdateItem()}
                                            //         disabled={this.state.isLoading || (CheckObjectNullValue(targetProfile, ItemProperty.RawPassword) === null ? true : false)}
                                            //     >Update</button>
                                        }
                                    </td>
                                </tr >
                                :
                                <tr>
                                    <td style={this.state.PA_Update ? { width: 250 } : { width: 'auto' }}>
                                        <label>{Locale("your-password", this.state.locale)}</label>
                                        <input
                                            name="Password"
                                            className={"form-control"}
                                            type="text"
                                            value={'******'}
                                            placeholder={'******'}
                                            disabled={true}
                                        />
                                    </td>
                                    <td valign='bottom' style={this.state.PA_Update ? { display: 'table-cell' } : { display: 'none' }}>
                                        &nbsp;&nbsp;
                                        <button
                                            type='button'
                                            className='btn btn-primary'
                                            // style={{ width: 85 }}
                                            onClick={() => {
                                                let profile = targetProfile;
                                                const cachedProfile = this.state.CachedTargetProfile;
                                                profile[ItemProperty.Email] = cachedProfile[ItemProperty.Email];
                                                this.setState({
                                                    TargetProfile: profile,
                                                    ToggleRevealTargetPassword: true,
                                                    ToggleRevealTargetEmailEdit: false,
                                                })
                                            }}
                                        >Reveal</button>
                                        &nbsp;&nbsp;
                                        <button
                                            type='button'
                                            className='btn btn-warning'
                                            // style={{ width: 85 }}
                                            onClick={() => this.SendPasswordToTeacherByEmailViaApi()}
                                        >Email Password</button>
                                    </td>
                                </tr>
                        }
                    </tbody >
                </table >
                {
                    this.state.ToggleRevealTargetPassword === false ? <span>&nbsp;&nbsp;</span> :
                        <span style={{ color: 'gray', fontSize: 14, paddingLeft: 15 }}>({Locale("password-min-req", this.state.locale)})</span>
                }
            </div >);
        }

        //2024.07.26 - Zach said not needed.
        //#region old code before 2024.07.26
        // //Dialing Code & Phone Number.
        // const dialingCode = CheckObjectStringEmpty(targetProfile, ItemProperty.DialingCode, '+60');
        // const findIndex_dialingCode = this.state.DialingCodeOptions.findIndex(x => String(x.value) === dialingCode);
        // const contactNumber = CheckObjectStringEmpty(targetProfile, ItemProperty.ContactNumber);
        // const cn_ele = CheckNullValue(contactNumber) === null ? null : <span className="sample-text">(Preview: {contactNumber})</span>;
        // infoComponents.push(<div key='contact-number' className="form-group">
        //     <label>{Locale("contact-number", this.state.locale)} {cn_ele}</label>
        //     <div className="row">
        //         <div className="col-5" style={{ paddingRight: 0 }}>
        //             <ReactSelect
        //                 id='dialing-code'
        //                 name='DialingCodes'
        //                 // className="basic-multi-select"
        //                 classNamePrefix='select'
        //                 // isMulti
        //                 options={this.state.DialingCodeOptions}
        //                 // defaultValue={'+60'}
        //                 defaultValue={findIndex_dialingCode < 0 ? '' : this.state.DialingCodeOptions[findIndex_dialingCode]}
        //                 onChange={(e) => this.UpdateItemValues(ItemProperty.DialingCode, e.value)}
        //                 theme={theme => ({
        //                     ...theme,
        //                     width: 'max-content',
        //                     colors: {
        //                         ...theme.colors,
        //                         neutral50: 'black',  // placeholder color
        //                     }
        //                 })}
        //                 style={{ width: 50 }}
        //             />
        //         </div>
        //         <div className="col">
        //             <input
        //                 name="phoneNumber"
        //                 className="form-control"
        //                 type="text"
        //                 value={CheckObjectStringEmpty(targetProfile, ItemProperty.PhoneNumber)}
        //                 placeholder={CheckObjectStringEmpty(targetProfile, ItemProperty.PhoneNumber)}
        //                 onChange={(e) => this.UpdateItemValues(ItemProperty.PhoneNumber, e.target.value)}
        //             />
        //         </div>
        //     </div>
        // </div>);
        //#endregion

        topLeftComponents.push(<div key='profile-details' className="form-group" >
            <label>Information</label>
            <div className="setting-box-border" style={{ display: 'grid', gap: 10, height: 435, overflowY: 'auto' }}>
                {infoComponents}
            </div>
        </div>);

        //#endregion

        //#region === Right Components ===

        //Subjects (DropDown Select).
        // rightComponents.push(<div key='profile-subjects' className="form-group">
        //     <label>Subject(s) In-charge</label>
        //     <ReactSelect
        //         id='subjects'
        //         name='Subjects'
        //         className="basic-multi-select"
        //         classNamePrefix='select'
        //         isMulti
        //         closeMenuOnSelect={false}
        //         options={this.state.SubjectOptions}
        //         defaultValue={this.GetSelectedSubjects()}
        //         onChange={(option) => this.UpdateItemValues(ItemProperty.Subjects, option)}
        //         theme={theme => ({
        //             ...theme,
        //             width: 'max-content',
        //             colors: {
        //                 ...theme.colors,
        //                 neutral50: 'black',  // placeholder color
        //             }
        //         })}
        //     />
        // </div>);

        //Subjects (Custom Select Ui).
        //2024.11.25
        topRightComponents.push(<Tabs defaultActiveKey={1} id={'tabs-report-detail'}>
            <Tab eventKey={0} title='Group(s) In-charge' className="base-tab tab-group-incharge">
                <div className="setting-box-border" style={{ height: 435, overflowY: 'auto' }}>
                    <div className="form-check setting-checkbox" onChange={() => this.UpdateItemValues(ItemProperty.Groups, 'select-all')}>
                        <input className="form-check-input" type="checkbox" id={`checkbox-group-0`}
                            readOnly={true}
                            checked={Array.isArray(Groups) && Array.isArray(this.state.GroupOptions) ? Groups.length === this.state.GroupOptions.length : false}
                        />
                        <label className="form-check-label" htmlFor={`checkbox-group-0`} style={{ cursor: 'pointer' }}
                        >Select All</label>
                    </div>
                    {
                        Array.isArray(this.state.GroupOptions) ?
                            this.state.GroupOptions.map((option, key) => {
                                return <div className="form-check setting-checkbox" onChange={() => this.UpdateItemValues(ItemProperty.Groups, option)}>
                                    <input className="form-check-input" type="checkbox" id={`checkbox-group-${key + 1}`}
                                        readOnly={true}
                                        checked={Array.isArray(Groups) ? (Groups.findIndex(x => Number(x) === Number(option.id)) > -1) : false}
                                    />
                                    <label className="form-check-label" htmlFor={`checkbox-group-${key + 1}`} style={{ cursor: 'pointer' }}
                                    >{CheckObjectStringEmpty(option, 'label', `-${key}-`)}</label>
                                </div>;
                            })
                            : null
                    }
                </div>
            </Tab>
            <Tab eventKey={1} title='Subject(s) In-charge (General)' className="base-tab tab-subject-incharge">
                <div className="setting-box-border" style={{ height: 435, overflowY: 'auto' }}>
                    <div className="form-check setting-checkbox" onChange={() => this.UpdateItemValues(ItemProperty.Subjects, 'select-all')}>
                        <input className="form-check-input" type="checkbox" id={`checkbox-subject-0`}
                            readOnly={true}
                            checked={Array.isArray(Subjects) && Array.isArray(this.state.SubjectOptions) ? Subjects.length === this.state.SubjectOptions.length : false}
                        />
                        <label className="form-check-label" htmlFor={`checkbox-subject-0`} style={{ cursor: 'pointer' }}
                        >Select All</label>
                    </div>
                    {
                        Array.isArray(this.state.SubjectOptions) ?
                            this.state.SubjectOptions.map((option, key) => {
                                return <div className="form-check setting-checkbox" onChange={() => this.UpdateItemValues(ItemProperty.Subjects, option)}>
                                    <input className="form-check-input" type="checkbox" id={`checkbox-subject-${key + 1}`}
                                        readOnly={true}
                                        checked={Array.isArray(Subjects) ? (Subjects.findIndex(x => Number(x) === Number(option.id)) > -1) : false}
                                    />
                                    <label className="form-check-label" htmlFor={`checkbox-subject-${key + 1}`} style={{ cursor: 'pointer' }}
                                    >{CheckObjectStringEmpty(option, 'label', `-${key}-`)}</label>
                                </div>;
                            })
                            : null
                    }
                </div>
            </Tab>
        </Tabs>);
        // topRightComponents.push(<div key='profile-group-subjects' className="form-group">
        //     <label>Subject(s) In-charge (General)</label>
        //     <div className="setting-box-border" style={{ height: 435, overflowY: 'auto' }}>
        //         <div className="form-check setting-checkbox" onChange={() => this.UpdateItemValues(ItemProperty.Subjects, 'select-all')}>
        //             <input className="form-check-input" type="checkbox" id={`checkbox-subject-0`}
        //                 readOnly={true}
        //                 checked={Array.isArray(Subjects) && Array.isArray(this.state.SubjectOptions) ? Subjects.length === this.state.SubjectOptions.length : false}
        //             />
        //             <label className="form-check-label" htmlFor={`checkbox-subject-0`} style={{ cursor: 'pointer' }}
        //             >Select All</label>
        //         </div>
        //         {
        //             Array.isArray(this.state.SubjectOptions) ?
        //                 this.state.SubjectOptions.map((option, key) => {
        //                     return <div className="form-check setting-checkbox" onChange={() => this.UpdateItemValues(ItemProperty.Subjects, option)}>
        //                         <input className="form-check-input" type="checkbox" id={`checkbox-subject-${key + 1}`}
        //                             readOnly={true}
        //                             checked={Array.isArray(Subjects) ? (Subjects.findIndex(x => Number(x) === Number(option.id)) > -1) : false}
        //                         />
        //                         <label className="form-check-label" htmlFor={`checkbox-subject-${key + 1}`} style={{ cursor: 'pointer' }}
        //                         >{CheckObjectStringEmpty(option, 'label', `-${key}-`)}</label>
        //                     </div>;
        //                 })
        //                 : null
        //         }
        //     </div>
        // </div>);

        //Classrooms (DropDown Select).
        // rightComponents.push(<div key='profile-classrooms' className="form-group">
        //     <label>Classroom(s) In-charge</label>
        //     <ReactSelect
        //         id='classrooms'
        //         name='Classrooms'
        //         className="basic-multi-select"
        //         classNamePrefix='select'
        //         isMulti
        //         closeMenuOnSelect={false}
        //         options={this.state.ClassroomOptions}
        //         defaultValue={this.GetSelectedClassrooms()}
        //         onChange={(option) => this.UpdateItemValues(ItemProperty.Classrooms, option)}
        //         theme={theme => ({
        //             ...theme,
        //             width: 'max-content',
        //             colors: {
        //                 ...theme.colors,
        //                 neutral50: 'black',  // placeholder color
        //             }
        //         })}
        //     />
        // </div>);

        //#endregion

        //#endregion

        let middleLeftComponents = [];
        let middleRightComponents = [];
        const highlightedClassroom = this.state.HighlightedClassroom;
        //#region === Middle ===

        //#region === Left Components ===
        //2024.09.26
        let classroomNameList = null;
        if (Array.isArray(Classrooms)) {
            const classroomNames = this.state.ClassroomOptions.map((option, key) => {
                return Classrooms.find(x => String(x) === String(option.value))
            }).filter(x => CheckNullValue(x) !== null).join(', ');
            classroomNameList = <span style={{ color: 'blue', fontStyle: 'italic' }}>{`(${classroomNames})`}</span>;
        }
        //Classrooms (Custom Select Ui).
        middleLeftComponents.push(<div key='profile-classrooms' className="form-group">
            <label>Classroom(s) In-charge {classroomNameList}</label>
            <div className="setting-box-border" style={{ height: 385, overflowY: 'auto' }}>
                {
                    Array.isArray(this.state.ClassroomOptions) ?
                        this.state.ClassroomOptions.map((option, key) => {
                            const highlightClassName = highlightedClassroom === null ? ''
                                : (String(highlightedClassroom.value) === String(option.value) ? 'checkbox-highlighted' : '');
                            return <div className="form-check setting-checkbox" >
                                <input className="form-check-input" type="checkbox" value="" id={`checkbox-classroom-${key + 1}`}
                                    readOnly={true}
                                    // checked={Array.isArray(Classrooms) ? (Classrooms.findIndex(x => String(x) === String(option.value)) > -1) : false}
                                    checked={Array.isArray(ClassroomIds) ? (ClassroomIds.findIndex(x => Number(x) === Number(option.id)) > -1) : false}
                                    onClick={() => this.UpdateItemValues(ItemProperty.Classrooms, option)}
                                />
                                <label className={`form-check-label ${highlightClassName}`}
                                    // htmlFor={`checkbox-classroom-${key + 1}`}
                                    style={{ cursor: 'pointer' }}
                                    onClick={async () => {
                                        this.setState({
                                            HighlightedClassroom: null,
                                        });
                                        await Delay(0);
                                        this.setState({
                                            HighlightedClassroom: option,
                                        }, () => {
                                            if (this.state.isDevMode)
                                                console.log('Hightlighted Classroom = ' + option.value);
                                        })
                                    }}
                                >{CheckObjectStringEmpty(option, 'value', `-${key}-`)}</label>
                            </div>;
                        })
                        : null
                }
            </div>
        </div>);
        //#endregion

        //#region === Right Components ===
        if (highlightedClassroom !== null && Array.isArray(Classrooms) && Array.isArray(ClassroomSubjects)) {
            const classroomName = String(highlightedClassroom.value);
            const classroom_index = ClassroomSubjects.findIndex(x => String(x.Classroom) === classroomName);
            if (classroom_index > -1) {
                const c_subjects = ClassroomSubjects[classroom_index]['Subjects'];
                const filteredSubjectOptions = this.state.SubjectOptions.filter(x => targetProfile[ItemProperty.Subjects].some(subject => Number(x.id) === Number(subject)));   //2025.01.24
                middleRightComponents.push(<div key='profile-classroom-subjects' className="form-group">
                    <label>{`Subject(s) In-charge (Classroom) (${classroomName})`}</label>
                    <div className="setting-box-border" style={{ height: 385, overflowY: 'auto' }}>
                        <div className="form-check setting-checkbox" onChange={() => this.UpdateItemValues(ItemProperty.ClassroomSubjects, 'select-all', highlightedClassroom)}>
                            <input className="form-check-input" type="checkbox" id={`checkbox-classroom-subject-0`}
                                readOnly={true}
                                // checked={Array.isArray(c_subjects) && Array.isArray(this.state.SubjectOptions) ? c_subjects.length === this.state.SubjectOptions.length : false}
                                checked={Array.isArray(c_subjects) && Array.isArray(this.state.SubjectOptions) ? c_subjects.length === filteredSubjectOptions.length : false}   //2025.01.24
                            />
                            <label className="form-check-label" htmlFor={`checkbox-classroom-subject-0`} style={{ cursor: 'pointer' }}
                            >Select All</label>
                        </div>
                        {
                            Array.isArray(this.state.SubjectOptions) ?
                                this.state.SubjectOptions.map((option, key) => {
                                    // 2024.07.26 - added filter by Subjects In-Charge (General).
                                    if (Subjects.findIndex(x => Number(x) === Number(option.id)) > -1) {
                                        return <div className="form-check setting-checkbox" onChange={() => this.UpdateItemValues(ItemProperty.ClassroomSubjects, option, highlightedClassroom)}>
                                            <input className="form-check-input" type="checkbox" value="" id={`checkbox-classroom-subject-${key + 1}`}
                                                readOnly={true}
                                                checked={Array.isArray(c_subjects) ? (c_subjects.findIndex(x => Number(x) === Number(option.id)) > -1) : false}
                                            />
                                            <label className="form-check-label" htmlFor={`checkbox-classroom-subject-${key + 1}`} style={{ cursor: 'pointer' }}
                                            >{CheckObjectStringEmpty(option, 'label', `-${key}-`)}</label>
                                        </div>;
                                    }
                                    return null;
                                })
                                : null
                        }
                        {
                            // Array.isArray(this.state.SubjectOptions) ?
                            //     this.state.SubjectOptions.map((option, key) => {
                            //         return <div className="form-check setting-checkbox" onChange={() => this.UpdateItemValues(ItemProperty.ClassroomSubjects, option, highlightedClassroom)}>
                            //             <input className="form-check-input" type="checkbox" value="" id={`checkbox-classroom-subject-${key + 1}`}
                            //                 readOnly={true}
                            //                 defaultChecked={Array.isArray(subjects) ? (subjects.findIndex(x => Number(x) === Number(option.id)) > -1) : false}
                            //             />
                            //             <label className="form-check-label" htmlFor={`checkbox-classroom-subject-${key + 1}`} style={{ cursor: 'pointer' }}
                            //             >{CheckObjectStringEmpty(option, 'label', `-${key}-`)}</label>
                            //         </div>;
                            //     })
                            //     : null
                        }
                    </div>
                </div>);
            }
            else {
                middleRightComponents.push(<div key='profile-classroom-subjects' className="form-group">
                    <label>{`Subject(s) In-charge (Classroom) (${classroomName})`}</label>
                    <div className="setting-box-border">
                        <div style={{ width: '100%', textAlign: 'center' }}
                        >- classroom is not checked -</div>
                    </div>
                </div>);
            }
        }
        else {
            middleRightComponents.push(<div key='profile-classroom-subjects' className="form-group">
                <label>{`Subject(s) In-charge (Classroom)`}</label>
                <div className="setting-box-border">
                    <div style={{ width: '100%', textAlign: 'center' }}
                    >- select a classroom -</div>
                </div>
            </div>);
        }
        //#endregion

        //#endregion

        //return.
        return (<>
            <div className="row">
                <div className="col">{topLeftComponents}</div>
                <div className="col">{topRightComponents}</div>
            </div>
            <div className="row" style={{ padding: 19 }}>
                <div className="col setting-box-border" style={{ paddingTop: 5 }}>
                    <div className="row">
                        <div className="col">{middleLeftComponents}</div>
                        <div className="col">{middleRightComponents}</div>
                    </div>
                </div>
            </div>
            <div className="row" style={{ paddingLeft: 6, paddingBottom: 10 }}>
                <Accordion alwaysOpen className="accordian-border">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header onClick={() => this.ShowPermissionDetail()}><b>{this.state.PermissionDetail_Toggle ? 'Hide' : 'Show'} Permissions</b></Accordion.Header>
                        <Accordion.Body>
                            {
                                this.state.PermissionDetail_Toggle ?
                                    <div className="row" style={{ padding: 19, paddingTop: 0, paddingLeft: 6, }}>
                                        <div className="col">
                                            <div className="setting-box-border" style={{ paddingTop: 10 }}>
                                                {this.PermissionCheckboxesComponent(targetProfile)}
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion >
            </div >
            {/* <div className="row" style={{ paddingLeft: 6, paddingBottom: 10 }}>
                <div className="col">
                    <Button type="button" variant="primary"
                        onClick={() => this.ShowPermissionDetail()}
                    >{this.state.PermissionDetail_Toggle ? 'Hide' : 'Show'} Permissions</Button>
                </div>
            </div>
            {
                this.state.PermissionDetail_Toggle ?
                    <div className="row" style={{ padding: 19, paddingTop: 0, paddingLeft: 6, }}>
                        <div className="col">
                            <div className="setting-box-border" style={{ paddingTop: 10 }}>
                                {this.PermissionCheckboxesComponent(targetProfile)}
                            </div>
                        </div>
                    </div>
                    : null
            } */}
        </>);
    }
    UpdateItemValues = async (itemProperty = ItemProperty.None, value = null, extra = null) => {
        let targetProfile = this.state.TargetProfile;
        switch (itemProperty) {
            case ItemProperty.Name: targetProfile[itemProperty] = String(value); break;
            case ItemProperty.Email: targetProfile[itemProperty] = CheckStringEmpty(value); break;
            case ItemProperty.RawPassword: targetProfile[itemProperty] = CheckStringEmpty(value); break;
            case ItemProperty.DialingCode:
                targetProfile[itemProperty] = CheckStringEmpty(value);
                targetProfile[ItemProperty.ContactNumber] = CheckObjectStringEmpty(targetProfile, ItemProperty.DialingCode) + CheckObjectStringEmpty(targetProfile, ItemProperty.PhoneNumber);
                break;
            case ItemProperty.PhoneNumber:
                // const phoneNumber = Number(value);
                // console.log('phoneNumber !== NaN > ' + String(Number.isNaN(phoneNumber)) + ' | ' + String(typeof phoneNumber));
                if (Number.isNaN(Number(value)) === false) {
                    let phoneNumber = CheckStringEmpty(value);
                    if (phoneNumber.length > 0 && phoneNumber.charAt(0) === '0')
                        phoneNumber = phoneNumber.length === 1 ? '' : phoneNumber.slice(1);
                    targetProfile[itemProperty] = CheckStringEmpty(phoneNumber);
                    targetProfile[ItemProperty.ContactNumber] = CheckObjectStringEmpty(targetProfile, ItemProperty.DialingCode) + CheckObjectStringEmpty(targetProfile, ItemProperty.PhoneNumber);
                }
                break;
            case ItemProperty.Groups:
                //2024.11.25
                let tmp_groups = targetProfile[itemProperty];
                if (Array.isArray(tmp_groups) === false)
                    tmp_groups = [];
                if (typeof value === 'string' && value === 'select-all') {
                    if (Array.isArray(this.state.GroupOptions) && tmp_groups.length < this.state.GroupOptions.length) {
                        tmp_groups = [];
                        this.state.GroupOptions.map((option, key) => {
                            return tmp_groups.push(option.id);
                        });
                        tmp_groups.sort((a, b) => a - b);
                    }
                    else {
                        tmp_groups = [];      //reset.
                    }
                }
                else {
                    const index = tmp_groups.findIndex(x => Number(x) === Number(value.id));
                    if (index < 0)
                        tmp_groups.push(value.id);
                    else
                        tmp_groups.splice(index, 1);
                    tmp_groups.sort((a, b) => a - b);
                }
                targetProfile[itemProperty] = tmp_groups;
                break;
            case ItemProperty.Subjects:
                let tmp_subjects = JSON.parse(JSON.stringify(targetProfile[itemProperty]))
                if (Array.isArray(tmp_subjects) === false)
                    tmp_subjects = [];
                if (typeof value === 'string' && value === 'select-all') {
                    //2024.09.26
                    if (Array.isArray(this.state.SubjectOptions) && tmp_subjects.length < this.state.SubjectOptions.length) {
                        tmp_subjects = [];
                        this.state.SubjectOptions.map((option, key) => {
                            return tmp_subjects.push(option.id);
                        });
                        tmp_subjects.sort((a, b) => a - b);
                    }
                    else {
                        tmp_subjects = [];      //reset.
                    }
                }
                else {
                    const index = tmp_subjects.findIndex(x => Number(x) === Number(value.id));
                    if (index < 0)
                        tmp_subjects.push(value.id);
                    else
                        tmp_subjects.splice(index, 1);
                    tmp_subjects.sort((a, b) => a - b);
                }
                targetProfile[itemProperty] = tmp_subjects;
                // console.log('tmp_subjects =\n' + JSON.stringify(tmp_subjects));
                //filter during update.
                //2024.08.08 - if general subject not selected = oso not selected classroom subject.
                let tmp_cs = JSON.parse(JSON.stringify(targetProfile[ItemProperty.ClassroomSubjects]));
                if (Array.isArray(tmp_cs) && Array.isArray(this.state.SubjectOptions)) {
                    for (let key = 0; key < tmp_cs.length; key++) {
                        let cs_subjects = tmp_cs[key]['Subjects'];
                        if (Array.isArray(cs_subjects) && tmp_subjects.length > 0) {
                            for (let css = 0; css < cs_subjects.length; css++) {
                                const index = tmp_subjects.findIndex(x => Number(x) === Number(cs_subjects[css]));
                                if (index < 0) {
                                    cs_subjects.splice(css, 1);
                                    tmp_cs[key]['Subjects'] = cs_subjects;
                                }
                            }
                        }
                        else {
                            tmp_cs[key]['Subjects'] = [];
                        }
                    }
                }
                targetProfile[ItemProperty.ClassroomSubjects] = tmp_cs;
                // console.log('tmp_cs =\n' + JSON.stringify(tmp_cs));
                break;
            case ItemProperty.Classrooms:
                // let tmp_subjects_cs = [...targetProfile[ItemProperty.Subjects]];
                let tmp_classroomSubjects = targetProfile[ItemProperty.ClassroomSubjects];
                let tmp_classrooms = targetProfile[itemProperty];
                let tmp_classroomIds = targetProfile[ItemProperty.ClassroomIds];    //2025.01.24
                if (Array.isArray(tmp_classrooms) && Array.isArray(tmp_classrooms)) {
                    //update.
                    const index = tmp_classroomIds.findIndex(x => Number(x) === Number(value.id));
                    if (index < 0) {
                        //select.
                        tmp_classroomIds.push(value.id);
                        const c_index = tmp_classrooms.findIndex(x => String(x) === String(value.value));
                        if (c_index < 0)
                            tmp_classrooms.push(value.value);
                        const cs_index = tmp_classroomSubjects.findIndex(x => String(x.Classroom) === String(value.value));
                        if (cs_index < 0)
                            tmp_classroomSubjects.push({ Classroom: value.value, Subjects: [] });
                    }
                    else {
                        //deselect.
                        tmp_classroomIds.splice(index, 1);
                        const c_index = tmp_classrooms.findIndex(x => String(x) === String(value.value));
                        if (c_index > -1)
                            tmp_classrooms.splice(c_index, 1);
                        const cs_index = tmp_classroomSubjects.findIndex(x => String(x.Classroom) === String(value.value));
                        if (cs_index > -1)
                            tmp_classroomSubjects.splice(cs_index, 1);
                    }
                    // const index = tmp_classrooms.findIndex(x => String(x) === String(value.value));
                    // if (index < 0) {
                    //     //select.
                    //     tmp_classrooms.push(value.value);
                    //     tmp_classroomSubjects.push({ Classroom: value.value, Subjects: [] });
                    // }
                    // else {
                    //     //deselect.
                    //     tmp_classrooms.splice(index, 1);
                    //     const cs_index = tmp_classroomSubjects.findIndex(x => String(x.Classroom) === String(value.value));
                    //     if (cs_index > -1)
                    //         tmp_classroomSubjects.splice(cs_index, 1);
                    // }
                    //sort.
                    tmp_classroomIds.sort((a, b) => a - b);
                    tmp_classrooms.sort((a, b) => {
                        if (a < b) { return -1; }
                        if (a > b) { return 1; }
                        return 0;
                    });
                    tmp_classroomSubjects.sort((a, b) => {
                        if (a.Classroom < b.Classroom) { return -1; }
                        if (a.Classroom > b.Classroom) { return 1; }
                        return 0;
                    });
                    // ConsoleLog(`${itemProperty} \n ${JSON.stringify(tmp_classrooms)}`);
                    // ConsoleLog(`${itemProperty} \n ${JSON.stringify(tmp_classroomSubjects)}`);
                }
                targetProfile[ItemProperty.ClassroomIds] = tmp_classroomIds;
                targetProfile[itemProperty] = tmp_classrooms;
                targetProfile[ItemProperty.ClassroomSubjects] = tmp_classroomSubjects;
                break;
            case ItemProperty.ClassroomSubjects:
                if (extra !== null) {
                    const highlightedClassroom = extra;
                    const classrooms = targetProfile[itemProperty];
                    if (Array.isArray(classrooms)) {
                        const highlightedClassroom_index = classrooms.findIndex(x => String(x.Classroom) === String(highlightedClassroom.value));
                        if (highlightedClassroom_index > -1) {
                            let tmp_subjects = targetProfile[itemProperty][highlightedClassroom_index]['Subjects'];
                            if (Array.isArray(tmp_subjects) === false)
                                tmp_subjects = [];
                            if (typeof value === 'string' && value === 'select-all') {
                                //2025.01.24
                                const filteredSubjectOptions = this.state.SubjectOptions.filter(x => targetProfile[ItemProperty.Subjects].some(subject => Number(x.id) === Number(subject)));
                                if (Array.isArray(this.state.SubjectOptions) && tmp_subjects.length < filteredSubjectOptions.length) {
                                    //2024.09.26
                                    // if (Array.isArray(this.state.SubjectOptions) && tmp_subjects.length < this.state.SubjectOptions.length) {
                                    tmp_subjects = [];
                                    this.state.SubjectOptions.map((option, key) => {
                                        if (targetProfile[ItemProperty.Subjects].findIndex(x => Number(x) === Number(option.id)) > -1)  //2025.01.24
                                            tmp_subjects.push(option.id);
                                        return null;
                                    });
                                    tmp_subjects.sort((a, b) => a - b);
                                }
                                else {
                                    tmp_subjects = [];      //reset.
                                }
                            }
                            else {
                                const index = tmp_subjects.findIndex(x => Number(x) === Number(value.id));
                                if (index < 0)
                                    tmp_subjects.push(value.id);
                                else
                                    tmp_subjects.splice(index, 1);
                                tmp_subjects.sort((a, b) => a - b);
                            }
                            // targetProfile[itemProperty][highlightedClassroom_index] = { Classroom: String(highlightedClassroom.value), Subjects: tmp_subjects };
                            targetProfile[itemProperty][highlightedClassroom_index]['Subjects'] = tmp_subjects;
                        }
                    }
                }
                break;
            case ItemProperty.TeacherType: targetProfile[itemProperty] = CheckStringEmpty(value); break;
            default: break;
        }
        if (this.state.isDevMode) {
            if (extra === null)
                console.log(`UpdateItemValues (${itemProperty}) = ${JSON.stringify(value)} \n${JSON.stringify(targetProfile)}`);
            else
                console.log(`UpdateItemValues (${itemProperty}) = ${JSON.stringify(value)} \n extra = ${JSON.stringify(extra)}\n${JSON.stringify(targetProfile)}`);
        }
        await Delay(200);
        this.setState({
            TargetProfile: targetProfile,
        });
    }
    ShowPermissionDetail = () => {
        this.setState({
            PermissionDetail_Toggle: !this.state.PermissionDetail_Toggle,
        });
    }
    GetCustomPermissions = async (targetProfile = null) => {
        if (targetProfile === null)
            return this.state.TargetProfile;

        let profileCustomPermissions =
            Array.isArray(targetProfile[ItemProperty.CustomPermissions]) ?
                targetProfile[ItemProperty.CustomPermissions]
                :
                CheckNullValue(targetProfile[ItemProperty.CustomPermissions]) === null ?
                    null
                    :
                    JSON.parse(targetProfile[ItemProperty.CustomPermissions]);
        if (Array.isArray(profileCustomPermissions) === false)
            profileCustomPermissions = [];
        // console.log('GetCustomPermissions (profileCustomPermissions) (before) \n' + JSON.stringify(profileCustomPermissions));

        let updatedCustomPermissions = [];
        BasicRolePermissions.map((section, skey) => {
            const sectionName = Object.keys(section)[0];
            let t_section = {};
            const findIndex_section = profileCustomPermissions.findIndex(x => Object.keys(x).findIndex(y => String(y) === String(sectionName)) > -1);
            if (findIndex_section > -1) {
                // console.log('GetCustomPermissions (profileCustomPermissions) (middle) (section) \n' + JSON.stringify(profileCustomPermissions[findIndex_section][sectionName]));
                const source_ActionKeys = Object.keys(profileCustomPermissions[findIndex_section][sectionName]);
                // console.log('GetCustomPermissions (profileCustomPermissions) (middle) (source_ActionKeys) \n' + JSON.stringify(source_ActionKeys));
                let actions = {};
                Object.keys(section[sectionName]).map((action, akey) => {
                    const findIndex_action = source_ActionKeys.findIndex(x => String(x) === String(action));
                    if (findIndex_action > -1)
                        actions[action] = CheckBoolean(profileCustomPermissions[findIndex_section][sectionName][action]);
                    else
                        actions[action] = false;
                    // console.log('GetCustomPermissions (profileCustomPermissions) (middle) (action) ' + (findIndex_action < 0 ? 'not found' : 'found') + ' ' + action + ' = ' + String(actions[action]));
                    return null;
                });
                t_section[sectionName] = actions;
                updatedCustomPermissions.push(t_section);
            }
            else {
                t_section[sectionName] = DefaultCustomPermissionModel_Restricted;
                updatedCustomPermissions.push(t_section);
            }
            return null;
        });
        targetProfile[ItemProperty.CustomPermissions] = updatedCustomPermissions;
        this.setState({
            TargetProfile: targetProfile,
        });
        await Delay(0);
        // console.log('GetCustomPermissions (updatedCustomPermissions) (after) \n' + JSON.stringify(updatedCustomPermissions));
        return targetProfile;

        // const permissions = useGlobal.getState().user.CustomPermissions;
        // permissions.map((perm, key) => {
        //     const _section = Object.keys(perm)[0];
        //     const _type = Object.values(Object.values(perm))[0];

        //     let t_type = null;
        //     if (Array.isArray(profileCustomPermissions)) {
        //         for (let tc = 0; tc < profileCustomPermissions.length; tc++) {
        //             const tmp_section = Object.keys(profileCustomPermissions[tc])[0];
        //             const tmp_type = Object.values(Object.values(profileCustomPermissions[tc]))[0];
        //             if (tmp_section === _section) {
        //                 t_type = tmp_type;
        //                 break;
        //             }
        //         }
        //     }
        //     // console.log('t_type !== null > ' + String(t_type !== null));

        //     const _typeKeys = Object.keys(_type);
        //     let type = {};
        //     _typeKeys.map((tk, k) => {
        //         if (t_type !== null)
        //             type[tk] = t_type[tk];   //follow fetched data.
        //         else
        //             // type[tk] = _type[tk];    //default.
        //             type[tk] = false;
        //         return null;
        //     });
        //     let section = {};
        //     section[_section] = type;

        //     tmp_customPermissions.push(section);
        //     return null;
        // });
        // // console.log('teacher (profileCustomPermissions) \n' + JSON.stringify(profileCustomPermissions));
        // // console.log('teacher (tmp_customPermissions) \n' + JSON.stringify(tmp_customPermissions));
        // teacher[ItemProperty.CustomPermissions] = tmp_customPermissions;
        // return teacher;
    }
    PermissionCheckboxesComponent = (targetProfile = null) => {

        let components = [];

        const profileWithCustomPermissions = targetProfile === null ? this.state.TargetProfile : targetProfile;
        // const profileWithCustomPermissions = this.GetCustomPermissions();
        // console.log('teacher (Permiss    ionCheckboxesComponent) \n' + JSON.stringify(profileWithCustomPermissions[ItemProperty.CustomPermissions]));

        if (Array.isArray(profileWithCustomPermissions[ItemProperty.CustomPermissions]) === false)
            return null;

        const ignoreCategory = [
            LayoutScreen.ManageTeacherProfile,
            LayoutScreen.ManageSetting, LayoutScreen.ManageCustomGroup, LayoutScreen.ManageEducationStage,
            LayoutScreen.ManageAuthor, LayoutScreen.ManageOrganizer,

            //others.
            LayoutScreen.ManageEvent, LayoutScreen.LiveQuizRankingList, LayoutScreen.EventParticipationReportLite,
        ];

        profileWithCustomPermissions[ItemProperty.CustomPermissions].map((data, key) => {
            const permissions = Object.entries(data).filter(x => x !== null);
            permissions.map((permission, pkey) => {

                // console.log(JSON.stringify(permission));

                const title = permission[0];
                const actions = Object.entries(permission[1]);
                // const subCategories = permission[1];

                // console.log(JSON.stringify(permission[0]));
                // console.log(JSON.stringify(permission[1]));
                // console.log(JSON.stringify(subCategories));

                if (ignoreCategory.findIndex(x => x === title) < 0) {

                    let totalChecked = 0;
                    let subComponents = [];
                    for (let s = 0; s < actions.length; s++) {
                        const actionKey = String(actions[s][0]);
                        const checked = CheckBoolean(actions[s][1]);
                        const id = profileWithCustomPermissions.Id + '_' + title + '_' + actionKey;
                        // console.log(`PermissionCheckboxesComponent (${title}) | ${skey} ${String(checked)} | ${JSON.stringify(subCategories[s])}`);
                        subComponents.push(<dd style={{ display: 'inline-grid', padding: 5 }}>
                            <input type='checkbox' id={id} name={id}
                                readOnly={true}
                                checked={checked}
                                style={{ cursor: 'pointer' }}
                                onChange={() => this.HandleEditPermission({ id: id, name: id, value: '', checked: checked }, false)}
                            />
                            <label htmlFor={id} style={{ cursor: 'pointer' }}>{actionKey.charAt(0).toUpperCase() + actionKey.slice(1)}</label>
                        </dd>);
                        if (checked)
                            totalChecked += 1;
                    }
                    const selectAllId = profileWithCustomPermissions.Id + '_' + title + '_select-all';
                    const checkedAll = totalChecked === actions.length;
                    // subComponents.push(<dd style={{ display: 'inline-grid', }}>
                    //     <input type='checkbox' id={selectAllId} name={selectAllId}
                    //         checked={checkedAll}
                    //         style={{ cursor: 'pointer' }}
                    //         onChange={() => this.HandleEditPermission({ id: selectAllId, name: selectAllId, value: '', checked: checkedAll }, true)}
                    //         readOnly={true}
                    //     />
                    //     <label htmlFor={selectAllId} style={{ cursor: 'pointer' }}>Select All</label>
                    // </dd>);
                    const selectAllComponent = <div style={{ display: 'flex', gap: 3, color: 'blue' }}>
                        (<input type='checkbox' id={selectAllId} name={selectAllId}
                            checked={checkedAll}
                            style={{ cursor: 'pointer' }}
                            onChange={() => this.HandleEditPermission({ id: selectAllId, name: selectAllId, value: '', checked: checkedAll }, true)}
                            readOnly={true}
                        />
                        <label htmlFor={selectAllId} style={{ cursor: 'pointer' }}>Select All</label>)
                    </div>;
                    //assign.
                    components.push(<>
                        <dt style={{ display: 'flex', gap: 10 }}>{title.charAt(0).toUpperCase() + title.slice(1)} {selectAllComponent}</dt>
                        {subComponents}
                    </>);
                }
                return null;
            });
            return null;
        });
        return (<dl style={{ margin: 0, marginBottom: -18 }}>{components}</dl>);
        //     permissions = [...BasicRolePermissions];
        // console.log('PermissionCheckboxesComponent =\n' + JSON.stringify(permissions));

        // let components = [];

        // for (let p = 0; p < permissions.length; p++) {
        //     const permission = Object.entries(permissions[p]);
        //     // console.log(JSON.stringify(permission));

        //     const title = String(permission[0][0]);
        //     const subCategories = Object.entries(permission[0][1]);
        //     // console.log(JSON.stringify(subCategories));

        //     let totalChecked = 0;
        //     let subComponents = [];
        //     for (let s = 0; s < subCategories.length; s++) {
        //         // const entry = Object.entries(subCategories[s]);
        //         const skey = String(subCategories[s][0]);
        //         const checked = CheckBoolean(subCategories[s][1]) ? true : false;
        //         const id = teacher.id + '-' + title + '-' + skey;
        //         subComponents.push(<dd style={{ display: 'inline-grid', padding: 5 }}>
        //             <input type='checkbox' id={id} name={id}
        //                 checked={checked}
        //                 style={{ cursor: 'pointer' }}
        //                 onClick={() => this.HandleEditPermission()}
        //                 readOnly={true}
        //             />
        //             <label htmlFor={id} style={{ cursor: 'pointer' }}>{skey.charAt(0).toUpperCase() + skey.slice(1)}</label>
        //         </dd>);
        //         if (checked)
        //             totalChecked += 1;
        //     }
        //     const selectAllId = teacher.Id + '-' + title + '-select-all';
        //     subComponents.push(<dd style={{ display: 'inline-grid', }}>
        //         <input type='checkbox' id={selectAllId} name={selectAllId}
        //             checked={totalChecked === subCategories.length}
        //             style={{ cursor: 'pointer' }}
        //             onClick={e => this.HandleEditPermission(e, true)}
        //             readOnly={true}
        //         />
        //         <label htmlFor={selectAllId} style={{ cursor: 'pointer' }}>Select All</label>
        //     </dd>);

        //     components.push(<>
        //         <dt>{title.charAt(0).toUpperCase() + title.slice(1)}</dt>
        //         {subComponents}
        //     </>);
        // }
        // return (<dl style={{ margin: 0, marginBottom: -18 }}>{components}</dl>);
    }
    HandleEditPermission = async (ele = null, selectAll = false) => {
        let teacher = await this.GetCustomPermissions();
        if (teacher === null || ele === null)
            return null;

        // console.log(`HandleEditPermission \n${JSON.stringify(ele)}`);

        // const ele = e.currentTarget;
        if (this.state.isDevMode)
            console.log(`HandleEditPermission ${ele.id}, ${ele.name}, ${ele.value}, ${ele.checked}`);

        const splits = String(ele.id).split('_');
        // const section_index = Number(splits[0]);     //aka. index
        const section = splits[1];     //aka. title
        const action = selectAll ? 'select-all' : splits[2];    //aka. Read/Download/Upload/etc

        let section_index = -1;
        let action_index = -1;

        let permissions = JSON.parse(JSON.stringify(teacher[ItemProperty.CustomPermissions]));
        for (let p = 0; p < permissions.length; p++) {
            const permission = Object.entries(permissions[p]);
            const title = String(permission[0][0]);
            if (title === section) {
                // console.log('permission', JSON.stringify(permission));
                // console.log('title', title);
                section_index = p;
                const actions = Object.entries(permission[0][1]);
                for (let a = 0; a < actions.length; a++) {
                    const akey = String(actions[a][0]);
                    if (akey === action) {
                        action_index = a;
                        // console.log('skey', skey);
                        break;
                    }
                }
                break;
            }
        }
        if (this.state.isDevMode) {
            console.log('HandleEditPermission ' + section + ' ' + action + ' ' + (selectAll ? 'selectAll' : 'single') + ' ' + section_index + ' ' + action_index);
            // console.log('(before) ' + JSON.stringify(permissions[section_index]));
            // console.log('(before) ' + JSON.stringify(permissions[section_index][section]));
            // console.log('(before) ' + JSON.stringify(permissions[section_index][section][action]));
        }

        if (section_index > -1) {
            if (selectAll === false) {
                if (action_index > -1)
                    permissions[section_index][section][action] = !ele.checked;
            }
            else {
                const subCategories = Object.entries(permissions[section_index][section]);
                for (let s = 0; s < subCategories.length; s++) {
                    const skey = String(subCategories[s][0]);
                    permissions[section_index][section][skey] = !ele.checked;
                }
            }
        }
        // console.log('(after) ' + JSON.stringify(permissions[sectionIndex]));
        // console.log('(after) ' + JSON.stringify(permissions[sectionIndex][section]));
        // console.log('(after) ' + JSON.stringify(permissions[sectionIndex][section][action]));
        teacher[ItemProperty.CustomPermissions] = permissions;
        this.setState({
            TargetProfile: teacher,
        });
        await Delay(0);
        if (this.state.isDevMode)
            console.log(JSON.stringify(this.state.TargetProfile));
    }
    // UpdateProfilePassword = () => {
    //     const targetProfile = GetTempTarget();
    //     const rawPassword = CheckObjectNullValue(targetProfile, ItemProperty.RawPassword) === null ? '' : String(targetProfile[ItemProperty.RawPassword]);
    //     this.UpdateProfileViaAPI(rawPassword);
    // }
    //2023.09.28
    UpdateItem = () => {
        const sourceProfile = this.state.List[this.state.TargetItemIndex];
        const targetProfile = this.state.TargetProfile; //GetTempTarget();
        const rawPassword = CheckObjectNullValue(targetProfile, ItemProperty.RawPassword) === null
            // || CheckObjectNullValue(sourceProfile, ItemProperty.RawPassword) === null          //some profile has no rawPassword (not found in backup)
            ? '' :
            (CheckObjectStringEmpty(sourceProfile, ItemProperty.RawPassword) === CheckObjectStringEmpty(targetProfile, ItemProperty.RawPassword) ?
                '' : String(targetProfile[ItemProperty.RawPassword]));
        const newEmail = CheckObjectNullValue(targetProfile, ItemProperty.Email) === null
            || CheckObjectNullValue(sourceProfile, ItemProperty.Email) === null
            ? '' :
            (CheckObjectStringEmpty(sourceProfile, ItemProperty.Email) === CheckObjectStringEmpty(targetProfile, ItemProperty.Email) ?
                '' : CheckObjectStringEmpty(targetProfile, ItemProperty.Email));
        this.UpdateItemViaAPI(rawPassword, newEmail);
    }
    UpdateItemViaAPI = async (rawPassword = '', newEmail = '') => {

        const editItemState = this.state.EditItemState;

        //kw0.
        let keyword0 = '';
        if (editItemState === CommonState.New)
            keyword0 = 'saving';
        if (editItemState === CommonState.Delete)
            keyword0 = 'removing';
        else
            keyword0 = 'updating';

        // this.setState({ isLoading: true, });
        useAppService.getState().setModal('', keyword0 + ' profile...', null, AlertMode.Loading);
        window.scrollTo(0, 0);

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);

        let url = GlobalSetting.ApiUrl + 'Api/LearningCentre/Organizer/Teacher/Profile/Update'; //update.
        if (editItemState === CommonState.New)
            url = GlobalSetting.ApiUrl + 'Api/LearningCentre/Organizer/Teacher/Profile/Add';    //create new.
        else if (editItemState === CommonState.Delete)
            url = GlobalSetting.ApiUrl + 'Api/LearningCentre/Organizer/Teacher/Profile/Remove'; //delete.

        if (this.state.isDevMode)
            console.log(url);

        if (CheckNullValue(rawPassword) === null)
            rawPassword = '';

        let targetProfile = { ...this.state.TargetProfile };
        targetProfile[ItemProperty.Name] = CheckObjectStringEmpty(targetProfile, ItemProperty.Name);    //trim.
        targetProfile[ItemProperty.CustomPermissions] = JSON.stringify(targetProfile[ItemProperty.CustomPermissions]);

        //#region === populate proper format for api modal === start ===
        if (editItemState === CommonState.Delete) {
            targetProfile[ItemProperty.Groups] = [];
            targetProfile[ItemProperty.Subjects] = [];
            targetProfile[ItemProperty.Classrooms] = [];
            targetProfile[ItemProperty.ClassroomSubjects] = [];
        }
        else {
            //groups.
            let tmp_groups = [];
            const groupOptions = this.state.GroupOptions;
            const groups = targetProfile[ItemProperty.Groups];
            groupOptions.map((option, key) => {
                return tmp_groups.push({
                    Id: Number(option.id),
                    Name: String(option.value),
                    Selected: Array.isArray(groups) === false ? false : groups.findIndex(x => Number(x) === Number(option.id)) > -1,
                });
            });
            targetProfile[ItemProperty.Groups] = tmp_groups;    //replace.
            //subjects.
            let tmp_subjects = [];
            const subjectOptions = this.state.SubjectOptions;
            const subjects = targetProfile[ItemProperty.Subjects];
            subjectOptions.map((option, key) => {
                return tmp_subjects.push({
                    Id: Number(option.id),
                    Name: String(option.value),
                    Selected: Array.isArray(subjects) === false ? false : subjects.findIndex(x => Number(x) === Number(option.id)) > -1,
                });
            });
            targetProfile[ItemProperty.Subjects] = tmp_subjects;    //replace.
            //classrooms.
            let tmp_classrooms = [];
            const classroomOptions = this.state.ClassroomOptions;
            // const classrooms = targetProfile[ItemProperty.Classrooms];
            const classroomIds = targetProfile[ItemProperty.ClassroomIds];      //2025.01.24
            classroomOptions.map((option, key) => {
                return tmp_classrooms.push({
                    Id: Number(option.id),
                    Name: String(option.value),
                    // Selected: Array.isArray(classrooms) === false ? false : classrooms.findIndex(x => String(x) === String(option.value)) > -1,
                    Selected: Array.isArray(classroomIds) === false ? false : classroomIds.findIndex(x => Number(x) === Number(option.id)) > -1,    //2025.01.24
                });
            });
            targetProfile[ItemProperty.Classrooms] = tmp_classrooms;    //replace.
            //classroom subjects.
            let tmp_classroomSubjects = [];
            const classroomSubjects = targetProfile[ItemProperty.ClassroomSubjects];
            classroomOptions.map((option, key) => {
                let tmp_subjects = [];
                const findIndex_cs = Array.isArray(classroomSubjects) === false ? -1 : classroomSubjects.findIndex(x => String(x.Classroom) === String(option.value));
                if (findIndex_cs > -1) {
                    subjectOptions.map((option_s, key) => {
                        const cs_subjects = classroomSubjects[findIndex_cs]['Subjects'];

                        //2024.08.08
                        const findIndex_cs_subjects = cs_subjects.findIndex(x => Number(x) === Number(option_s.id));
                        let selected = Array.isArray(cs_subjects) === false ? false : findIndex_cs_subjects > -1;
                        //filter during output.
                        // if (selected) {
                        //     //if general subject not selected = oso not selected classroom subject.
                        //     const tmp_subjects_general = targetProfile[ItemProperty.Subjects];
                        //     const findIndex_subject = Array.isArray(tmp_subjects_general) ? tmp_subjects_general.findIndex(x => Number(x.Id) === Number(option_s.id)) : -1;
                        //     selected = CheckObjectBoolean(tmp_subjects_general[findIndex_subject], 'Selected');
                        // }

                        return tmp_subjects.push({
                            Id: Number(option_s.id),
                            Name: String(option_s.value),
                            // Selected: Array.isArray(cs_subjects) === false ? false : cs_subjects.findIndex(x => Number(x) === Number(option_s.id)) > -1,
                            Selected: selected,
                        });
                    });
                }
                return tmp_classroomSubjects.push({
                    Classroom: String(option.value),
                    Subjects: tmp_subjects,
                });
            });
            targetProfile[ItemProperty.ClassroomSubjects] = tmp_classroomSubjects;    //replace.
        }
        //#endregion === populate proper format for api modal === end ===

        // useAppService.getState().setModal('Sample Data', JSON.stringify(targetProfile));
        if (this.state.isDevMode)
            console.log(JSON.stringify(targetProfile));
        // useAppService.getState().setModal();
        // return null;

        let done = false;
        let errorMessage = '';
        let success = false;
        let _profile = null;
        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    secret: this.state.SecretKey,
                    authorId: authorId,
                    organizerId: organizerId,
                    rawPassword: rawPassword,
                    newEmail: newEmail,
                    authorTeacherModel: targetProfile,     //GetTempTarget(),    // this.state.List[this.state.TargetItemIndex],
                    removeQuizProfile: editItemState === CommonState.Delete,
                })
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('Response', 'api - teacher profile - update\n' + JSON.stringify(data));

                success = CheckObjectBoolean(data, 'success');
                if (success) {
                    if (data.data !== undefined && data.data !== null)
                        _profile = data.data;
                }
                else {
                    errorMessage = CheckObjectStringEmpty(data, 'message');
                    // if (this.state.isDevMode)
                    //     console.log('Error', 'api - profile - update (failed)\n' + JSON.stringify(data));
                }
                done = true;
            })
            .catch(error => {
                errorMessage = CheckObjectStringEmpty(error, 'message');
                done = true;
                if (this.state.isDevMode)
                    console.log('Error', 'api - profile - update (error)\n' + errorMessage);
            });
        await DelayUntil(() => done === true);

        // //update result to table.
        // if (_profile !== null) {
        //     let _List = this.state.List;
        //     this.setState({
        //         List: _List,
        //     });
        //     //init field values.
        //     // await this.LoadStudentProfileList_ViaApi();
        //     this.InitEditItemUiModal();
        // }

        //kw1.
        let keyword1 = '';
        if (CheckNullValue(rawPassword) !== null && CheckNullValue(newEmail) !== null)
            keyword1 = "'s email & password";
        else if (CheckNullValue(rawPassword) !== null)
            keyword1 = "'s password";
        else if (CheckNullValue(newEmail) !== null)
            keyword1 = "'s email";

        //kw2.
        let keyword2 = '';
        if (editItemState === CommonState.New)
            keyword2 = 'Create';
        if (editItemState === CommonState.Delete)
            keyword2 = 'Remove';
        else
            keyword2 = 'Update';

        //alert.
        if (success) {
            await this.LoadList_ViaApi();
            this.ToggleEditItemUiModal();
            await Delay(200);
            useAppService.getState().setModal('Success', `Teacher profile${keyword1} has been ${keyword2.toLowerCase()}d.`);
            if (editItemState !== CommonState.Delete) {
                //re-open edit modal.
                const email = CheckNullValue(newEmail) === null ? targetProfile[ItemProperty.Email] : newEmail;
                let findIndex = this.state.List.findIndex(x => String(x.Email) === email);
                if (findIndex < 0) {
                    if (_profile !== null) {
                        _profile = CapitalizeJsonKeys(_profile);
                        if (CheckObjectNullValue(_profile, 'Email') !== null)
                            findIndex = this.state.List.findIndex(x => String(x.Email) === CheckObjectStringEmpty(_profile, 'Email'));
                        else if (CheckObjectNullValue(_profile, 'Id') !== null)
                            findIndex = this.state.List.findIndex(x => String(x.Id) === CheckObjectStringEmpty(_profile, 'Id'));
                    }
                }
                if (findIndex > -1) {
                    this.setState({ TargetItemIndex: findIndex, });
                    this.ToggleEditItemUiModal(findIndex, false);
                }
            }
        }
        else {
            useAppService.getState().setModal(`${keyword2} Failed`,
                `Teacher profile${keyword1} has failed to ${keyword2.toLowerCase()}. ${CheckNullValue(errorMessage) === null ? '' : '<br /><br />Error:<br />' + errorMessage}`);
        }
    }
    // SendPasswordResetEmail = async () => {

    //     useAppService.getState().setModal('', 'sending password reset email...', null, AlertMode.Loading);
    //     window.scrollTo(0, 0);

    //     const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);

    //     const url = GlobalSetting.ApiUrl + 'Api/LearningCentre/User/Profile/SendPasswordResetEmail';

    //     if (this.state.isDevMode)
    //         console.log(url);

    //     const profile = GetTempTarget();
    //     let emailSent = false;
    //     let errorMessage = '';
    //     await fetch(url,
    //         {
    //             method: 'POST',
    //             headers: {
    //                 'Accept': 'application/json',
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify({
    //                 secret: this.state.SecretKey,
    //                 authorId: authorId,
    //                 organizerId: organizerId,
    //                 rawPassword: '',
    //                 studentProfile: profile,    // this.state.List[this.state.TargetItemIndex],
    //             })
    //         })
    //         .then(res => res.json())
    //         .then(data => {
    //             if (data.success) {
    //                 emailSent = true;
    //             }
    //             else {
    //                 errorMessage = data.message;
    //                 if (this.state.isDevMode)
    //                     console.log('Error', 'api - send password reset email (failed)\n' + JSON.stringify(data));
    //             }
    //         })
    //         .catch(error => {
    //             errorMessage = error.message;
    //             if (this.state.isDevMode)
    //                 console.log('Error', 'client - send password reset email (error)\n' + error.message);
    //         });

    //     //close loading.
    //     if (emailSent)
    //         useAppService.getState().setModal('', 'A password reset email has been sent' + (CheckNullValue(profile.email) === null ? '.' : ' to &#60;' + profile.email + '&#62;.'));
    //     else
    //         useAppService.getState().setModal('Failed', 'Failed to send password reset email.' + (CheckNullValue(errorMessage) === null ? '' : '<br /><br />Error:<br />' + errorMessage));
    // }
    ToggleDeleteItemModal = () => {
        this.setState({
            DeleteItemModal_Toggle: !this.state.DeleteItemModal_Toggle,
        });
    }
    RemoveItem = async () => {
        const index = CheckNumber(this.state.TargetItemIndex, -1);
        if (index < 0)
            return null;
        // const email = CheckObjectStringEmpty(this.state.TargetProfile, ItemProperty.Email);
        this.setState({
            EditItemState: CommonState.Delete,
        });
        await Delay(0);
        await this.UpdateItemViaAPI();
        // await this.LoadList_ViaApi(true);
        this.ToggleDeleteItemModal();
        // useAppService.getState().setModal('Success',
        //     `Teacher${CheckNullValue(email) === null ? ' ' : ' (' + email + ') '}has been successfully removed.`);
    }
    //2024.09.02
    SendPasswordToTeacherByEmailViaApi = async () => {

        useAppService.getState().setModal('', 'sending login credential via email...', null, AlertMode.Loading);

        let done = false;
        let errorMessage = '';
        let success = false;

        const targetProfile = this.state.TargetProfile;
        const teacherUserId = CheckObjectStringEmpty(targetProfile, ItemProperty.UserId, '0');
        if (targetProfile !== null && teacherUserId !== '0') {

            const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
            const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Organizer/Teacher/Profile/SendEmail/Password/${organizerId}/${authorId}/${teacherUserId}`;

            await fetch(url,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        // 'Content-Type': 'application/json',
                    },
                })
                .then(res => res.json())
                .then(data => {
                    if (this.state.isDevMode)
                        console.log('Response', 'api - teacher profile - email password \n' + JSON.stringify(data));

                    success = CheckObjectBoolean(data, 'success');
                    if (!success) {
                        errorMessage = CheckObjectStringEmpty(data, 'message');
                    }
                    done = true;
                })
                .catch(error => {
                    errorMessage = CheckObjectStringEmpty(error, 'message');
                    done = true;
                    if (this.state.isDevMode)
                        console.log('Error', 'api - profile - email password (error)\n' + errorMessage);
                });
            await DelayUntil(() => done === true);
        }
        if (success) {
            useAppService.getState().setModal('', 'Email has been sent successfully.');
        }
        else {
            useAppService.getState().setModal(`Unsuccessful`,
                `Failed to send login credential via email. ${CheckNullValue(errorMessage) === null ? '' : '<br /><br />Error:<br />' + errorMessage}`);
        }
    }
    //2025.01.23
    ActivateProfile = () => {
        const confirm = window.confirm('Proceed to re-activate current teacher ?');
        if (confirm) {
            let targetProfile = this.state.TargetProfile;
            const deactivated = CheckObjectBoolean(targetProfile, 'Deactivated');
            if (deactivated) {
                targetProfile['Deactivated'] = false;
                this.setState({ TargetProfile: targetProfile });
            }
            this.UpdateItem();
        }
    }
    //2025.01.23
    DeactivateProfile = () => {
        const confirm = window.confirm('Proceed to deactivate current teacher ?');
        if (confirm) {
            let targetProfile = this.state.TargetProfile;
            const deactivated = CheckObjectBoolean(targetProfile, 'Deactivated');
            if (!deactivated) {
                targetProfile['Deactivated'] = true;
                this.setState({ TargetProfile: targetProfile });
            }
            this.UpdateItem();
        }
    }
    //#endregion === Teacher Profile - New/View/Edit

    //#region === Search Teacher by Condition ===
    SearchUserByCondition_ViaAPI = async () => {
        this.setState({
            SearchByCondition_Processing: true,
            SearchByConditionModal_Toggle: false,
            PageIndex: 0,
        });
        await this.LoadList_ViaApi();
        await Delay(500);
        // this.resetSearchStudentParams();
        // await Delay(500);
        this.setState({ SearchByCondition_Processing: false, });
    }
    ToggleSearchUserByConditionModal = async (toggleOn = false, search = SearchCondition.Name) => {
        this.setState({
            SearchUserByName: '',
            SearchUserByEmail: '',
            SearchUserBySchoolName: '',
            SearchUserByGroups: [],
            SearchUserBySubjects: [],
            SearchUserByClassrooms: [],
            SearchUserByCondition: search,
            SearchByConditionModal_Toggle: toggleOn,
            SearchByCondition_Processing: false,
        });
        await Delay(0);
        if (toggleOn) {
            await useAppService.getState().getClassrooms(true);
            this.setState({
                GroupOptions: useAppService.getState().groupOptions,
                SubjectOptions: useAppService.getState().subjectOptions,
                ClassroomOptions: useAppService.getState().classroomOptions,
            });
            await Delay(0);
        }
    }
    SetSearchUserByConditionValue = (value = null) => {
        if (value === null)
            return null;

        switch (this.state.SearchUserByCondition) {

            case SearchCondition.Name: this.setState({ SearchUserByName: String(value) }); break;
            case SearchCondition.Email: this.setState({ SearchUserByEmail: String(value) }); break;
            case SearchCondition.SchoolName: this.setState({ SearchUserBySchoolName: String(value) }); break;

            case SearchCondition.Groups: this.setState({ SearchUserByGroups: value }); break;
            case SearchCondition.Subjects: this.setState({ SearchUserBySubjects: value }); break;
            case SearchCondition.Classrooms: this.setState({ SearchUserByClassrooms: value }); break;

            // case SearchCondition.Groups:
            //     let groups = this.state.SearchUserByGroups;
            //     if (Array.isArray(groups) === false)
            //         groups = [];
            //     const findIndex_group = groups.findIndex(x => Number(x.id) === Number(value.id));
            //     if (findIndex_group < 0)
            //         groups.push(value);
            //     // groups.sort((a, b) => a - b);
            //     console.log('groups = ' + JSON.stringify(groups));
            //     this.setState({ SearchUserByGroups: groups });
            //     break;

            // case SearchCondition.Subjects:
            //     let subjects = this.state.SearchUserBySubjects;
            //     if (Array.isArray(subjects) === false)
            //         subjects = [];
            //     const findIndex_subject = subjects.findIndex(x => Number(x.id) === Number(value.id));
            //     if (findIndex_subject < 0)
            //         subjects.push(value);
            //     // subjects.sort((a, b) => a - b);
            //     console.log('subjects = ' + JSON.stringify(subjects));
            //     this.setState({ SearchUserBySubjects: subjects });
            //     break;

            // case SearchCondition.Classrooms:
            //     let classrooms = this.state.SearchUserByClassrooms;
            //     if (Array.isArray(classrooms) === false)
            //         classrooms = [];
            //     const findIndex_classroom = classrooms.findIndex(x => String(x.value) === String(value.value));
            //     if (findIndex_classroom < 0)
            //         classrooms.push(value);
            //     // classrooms.sort(function (a, b) {
            //     //     if (a < b) { return -1; }
            //     //     if (a > b) { return 1; }
            //     //     return 0;
            //     // });
            //     console.log('classrooms = ' + JSON.stringify(classrooms));
            //     this.setState({ SearchUserByClassrooms: classrooms });
            //     break;

            default: break;
        }
    }
    //#endregion === Search Teacher by Condition ===

    //#region === Table / Upload Teacher Profile Template File
    ToggleUploadProfileModal = () => {
        this.setState({
            UploadProfileModal_Toggle: !this.state.UploadProfileModal_Toggle
        }, () => {
            if (!this.state.UploadProfileModal_Toggle) {
                //close.
                this.ResetUploadProfileModal();
            }
            else {
                //open.
                this.setState({
                    SendEmailAfterUpload: true,     //2024.09.26
                });
            }
        });
    }
    ResetUploadProfileModal = (action = '') => {
        this.setState({
            UploadStatus: UploadState.None,
            UploadStatusText: '',
            UniqueId: '',
            AttachedFile: null,
            UploadModal: null,
        }, () => {
            if (action !== '') {
                if (action === 'reset') {
                    if (this.state.UploadProfileModal_Toggle) {
                        this.ToggleUploadProfileModal();
                        setTimeout(() => {
                            this.ToggleUploadProfileModal();
                        }, 500);
                    }
                }
                if (action === 'reload') {
                    this.LoadList_ViaApi(true);
                }
            }
        });
    }
    onUploadFileChange = (event) => {
        this.setState({ AttachedFile: event.target.files[0] });
    }
    HideComponent_UploadUiModal = () => {
        return this.state.UploadStatus === UploadState.ConvertFailed ||
            this.state.UploadStatus === UploadState.Success ||
            this.state.UploadStatus === UploadState.Failed ? false : true
    }
    ProcessUploadProfileFile = async () => {
        let processSuccess = false;
        let processErrorMessage = '';
        let done = false;
        // console.log('ProcessUploadProfileFile (enter)');

        this.setState({
            UploadStatus: UploadState.Validation,
            UploadStatusText: '',
        });

        const subjectOptions = useAppService.getState().subjectOptions;

        let jsonData = null;
        try {
            let reader = new FileReader();
            reader.onload = (event) => {
                /* Parse data */
                let bstr = event.target.result;
                let wb = XLSX.read(bstr, { type: "binary" });
                let wsname = wb.SheetNames[0];
                // console.log(wsname);
                if (wsname === 'TeacherProfileTemplate') {
                    let ws = wb.Sheets[wsname];
                    /* Convert array of arrays */
                    let jsonData_raw = XLSX.utils.sheet_to_json(ws);
                    let jsonStrings = JSON.stringify(jsonData_raw);
                    jsonStrings = jsonStrings.replaceAll('\\r\\n', '<br/>');
                    // if (this.state.isDevMode)
                    //     console.log('StudentProfile =\n' + jsonStrings);
                    jsonData = JSON.parse(jsonStrings, (key, value) => { return (CheckNullValue(value) === null ? '' : value); });
                    if (Array.isArray(jsonData))
                        jsonData = jsonData.filter(x => CheckNullValue(x.Email) !== null);
                    // if (this.state.isDevMode) {
                    //     console.log('Total Profiles (init) = ' + (jsonData === null ? 0 : jsonData.length));
                    //     console.log('jsonData (init) =\n' + JSON.stringify(jsonData));
                    // }

                    if (Array.isArray(jsonData)) {
                        let newArray = [];
                        for (let j = 0; j < jsonData.length; j++) {
                            if (Number(jsonData[j].No) <= 500) {
                                if (CheckObjectNullValue(jsonData[j], 'Email') !== null) {

                                    // console.log(`${j} (before) \n ${JSON.stringify(jsonData[j])}`);

                                    if (Array.isArray(subjectOptions)) {
                                        for (let s = 0; s < subjectOptions.length; s++) {
                                            const subjectOption = subjectOptions[s];
                                            const subjectName = CheckObjectStringEmpty(subjectOption, 'value');
                                            // const subjects = CheckObjectStringEmpty(jsonData[j], subjectName);
                                            // if (subjects !== '') {
                                            //     jsonData[j][subjectName] = subjects.split(',').filter(x => CheckNullValue(x) !== null).forEach(e => { return CheckStringEmpty(e); });
                                            // }
                                            const classrooms = CheckObjectStringEmpty(jsonData[j], subjectName);
                                            if (classrooms !== '') {
                                                delete jsonData[j][subjectName];
                                                if (jsonData[j]['Subjects'] === undefined || jsonData[j]['Subjects'] === null)
                                                    jsonData[j]['Subjects'] = [];

                                                const t_classrooms = classrooms.includes(',') ?
                                                    classrooms.split(',').filter(x => CheckNullValue(x) !== null).map((data, key) => { return CheckStringEmpty(data); })
                                                    : [classrooms];

                                                jsonData[j]['Subjects'].push({
                                                    SubjectId: CheckObjectNumber(subjectOption, 'id'),
                                                    SubjectName: subjectName,
                                                    Classrooms: t_classrooms,
                                                });
                                            }
                                        }
                                    }

                                    newArray.push(jsonData[j]);

                                    // console.log(`${j} (after) \n ${JSON.stringify(jsonData[j])}`);
                                }
                                // console.log(`${j} (after) \n ${JSON.stringify(jsonData[j])}`);
                            }
                        }
                        jsonData = newArray;
                        // if (this.state.isDevMode)
                        //     console.log('Total Profiles (500) = ' + (jsonData === null ? 0 : jsonData.length));
                    }
                    if (this.state.isDevMode) {
                        console.log('Total Profiles (final) = ' + (jsonData === null ? 0 : jsonData.length));
                        console.log('jsonData (final) =\n' + JSON.stringify(jsonData));
                        // DownloadTxtFile(jsonData, 'Debug_StudentProfile_' + moment.utc().unix());
                    }
                    processSuccess = jsonData !== null;
                    done = true;
                }
            };
            reader.readAsArrayBuffer(this.state.AttachedFile);
        }
        catch (err) {
            processErrorMessage = err;
            done = true;
        };
        await DelayUntil(() => done === true);

        return { processSuccess, processErrorMessage, jsonData };
    }
    UploadProcessedProfile_ViaAPI = async (jsonData = null) => {

        let done = false;
        let uploadSuccess = false;
        let uploadErrorMessage = '';
        let profileEmails = [];

        if (jsonData === null) {
            uploadErrorMessage = 'invalid json data.';
            return { uploadSuccess, uploadErrorMessage, profileEmails };
        }

        this.setState({
            UploadStatus: UploadState.Processing,
            UploadStatusText: 'Please wait patiently...',
        });

        //api.
        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        // if (this.state.isDevMode)
        //     console.log('LoadStudentProfileList_ViaApi', centerUserId, authorId, authorRoleId, organizerId, organizerDiplayName, this.state.OrderBy, this.state.OrderType);

        const url = GlobalSetting.ApiUrl + 'Api/LearningCentre/Organizer/Teacher/Profile/CreateOrUpdate/TemplateFile/Upload';

        if (this.state.isDevMode)
            console.log('UploadProcessedProfile_ViaAPI', url);

        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    // 'Access-Control-Allow-Origin': '*',
                    // 'Access-Control-Allow-Headers': 'X-Requested-With',
                },
                body: JSON.stringify({
                    secret: this.state.SecretKey,
                    authorId: authorId,
                    organizerId: organizerId,
                    profiles: jsonData,
                    sendEmail: this.state.SendEmailAfterUpload,     //2024.09.26
                })
            })
            .then(res => res.json())
            .then(data => {
                if (data.success) {
                    uploadSuccess = true;
                    profileEmails = data.data;
                    if (this.state.isDevMode)
                        console.log('File has been uploaded & processed successfully.');
                }
                else {
                    uploadErrorMessage = data.message;
                    if (this.state.isDevMode)
                        console.log('Error', 'api - profile - upload file (failed)\n' + JSON.stringify(data));
                }
                done = true;
            })
            .catch(error => {
                done = true;
                uploadErrorMessage = error.message;
                if (this.state.isDevMode)
                    console.log('Error', 'api - profile - upload file (error)\n' + error.message);
            });
        await DelayUntil(() => done === true);

        // this.setState({
        //     UploadStatus: uploadSuccess ? UploadState.Success : UploadState.Failed,
        // });

        return { uploadSuccess, uploadErrorMessage, profileEmails };
    }
    TriggerUploadProfileFile = async () => {
        let success = false;
        let errorMessage = '';
        let profileUidsComponentHTML = '';

        if (this.state.PA_Upload === false)
            return null;

        if (this.state.AttachedFile === undefined || this.state.AttachedFile === null) {
            useAppService.getState().setModal('Upload Failed', 'Please select a template file before upload.');
            return null;
        }

        this.setState({ UploadResultModal: null, });

        // //get organizer custom groups.
        // const { fetchSuccess, fetchErrorMessage } = await this.GetOrganizerCustomGroups_ViaApi();
        // if (fetchSuccess === false)
        //     errorMessage = fetchErrorMessage;

        //converting attached file to json.
        const { processSuccess, processErrorMessage, jsonData } = await this.ProcessUploadProfileFile();
        if (processSuccess === false)
            errorMessage = processErrorMessage;
        await Delay(1000);
        console.log('ProcessUploadProfileFile', processSuccess, processErrorMessage);

        // useAppService.getState().setModal();
        // this.setState({
        //     UploadStatus: UploadState.None,
        //     UploadStatusText: '',
        // });
        // return null;

        if (processSuccess) {
            //upload via api.
            const { uploadSuccess, uploadErrorMessage, profileEmails } = await this.UploadProcessedProfile_ViaAPI(jsonData);
            if (uploadSuccess) {
                success = true;
                profileUidsComponentHTML = this.PopulateUploadProfileTemplateResultComponentHTML(jsonData, profileEmails);
                this.ToggleUploadProfileModal();
            }
            else {
                errorMessage = uploadErrorMessage;
            }
            await Delay(2000);
            // console.log('UploadProcessedProfile_ViaAPI', uploadSuccess, uploadErrorMessage);
        }

        this.setState({
            UploadStatus: success ? UploadState.Success : UploadState.Failed,
            UploadStatusText: success ? 'File has been uploaded & processed.' + CheckStringEmpty(profileUidsComponentHTML) : errorMessage,
        });
    }
    PopulateUploadProfileTemplateResultComponentHTML = (jsonData = [], profileEmails = []) => {
        if (Array.isArray(jsonData) === false || Array.isArray(profileEmails) === false)
            return '';

        if (jsonData.length === 0 || profileEmails.length === 0)
            return '';

        let uploadResultModal = [];
        let html = '<br /><br /><table id="uploadTableResult" width="100%" border="1" cellpadding="5" style="font-size:12px;">';
        let html2 = '<table id="uploadTableResult_hidden" hidden="hidden" width="100%" border="1" cellpadding="5" style="font-size:12px;">';
        html += '<thead><th>No</th><th>Name</th><th width="170">Email</th><th align="center">Status</th></thead><tbody>';
        html2 += '<thead><th>No</th><th>Name</th><th width="170">Email</th><th align="center">Status</th></thead><tbody>';

        for (let p = 0; p < jsonData.length; p++) {
            const email = CheckObjectStringEmpty(jsonData[p], 'Email');
            let result = '<i class="fa fa-check" style="font-size:24px;color:green"></i>';
            const notFound = profileEmails.findIndex(x => x === email) < 0;
            if (notFound)
                result = '<i class="fa fa-remove" style="font-size:24px;color:green"></i>';

            html += '<tr ' + (notFound ? 'style="background-color:lightgray;"' : '') + '>';
            html += '<td align="center">' + (p + 1) + '</td>';
            html += '<td>' + CheckObjectStringEmpty(jsonData[p], 'Name') + '</td>';
            html += '<td>' + email + '</td>';
            html += '<td align="center">' + result + '</td>';
            html += '</tr>';

            html2 += '<tr ' + (notFound ? 'style="background-color:lightgray;"' : '') + '>';
            html2 += '<td align="center">' + (p + 1) + '</td>';
            html2 += '<td>' + CheckObjectStringEmpty(jsonData[p], 'Name') + '</td>';
            html2 += '<td>' + email + '</td>';
            html2 += '<td align="center">' + (notFound ? 'Failed' : 'Success') + '</td>';
            html2 += '</tr>';

            uploadResultModal.push({
                No: (p + 1),
                Name: CheckObjectStringEmpty(jsonData[p], 'Name'),
                Email: email,
                Status: notFound ? 'Failed' : 'Success',
            })
        }
        html += '</tbody></table>';
        html2 += '</tbody></table>';
        this.setState({ UploadResultModal: uploadResultModal, });

        let content = html + html2;
        // content += html + '<br /><br />';
        // content += '<input type="button" onclick="window.open(<html><head></head><body>' + html + '</body></html>, _blank)" >Open table in new tab</input>';
        return CheckNullValue(content);
    }
    DownloadTableAsXLSX = (tableId = '', tableName = '') => {
        if (tableId === '')
            return null;

        const table = document.getElementById(tableId);
        if (table !== null) {
            let wb = XLSX.utils.table_to_book(table, { raw: true });
            XLSX.writeFile(wb, tableName + ".xlsx");
        }
    }
    ShowTemplateDownloadAlertBox = () => {
        // let itemCom = [];
        // for (let k = 1; k <= 6; k++) {
        //     itemCom.push(<tr key={'tp-grade-' + k}><td>{'Standard ' + k}</td><td><button className="link-button" onClick={() => this.GetProfileTemplate(k)}>download</button></td></tr>);
        // }
        // let tableCom = [];
        // tableCom.push(<table key='tp-grade' cellPadding="5" width="100%" border="1" style={{ textAlign: 'center' }}><thead><tr><th>Grade</th><th>Spreadsheet Template</th></tr></thead><tbody>{itemCom}</tbody></table>);
        // tableCom.push(<br key='br' />);
        // const sampleLink = 'https://ikeynew.blob.core.windows.net/ikeykidz/quizbank/TEMPLATE_STUDENT_PROFILE_SAMPLE.xlsx';
        // tableCom.push(<table key='tp-sample' cellPadding="5" width="100%" border="1" style={{ textAlign: 'center' }}><thead><tr><th>Spreadsheet Implementation Sample</th></tr></thead><tbody>
        //     <tr><td><button className="link-button" onClick={() => window.open(sampleLink, '_new')}>download sample file</button></td></tr>
        // </tbody></table>);

        // const sampleLink = 'https://ikeynew.blob.core.windows.net/ikeykidz/quizbank/TEMPLATE_TEACHER_PROFILE.xlsx';
        let tableCom = [];
        tableCom.push(<table key='tp-sample' cellPadding="5" width="100%" border="1" style={{ textAlign: 'center' }}><thead><tr><th>Spreadsheet Template</th></tr></thead><tbody>
            <tr><td><button className="link-button"
                // onClick={() => window.open(sampleLink, '_new')}
                onClick={() => this.GetProfileTemplate()}
            >download template file</button></td></tr>
        </tbody></table>);
        useAppService.getState().setModal('Teacher Profile Upload Template', tableCom);
    }
    GetProfileTemplate = async () => {

        useAppService.getState().setModal('', 'requesting template file...', null, AlertMode.Loading);

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        if (this.state.isDevMode)
            console.log('GetProfileTemplate', authorId, organizerId);

        const url = GlobalSetting.ApiUrl
            + `Api/LearningCentre/Organizer/Download/Template/UserProfile/Teacher/${organizerId}/${authorId}`;

        await fetch(url,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (data.success) {
                    if (this.state.isDevMode)
                        console.log('GetProfileTemplate (data)\n', JSON.stringify(data.data));
                    if (CheckObjectNullValue(data, 'data') === null) {
                        useAppService.getState().setModal('Error', 'Failed to receive template file link.');
                    }
                    else {
                        if (CheckObjectNullValue(data.data, 'url') === null)
                            useAppService.getState().setModal('Error', 'Failed to download template file.');
                        else {
                            window.open(String(data.data.url));
                            this.ShowTemplateDownloadAlertBox();    //close ui.
                        }
                    }
                }
                else {
                    useAppService.getState().setModal('Error', 'Failed to receive template file.<br /><br />Error:<br />' + data.message);
                    if (this.state.isDevMode)
                        console.log('Error', 'api - receive template file link (failed)\n' + JSON.stringify(data));
                }
            })
            .catch(error => {
                useAppService.getState().setModal('Request Error', 'Failed to request file.<br /><br />Error:<br />' + error.message);
                if (this.state.isDevMode)
                    console.log('Error', 'api - request template file (error)\n' + error.message);
            });
    }
    //#endregion === Table / Upload Teacher Profile Template File

    //#region === Send Password To ALL or SELECTED Teachers By Email ===
    //2025.02.07 revamped.
    SendPasswordTo_ALL_OR_SELECTED_Profiles_ByEmailViaApi = async () => {

        const list = this.state.List;
        const checkedItems = this.state.BulkEdit_CheckedItems;

        let checkedItemsInfo_html = '\n\n';
        for (let n = 0; n < list.length; n++) {
            if (checkedItems[n])
                checkedItemsInfo_html += `(${n + 1}) ${CheckObjectStringEmpty(list[n], ItemProperty.Name)} \n        (${CheckObjectStringEmpty(list[n], ItemProperty.Email)})\n`;
        }

        const confirm = window.confirm(`Continue to send login credential email to ${this.state.BulkEdit_CheckedItems.includes(true) ? 'selected' : 'all'} Teachers ? ${checkedItemsInfo_html}`);
        if (confirm === false)
            return null;

        useAppService.getState().setModal('', 'sending login credentials via email...', null, AlertMode.Loading);
        this.setState({
            isProcessing: true,
        });

        let emails = '0';
        let classrooms = '0';

        //#region userIds
        let userIds = '0';
        let itemIds = [];
        for (let n = 0; n < list.length; n++) {
            if (checkedItems[n])
                itemIds.push(CheckObjectNumber(list[n], ItemProperty.UserId));
        }
        if (itemIds.length > 0)
            userIds = itemIds.join(',');
        //#endregion

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Organizer/Teacher/Profile/SendEmail/Password/Multiple/${organizerId}/${authorId}/${emails}/${classrooms}/${userIds}`;

        // console.log('SendPasswordTo_ALL_OR_SELECTED_Profiles_ByEmailViaApi = \n' + url);
        // useAppService.getState().setModal();
        // this.setState({ isProcessing: false, });
        // return null;

        let done = false;
        let errorMessage = '';
        let success = false;
        let responseData = null;

        await fetch(url,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('Response', 'api - all teacher - send email password \n' + JSON.stringify(data));

                responseData = data.data;
                success = CheckObjectBoolean(data, 'success');
                if (!success) {
                    errorMessage = CheckObjectStringEmpty(data, 'message');
                }
                done = true;
            })
            .catch(error => {
                errorMessage = CheckObjectStringEmpty(error, 'message');
                done = true;
                if (this.state.isDevMode)
                    console.log('Error', 'api - all teacher - send email password (error)\n' + errorMessage);
            });
        await DelayUntil(() => done === true);

        // //debug.
        // success = true;
        // responseData = [{ Email: 'email_1', Name: 'name_1', Sent: true }, { Email: 'email_2', Name: 'name_2', Sent: false }];

        //responseData.
        if (responseData !== null && responseData !== undefined && Array.isArray(responseData)) {
            let htmlText = '';
            responseData.map((data, key) => {
                return htmlText += `<tr><td>${key + 1}</td>`
                    + `<td class="left">${CheckObjectStringEmpty(data, 'Name', '-')}</td>`
                    + `<td class="left">${CheckObjectStringEmpty(data, 'Email', '-')}</td>`
                    + `<td class="icon-color"><i class="fa ${CheckObjectBoolean(data, 'Sent') ? 'fa-check-circle blue' : 'fa-times-circle red'} fs20"></i></td></tr>`;
            });
            if (htmlText === '')
                responseData = '';
            else
                responseData = `<br /><br /><table class="table tbStyle" border="1"><thead><tr><th>#</th><th class="left">Name</th><th class="left">Email</th><th>Sent</th></tr></thead><tbody>${htmlText}</tbody></table>`;
        }
        else {
            responseData = '';
        }

        this.setState({
            isProcessing: false,
        });
        if (success) {
            useAppService.getState().setModal('Email Sent', 'All emails have been sent successfully.' + responseData);
        }
        else {
            useAppService.getState().setModal(`Unsuccessful`,
                `Failed to send login credentials via email. ${errorMessage === '' ? '' : '<br /><br />Error:<br />' + errorMessage}` + responseData);
        }
    }
    //#endregion === Send Password To ALL Teachers By Email ===

    //#region === bulk edit ===
    BulkEdit_ToggleEditSettingModal = () => {
        if (this.state.PA_Update === false) {
            useAppService.getState().setModal(`Bulk Edit ${settingTitle}(s)`, 'Invalid permission.');
            return null;
        }
        const toggle = !this.state.BulkEdit_Toggle_EditSettingModal;
        this.setState({
            BulkEdit_Toggle_EditSettingModal: toggle,
        });
        this.BulkEdit_ResetSetting();
    }
    BulkEdit_SettingModalComponent = () => {
        let components = [];
        const setting = this.state.BulkEdit_Setting;
        const setting_checked = this.state.BulkEdit_Setting_checked;

        //Teacher Type.
        const teacher_type_setting_index = Object.keys(BulkSetting).indexOf(BulkSetting.TeacherType);
        const teacher_type_setting_checked = setting_checked[teacher_type_setting_index];
        const teacher_type_Options = TeacherTypeOptions;    //useAppService.getState().teacherTypes;
        const teacher_type_ValueIndex = teacher_type_Options === undefined ? -1 : teacher_type_Options.findIndex(x => String(x.value) === CheckObjectStringEmpty(setting[teacher_type_setting_index], 'value'));
        components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (teacher_type_setting_checked ? 'bg-lightskyblue' : '')}`}>
            <div className="select-setting form-group width-max pd-7-10">
                <label>Teacher Type</label>
                <ReactSelect
                    options={teacher_type_Options}
                    placeholder={teacher_type_ValueIndex < 0 ? '(Select Teacher Type...)' : teacher_type_Options[teacher_type_ValueIndex].label}
                    value={teacher_type_ValueIndex < 0 ? 0 : teacher_type_Options[teacher_type_ValueIndex].value}
                    onChange={(option) => this.BulkEdit_SetSetting(BulkSetting.TeacherType, option)}
                />
            </div>
            <div className="select-checkbox">
                <div className="form-check" onChange={() => this.BulkEdit_SetSetting(ItemProperty.CheckedItem, teacher_type_setting_index)}>
                    <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : teacher_type_setting_checked} readOnly={true} />
                </div>
            </div>
        </div>);

        //Groups.
        const groups_setting_index = Object.keys(BulkSetting).indexOf(BulkSetting.Groups);
        const groups_setting_checked = setting_checked[groups_setting_index];
        const groupOptions = useAppService.getState().groupOptions;
        components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (groups_setting_checked ? 'bg-lightskyblue' : '')}`}>
            <div className="select-setting form-group width-max pd-7-10">
                <label>Group(s)</label>
                <ReactSelect
                    className="basic-multi-select"
                    classNamePrefix='select'
                    isMulti
                    closeMenuOnSelect={false}
                    options={groupOptions}
                    placeholder='Select Group(s)...'
                    onChange={(option) => this.BulkEdit_SetSetting(BulkSetting.Groups, option)}
                    theme={theme => ({
                        ...theme,
                        width: 'max-content',
                        colors: {
                            ...theme.colors,
                            neutral50: 'gray',  // placeholder color
                        }
                    })}
                />
            </div>
            <div className="select-checkbox">
                <div className="form-check" onChange={() => this.BulkEdit_SetSetting(ItemProperty.CheckedItem, groups_setting_index)}>
                    <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : groups_setting_checked} readOnly={true} />
                </div>
            </div>
        </div>);

        //Subjects.
        const subjects_setting_index = Object.keys(BulkSetting).indexOf(BulkSetting.Subjects);
        const subjects_setting_checked = setting_checked[subjects_setting_index];
        const subjectOptions = useAppService.getState().subjectOptions;
        components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (subjects_setting_checked ? 'bg-lightskyblue' : '')}`}>
            <div className="select-setting form-group width-max pd-7-10">
                <label>Subject(s)</label>
                <ReactSelect
                    className="basic-multi-select"
                    classNamePrefix='select'
                    isMulti
                    closeMenuOnSelect={false}
                    options={subjectOptions}
                    placeholder='Select Subject(s)...'
                    onChange={(option) => this.BulkEdit_SetSetting(BulkSetting.Subjects, option)}
                    theme={theme => ({
                        ...theme,
                        width: 'max-content',
                        colors: {
                            ...theme.colors,
                            neutral50: 'gray',  // placeholder color
                        }
                    })}
                />
            </div>
            <div className="select-checkbox">
                <div className="form-check" onChange={() => this.BulkEdit_SetSetting(ItemProperty.CheckedItem, subjects_setting_index)}>
                    <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : subjects_setting_checked} readOnly={true} />
                </div>
            </div>
        </div>);

        // //Display Order.
        // const displayOrder_setting_index = Object.values(BulkSetting).indexOf(BulkSetting.DisplayOrder);
        // const displayOrder_setting_checked = setting_checked[displayOrder_setting_index];
        // components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (displayOrder_setting_checked ? 'bg-lightskyblue' : '')}`}>
        //     <div className="form-group width-max">
        //         <label>Display Order</label>
        //         <input type="number" className="form-control" style={{ width: '100%' }}
        //             value={CheckNumber(setting[displayOrder_setting_index].value)}
        //             onChange={(e) => this.BulkEdit_SetSetting(BulkSetting.DisplayOrder, e.target.value)}
        //         ></input>
        //     </div>
        //     <div className="select-checkbox">
        //         <div className="form-check" onChange={() => this.BulkEdit_SetSetting(BulkSetting.CheckedItem, displayOrder_setting_index)}>
        //             <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : displayOrder_setting_checked} readOnly={true} />
        //         </div>
        //     </div>
        // </div>);

        // //Active.
        // const active_setting_index = Object.values(BulkSetting).indexOf(BulkSetting.Active);
        // const active_setting_checked = setting_checked[active_setting_index];
        // components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (active_setting_checked ? 'bg-lightskyblue' : '')}`}>
        //     <div className="form-group width-max">
        //         <label>Active</label>
        //         <input type="checkbox" className="form-check form-check-input"
        //             onClick={(e) => this.BulkEdit_SetSetting(BulkSetting.Active, e.currentTarget.checked)}
        //             checked={CheckBoolean(setting[active_setting_index].value)}
        //             readOnly={true}
        //         ></input>
        //     </div>
        //     <div className="select-checkbox">
        //         <div className="form-check" onChange={() => this.BulkEdit_SetSetting(BulkSetting.CheckedItem, active_setting_index)}>
        //             <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : active_setting_checked} readOnly={true} />
        //         </div>
        //     </div>
        // </div>);

        // //Effective Date Start.
        // const effectiveDateStart_setting_index = Object.values(BulkSetting).indexOf(BulkSetting.EffectiveDateStart);
        // const effectiveDateStart_setting_checked = setting_checked[effectiveDateStart_setting_index];
        // components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (effectiveDateStart_setting_checked ? 'bg-lightskyblue' : '')}`}>
        //     <div className="form-group width-max">
        //         <label>Effective Date Start</label>
        //         <input type="datetime-local" className="form-control" style={{ width: '100%' }}
        //             onChange={(e) => this.BulkEdit_SetSetting(BulkSetting.EffectiveDateStart, e.target.value)}
        //             value={moment.utc(setting[effectiveDateStart_setting_index].value).local().format('YYYY-MM-DD HH:mm')}
        //         ></input>
        //     </div>
        //     <div className="select-checkbox">
        //         <div className="form-check" onChange={() => this.BulkEdit_SetSetting(BulkSetting.CheckedItem, effectiveDateStart_setting_index)}>
        //             <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : effectiveDateStart_setting_checked} readOnly={true} />
        //         </div>
        //     </div>
        // </div>);

        // //Effective Date End.
        // const effectiveDateEnd_setting_index = Object.values(BulkSetting).indexOf(BulkSetting.EffectiveDateEnd);
        // const effectiveDateEnd_setting_checked = setting_checked[effectiveDateEnd_setting_index];
        // components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (effectiveDateEnd_setting_checked ? 'bg-lightskyblue' : '')}`}>
        //     <div className="form-group width-max">
        //         <label>Effective Date End</label>
        //         <input type="datetime-local" className="form-control" style={{ width: '100%' }}
        //             onChange={(e) => this.BulkEdit_SetSetting(BulkSetting.EffectiveDateEnd, e.target.value)}
        //             value={moment.utc(setting[effectiveDateEnd_setting_index].value).local().format('YYYY-MM-DD HH:mm')}
        //         ></input>
        //     </div>
        //     <div className="select-checkbox">
        //         <div className="form-check" onChange={() => this.BulkEdit_SetSetting(BulkSetting.CheckedItem, effectiveDateEnd_setting_index)}>
        //             <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : effectiveDateEnd_setting_checked} readOnly={true} />
        //         </div>
        //     </div>
        // </div>);

        // //Remark.
        // const remark_setting_index = Object.values(BulkSetting).indexOf(BulkSetting.Remark);
        // const remark_setting_checked = setting_checked[remark_setting_index];
        // components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (remark_setting_checked ? 'bg-lightskyblue' : '')}`}>
        //     <div className="form-group width-max">
        //         <label>Remark</label>
        //         <input type="text" className="form-control" style={{ width: '100%' }}
        //             value={CheckStringEmpty(setting[remark_setting_index].value)}
        //             onChange={(e) => this.BulkEdit_SetSetting(BulkSetting.Remark, e.target.value)}
        //         ></input>
        //     </div>
        //     <div className="select-checkbox">
        //         <div className="form-check" onChange={() => this.BulkEdit_SetSetting(BulkSetting.CheckedItem, remark_setting_index)}>
        //             <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : remark_setting_checked} readOnly={true} />
        //         </div>
        //     </div>
        // </div>);

        // //Display.
        // const display_setting_index = Object.values(BulkSetting).indexOf(BulkSetting.Display);
        // const display_setting_checked = setting_checked[display_setting_index];
        // components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (display_setting_checked ? 'bg-lightskyblue' : '')}`}>
        //     <div className="form-group width-max">
        //         <label>Display</label>
        //         <input type="checkbox" className="form-check form-check-input"
        //             onClick={(e) => this.BulkEdit_SetSetting(BulkSetting.Display, e.currentTarget.checked)}
        //             checked={CheckBoolean(setting[display_setting_index].value)}
        //             readOnly={true}
        //         ></input>
        //     </div>
        //     <div className="select-checkbox">
        //         <div className="form-check" onChange={() => this.BulkEdit_SetSetting(BulkSetting.CheckedItem, display_setting_index)}>
        //             <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : display_setting_checked} readOnly={true} />
        //         </div>
        //     </div>
        // </div>);

        return (components);
    }
    BulkEdit_SetSetting = (property = ItemProperty.None, value = null) => {
        let setting = this.state.BulkEdit_Setting;
        let setting_checked = this.state.BulkEdit_Setting_checked;
        const setting_index = property === ItemProperty.CheckedItem ? 999 : Object.keys(BulkSetting).indexOf(property);
        if (this.state.isDevMode)
            console.log(`BulkEdit_SetSetting (setting_index = ${setting_index}) | (property = ${property}) | (value = ${JSON.stringify(value)})`);
        if (property === ItemProperty.None || setting === null || value === null || setting_index < 0)
            return null;

        switch (property) {
            case ItemProperty.TeacherType: setting[setting_index].value = CheckObjectStringEmpty(value, 'value'); break;
            case ItemProperty.Groups: setting[setting_index].value = Array.isArray(value) ? value : []; break;
            case ItemProperty.Subjects: setting[setting_index].value = Array.isArray(value) ? value : []; break;
            case ItemProperty.CustomPermissions: break;

            case ItemProperty.CheckedItem:
                setting_checked[value] = !setting_checked[value];
                if (this.state.isDevMode)
                    console.log(`BulkEdit_SetSetting (checkedItem) = ` + JSON.stringify(setting_checked));
                break;
            default: break;
        }
        this.setState({
            BulkEdit_Setting: setting,
            BulkEdit_Setting_checked: setting_checked,
        }, () => {
            if (this.state.isDevMode && property !== ItemProperty.CheckedItem) {
                console.log(`BulkEdit_SetSetting (${property}) = ` + JSON.stringify(value));
                console.log(`BulkEdit_SetSetting (setting) = ` + JSON.stringify(setting));
            }
        });
    }
    BulkEdit_ResetSetting = () => {
        // const currentUtc = moment.utc();
        this.setState({
            BulkEdit_Setting: [
                // { key: BulkSetting.TeacherType, value: TeacherTypeOptions[0].value },
                { key: BulkSetting.TeacherType, value: null },
                { key: BulkSetting.Groups, value: [] },
                { key: BulkSetting.Subjects, value: [] },
                { key: BulkSetting.CustomPermissions, value: null },
            ],
            // ] : Object.keys(BulkSetting).map((data, key) => { return { key: data, value: null }; }),
            BulkEdit_Setting_checked: Object.keys(BulkSetting).map(() => { return false; }),
        });
    }
    BulkEdit_ToggleRemoveSettingModal = () => {
        this.setState({
            BulkEdit_Toggle_RemoveSettingModal: !this.state.BulkEdit_Toggle_RemoveSettingModal,
        });
    }
    BulkEdit_CUD_Setting_ViaApi = async (remove = false) => {

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const { textTitle, textBody, text, urlParam } = GetPostParams({ id: 999 }, remove);
        this.setState({
            BulkEdit_IsUpdating: true,
        });
        useAppService.getState().setModal('', `${textTitle} setting...`, null, AlertMode.Loading);

        const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Organizer/Teacher/BulkEdit/${urlParam}`;
        // Api/LearningCentre/Organizer/Teacher/BulkEdit/{Update|Remove}

        let setting_params = [];
        const setting_keys = Object.values(BulkSetting);
        if (remove === false) {
            for (let i = 0; i < this.state.BulkEdit_Setting_checked.length; i++) {
                if (this.state.BulkEdit_Setting_checked[i])
                    setting_params.push({ key: setting_keys[i], value: this.state.BulkEdit_Setting[i].value });
                else
                    setting_params.push({ key: setting_keys[i], value: null });
            }
        }

        let userIds = [];
        const list = this.state.List;
        const checkedItems = this.state.BulkEdit_CheckedItems;
        for (let n = 0; n < list.length; n++) {
            if (checkedItems[n])
                userIds.push(CheckObjectNumber(list[n], ItemProperty.UserId));
        }

        const json = JSON.stringify({
            OrganizerId: organizerId,
            AuthorId: authorId,

            BulkUserIds: userIds.join(','),
            TeacherType: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.TeacherType)].value,
            Groups: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.Groups)].value,
            Subjects: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.Subjects)].value,
            CustomPermissions: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.CustomPermissions)].value,

            Remove: remove,
        });
        if (this.state.isDevMode)
            console.log(`BulkEdit_CUD_Setting_ViaApi (${text}) (postData) =\n` + json);

        // this.setState({ BulkEdit_IsUpdating: false });
        // useAppService.getState().setModal();
        // return null;

        // let data = null;
        let success = false;
        let msg = '';
        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: json,
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('BulkEdit_CUD_Setting_ViaApi =\n' + JSON.stringify(data));

                success = CheckObjectBoolean(data, 'success');
                // data = CheckObjectNullValue(data, 'data');

                if (!success)
                    msg = CheckObjectStringEmpty(data, 'message');
            })
            .catch(error => {
                msg = error.message;
                if (this.state.isDevMode)
                    console.log('Error', `api - ${text} (error)\n` + error.message);
            });

        if (success) {
            this.LoadList_ViaApi();
            this.BulkEdit_ToggleEditSettingModal();
            await Delay(500);
            useAppService.getState().setModal();
            await Delay(500);
            useAppService.getState().setModal('', `${settingTitle}(s) have been ${textBody}.`);
        }
        else {
            useAppService.getState().setModal('', `Failed to ${text} ${settingTitle.toLowerCase()}(s).<br /><br />` + msg);
        }
        this.setState({
            BulkEdit_IsUpdating: false,
        });
    }
    //#endregion === bulk edit ===

    render = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        return (<div className="">
            <table className="table page-header">
                <tbody>
                    <tr>
                        <td className="left" style={{ flex: 2 }}>
                            <h5>Teacher Profile</h5>
                            <div className="form-check">
                                <input
                                    id='formCheck_DeactivatedItems_Toggle'
                                    className='form-check-input cursor-pointer'
                                    type='checkbox'
                                    defaultChecked={this.state.DeactivatedItems_Toggle}
                                    readOnly={true}
                                    onClick={() => this.setState({
                                        DeactivatedItems_Toggle: !this.state.DeactivatedItems_Toggle
                                    }, () => {
                                        this.LoadList_ViaApi(true);
                                    })}
                                ></input>
                                <label className='form-check-label cursor-pointer' htmlFor='formCheck_DeactivatedItems_Toggle'
                                    style={{ color: 'gray', fontSize: 'small', userSelect: 'none', }}> show deactivated teachers</label>
                            </div>
                            <Button
                                variant='outline-primary'
                                onClick={() => this.SendPasswordTo_ALL_OR_SELECTED_Profiles_ByEmailViaApi()}
                                disabled={this.state.isProcessing || this.state.List.length === 0}
                            >Send login credential email to {this.state.BulkEdit_CheckedItems.includes(true) ? 'selected' : 'all'} Teachers</Button>
                            <Button variant="primary"
                                onClick={() => this.BulkEdit_ToggleEditSettingModal()}
                                disabled={this.state.BulkEdit_CheckedItems.length === 0 ? true : (this.state.BulkEdit_CheckedItems.includes(true) ? false : true)}
                            >Bulk Edit</Button>
                            {
                                this.state.PA_View === false ? null :
                                    <>
                                        <button
                                            type="button"
                                            className="btn-link"
                                            onClick={() => this.LoadList_ViaApi(true)}
                                            title="Refresh Teacher Profile list"
                                        ><i className="fa fa-refresh" title="Refresh Teacher Profile list"></i></button>
                                    </>
                            }
                        </td>
                        {/* <td className="center"></td> */}
                        <td className="right">
                            {
                                this.state.PA_Search === false ? null :
                                    <Button
                                        variant='outline-primary'
                                        onClick={() => this.ToggleSearchUserByConditionModal(true)}
                                        disabled={this.state.SearchByConditionModal_Toggle || this.state.isProcessing}
                                    >Search Teacher</Button>
                            }
                            {
                                this.state.PA_Upload === false ? null :
                                    <Button
                                        variant='outline-primary'
                                        onClick={this.ToggleUploadProfileModal}
                                        disabled={this.state.UploadProfileModal_Toggle}
                                    >Upload Teacher</Button>
                            }
                            {
                                this.state.PA_Create === false ? null :
                                    <Button
                                        variant='outline-primary'
                                        onClick={this.ToggleCreateItemUiModal}
                                        disabled={this.state.isProcessing}
                                    >Add Teacher</Button>
                            }
                            {/* <Button
                                variant='outline-primary'
                                onClick={() => this.setState({ redirectLink: getMenuLink(LayoutScreen.Dashboard), redirect: true, })}
                                disabled={this.state.isProcessing}
                            >Back to Dashboard</Button> */}
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className='table table-hover table-bordered tbStyle' cellPadding='10' cellSpacing='10' style={{ fontSize: 14 }}>
                <thead>
                    <tr>
                        <th width='50' className="pointer" onClick={() => this.state.isLoading || this.state.List.length === 0 ? DoNothing() : this.ToggleItemChecked(-1, this.state.BulkEdit_CheckedItems.findIndex(x => x === false) < 0)}>
                            <input type='checkbox' className={this.state.isLoading || this.state.List.length === 0 ? '' : 'pointer'}
                                checked={this.state.isLoading || this.state.List.length === 0 ? false : !(this.state.BulkEdit_CheckedItems.findIndex(x => x === false) > -1)}
                                readOnly={true} disabled={this.state.isLoading || this.state.List.length === 0}></input>
                        </th>
                        {/* <th width='50'>#</th> */}
                        <th className="left">Name</th>
                        <th className='left'>Email</th>
                        <th className='left' width='105'>Teacher Type</th>
                        <th className='left' width='105'>Group</th>
                        <th className='left'>Subject</th>
                        <th className='left'>Classroom</th>
                        {/* <th width='100'>Group</th> */}
                        <th width='75'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.isLoading && !this.state.IsListLoaded ?
                            // <tr><td colSpan='15' align='center'><LoadingIndicator /></td></tr>
                            <tr><td colSpan='15' height={63}><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10 }} /></td></tr>
                            : this.state.List.length > 0 ?
                                this.ListComponents()
                                : <tr><td colSpan='15' align='center'>list is empty</td></tr>
                    }
                    {
                        this.state.List.length === 0 ? null :
                            PagingComponents(15, this.state.TotalRows, this.state.PageIndex, this.state.PageSize, this.CallbackFunctionForPagingComponents_PageSize, this.CallbackFunctionForPagingComponents_PageIndex)
                    }
                </tbody>
            </table>

            {/* Profile - Search by Condition - Modal */}
            <Modal show={this.state.SearchByConditionModal_Toggle}
                // onHide={() => this.state.SearchByCondition_Processing ? DoNothing() : this.resetSearchStudentParams()}
                // onHide={DoNothing}
                onHide={() => this.state.SearchByCondition_Processing ? DoNothing() : this.ToggleSearchUserByConditionModal()}
                centered>
                <Modal.Header closeButton={this.state.SearchByCondition_Processing === false}>
                    <Modal.Title>{
                        this.state.SearchByCondition_Processing ? 'Searching...' : 'Search Teacher by ' + this.state.SearchUserByCondition.Label
                    }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        this.state.SearchByCondition_Processing ?
                            <ProgressBar animated now={100} className='progressbar1' />
                            :
                            <table cellPadding={5} cellSpacing={0} width='100%'>
                                <tbody>
                                    {/* <tr><td><span>Search Teacher by</span></td></tr> */}
                                    <tr>
                                        <td>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                {
                                                    Object.keys(SearchCondition).map((name, key) => {
                                                        const option = SearchCondition[name];
                                                        if (option !== SearchCondition.None) {
                                                            return (<div className='form-control' style={{ border: 0, cursor: 'pointer', }}
                                                                onClick={async () => {
                                                                    this.setState({
                                                                        SearchUserByCondition: SearchCondition.None,
                                                                    });
                                                                    await Delay(0);
                                                                    this.setState({
                                                                        SearchUserByCondition: option,
                                                                        SearchUserByName: option === SearchCondition.Name ? '' : this.state.SearchUserByName,
                                                                        SearchUserByEmail: option === SearchCondition.Email ? '' : this.state.SearchUserByEmail,
                                                                        SearchUserBySchoolName: option === SearchCondition.SchoolName ? '' : this.state.SearchUserBySchoolName,
                                                                        SearchUserByGroups: option === SearchCondition.Groups ? [] : this.state.SearchUserByGroups,
                                                                        SearchUserBySubjects: option === SearchCondition.Subjects ? [] : this.state.SearchUserBySubjects,
                                                                        SearchUserByClassrooms: option === SearchCondition.Classrooms ? [] : this.state.SearchUserByClassrooms,
                                                                    })
                                                                }
                                                                }
                                                                disabled={this.state.SearchByCondition_Processing}
                                                            >
                                                                <input type='radio' name='searchBy' readOnly={true}
                                                                    checked={this.state.SearchUserByCondition === option}
                                                                />&nbsp;&nbsp;<span style={this.state.SearchUserByCondition === option ? { fontWeight: 'bold' } : {}}>{option.Label}</span>
                                                            </div>);
                                                        }
                                                        return null;
                                                    })
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{ height: 50 }}>
                                            {
                                                this.state.SearchUserByCondition === null ? null :
                                                    this.state.SearchUserByCondition.Type === 'Input' ?
                                                        <input className='form-control' type="text" style={{ width: '100%' }}
                                                            id={this.state.SearchUserByCondition.Id}
                                                            placeholder={`(${this.state.SearchUserByCondition.Label})`}
                                                            onChange={(e) => this.SetSearchUserByConditionValue(e.target.value)}
                                                            disabled={this.state.SearchByCondition_Processing}
                                                        />
                                                        :
                                                        this.state.SearchUserByCondition.Type === 'Select' ?
                                                            <ReactSelect
                                                                id={this.state.SearchUserByCondition.Id}
                                                                name={this.state.SearchUserByCondition.Id}
                                                                className="basic-multi-select"
                                                                classNamePrefix='select'
                                                                isMulti
                                                                closeMenuOnSelect={false}
                                                                options={
                                                                    this.state.SearchUserByCondition === null || this.state.SearchUserByCondition === SearchCondition.None ?
                                                                        []
                                                                        :
                                                                        this.state.SearchUserByCondition === SearchCondition.Subjects ?
                                                                            this.state.SubjectOptions
                                                                            :
                                                                            this.state.SearchUserByCondition === SearchCondition.Classrooms ?
                                                                                this.state.ClassroomOptions
                                                                                :
                                                                                this.state.SearchUserByCondition === SearchCondition.Groups ?
                                                                                    this.state.GroupOptions
                                                                                    :
                                                                                    []
                                                                }
                                                                placeholder={
                                                                    this.state.SearchUserByCondition === SearchCondition.Subjects ?
                                                                        'Select Subject(s)...'
                                                                        :
                                                                        this.state.SearchUserByCondition === SearchCondition.Classrooms ?
                                                                            'Select Classroom(s)...'
                                                                            :
                                                                            this.state.SearchUserByCondition === SearchCondition.Groups ?
                                                                                'Select Group(s)...'
                                                                                :
                                                                                ''
                                                                }
                                                                onChange={(option) => this.SetSearchUserByConditionValue(option)}
                                                                theme={theme => ({
                                                                    ...theme,
                                                                    width: 'max-content',
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        neutral50: 'gray',  // placeholder color
                                                                    }
                                                                })}
                                                                isDisabled={this.state.SearchByCondition_Processing}
                                                            />
                                                            :
                                                            <span>&nbsp;</span>
                                            }
                                            {/* <input className='form-control' type="text" style={{ width: '100%' }}
                                                placeholder={this.getSearchInputPlaceholder()}
                                                onChange={(e) => {
                                                    switch (this.state.SearchUserByCondition) {
                                                        case SearchCondition.Name: this.setState({ SearchUserByName: String(e.target.value) }); break;
                                                        case SearchCondition.Email: this.setState({ SearchUserByEmail: String(e.target.value) }); break;
                                                        case SearchCondition.Groups: this.setState({ SearchUserByGroups: String(e.target.value) }); break;
                                                        case SearchCondition.Subjects: this.setState({ SearchUserBySubjects: String(e.target.value) }); break;
                                                        case SearchCondition.Classrooms: this.setState({ SearchUserByClassrooms: String(e.target.value) }); break;
                                                        case SearchCondition.SchoolName: this.setState({ SearchUserBySchoolName: String(e.target.value) }); break;
                                                        default: break;
                                                    }
                                                }}
                                                disabled={this.state.SearchByCondition_Processing}
                                            /> */}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                    }
                </Modal.Body>
                {
                    !this.state.SearchByCondition_Processing ?
                        <Modal.Footer>
                            <Button variant="secondary"
                                onClick={() => this.ToggleSearchUserByConditionModal()}
                            >Cancel</Button>
                            <Button variant="primary"
                                onClick={() => this.SearchUserByCondition_ViaAPI()}
                                disabled={
                                    this.state.SearchUserByCondition === null || this.state.SearchUserByCondition === SearchCondition.None ?
                                        true
                                        :
                                        this.state.SearchUserByCondition === SearchCondition.SchoolName ?
                                            CheckNullValue(this.state.SearchUserBySchoolName) === null
                                            :
                                            this.state.SearchUserByCondition === SearchCondition.Name ?
                                                CheckNullValue(this.state.SearchUserByName) === null
                                                :
                                                this.state.SearchUserByCondition === SearchCondition.Email ?
                                                    CheckNullValue(this.state.SearchUserByEmail) === null
                                                    :
                                                    this.state.SearchUserByCondition === SearchCondition.Classrooms ?
                                                        CheckNullValue(this.state.SearchUserByClassrooms) === null
                                                        :
                                                        this.state.SearchUserByCondition === SearchCondition.Groups ?
                                                            CheckNullValue(this.state.SearchUserByGroups) === null
                                                            :
                                                            this.state.SearchUserByCondition === SearchCondition.Subjects ?
                                                                CheckNullValue(this.state.SearchUserBySubjects) === null
                                                                : true
                                }
                            >Search</Button>
                        </Modal.Footer>
                        : null
                }
            </Modal>

            {/* Teacher Profile - Edit / Create - Modal */}
            < Modal show={this.state.EditItemModal_Toggle}
                onHide={() => this.ToggleEditItemUiModal()}
                centered
                dialogClassName='alert-dialog-bordered'
                size='xl'
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title style={{ fontSize: 20 }}>{this.state.EditItemState} Teacher {
                        this.state.TargetItemIndex < 0 ? null :
                            '(' + CheckObjectStringEmpty(this.state.TargetProfile, 'Email') + ')'
                    }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.EditItemComponents()}
                    {CommonStatusMessage(this.state.EditItemState, this.state.CommonStatus)}
                </Modal.Body>
                {
                    this.state.EditItemState === CommonState.Processing ? null :
                        <Modal.Footer>
                            {
                                this.state.PA_Delete === false ? null :
                                    <Button variant="danger" className="pull-left" onClick={() => this.ToggleDeleteItemModal()}>Delete</Button>
                            }
                            {
                                this.state.PA_Update === false ? null :
                                    <>
                                        {
                                            this.state.EditItemState === CommonState.New ? null :
                                                CheckObjectBoolean(this.state.TargetProfile, 'Deactivated') ?
                                                    <Button variant="primary" className="pull-left" onClick={() => this.ActivateProfile()}>Activate</Button>
                                                    :
                                                    <Button variant="danger" className="pull-left" onClick={() => this.DeactivateProfile()}>Deactivate</Button>
                                        }
                                        <Button variant="secondary" onClick={() => this.ResetItemValue()}>Reset</Button>
                                        <Button variant="primary" onClick={() => {
                                            if (this.state.EditItemState === CommonState.New)
                                                this.UpdateItemViaAPI();
                                            else
                                                this.UpdateItem();
                                        }}>{this.state.EditItemState === CommonState.New ? 'Save' : 'Update'}</Button>
                                    </>
                            }
                            <Button variant="secondary" onClick={() => this.ToggleEditItemUiModal()}>Close</Button>
                        </Modal.Footer>
                }
            </Modal >

            {/* Teacher Profile - Delete - Modal */}
            <Modal show={this.state.DeleteItemModal_Toggle} onHide={() => this.ToggleDeleteItemModal()} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Teacher</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        Teacher: <b>{CheckObjectStringEmpty(this.state.TargetProfile, ItemProperty.Email)}</b>
                        <br />Are you sure you want to <b>delete</b> current teacher ?
                        <br />The deletion is not reversible.
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.ToggleDeleteItemModal()}>Cancel</Button>
                    &nbsp;&nbsp;
                    <Button variant="primary" onClick={() => this.RemoveItem()}>Confirm</Button>
                </Modal.Footer>
            </Modal>

            {/* Teacher Profile - Create / Upload - Modal */}
            <Modal show={this.state.UploadProfileModal_Toggle}
                onHide={this.ToggleUploadProfileModal}
                centered
                size='lg'
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>Upload Teacher Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table width='100%' cellPadding='5'>
                        <tbody>
                            <tr>
                                <td colSpan='3'>
                                    <button
                                        className='link-button'
                                        onClick={() => this.ShowTemplateDownloadAlertBox()}
                                    >Download & use the provided spreadsheet template file</button> for upload purpose.
                                    <p>Using other files with different column name or format will cause errors during data validation.</p>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <input type="file" onChange={this.onUploadFileChange} style={{ width: '100%' }} />*
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>&nbsp;
                                    <span>Continue to upload this file ?</span>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td colSpan={3}>
                                    <div className="form-check setting-checkbox framed-checkbox"
                                        onChange={() => this.setState({ SendEmailAfterUpload: !this.state.SendEmailAfterUpload, })}
                                        style={{ height: 32 }}
                                    >
                                        <input className="form-check-input" type="checkbox" id="checkbox-send-email-after-upload"
                                            readOnly={true}
                                            checked={this.state.SendEmailAfterUpload}
                                        />
                                        <label className="form-check-label" htmlFor="checkbox-send-email-after-upload" style={{ cursor: 'pointer' }}
                                        >Send Email after Upload.</label>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.ToggleUploadProfileModal()}>Cancel</Button>
                    &nbsp;&nbsp;
                    <Button variant="secondary" onClick={() => this.ResetUploadProfileModal('reset')}>Reset</Button>
                    &nbsp;&nbsp;
                    <Button
                        variant="primary"
                        onClick={() => this.TriggerUploadProfileFile()}
                        disabled={this.state.UploadStatus !== UploadState.None}
                    >Upload</Button>
                </Modal.Footer>
            </Modal>

            {/* Teacher Profile - Process Upload - Modal */}
            <Modal show={this.state.UploadStatus !== UploadState.None}
                onHide={DoNothing}
                centered
                dialogClassName='alert-dialog-bordered'
            >
                <Modal.Header>
                    <Modal.Title style={{ fontSize: 20 }}>{this.state.UploadStatus}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ display: 'grid' }}>
                    {UploadStatusMessage(this.state.UploadStatus, this.state.UploadStatusText, this.state.UniqueId)}
                    {this.state.UploadResultModal === null ? null : <>
                        <br />
                        <button type='button' className='btn btn-primary' onClick={() => this.DownloadTableAsXLSX('uploadTableResult_hidden', 'Processed-Profile-Template_' + moment().format('YYYY-MM-DD_HH-mm-ss'))}>Download table as XLSX</button>
                    </>}
                </Modal.Body>
                {
                    this.HideComponent_UploadUiModal() ? null :
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.ResetUploadProfileModal('reload')}>Close</Button>
                        </Modal.Footer>
                }
            </Modal>

            {/* Setting - (BULK) Edit / Update - Modal */}
            <Modal show={this.state.BulkEdit_Toggle_EditSettingModal}
                onHide={() => this.BulkEdit_ToggleEditSettingModal()}
                centered
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>Bulk Edit</Modal.Title>
                </Modal.Header>
                <Modal.Body className="setting-bulk-parent">
                    {this.BulkEdit_SettingModalComponent()}
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="danger"
                        onClick={() => this.BulkEdit_ToggleRemoveSettingModal()}
                        style={{ position: "absolute", left: 0, marginLeft: 15 }}
                        disabled={this.state.BulkEdit_IsUpdating || (this.state.isSuperAdmin ? false : this.state.PA_Delete === false)}
                    >Bulk Remove</Button> */}
                    <Button variant="secondary" onClick={() => this.BulkEdit_ToggleEditSettingModal()} disabled={this.state.BulkEdit_IsUpdating}>Cancel</Button>
                    <Button variant="secondary" onClick={async () => {
                        this.BulkEdit_ToggleEditSettingModal(); //close.
                        await Delay(200);
                        this.BulkEdit_ToggleEditSettingModal(); //open again.
                    }} disabled={this.state.BulkEdit_IsUpdating}>Reset</Button>
                    <Button variant="primary" onClick={() => this.BulkEdit_CUD_Setting_ViaApi()} disabled={this.state.BulkEdit_IsUpdating || this.state.BulkEdit_Setting_checked.indexOf(true) < 0}>Bulk Update</Button>
                </Modal.Footer>
            </Modal >

        </div>);
    }
}