import React from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { Button, Modal, ProgressBar } from "react-bootstrap";
import Select from "react-select";
import moment from "moment";

import LoadingIndicator from "./LoadingIndicator";
import { Locale } from "../utilities/localization/CustomLocalization";
import { PermissionAccess, CapitalizeJsonKeys, CheckNullValue, CheckNumber, CheckObjectBoolean, CheckObjectNullValue, CheckObjectNumber, CheckObjectStringEmpty, CheckStringEmpty, Delay, DelayUntil, DoNothing, RoomFormatDate, FormatList_QuestionSet, FormatTime, GetDurationText, GetGroupData, GetPropIds, PopulateRoomData } from "../utilities/GlobalFunctions";
import { GlobalSetting, LayoutScreen, PermissionAccessType, RoomTypeOptions } from "../utilities/GlobalSetting";
import { useGlobal } from "../utilities/GlobalVariables";
import { useAppService } from "../services/AppService";
import { AlertMode } from "./AlertComponent";

import RoomResultComponent from "./RoomResultComponent";
import PreviewQuestionSetComponent from "./PreviewQuestionSetComponent";
import { RoomDataInitValue, RoomSearchBy, Room_SearchQuery_DefaultModal } from "./Enums/EnumManageRoom";

export default class ManageRoomPreview extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.

        //Room.
        this.EDR_FromTime = React.createRef();
        this.EDR_ToTime = React.createRef();
        this.EDR_FromDate = React.createRef();
        this.EDR_ToDate = React.createRef();
        this.EDR_Duration_Hour = React.createRef();
        this.EDR_Duration_Min = React.createRef();
        this.EDR_Duration_Sec = React.createRef();

        //Room Result.
        this.Ref_RoomResultComponent = React.createRef();

        //Preview Question Set.
        this.Ref_PreviewQuestionSetComponent = React.createRef();
    }

    getInitState = () => ({
        isDevMode: window.location.href.includes('localhost'),
        locale: useGlobal.getState().locale,
        redirect: false,
        redirectLink: '/',
        isLoading: false,

        PA_View: false,
        PA_Search: false,
        PA_Create: false,
        PA_Update: false,
        PA_Delete: false,
        PA_Upload: false,
        PA_Download: false,
        IsAdmin: false,

        //List.
        List: [],
        IsListLoaded: false,
        hasTotalFileQty: false,
        PageIndex: 0,
        PageSize: 99999,  // GlobalSetting.PageSize,
        TotalRows: 0,

        SearchRoomQueryModal: JSON.parse(JSON.stringify(Room_SearchQuery_DefaultModal)),
        SearchRoomQueryModal_Toggle: false,
        SearchRoomQueryModal_Processing: false,

        //Search.
        // SelectedRoomTypeForQuery: 0,
        ShowSearchRoomByRoomCodeModal: false,
        SearchRoomByRoomCode_Processing: false,
        SearchRoomByRoomCode_RoomCode: '',
        //Search Options.
        SearchQsSet_ByGroup: null,          //standard
        SearchQsSet_BySubject: null,        //subject
        SearchQsSet_ByName: '',             //name
        SearchQsSet_MaxQtyShow: 5,          //max result

        //Room.
        RoomData: null,
        ClassroomOptions: [],
        SubjectOptions: [],


    });

    componentWillUnmount = () => { }

    componentDidMount = () => {
        //#region init.
        window.scrollTo(0, 0);
        useGlobal.getState().setScreen(LayoutScreen.ManageRoomPreview);
        // this.LoadRoomList_ViaApi();
        setTimeout(() => {
            this.ToggleSearchRoomQueryModal();
        }, 700);
        //#endregion
    }

    //#region Room List
    CheckPermissions = async () => {
        const { uid, organizerId } = GetPropIds(useGlobal.getState().user);
        this.setState({
            PA_View: PermissionAccess(LayoutScreen.ManageRoomPreview, PermissionAccessType.View),
            PA_Search: PermissionAccess(LayoutScreen.ManageRoomPreview, PermissionAccessType.Search),
            PA_Create: PermissionAccess(LayoutScreen.ManageRoomPreview, PermissionAccessType.Create),
            PA_Update: PermissionAccess(LayoutScreen.ManageRoomPreview, PermissionAccessType.Update),
            PA_Delete: PermissionAccess(LayoutScreen.ManageRoomPreview, PermissionAccessType.Delete),
            PA_Upload: PermissionAccess(LayoutScreen.ManageRoomPreview, PermissionAccessType.Upload),
            PA_Download: PermissionAccess(LayoutScreen.ManageRoomPreview, PermissionAccessType.Download),

            PageSize: CheckNumber(localStorage.getItem(`ManageRoomPreview_PageSize_${uid}_${organizerId}`), GlobalSetting.PageSize),

            IsAdmin: useGlobal.getState().isAdmin,
        });
        await Delay(0);
    }
    LoadRoomList_ViaApi = async (resetPage = false) => {

        await this.CheckPermissions();    //2024.07.24

        //2025.01.27
        await useAppService.getState().getGroups(true);
        // await useAppService.getState().getSubjects(true);
        // await useAppService.getState().getClassrooms(true);
        await this.ReloadSubjectOptions();
        await this.ReloadClassroomOptions();

        const fnName = 'LoadRoomList_ViaApi';
        const params = GetPropIds(useGlobal.getState().user);
        const uid = CheckObjectStringEmpty(params, 'uid');
        const authorId = CheckObjectNumber(params, 'authorId');
        const organizerId = CheckObjectNumber(params, 'organizerId');

        if (authorId === 0 || organizerId === 0)
            return null;

        if (this.state.isDevMode)
            console.log(`${fnName} ${authorId} ${organizerId}`);

        let success = false;
        let messages = [];
        let list = [];
        let totalRows = 0;

        if (resetPage) {
            const pageSize = CheckNumber(localStorage.getItem(`ManageRoomPreview_PageSize_${uid}_${organizerId}`), GlobalSetting.PageSize);
            this.setState({
                PageIndex: 0,
                PageSize: pageSize < GlobalSetting.PageSize ? GlobalSetting.PageSize : pageSize,
                SearchRoomQueryModal: JSON.parse(JSON.stringify(Room_SearchQuery_DefaultModal)),
            });
        }

        this.setState({
            IsListLoaded: false,
            List: [],
            hasTotalFileQty: false,
            // SelectedRoomTypeForQuery: 0,
            isLoading: true,
        });
        // await Delay(200);

        if (authorId > 0 && organizerId > 0) {
            let done = false;
            //Jay revamped to POST 2025.01.16
            const jsonData = JSON.stringify({
                OrganizerId: organizerId,
                AuthorId: authorId,
                RoomTypeId: this.state.SearchRoomQueryModal.RoomTypeId,
                RoomCode: this.state.SearchRoomQueryModal.RoomCode,
                RoomTitle: this.state.SearchRoomQueryModal.RoomTitle,
                GroupId: this.state.SearchRoomQueryModal.GroupId,
                SubjectId: this.state.SearchRoomQueryModal.SubjectId,
                OrderBy: 'RoomId',
                OrderType: 'DESC',
                PageIndex: this.state.PageIndex,
                // PageSize: this.state.PageSize,
                PageSize: 99999,
                // SearchRoomCode: '',
                // SearchRoomId: '',
            });
            if (this.state.isDevMode)
                console.log('LoadRoomList_ViaApi (postData) =\n' + jsonData);
            await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/Quiz/Room/List',
                // + organizerId + '/'
                // + authorId + '/'
                // + this.state.SelectedRoomTypeForQuery + '/'
                // + this.state.PageIndex + '/'
                // + this.state.PageSize,
                // // Api/LearningCentre/Quiz/Room/List/{organizerId}/{authorId}/{roomType}/{pageIndex}/{pageSize}
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: jsonData,
                })
                .then(res => res.json())
                .then(data => {
                    // if (this.state.isDevMode)
                    //     console.log(`${fnName} (source)`, JSON.stringify(data));

                    // success = CheckBoolean(data.success);
                    success = CheckObjectBoolean(data, 'success');
                    if (success) {
                        if (Array.isArray(data.data.list))
                            list = data.data.list;
                        totalRows = CheckObjectNumber(data.data, 'totalRows', list.length);
                    }
                    else {
                        if (CheckObjectNullValue(data, 'message') !== null)
                            messages.push(data.message);
                        if (this.state.isDevMode)
                            console.log('Error', 'api - room list (failed)\n' + JSON.stringify(data));
                    }
                    done = true;
                })
                .catch(error => {
                    messages.push(error.message);
                    if (this.state.isDevMode)
                        console.log('Error', 'api - room list (error)\n' + error.message);
                    done = true;
                });
            await DelayUntil(() => done === true);
        }

        // console.log(`${fnName} (source) \n ${JSON.stringify(list)}`);
        if (list.length > 0) {
            let _List = []
            //2024.07.25
            for (let i = 0; i < list.length; i++) {
                const t_room = await PopulateRoomData(list[i], list[i]['QuestionSet'] ?? this.state.QuestionSet);
                _List.push(t_room);
            }
            // list.map(async (data, key) => {
            //     const t_room = await PopulateRoomData(data, this.state.QuestionSet);
            //     return _List.push(t_room);
            // });
            list = _List;
            if (this.state.isDevMode) {
                console.log(`${fnName} (final)`, JSON.stringify(list));
            }
        }

        if (!success)
            useAppService.getState().setModal(Locale("error", this.state.locale), messages.join('<br />'));

        this.setState({
            TotalRows: totalRows,
            List: list,
            IsListLoaded: true,
            isLoading: false,
        }, () => {
            //Document Room
            // if (this.state.SelectedRoomTypeForQuery === 1)
            if (this.state.SearchRoomQueryModal.RoomTypeId === 1)
                if (this.state.List.length > 0)
                    this.LoadRoomUploadedFileQty();
        });
        await Delay(0);
    }
    GetPlaceholder_Group = () => {
        if (this.state.RoomData !== null) {
            const groupOptions = useAppService.getState().groupOptions;
            let _findIndex = groupOptions.findIndex(x => Number(x.id) === CheckObjectNumber(this.state.RoomData, 'GroupId'));
            if (_findIndex > -1) {
                // if (this.state.SearchQsSet_ByGroup === null)
                //     this.setState({ SearchQsSet_ByGroup: groupOptions[_findIndex], });
                return String(groupOptions[_findIndex].label);
            }
        }
        return Locale("not-specify-group", this.state.locale);
    }
    GetPlaceholder_Subject = () => {
        if (this.state.RoomData !== null) {
            const subjectOptions = useAppService.getState().subjectOptions;
            let _findIndex = subjectOptions.findIndex(x => Number(x.id) === CheckObjectNumber(this.state.RoomData, 'SubjectId'));
            if (_findIndex > -1) {
                // if (this.state.SearchQsSet_BySubject === null)
                //     this.setState({ SearchQsSet_BySubject: subjectOptions[_findIndex], });
                return String(subjectOptions[_findIndex].label);
            }
        }
        return Locale("not-specify-subject", this.state.locale);
    }
    ListComponents = () => {
        if (this.state.List.length > 0) {
            let listRows = [];
            this.state.List.map((data, key) => {
                const qSet = CheckObjectNullValue(data, 'QuestionSet');
                return listRows.push(
                    <tr key={'list-item-' + key}>
                        <td>{key + 1 + this.state.PageIndex}</td>
                        <td className="icon-color">
                            {CheckObjectStringEmpty(data, 'RoomCode')}<br />
                            <button type="button" className={`btn-link ${CheckObjectStringEmpty(qSet, 'Remark') === '' ? 'gray' : 'blue'}`}
                                onClick={() => {
                                    let title = 'Room <' + CheckObjectStringEmpty(data, 'RoomCode') + '> :: Question Set Info';
                                    let content = null;
                                    if (qSet !== null) {
                                        content = <>
                                            <table>
                                                <tbody>
                                                    <tr hidden={true}>
                                                        <th>Question Set Unique Id</th>
                                                        <td>:</td>
                                                        <td>{CheckObjectStringEmpty(qSet, 'UniqueId', '-')}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Group</th>
                                                        <td>:</td>
                                                        <td>{CheckObjectStringEmpty(qSet, 'GroupName', '-')}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Subject</th>
                                                        <td>:</td>
                                                        <td>{CheckObjectStringEmpty(qSet, 'SubjectName', '-')}</td>
                                                    </tr>
                                                    <tr>
                                                        <th width={150}>Total Question</th>
                                                        <td width={15}>:</td>
                                                        <td>{CheckObjectStringEmpty(qSet, 'TotalQuestion', '-')}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div style={{ marginTop: 15, padding: 5, border: '1px solid gray', borderRadius: 5 }}>
                                                <table width='100%' cellPadding={7}>
                                                    <tbody>
                                                        <tr><th>Remark</th></tr>
                                                        <tr><td>{CheckObjectStringEmpty(qSet, 'Remark', '-')}</td></tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>;
                                        useAppService.getState().setModal(title, content);
                                    }
                                }}
                                style={{ marginTop: 7 }}
                            ><i className="fa fa-info-circle" title='Question Set Info' style={{ fontSize: 25 }}></i></button>
                        </td>
                        <td className="left">{CheckObjectStringEmpty(data, 'RoomTitle')}
                            {
                                CheckNullValue(data, 'Group') === null ? null :
                                    <><br /><span style={{ color: 'gray', fontSize: 12, }}>{CheckObjectStringEmpty(data.Group, 'Name')}</span></>
                            }
                            {
                                CheckObjectNumber(data, 'RoomType') === 1 ?
                                    CheckNullValue(data, 'Remark') === null ? null :
                                        <><br /><span style={{ color: 'gray', fontSize: 12, }}>{'< ' + data.Remark + ' >'}</span></>
                                    : null
                            }
                        </td>
                        <td hidden={this.state.hasTotalFileQty === false}>{CheckObjectNumber(data, 'TotalFile')}</td>
                        <td>{CheckObjectStringEmpty(data, 'GroupName')}</td>
                        <td>{CheckObjectStringEmpty(data, 'SubjectName')}</td>
                        <td>{CheckObjectStringEmpty(data, 'TimeStart') === '_none' ? '12:00 AM' : FormatTime(CheckObjectStringEmpty(data, 'TimeStart'))}</td>
                        <td>{CheckObjectStringEmpty(data, 'TimeEnd') === '_none' ? '11:59 PM' : FormatTime(CheckObjectStringEmpty(data, 'TimeEnd'))}</td>
                        <td>{RoomFormatDate(data)}</td>
                        <td hidden={!this.state.IsAdmin}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>{
                                data['AuthorUser'] === undefined || data['AuthorUser'] === null ? '-' :
                                    data['AuthorUser']['Id'] === 0 ?
                                        <i style={{ color: 'red', fontWeight: 500 }}>- User has been deleted -</i>
                                        :
                                        Object.keys(data['AuthorUser']).map((au, auKey) => {
                                            if (au.includes('Id'))
                                                return null;
                                            const value = CheckObjectStringEmpty(data['AuthorUser'], au, '-');
                                            return <span key={'au_' + au} title={`(${au}) ${value}`}>{value}</span>;
                                        })
                            }</div>
                        </td>
                        <td style={{ padding: 5 }}>
                            {/* 2021.12.10 - new pattern. */}
                            {
                                this.state.PA_Update === false ? null :
                                    <Button
                                        variant='outline-primary'
                                        onClick={() => this.LoadSelectedRoom(data.RoomCode, data.RoomId)}
                                        style={{ width: '100%', }}
                                    >Edit</Button>
                            }
                            {
                                this.state.PA_Update === false ? null :
                                    <Button
                                        variant='outline-primary'
                                        // onClick={() => this.props.TogglePage(this.props.Toggle.RoomTypeFileDetail, data)}
                                        hidden={CheckObjectNumber(data, 'RoomType') === 1 ? false : true}
                                        style={{ width: '100%', marginTop: 5, }}
                                    >Detail</Button>
                            }
                            {
                                this.state.PA_Update === false ? null :
                                    <Button
                                        variant='outline-primary'
                                        // onClick={() => this.LoadResultFromSelectedRoom(data.RoomCode, data.RoomId)}
                                        onClick={() => this.Ref_RoomResultComponent.current.LoadResultFromSelectedRoom(data.RoomCode, data.RoomId, null, null, null, LayoutScreen.ManageRoomPreview, null)}
                                        style={{ width: '100%', marginTop: 5, }}
                                    >Result</Button>
                            }
                        </td>
                    </tr >
                );
            });
            return listRows;
        }
        return this.state.IsListLoaded ?
            <tr><td colSpan='15' align='center'>list is empty</td></tr>
            : <tr><td colSpan='15' align='center'><LoadingIndicator /></td></tr>;
    }
    LoadRoomUploadedFileQty = async () => {
        if (this.state.List.length > 0) {
            let _roomList = this.state.List;
            await Promise.all(
                this.state.List.map(async (data, key) => {
                    if (_roomList[key].TotalFile === undefined) {
                        _roomList[key].TotalFile = '0';
                        await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/Quiz/Room/File/RequestFileCount/'
                            + CheckStringEmpty(data, 'RoomCode') + '/'
                            + CheckStringEmpty(data, 'RoomId'),
                            // Api/LearningCentre/Quiz/Room/File/RequestFileCount/{roomcode}/{room_id}
                            {
                                method: 'GET',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                },
                            })
                            .then(res => res.json())
                            .then(data => {
                                if (this.state.isDevMode)
                                    console.log(JSON.stringify(data));

                                if (!data.success)
                                    if (this.state.isDevMode)
                                        console.log('Error', 'api - room total file (failed)\n' + JSON.stringify(data));

                                if (CheckObjectNullValue(data, 'data') !== null)
                                    _roomList[key].TotalFile = String(data.data);
                            })
                            .catch(error => {
                                if (this.state.isDevMode)
                                    console.log('Error', 'api - room total file (error)\n' + error.message);
                            });
                    }
                    return null;
                })
            );
            this.setState({
                List: _roomList,
                hasTotalFileQty: true,
            });
        }
    }
    //#region === Paging Components
    CallbackFunctionForPagingComponents_PageSize = (pageSize = GlobalSetting.PageSize) => {
        this.setState({
            PageSize: pageSize < GlobalSetting.PageSize ? GlobalSetting.PageSize : pageSize,
        }, () => {
            const { uid, organizerId } = GetPropIds(useGlobal.getState().user);
            localStorage.setItem(`ManageRoomPreview_PageSize_${uid}_${organizerId}`, this.state.PageSize);
            setTimeout(() => {
                this.LoadRoomList_ViaApi();
            }, 500);
        });
    }
    CallbackFunctionForPagingComponents_PageIndex = (pageIndex = 0) => {
        this.setState({
            PageIndex: pageIndex,
        }, () => {
            setTimeout(() => {
                this.LoadRoomList_ViaApi();
            }, 500);
        });
    }
    //#endregion === Paging Components
    //#endregion

    //#region === Search Room by Params ===//
    ToggleSearchRoomQueryModal = () => {
        const toggle = !this.state.SearchRoomQueryModal_Toggle;
        this.setState({
            SearchRoomQueryModal_Toggle: toggle,
            SearchRoomQueryModal: JSON.parse(JSON.stringify(Room_SearchQuery_DefaultModal)),
        });
    }
    SetSearchRoomQueryParam = (propertyName = '', value = null) => {
        let params = this.state.SearchRoomQueryModal;
        switch (propertyName) {
            case 'RoomCode': params[propertyName] = String(value).trim(); break;
            case 'RoomTitle': params[propertyName] = String(value).trim(); break;
            case 'RoomTypeId': params[propertyName] = Number(value); break;
            case 'GroupId': params[propertyName] = Number(value); break;
            case 'SubjectId': params[propertyName] = Number(value); break;
            default: break;
        }
        this.setState({
            SearchRoomQueryModal: params,
        });
    }
    //#endregion === Search Room by Params ===//

    //#region === Edit Room === start ===//
    ToggleCreateEditRoomModal = async () => {
        if (this.state.PA_Update === false)
            return null;
        this.setState({
            ShowCreateEditRoomModal: !this.state.ShowCreateEditRoomModal
        });
        await Delay(0);
        if (this.state.ShowCreateEditRoomModal) {
            // await this.ReloadSubjectOptions();
            // await this.ReloadClassroomOptions();
        }
        else {
            this.setState({ Mode_Create_RoomDataModal: false, });
            this.ResetEditRoomParams();
            await Delay(0);
        }
    }
    ReloadSubjectOptions = async () => {
        await useAppService.getState().getSubjects();
        // await useAppService.getState().getSubjects(true);
        this.setState({
            SubjectOptions: useAppService.getState().subjectOptions,
        });
        await Delay(0);
    }
    ReloadClassroomOptions = async () => {
        await useAppService.getState().getClassrooms(true, false);
        this.setState({
            ClassroomOptions: useAppService.getState().classroomOptions,
        });
        await Delay(0);
    }
    ResetEditRoomParams = () => {
        let roomData = { ...RoomDataInitValue };
        roomData.DateStart = moment().format('YYYY-MM-DD');
        roomData.DateEnd = moment().format('YYYY-MM-DD');
        this.setState({
            RoomData: roomData,
            RoomData_RoomCode_isValid: false,
            Cached_RoomData: null,
            EditRoom_Group: null,
            Cached_EditRoom_Group: null,
            EditRoom_QuestionSet: null,
            Cached_EditRoom_QuestionSet: null,
            SearchByCondition_QuestionSet: RoomSearchBy.Group,
        });
    }
    ResetEditedRoomData = async () => {
        const room = this.state.RoomData;
        this.ToggleCreateEditRoomModal();
        await Delay(500);
        this.LoadSelectedRoom(room.RoomCode, room.RoomId);
    }
    LoadSelectedRoom = async (roomCode = '', roomId = '') => {

        if (CheckNullValue(roomCode) === null || CheckNullValue(roomId) === null)
            return null;

        this.ResetEditRoomParams();
        useAppService.getState().setModal('', 'Loading Room <' + roomCode + '>...', null, AlertMode.Loading);
        await Delay(500);

        if (this.state.isDevMode)
            console.log('LoadSelectedRoom = ' + roomCode + ' / ' + roomId);

        let _roomData = null;
        const selectedRoomIndex = this.state.List.findIndex(x => CheckObjectStringEmpty(x, 'RoomCode') === String(roomCode) && CheckObjectStringEmpty(x, 'RoomId') === String(roomId));
        if (selectedRoomIndex > -1)
            _roomData = await PopulateRoomData(this.state.List[selectedRoomIndex], this.state.QuestionSet);

        if (_roomData !== null) {

            if (this.state.isDevMode)
                console.log('LoadSelectedRoom =\n' + JSON.stringify(_roomData));

            this.setState({
                RoomData: _roomData,
                Cached_RoomData: JSON.parse(JSON.stringify(_roomData)),
                EditRoom_QuestionSet: null,
                Cached_EditRoom_QuestionSet: null,
                RoomData_RoomCode_isValid: CheckObjectNumber(_roomData, 'RoomCode') > 0,
            }, async () => {
                useAppService.getState().setModal();
                this.ToggleCreateEditRoomModal();
                await Delay(0);
                this.Recalculate_Duration_EditRoom();
                this.Recalculate_DateTime_EditRoom();
                // this.EDR_CHK_RandomQuestionMode.current.checked = CheckObjectBoolean(_roomData, 'RandomQuestionMode');
                // this.EDR_CHK_RestrictAccessToTimeRangeOnly.current.checked = CheckObjectBoolean(_roomData, 'RestrictAccessToTimeRangeOnly');
                // this.EDR_CHK_ForceRetrictedAccess.current.checked = CheckObjectBoolean(_roomData, 'ForceRetrictedAccess');
                // this.EDR_CHK_QuizEnded.current.checked = CheckObjectBoolean(_roomData, 'QuizEnded');
                // this.EDR_CHK_EnableStatisticReport.current.checked = CheckObjectBoolean(_roomData, 'EnableStatisticReport');
                // this.EDR_CHK_ExcludedFromStatisticReport.current.checked = CheckObjectBoolean(_roomData, 'ExcludedFromStatisticReport');

                if (CheckObjectNullValue(_roomData, 'QuestionSetUniqueId') !== null) {
                    await this.LoadRoomQuestionSet_ViaApi();
                    this.Recalculate_Duration_EditRoom();
                }

                // this.SaveDataInput();
            });
        }
        else {
            //     useAppService.getState().setModal('', '<b>Unable to load Room <' + roomCode + '>.</b><br /><br /><i>' + _error + '</i>');
            useAppService.getState().setModal('Error', 'Room <' + roomCode + '> not found.');
        }
    }
    Recalculate_Duration_EditRoom = () => {
        if (this.state.ShowCreateEditRoomModal) {
            let _roomData = this.state.RoomData;

            let _hr = Number(this.EDR_Duration_Hour.current.value);
            let _min = Number(this.EDR_Duration_Min.current.value);
            let _sec = Number(this.EDR_Duration_Sec.current.value);

            let _qnQty = CheckObjectNumber(_roomData, 'QnQty');
            if (_qnQty === 0) {
                if (CheckObjectNullValue(_roomData, 'QuestionSet') !== null)
                    _qnQty = CheckObjectNumber(_roomData.QuestionSet, 'TotalQuestion');
            }

            let _duration = Number(_roomData.Duration);
            let _durationPerQuestion = Number(_roomData.DurationPerQuestion);
            // console.log('hr = ' + _hr + '\nmin = ' + _min + '\nsec = ' + _sec + '\nDuration = ' + _duration);

            if (_hr === 0 && _min === 0 && _sec === 0 && _duration > 0) {
                //Initial.
                _hr = Number((_duration / 3600).toFixed(3).split('.')[0]);
                _min = Number(((_duration - (_hr * 3600)) / 60).toFixed(3).split('.')[0]);
                _sec = _duration - (_hr * 3600) - (_min * 60);
                this.EDR_Duration_Hour.current.value = _hr;
                this.EDR_Duration_Min.current.value = _min;
                this.EDR_Duration_Sec.current.value = _sec;
                // console.log('hr = ' + _hr + '\nmin = ' + _min + '\nsec = ' + _sec);
            }
            else {
                //Edit Room Duration.
                if (_hr >= 6) {
                    _hr = 6;
                    _min = 0;
                    _sec = 0;
                    this.EDR_Duration_Hour.current.value = _hr;
                    this.EDR_Duration_Min.current.value = _min;
                    this.EDR_Duration_Sec.current.value = _sec;
                }
                if (_min > 59) {
                    _min = 59;
                    this.EDR_Duration_Min.current.value = _min;
                }
                if (_sec > 59) {
                    _sec = 59;
                    this.EDR_Duration_Sec.current.value = _sec;
                }
                _duration = (_hr * 3600) + (_min * 60) + _sec;
                _roomData['Duration'] = _duration;
                if (_qnQty > 0) {
                    _durationPerQuestion = Number((_duration / _qnQty).toFixed(0));
                    _roomData['DurationPerQuestion'] = _durationPerQuestion;
                }
                this.setState({
                    RoomData: _roomData,
                });
            }
        }
    }
    Recalculate_DateTime_EditRoom = () => {
        if (this.state.ShowCreateEditRoomModal) {
            let _roomData = this.state.RoomData;
            if (_roomData !== null) {
                this.EDR_FromDate.current.value = _roomData.DateStart;
                this.EDR_ToDate.current.value = _roomData.DateEnd;
                this.EDR_FromTime.current.value = _roomData.TimeStart;
                this.EDR_ToTime.current.value = _roomData.TimeEnd;
            }
        }
    }
    LoadRoomQuestionSet_ViaApi = async () => {
        if (this.state.RoomData !== null) {
            if (CheckObjectNullValue(this.state.RoomData, 'QuestionSetUniqueId') !== null) {
                const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
                //Fetch.
                let _questionSet = null;
                await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/Quiz/QuestionSet/Get/'
                    + organizerId + '/'
                    + authorId + '/'
                    + CheckObjectStringEmpty(this.state.RoomData, 'QuestionSetUniqueId'),
                    // Api/LearningCentre/Quiz/QuestionSet/Get/{organizerId}/{authorId}/{uniqueId}
                    {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },
                    })
                    .then(res => res.json())
                    .then(data => {
                        // if (this.state.isDevMode)
                        //     console.log('LoadRoomQuestionSet_ViaApi (source)', JSON.stringify(data));
                        if (data.success)
                            _questionSet = data.data;
                        else
                            if (this.state.isDevMode)
                                console.log('Error', 'api - question set (failed)\n' + JSON.stringify(data));
                    })
                    .catch(error => {
                        if (this.state.isDevMode)
                            console.log('Error', 'api - question set (error)\n' + error.message);
                    });

                if (_questionSet !== null) {
                    //Finalize json object.
                    _questionSet = CapitalizeJsonKeys(_questionSet);
                    // if (this.state.isDevMode)
                    //     console.log('LoadRoomQuestionSet_ViaApi (CapitalizeJsonKeys)', JSON.stringify(_questionSet));

                    let _List = [];
                    _List.push(_questionSet);
                    const groupOptions = useAppService.getState().groupOptions;
                    // const subjectOptions = useAppService.getState().subjectOptions;
                    const subjectOptions = this.state.SubjectOptions;
                    _List = FormatList_QuestionSet(_List, groupOptions, subjectOptions);
                    _questionSet = _List[0];
                    if (this.state.isDevMode)
                        console.log('LoadRoomQuestionSet_ViaApi (final)', JSON.stringify(_questionSet));

                    const { group } = GetGroupData(CheckObjectNullValue(_questionSet, 'Group'), CheckObjectNumber(_questionSet, 'GroupId'));

                    let roomData = this.state.RoomData;
                    roomData.QuestionSet = _questionSet;
                    let roomData_cached = this.state.Cached_RoomData;
                    roomData_cached.QuestionSet = _questionSet;

                    this.setState({
                        RoomData: roomData,
                        Cached_RoomData: roomData_cached,
                        EditRoom_QuestionSet: _questionSet,
                        Cached_EditRoom_QuestionSet: JSON.parse(JSON.stringify(_questionSet)),
                        EditRoom_Group: group,
                        Cached_EditRoom_Group: group === null ? null : JSON.parse(JSON.stringify(group)),
                    });
                }
            }
        }
    }
    //#endregion === Edit Room === end ===//

    GetClassroomOptions_Component = () => {
        let components = [];
        let roomData = this.state.RoomData;
        const classroomOptions = this.state.ClassroomOptions;
        if (Array.isArray(classroomOptions) && roomData !== null && roomData !== undefined) {

            //select all.
            components.push(<div className="form-check setting-checkbox"
            // onChange={() => {
            //     let roomData = this.state.RoomData;
            //     const selectAll_ele = document.getElementById('checkbox-classroom-select-all');
            //     const selectAll_checked = selectAll_ele === null ? false : selectAll_ele.checked;
            //     let tmp_classrooms = [];
            //     let tmp_classrooms_id = [];
            //     classroomOptions.map((option, key) => {
            //         const ele = document.getElementById(`checkbox-classroom-${key + 1}`);
            //         if (ele !== null) {
            //             ele.checked = selectAll_checked;
            //             if (selectAll_checked) {
            //                 tmp_classrooms.push(String(option.value));
            //                 tmp_classrooms_id.push(Number(option.id));
            //             }
            //         }
            //         return null;
            //     });
            //     tmp_classrooms_id.sort((a, b) => a - b);
            //     tmp_classrooms = tmp_classrooms.filter(x => CheckNullValue(x) !== null);
            //     tmp_classrooms.sort((a, b) => {
            //         if (a < b) { return -1; }
            //         if (a > b) { return 1; }
            //         return 0;
            //     });
            //     roomData['Classrooms'] = tmp_classrooms.join(',');
            //     roomData['ClassroomIds'] = tmp_classrooms_id;
            //     this.setState({
            //         RoomData: roomData,
            //     }, () => {
            //         console.log('(Select All) Classrooms =\n' + JSON.stringify(roomData))
            //     });
            // }}
            >
                <input className="form-check-input" type="checkbox" value="" id={`checkbox-classroom-select-all`}
                    readOnly={true}
                    // defaultChecked={this.state.RoomData === null || Array.isArray(this.state.ClassroomOptions) === false ? false :
                    //     String(this.state.RoomData['Classrooms']).split(',').filter(x => CheckNullValue(x) !== null).length === this.state.ClassroomOptions.length}
                    // checked={roomData === null || Array.isArray(roomData['ClassroomIds']) === false ? false :
                    //     classroomOptions.length === Array(roomData['ClassroomIds']).length}
                    checked={Array.isArray(roomData['ClassroomIds']) ? classroomOptions.length === roomData['ClassroomIds'].length : false}
                    disabled={true}
                />
                <label className='form-check-label'
                    htmlFor={`checkbox-classroom-select-all`}
                // style={{ cursor: 'pointer' }}
                >Select All</label>
            </div>);

            //select by group.
            //#region 
            //2025.01.24
            let groupOptions = [];
            classroomOptions.map((option, key) => {
                if (groupOptions.findIndex(x => x.id === option.groupId) < 0) {
                    groupOptions.push({
                        id: CheckObjectNumber(option, 'groupId'),
                        group: CheckObjectStringEmpty(option, 'group'),
                        classroomId: CheckObjectNumber(option, 'id'),
                        classroom: CheckObjectStringEmpty(option, 'value'),
                    });
                }
                return null;
            });
            groupOptions.map((g_option, gkey) => {
                const group_id = CheckObjectNumber(g_option, 'id')
                const g_GroupClassrooms = classroomOptions.filter(x => CheckObjectNumber(x, 'groupId') === group_id);
                const g_totalGroupQty = Array.isArray(g_GroupClassrooms) ? g_GroupClassrooms.length : 0;
                // const g_classrooms = Array.isArray(roomData['Classrooms']) ? roomData['Classrooms'] : [];
                const classrooms_id = Array.isArray(roomData['ClassroomIds']) ? roomData['ClassroomIds'] : [];
                let g_selectedGroupQty = 0;
                classrooms_id.forEach(crId => {
                    if (classroomOptions.some(x => CheckObjectNumber(x, 'id') === Number(crId) && CheckObjectNumber(x, 'groupId') === group_id))
                        g_selectedGroupQty++;
                });

                components.push(<div className="form-check setting-checkbox"
                // onChange={() => {
                //     let selectAll_ele = document.getElementById(`checkbox-classroom-select-by-groupId-${group_id}`);
                //     const selectAll_checked = selectAll_ele === null ? false : selectAll_ele.checked;
                //     selectAll_ele.checked = !selectAll_ele.checked;
                //     let tmp_classrooms = [];
                //     let tmp_classrooms_id = JSON.parse(JSON.stringify(classrooms_id));
                //     if (Array.isArray(tmp_classrooms_id)) {
                //         g_GroupClassrooms.map((g_c_option, gcKey) => {
                //             const g_c_id = CheckObjectNumber(g_c_option, 'id');
                //             if (selectAll_checked) {
                //                 //add.
                //                 const findIndex_id = tmp_classrooms_id.findIndex(x => Number(x) === g_c_id);
                //                 if (findIndex_id < 0)
                //                     tmp_classrooms_id.push(Number(g_c_option.id));
                //             }
                //             else {
                //                 //remove.
                //                 const findIndex_id = tmp_classrooms_id.findIndex(x => Number(x) === g_c_id);
                //                 if (findIndex_id > -1)
                //                     tmp_classrooms_id.splice(findIndex_id, 1);
                //             }
                //             return null;
                //         });
                //         classroomOptions.map((c_option, key) => {
                //             const findIndex = tmp_classrooms_id.findIndex(x => Number(x) === CheckObjectNumber(c_option, 'id'));
                //             if (findIndex > -1)
                //                 tmp_classrooms.push(CheckObjectStringEmpty(c_option, 'value'));
                //             return null;
                //         });
                //         tmp_classrooms_id.sort((a, b) => a - b);
                //         tmp_classrooms.sort((a, b) => {
                //             if (a < b) { return -1; }
                //             if (a > b) { return 1; }
                //             return 0;
                //         });
                //         roomData['Classrooms'] = tmp_classrooms.join(',');
                //         roomData['ClassroomIds'] = tmp_classrooms_id;
                //         this.setState({
                //             RoomData: roomData,
                //         }, () => {
                //             console.log(`(Select By Group (${g_option.group}) (${g_option.id})) Classrooms =\n ${JSON.stringify(roomData)}`)
                //         });
                //     }
                // }}
                >
                    <input className="form-check-input" type="checkbox" value="" id={`checkbox-classroom-select-by-groupId-${group_id}`}
                        readOnly={true}
                        checked={g_selectedGroupQty === g_totalGroupQty}
                        disabled={true}
                    />
                    <label className='form-check-label'
                        htmlFor={`checkbox-classroom-select-by-groupId-${g_option.id}`}
                    // style={{ cursor: 'pointer' }}
                    >Select All of {g_option.group} ({`${g_selectedGroupQty}/${g_totalGroupQty}`})</label>
                </div>);
                return null;
            });
            //#endregion

            components.push(<hr />);

            //classrooms.
            classroomOptions.map((option, key) => {
                return components.push(<div className="form-check setting-checkbox"
                // onChange={() => this.SaveDataInput(option, RoomDataInput.Classrooms)}
                >
                    <input className="form-check-input" type="checkbox" value="" id={`checkbox-classroom-${key + 1}`}
                        readOnly={true}
                        checked={this.state.RoomData === null ? false : String(this.state.RoomData['Classrooms']).split(',').includes(String(option.value))}
                        disabled={true}
                    />
                    <label className='form-check-label'
                        htmlFor={`checkbox-classroom-${key + 1}`}
                    // style={{ cursor: 'pointer' }}
                    >{CheckObjectStringEmpty(option, 'value', `-${key}-`)} ({CheckObjectStringEmpty(option, 'group')})</label>
                </div>);
            });
        }
        return (components);
    }
    GetSelectedClassrooms_Component = () => {
        let components = [];
        const classroomOptions = this.state.ClassroomOptions;
        const classrooms = this.state.RoomData['Classrooms'].split(',');
        let classrooms_group = [];
        classroomOptions.forEach((data, key) => {
            const findIndex = classrooms.findIndex(x => String(x) === CheckObjectStringEmpty(data, 'value'));
            if (findIndex > -1) {
                classrooms_group.push({ name: CheckObjectStringEmpty(data, 'value'), groupId: CheckObjectNumber(data, 'groupId'), group: CheckObjectStringEmpty(data, 'group') });
            }
        });
        classrooms_group.sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return a.groupId - b.groupId;
        });
        const filteredClassroomGroups = Array.from(new Map(classrooms_group.map(item => [item.groupId, item])).values());
        filteredClassroomGroups.forEach((cr_group, gKey) => {
            const classrooms_per_group = classrooms_group.filter(x => x.groupId === cr_group.groupId);
            components.push(<span style={{ color: 'black' }}>{cr_group.group + ': '}</span>);
            classrooms_per_group.forEach((cr, crKey) => {
                components.push(cr.name + (crKey < classrooms_per_group.length - 1 ? ', ' : ''));
            });
            if (gKey + 1 < filteredClassroomGroups.length)
                components.push(<br />);
        });
        return (components);
    }

    render = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        return (<div className="">
            <table className="table page-header">
                <tbody>
                    <tr>
                        <td className="left">
                            <h5>Room (Preview)</h5>
                            {
                                this.state.PA_View === false ? null :
                                    <>
                                        <Button
                                            variant='outline-primary'
                                            onClick={() => {
                                                useAppService.getState().setModal('', 'coming soon');
                                            }}
                                        >Export Room List</Button>
                                        <button
                                            type="button"
                                            className="btn-link"
                                            onClick={() => this.LoadRoomList_ViaApi(true)}
                                            title="Refresh Room list"
                                        ><i className="fa fa-refresh" title="Refresh Room list"></i></button>
                                    </>
                            }
                        </td>
                        <td className="center"></td>
                        <td className="right">
                            <Button
                                variant='outline-primary'
                                onClick={() => this.ToggleSearchRoomQueryModal()}
                            >Search Room</Button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className='table table-hover table-bordered tbStyle' cellPadding='10' cellSpacing='10' style={{ fontSize: 14 }}>
                <thead>
                    <tr>
                        <th width='50'>#</th>
                        <th width='100'>Room&nbsp;Code</th>
                        <th className="left">Room&nbsp;Title</th>
                        <th hidden={this.state.hasTotalFileQty === false} width='85'>Total&nbsp;Files</th>
                        <th width='120'>Group</th>
                        <th width='120'>Subject</th>
                        <th width='90'>Time&nbsp;Start</th>
                        <th width='90'>Time&nbsp;End&nbsp;</th>
                        <th width='110'>Date&nbsp;/&nbsp;Period</th>
                        <th width='110' hidden={!this.state.IsAdmin}>Created By</th>
                        <th width='95'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.isLoading && !this.state.IsListLoaded ?
                            <tr><td colSpan='15' height={63}><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10 }} /></td></tr>
                            : this.state.List.length > 0 ?
                                this.ListComponents()
                                : <tr><td colSpan='15' align='center'>list is empty</td></tr>
                    }
                    {
                        // this.state.List.length === 0 ? null :
                        //     PagingComponents(15, this.state.TotalRows, this.state.PageIndex, this.state.PageSize, this.CallbackFunctionForPagingComponents_PageSize, this.CallbackFunctionForPagingComponents_PageIndex)
                    }
                </tbody>
            </table>

            {/* Room - Edit Selected - Modal */}
            <Modal size='lg' show={this.state.ShowCreateEditRoomModal} onHide={() => this.ToggleCreateEditRoomModal()}>
                {
                    this.state.RoomData === null ?
                        <Modal.Body>empty room data.</Modal.Body>
                        :
                        <>
                            <Modal.Header closeButton>
                                <Modal.Title>{`Room <${CheckObjectNumber(this.state.RoomData, 'RoomCode')}>`}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{ paddingTop: 0 }}>
                                <div style={{ width: '100%' }}>
                                    <table className='table tbStyle1' cellPadding='10' cellSpacing='10' border='0' style={{ marginBottom: 0, borderStyle: 'hidden' }}>
                                        <tbody>
                                            <tr>
                                                <td width='155'>Room Code</td>
                                                <td style={{ gap: 15, }}>
                                                    <b>{CheckObjectStringEmpty(this.state.RoomData, 'RoomCode')}</b>
                                                    <Button
                                                        variant='primary'
                                                        // onClick={() => this.LoadResultFromSelectedRoom(data.RoomCode, data.RoomId)}
                                                        onClick={() => this.Ref_RoomResultComponent.current.LoadResultFromSelectedRoom(
                                                            CheckObjectStringEmpty(this.state.RoomData, 'RoomCode'),
                                                            CheckObjectStringEmpty(this.state.RoomData, 'RoomId'),
                                                            null, null, null, LayoutScreen.ManageRoomPreview, null
                                                        )}
                                                        style={{ marginLeft: 10, }}
                                                    >View Result</Button>
                                                    {/* <Button
                                                        variant='primary'
                                                        onClick={async () => {
                                                            //force to sync room result again.
                                                            this.Ref_RoomResultComponent.current.LoadResultFromSelectedRoom(
                                                                CheckObjectStringEmpty(this.state.RoomData, 'RoomCode'),
                                                                CheckObjectStringEmpty(this.state.RoomData, 'RoomId'),
                                                                true, null, null, LayoutScreen.ManageRoom, null
                                                            );
                                                            await Delay(500);
                                                            //close modal ui.
                                                            this.ToggleCreateEditRoomModal();
                                                        }}
                                                        style={{ marginLeft: 13, }}
                                                    >Refresh (Last Sync: {CheckObjectStringEmpty(this.state.RoomData, 'LastSyncDone', '-')})</Button> */}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign='middle'>Room Title *</td>
                                                <td>
                                                    <input type="text" className="form-control" style={{ width: '100%' }}
                                                        // onChange={(e) => { this.SaveDataInput(e.target.value, RoomDataInput.Title); }}
                                                        defaultValue={CheckObjectStringEmpty(this.state.RoomData, 'RoomTitle')}
                                                        disabled={true}
                                                        readOnly={true}
                                                        onChange={() => { }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign='middle'>Group *</td>
                                                <td>
                                                    <Select
                                                        options={useAppService.getState().groupOptions}
                                                        placeholder={this.GetPlaceholder_Group()}
                                                        theme={theme => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                neutral50: 'black',  // placeholder color
                                                            },
                                                        })}
                                                        // onChange={(option) => {
                                                        //     if (this.state.isDevMode)
                                                        //         console.log(JSON.stringify(option));
                                                        //     this.SaveDataInput(option, RoomDataInput.Group);
                                                        // }}
                                                        isDisabled={true}
                                                        readOnly={true}
                                                        onChange={() => { }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign='middle'>Subject *</td>
                                                <td>
                                                    <Select
                                                        // options={useAppService.getState().subjectOptions}
                                                        options={this.state.SubjectOptions}
                                                        placeholder={this.GetPlaceholder_Subject()}
                                                        theme={theme => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                neutral50: 'black',  // placeholder color
                                                            },
                                                        })}
                                                        // onChange={(option) => {
                                                        //     if (this.state.isDevMode)
                                                        //         console.log(JSON.stringify(option));
                                                        //     this.SaveDataInput(option, RoomDataInput.Subject);
                                                        // }}
                                                        isDisabled={true}
                                                        readOnly={true}
                                                        onChange={() => { }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Date *</td>
                                                <td>
                                                    From <input type='date'
                                                        ref={this.EDR_FromDate}
                                                        // className="pointer"
                                                        // onChange={(e) => {
                                                        //     this.SaveDataInput(e.currentTarget.value, RoomDataInput.FromDate);
                                                        // }}
                                                        defaultValue={CheckObjectStringEmpty(this.state.RoomData, 'DateStart')}
                                                        style={{ width: 145 }}
                                                        disabled={true}
                                                        readOnly={true}
                                                        onChange={() => { }}
                                                    ></input>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                    To <input type='date'
                                                        ref={this.EDR_ToDate}
                                                        // className="pointer"
                                                        // onChange={(e) => {
                                                        //     this.SaveDataInput(e.currentTarget.value, RoomDataInput.ToDate);
                                                        // }}
                                                        defaultValue={CheckObjectStringEmpty(this.state.RoomData, 'DateEnd')}
                                                        style={{ width: 145 }}
                                                        disabled={true}
                                                        readOnly={true}
                                                        onChange={() => { }}
                                                    ></input>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Time *</td>
                                                <td>
                                                    From <input type='time'
                                                        ref={this.EDR_FromTime}
                                                        // className="pointer"
                                                        // onChange={(e) => {
                                                        //     this.SaveDataInput(e.currentTarget.value, RoomDataInput.FromTime);
                                                        // }}
                                                        defaultValue={CheckObjectStringEmpty(this.state.RoomData, 'TimeStart', '00:00:00')}
                                                        style={{ width: 145 }}
                                                        disabled={true}
                                                        readOnly={true}
                                                        onChange={() => { }}
                                                    ></input>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                    To <input type='time'
                                                        ref={this.EDR_ToTime}
                                                        // className="pointer"
                                                        // onChange={(e) => {
                                                        //     this.SaveDataInput(e.currentTarget.value, RoomDataInput.ToTime);
                                                        // }}
                                                        defaultValue={CheckObjectStringEmpty(this.state.RoomData, 'TimeEnd', '23:59:00')}
                                                        style={{ width: 145 }}
                                                        disabled={true}
                                                        readOnly={true}
                                                        onChange={() => { }}
                                                    ></input>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Duration *</td>
                                                <td style={{ padding: 0 }}>
                                                    <table cellPadding='10' cellSpacing='0' border='0' width='100%' style={{ borderTop: 0, marginBottom: 10, borderStyle: 'hidden' }}>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <div style={{ padding: 5, paddingLeft: 15, paddingRight: 15, border: '1px solid green', borderRadius: 5, width: 'fit-content', textAlign: 'center' }}
                                                                    >{GetDurationText(this.state.RoomData === null ? 0 : Number(this.state.RoomData.Duration), true) + ' (max: 6 hours)'}</div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan='2' style={{ padding: 0 }}>
                                                                    <table cellPadding='0' cellSpacing='0' border='0' width='60%' style={{ marginBottom: 0, borderStyle: 'hidden' }}>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td align='center'>
                                                                                    <input type='number' min={0} max={6} defaultValue={0} ref={this.EDR_Duration_Hour}
                                                                                        // onChange={() => this.Recalculate_Duration_EditRoom()}
                                                                                        disabled={true}
                                                                                        readOnly={true}
                                                                                    ></input> hour
                                                                                </td>
                                                                                <td align='center'>
                                                                                    <input type='number' min={0} max={59} defaultValue={0} ref={this.EDR_Duration_Min}
                                                                                        // onChange={() => this.Recalculate_Duration_EditRoom()}
                                                                                        disabled={true}
                                                                                        readOnly={true}
                                                                                    ></input> min
                                                                                </td>
                                                                                <td align='center'>
                                                                                    <input type='number' min={0} max={59} defaultValue={0} ref={this.EDR_Duration_Sec}
                                                                                        // onChange={() => this.Recalculate_Duration_EditRoom()}
                                                                                        disabled={true}
                                                                                        readOnly={true}
                                                                                    ></input> sec
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign='middle'>Room Type *</td>
                                                <td>
                                                    <Select
                                                        options={RoomTypeOptions}
                                                        isOptionDisabled={(option) => option.disabled === true} //2023.10.04
                                                        placeholder={
                                                            this.state.RoomData !== null ?
                                                                RoomTypeOptions[CheckObjectNumber(this.state.RoomData, 'RoomType')].label
                                                                : Locale("room-type", this.state.locale)
                                                        }
                                                        theme={theme => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                neutral50: 'black',  // placeholder color
                                                            },
                                                        })}
                                                        // onChange={(option) => {
                                                        //     if (this.state.isDevMode)
                                                        //         console.log(JSON.stringify(option));
                                                        //     this.SaveDataInput(option.value, RoomDataInput.RoomType);
                                                        // }}
                                                        onChange={() => { }}
                                                        isDisabled={true}
                                                        readOnly={true}
                                                    />
                                                </td>
                                            </tr>
                                            <tr hidden={CheckObjectNumber(this.state.RoomData, 'RoomType') === 1}>
                                                <td>Question Set *</td>
                                                <td style={{ padding: 0, paddingLeft: 10 }}>
                                                    <table cellPadding='0' cellSpacing='0' border='0' width='100%' style={{ borderTop: 0, marginBottom: 0, borderStyle: 'hidden' }}>
                                                        <tbody>
                                                            <tr>
                                                                <td valign='middle'>
                                                                    {
                                                                        CheckObjectStringEmpty(this.state.RoomData, 'QuestionSetUniqueId') === '' || this.state.EditRoom_QuestionSet === null ?
                                                                            <span style={{ color: 'gray', }}><i>(no question set is selected)</i></span>
                                                                            :
                                                                            <>
                                                                                {this.state.EditRoom_QuestionSet.Name}
                                                                                <span style={{ fontSize: 12, color: 'gray' }}>
                                                                                    <br />{'Total Question : ' + CheckObjectStringEmpty(this.state.EditRoom_QuestionSet, 'TotalQuestion')}
                                                                                    <br />{'Group : ' + CheckObjectStringEmpty(this.state.EditRoom_QuestionSet['Group'], 'Name')}
                                                                                    <br />{'Subject : ' + CheckObjectStringEmpty(this.state.EditRoom_QuestionSet['Subject'], 'Label')}
                                                                                    <br />{CheckObjectStringEmpty(this.state.EditRoom_QuestionSet, 'UniqueId')}
                                                                                </span>
                                                                            </>
                                                                    }
                                                                </td>
                                                                <td align='right' style={{ verticalAlign: 'top', display: 'flex', flexDirection: 'column', gap: 5, padding: 8 }}>
                                                                    {/* <Button onClick={() => this.Toggle_Search_QuestionSetModal()}>Select Question Set</Button> */}
                                                                    <Button
                                                                        variant="outline-primary"
                                                                        onClick={() => this.Ref_PreviewQuestionSetComponent.current.TogglePreviewQuestionSetModal(CheckObjectStringEmpty(this.state.EditRoom_QuestionSet, 'UniqueId'))}
                                                                        hidden={CheckObjectStringEmpty(this.state.EditRoom_QuestionSet, 'UniqueId') === ''}
                                                                    >Preview Question Set</Button>
                                                                    <Button
                                                                        variant="outline-secondary"
                                                                        onClick={() => useAppService.getState().setModal('Remark', CheckObjectStringEmpty(this.state.EditRoom_QuestionSet, 'Remark', '-'))}
                                                                        hidden={CheckObjectStringEmpty(this.state.EditRoom_QuestionSet, 'UniqueId') === ''}
                                                                    >View Remark</Button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign='middle'>Extra Url (Flipbook)</td>
                                                <td>
                                                    <input type="text" className="form-control" style={{ width: '100%' }}
                                                        // onChange={(e) => {
                                                        //     this.SaveDataInput(e.target.value, RoomDataInput.ExtraUrl);
                                                        // }}
                                                        value={CheckObjectStringEmpty(this.state.RoomData, 'ExtraUrl')}
                                                        readOnly={true}
                                                        disabled={true}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Classroom</td>
                                                <td>
                                                    <div className="row">
                                                        {/* <div className="col-11" style={{ paddingRight: 0 }}> */}
                                                        if any classroom is selected, only the selected classroom(s)' students will be allowed to enter this room & showed in students' quiz room list.
                                                        {
                                                            CheckObjectStringEmpty(this.state.RoomData, 'Classrooms') === '' ? null :
                                                                <div style={{
                                                                    fontWeight: 'bold', fontStyle: 'italic',
                                                                    margin: '5px 20px', padding: '1px 7px 3px 7px',
                                                                    border: '1px solid gray', borderRadius: 5, color: 'blue',
                                                                    width: 555, overflowWrap: 'break-word',
                                                                    // textAlign: 'center'
                                                                }}>
                                                                    {/* {this.state.RoomData['Classrooms']} */}
                                                                    {this.GetSelectedClassrooms_Component()}
                                                                </div>
                                                        }
                                                        {/* </div> */}
                                                        {/* <div className="col-1" style={{ padding: 0 }}>
                                                            <Button variant="link" style={{ padding: 0 }}
                                                                onClick={() => this.SaveDataInput('', RoomDataInput.Classrooms)}>clear</Button>
                                                        </div> */}
                                                    </div>
                                                    <div className="setting-box-border" style={{ marginTop: 5, height: 200, overflowY: 'auto' }}>
                                                        {this.GetClassroomOptions_Component()}
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Modal.Body>
                            <Modal.Footer style={{ padding: '10px 0px', }}>
                                <Button variant="secondary" onClick={() => this.ToggleCreateEditRoomModal()} >Close</Button>
                            </Modal.Footer>
                        </>
                }
            </Modal>

            {/* Room - Search Room by Params (new) 2025.01.16 - Modal */}
            <Modal show={this.state.SearchRoomQueryModal_Toggle}
                onHide={() => this.state.SearchRoomQueryModal_Processing ? DoNothing() : this.ToggleSearchRoomQueryModal()}
                centered>
                <Modal.Header closeButton={this.state.SearchRoomQueryModal_Processing === false}>
                    <Modal.Title>{
                        this.state.SearchRoomQueryModal_Processing ? 'Searching...' : 'Search Room'
                    }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        this.state.SearchRoomQueryModal_Processing ?
                            <ProgressBar animated now={100} className='progressbar1' />
                            :
                            <table cellPadding={5} cellSpacing={0} width='100%'>
                                <tbody>
                                    <tr>
                                        <td align='right'><span>Room Code</span></td>
                                        <td>
                                            <input className='form-control' type="number" style={{ width: '100%' }}
                                                placeholder='(enter room code here)'
                                                onChange={(e) => this.SetSearchRoomQueryParam('RoomCode', String(e.target.value))}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align='right'><span>Room Title</span></td>
                                        <td>
                                            <input className='form-control' type="text" style={{ width: '100%' }}
                                                placeholder='(enter room code here)'
                                                onChange={(e) => this.SetSearchRoomQueryParam('RoomTitle', String(e.target.value))}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align='right'><span>Group</span></td>
                                        <td>
                                            <Select
                                                options={useAppService.getState().groupOptions}
                                                placeholder={CheckObjectStringEmpty(useAppService.getState().groupOptions.find(x => Number(x.value) === Number(this.state.SearchRoomQueryModal.GroupId)), 'label', '(select group)')}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        neutral50: 'gray',  // placeholder color
                                                    },
                                                })}
                                                onChange={(option) => {
                                                    if (this.state.isDevMode)
                                                        console.log(JSON.stringify(option));
                                                    this.SetSearchRoomQueryParam('GroupId', Number(option.id))
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align='right'><span>Subject</span></td>
                                        <td>
                                            <Select
                                                options={useAppService.getState().subjectOptions}
                                                placeholder={CheckObjectStringEmpty(useAppService.getState().subjectOptions.find(x => Number(x.value) === Number(this.state.SearchRoomQueryModal.SubjectId)), 'label', '(select subject)')}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        neutral50: 'gray',  // placeholder color
                                                    },
                                                })}
                                                onChange={(option) => {
                                                    if (this.state.isDevMode)
                                                        console.log(JSON.stringify(option));
                                                    this.SetSearchRoomQueryParam('SubjectId', Number(option.id))
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr hidden={true}>
                                        <td align='right'><span>Room Type</span></td>
                                        <td>
                                            <Select
                                                options={RoomTypeOptions}
                                                isOptionDisabled={(option) => option.disabled === true}
                                                placeholder={RoomTypeOptions[CheckObjectNumber(this.state.SearchRoomQueryModal, 'RoomTypeId')].label}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        neutral50: 'black',  // placeholder color
                                                    },
                                                })}
                                                onChange={(option) => {
                                                    if (this.state.isDevMode)
                                                        console.log(JSON.stringify(option));
                                                    this.SetSearchRoomQueryParam(Room_SearchQuery_DefaultModal.RoomTypeId.indexOf, Number(option.value))
                                                }}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                    }
                </Modal.Body>
                {
                    !this.state.SearchRoomQueryModal_Processing ?
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.ToggleSearchRoomQueryModal()}>Cancel</Button>
                            &nbsp;&nbsp;
                            <Button variant="primary" onClick={() => {

                                // const defaultQueryParams = JSON.stringify(Room_SearchQuery_DefaultModal);
                                // const queryParams = JSON.stringify(this.state.SearchRoomQueryModal);
                                // console.log('defaultQueryParams =\n' + JSON.stringify(defaultQueryParams));
                                // console.log('queryParams =\n' + JSON.stringify(queryParams));
                                // if (queryParams === defaultQueryParams) {
                                if (JSON.stringify(this.state.SearchRoomQueryModal) === JSON.stringify(Room_SearchQuery_DefaultModal)) {
                                    const confirm = window.confirm("You're searching without any params, the results may need to wait for a very long time depends on the existing dataset.\n\n Are you sure you want to continue ?");
                                    if (!confirm)
                                        return null;
                                }

                                this.LoadRoomList_ViaApi();
                                this.setState({
                                    SearchRoomQueryModal_Toggle: false,
                                });

                            }}>Search</Button>
                        </Modal.Footer>
                        : null
                }
            </Modal>

            {/* Room - Result - Component */}
            <RoomResultComponent ref={this.Ref_RoomResultComponent} List={this.state.List} RoomData={this.state.RoomData} />

            {/* Preview Question Set - Component */}
            <PreviewQuestionSetComponent ref={this.Ref_PreviewQuestionSetComponent} />
        </div>);
    }
}