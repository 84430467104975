import React from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { Button, Modal, ProgressBar } from "react-bootstrap";
import * as XLSX from "xlsx";
import moment from 'moment';
import ReactSelect from "react-select";

import { AlertMode } from "./AlertComponent";
import { Locale } from "../utilities/localization/CustomLocalization";
import { PermissionAccess, CheckNullValue, CheckNumber, CheckObjectBoolean, CheckObjectNullValue, CheckObjectNumber, CheckObjectStringEmpty, CheckStringEmpty, CheckValueNA, CommonStatusMessage, Delay, DelayUntil, DoNothing, GetInputComponent, GetPropIds, GetTempTarget, PagingComponents, SetTempTarget, TriggerDownloadFile, UploadStatusMessage, CapitalizeJsonKeys, GetPostParams } from "../utilities/GlobalFunctions";
import { CommonState, GlobalSetting, InputType, LayoutScreen, PermissionAccessType, SecretKey, UploadState } from "../utilities/GlobalSetting";
import { useGlobal } from "../utilities/GlobalVariables";
import { useAppService } from "../services/AppService";
import { NationalState, SchoolList, District } from '../utilities/NationalQuizEventSignUpSettings';

const tableCustomGroupStyleObj = { width: 15, height: 15, };
const editCustomGroupStyleObj = { width: 20, height: 20, };

const settingTitle = 'Student';

const SearchCondition = {
    // None: 'none',
    Name: 'Name',
    Email: 'Email',
    Group: 'Group',
    Classroom: 'Classroom',
    SchoolName: 'School Name',
};

//2025.02.10
const ItemProperty = {
    None: 'none',
    CheckedItem: 'checkedItem',

    Id: 'Id',
    Email: 'Email',
    Name: 'Name',
    RawPassword: 'RawPassword',
    Guardian: 'Guardian',
    ContactNumber: 'ContactNumber',

    Deactivated: 'Deactivated',
    DeactivatedByUserId: 'DeactivatedByUserId',
    DeactivatedOnUtc: 'DeactivatedOnUtc',
    DeactivatedByUserName: 'DeactivatedByUserName',
    DeactivatedByUserEmail: 'DeactivatedByUserEmail',

    Group: 'Group',
    GroupId: 'GroupId',
    // Groups: [],

    Classroom: 'Classroom',
    Classrooms: 'Classrooms',

    // Subjects: [],
    CustomGroups: 'CustomGroups',

    //etc.
    OrganizerId: 'OrganizerId',
    AlwaysOnTop: 'AlwaysOnTop',
    DisplayOrder: 'DisplayOrder',
    Active: 'Active',
    Label: 'Label',
};
const BulkSetting = {
    // Groups: 'Groups',
    // Subjects: 'Subjects',
    Group: 'Group',
    Classroom: 'Classroom',
    Classrooms: 'Classrooms',
    CustomGroups: 'CustomGroups',
};

export default class ManageStudentProfileScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
    }

    getInitState = () => ({

        isDevMode: window.location.href.includes('localhost'),
        locale: useGlobal.getState().locale,
        redirect: false,
        redirectLink: '/',
        isLoading: false,
        isProcessing: false,
        SecretKey: SecretKey.Admin,
        isSuperAdmin: false,
        // gv: null,

        PA_View: false,
        PA_Search: false,
        PA_Create: false,
        PA_Update: false,
        PA_Delete: false,
        PA_Upload: false,
        PA_Download: false,
        PA_Teacher: false,   //2024.07.23

        List: [],
        TableColumn: 7,
        IsListLoaded: false,
        TotalRows: 0,
        PageIndex: 0,
        PageSize: 10,
        OrderBy: 'Name',
        OrderType: 'ASC',

        SearchUserByName: '',
        SearchUserByEmail: '',
        SearchUserByGroup: '',
        SearchUserByClassroom: '',
        SearchUserBySchoolName: '',
        SearchByConditionModal_Toggle: false,
        SearchUserByCondition: SearchCondition.Name,
        SearchByCondition_Processing: false,

        EditProfileModal_Toggle: false,
        EditProfileState: CommonState.None,
        CommonStatus: CommonState.None,
        TargetProfileIndex: -1,
        TargetProfile: {},
        CachedTargetProfile: null,
        // GenderOptions: [
        //     { value: Locale("label-gender-male", Lang.English), label: Locale("label-gender-male", this.state.locale) },
        //     { value: Locale("label-gender-female", Lang.English), label: Locale("label-gender-female", this.state.locale) },
        //     { value: Locale("label-gender-other", Lang.English), label: Locale("label-gender-other", this.state.locale) },
        // ],
        schoolListArray: [],
        nationalStateListArray: [],
        showSelectSchoolListOption: false,
        filteredByState_DistrictAreaList: [],
        ToggleRevealTargetPassword: false,
        ToggleRevealTargetEmailEdit: false,

        UploadStudentProfileModal_Toggle: false,
        ErrorMessage: '',
        AttachedFile: null,
        UploadStatus: UploadState.None,
        UploadStatusText: '',
        UploadResultModal: null,    //2023.12.09
        UniqueId: '',
        UploadModal: null,

        OrganizerCustomGroups: [],
        CustomGroupOptions: [],

        //2023.10.25
        ManageCustomGroupModal_Toggle: false,
        ManageCustomGroupModal_Loading: false,
        EditCustomGroup_Toggle: false,
        EditCustomGroup_Target: null,
        EditCustomGroup_isNew: false,
        EditCustomGroup_Processing: false,

        //2023.11.29
        UploadBulkEditTemplateUi_Toggle: false,
        CustomGroups_BulkEditTemplateUploadFile: null,
        BulkEditTemplate_Processing: false,

        //2024.09.27
        DeactivatedItems_Toggle: false,
        CreateNewStudentProfile: false,
        SendEmailAfterUpload: false,

        //2025.02.10 === BulkEdit
        BulkEdit_Setting: Object.keys(BulkSetting).map((data, key) => { return { key: data, value: null }; }),
        BulkEdit_Setting_checked: Object.keys(BulkSetting).map(() => { return false; }),
        BulkEdit_Toggle_EditSettingModal: false,
        BulkEdit_Toggle_RemoveSettingModal: false,
        BulkEdit_CheckedItems: [],
        BulkEdit_IsUpdating: false,
    });

    componentWillUnmount = () => { }

    componentDidMount = async () => {
        //#region init.
        window.scrollTo(0, 0);
        useGlobal.getState().setScreen(LayoutScreen.ManageStudentProfile);
        await useAppService.getState().getGroups(true);
        await useAppService.getState().getSubjects(true);
        await Delay(0);
        this.LoadList_ViaApi(true);
        useGlobal.getState().setRefreshListCallbackFn(this.LoadList_ViaApi);
        //#endregion
    }

    //#region List
    //2024.07.24
    CheckPermissions = async () => {
        const gv = useGlobal.getState();
        const { uid, organizerId } = GetPropIds(gv.user);
        await Delay(0);
        this.setState({
            PA_View: PermissionAccess(LayoutScreen.ManageStudentProfile, PermissionAccessType.View),
            PA_Search: PermissionAccess(LayoutScreen.ManageStudentProfile, PermissionAccessType.Search),
            PA_Create: PermissionAccess(LayoutScreen.ManageStudentProfile, PermissionAccessType.Create),
            PA_Update: PermissionAccess(LayoutScreen.ManageStudentProfile, PermissionAccessType.Update),
            PA_Delete: PermissionAccess(LayoutScreen.ManageStudentProfile, PermissionAccessType.Delete),
            PA_Upload: PermissionAccess(LayoutScreen.ManageStudentProfile, PermissionAccessType.Upload),
            PA_Download: PermissionAccess(LayoutScreen.ManageStudentProfile, PermissionAccessType.Download),
            PA_Teacher: CheckObjectBoolean(gv.user, 'IsTeacher'),   //2024.07.23

            PageSize: CheckNumber(localStorage.getItem(`ManageStudentProfile_List_PageSize_${uid}_${organizerId}`), GlobalSetting.PageSize),
            isSuperAdmin: useGlobal.getState().isSuperAdmin,
            // gv: useGlobal.getState(),
        });
        await Delay(0);
    }
    LoadList_ViaApi = async (newSearch = false) => {

        await this.CheckPermissions();    //2024.07.24

        if (this.state.PA_View === false)
            return null;

        this.setState({
            isLoading: true,
            List: [],
            IsListLoaded: false,
            PageIndex: newSearch ? 0 : this.state.PageIndex,
        });
        if (newSearch)
            this.ResetSearchStudentParams();    //2024.07.18
        await Delay(0);
        window.scrollTo(0, 0);

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);

        const url = GlobalSetting.ApiUrl + 'Api/LearningCentre/User/Profile/List';

        const searchJson = JSON.stringify({
            orderBy: this.state.OrderBy,
            orderType: this.state.OrderType,
            pageIndex: this.state.PageIndex,
            pageSize: this.state.PageSize,

            // firebaseUserId: uid,
            // centerUserId: this.state.isSuperAdmin ? 0 : centerUserId,
            authorId: authorId,
            // organizerId: this.state.isSuperAdmin ? 0 : organizerId,
            organizerId: organizerId,

            schoolName: this.state.SearchUserBySchoolName,     //remain empty.
            // studentName: this.state.SearchUserByName,
            // studentEmail: this.state.SearchUserByEmail,
            // studentGrade: this.state.SearchUserByGroup,
            // studentClassroom: this.state.SearchUserByClassroom,
            userName: this.state.SearchUserByName,
            userEmail: this.state.SearchUserByEmail,
            userGroup: this.state.SearchUserByGroup,
            userClassroom: this.state.SearchUserByClassroom,
            isTeacher: false,

            isDeactivated: this.state.DeactivatedItems_Toggle,   //2025.01.22
        });

        if (this.state.isDevMode)
            console.log('LoadStudentProfileList_ViaApi', url, searchJson);

        let totalRows = 0;
        let _List = [];
        let _CustomGroups = [];

        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: searchJson,
            })
            .then(res => res.json())
            .then(data => {
                if (data.success) {
                    if (data.data !== undefined)
                        if (Array.isArray(data.data.data)) {
                            _List = CapitalizeJsonKeys(data.data.data);
                            _CustomGroups = CapitalizeJsonKeys(data.data.customGroups);     //2025.02.10
                            // totalRows = Number(data.data.totalCount);
                            totalRows = CheckObjectNumber(data.data, 'totalCount', _List.length);     //2023.12.07
                        }
                        else {
                            if (this.state.isDevMode)
                                console.log('Profile List is empty.');
                        }
                }
                else {
                    if (this.state.isDevMode)
                        console.log('Error', 'api - profile - load list (failed)\n' + JSON.stringify(data));
                }
            })
            .catch(error => {
                if (this.state.isDevMode)
                    console.log('Error', 'api - profile - load list (error)\n' + error.message);
            });

        this.setState({
            isLoading: false,
            // List: JSON.parse(JSON.stringify(_List)),
            List: _List,
            OrganizerCustomGroups: _CustomGroups,   //2025.02.10
            TotalRows: totalRows,
            IsListLoaded: true,
            BulkEdit_CheckedItems: Array.isArray(_List) ? _List.map((data, key) => { return false; }) : [],  //2025.02.10
        }, () => {
            this.PopupateCustomGroupOptions();  //2025.02.10
            if (this.state.isDevMode) {
                console.log('TotalRows', totalRows);
                console.log('List', JSON.stringify(_List));
                console.log('Custom Groups', JSON.stringify(_CustomGroups));
                console.log('Custom Groups (Options)', JSON.stringify(this.state.CustomGroupOptions));
            }
        });
    }
    ListComponents = () => {
        let components = [];

        if (this.state.IsListLoaded === false)
            return null;

        if (this.state.List.length === 0)
            return (<tr><td colSpan={this.state.TableColumn} align='center'>- list is empty -</td></tr>);

        this.state.List.map((data, key) => {
            const customGroup_NoValue = CheckObjectNullValue(data, ItemProperty.CustomGroups) === null || Array.isArray(data[ItemProperty.CustomGroups]) === false || data[ItemProperty.CustomGroups].length === 0;
            components.push(<tr key={'tbi_' + key} className={`setting-parant-tr ${this.state.BulkEdit_CheckedItems[key] ? 'tr-selected' : ''}`}>
                <td className="pointer setting-child-td" onClick={() => this.ToggleItemChecked(key)}>
                    <div className="no-td">
                        {this.state.PageIndex + key + 1}
                        <input type='checkbox' className='pointer' checked={this.state.BulkEdit_CheckedItems[key]} readOnly={true}></input>
                    </div>
                </td>
                {/* <td>{this.state.PageIndex + key + 1}</td> */}
                <td className='left'>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>{CheckValueNA(data[ItemProperty.Name])}</span>
                        <span style={{ color: 'red', fontStyle: 'italic', fontWeight: 600, }}>{this.state.DeactivatedItems_Toggle ? '(Deactivated)' : null}</span>
                    </div>
                </td>
                <td style={customGroup_NoValue ? {} : { padding: '5px 0px' }}>
                    {
                        customGroup_NoValue ? <tr><td>-</td></tr> :
                            data.CustomGroups.map((group, gkey) => {
                                return (<div key={'cg_' + gkey} style={{ display: 'flex', gap: 10, padding: '0px 10px', alignItems: 'center' }}>
                                    {GetInputComponent(InputType.Checkbox, this.state.OrganizerCustomGroups, data, ItemProperty.CustomGroups, null, '', this.state.locale, this.CallbackSaveTarget, null, true, tableCustomGroupStyleObj, gkey)}
                                    <label style={{ margin: 0 }}>{CheckObjectStringEmpty(group, 'Name', '-')}</label>
                                </div>);
                            })
                    }
                    {/* <table style={{ width: '100%' }} hidden>
                        <tbody>
                            {
                                customGroup_NoValue ? <tr><td>-</td></tr> :
                                    data.CustomGroups.map((group, gkey) => {
                                        return (<tr key={'cg_' + gkey}>
                                            <td>
                                                {GetInputComponent(InputType.Checkbox, null, data, ItemProperty.CustomGroups, null, '', this.state.locale, this.CallbackSaveTarget, null, true, tableCustomGroupStyleObj, gkey)}
                                            </td>
                                            <td><label style={{ margin: 0 }}>{CheckObjectStringEmpty(group, 'Name', '-')}</label></td>
                                        </tr>);
                                    })
                            }
                            <tr>
                                <td style={{ border: 0 }}>
                                    <div className="form-group" style={{ display: 'inline-flex', margin: 0 }}>
                                        <label>{Locale("label-csr", this.state.locale)}</label>&nbsp;&nbsp;
                                        {GetInputComponent(InputType.Checkbox, null, data, 'csr', null, 'placeholder-csr', this.state.locale, this.CallbackSaveTarget, null, true, tableCustomGroupStyleObj)}
                                    </div>
                                </td>
                                <td style={{ border: 0 }}>
                                    <div className="form-group" style={{ display: 'inline-flex', margin: 0 }}>
                                        <label>{Locale("label-tuition", this.state.locale)}</label>&nbsp;&nbsp;
                                        {GetInputComponent(InputType.Checkbox, null, data, 'tuition', null, 'placeholder-pemulihan', this.state.locale, this.CallbackSaveTarget, null, true, tableCustomGroupStyleObj)}
                                    </div>
                                </td>
                                <td style={{ border: 0 }}>
                                    <div className="form-group" style={{ display: 'inline-flex', margin: 0 }}>
                                        <label>{Locale("label-pemulihan", this.state.locale)}</label>&nbsp;&nbsp;
                                        {GetInputComponent(InputType.Checkbox, null, data, 'pemulihan', null, 'placeholder-pemulihan', this.state.locale, this.CallbackSaveTarget, null, true)}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table> */}
                </td>
                {/* <td>{CheckValueNA(data[ItemProperty.Classroom])}</td> */}
                <td><div dangerouslySetInnerHTML={{ __html: CheckValueNA(data[ItemProperty.Classroom]).split(',').join('<br />') }}></div></td>
                <td>{CheckValueNA(data[ItemProperty.Group])}</td>
                <td>{CheckValueNA(data[ItemProperty.Email])}</td>
                {
                    this.state.DeactivatedItems_Toggle ?
                        <td>Deactivated by<br />{CheckValueNA(data[ItemProperty.DeactivatedByUserName])}
                            <br /><i>({CheckValueNA(data[ItemProperty.DeactivatedByUserEmail])})</i>
                            <br /><i>({moment.utc(data[ItemProperty.DeactivatedOnUtc], 'YYYY-MM-DDTHH:mm:ss').local().format('YYYY-MM-DD')})</i>
                        </td>
                        : null
                }
                <td>
                    <button
                        type='button'
                        className='btn btn-primary'
                        onClick={() => this.ToggleEditProfileUiModal(key)}
                    >{this.state.PA_Update ? 'Edit' : 'View'}</button>
                </td>
            </tr>);
            return null;
        });

        return (components);
    }
    ToggleItemChecked = (index, selectAll = null) => {
        if (selectAll !== null) {
            this.setState({
                BulkEdit_CheckedItems: this.state.List.map((data, key) => { return !selectAll; }),
            });
        }
        else {
            if (index < 0)
                return null;
            let checkedItems = this.state.BulkEdit_CheckedItems;
            checkedItems[index] = !checkedItems[index];
            this.setState({
                BulkEdit_CheckedItems: checkedItems,
            });
        }
    }
    //#region === Paging Components
    CallbackFunctionForPagingComponents_PageSize = (pageSize = GlobalSetting.PageSize) => {
        this.setState({
            PageSize: pageSize < GlobalSetting.PageSize ? GlobalSetting.PageSize : pageSize,
        }, () => {
            const { uid, organizerId } = GetPropIds(useGlobal.getState().user);
            localStorage.setItem(`ManageStudentProfile_List_PageSize_${uid}_${organizerId}`, this.state.PageSize);
            setTimeout(() => {
                this.LoadList_ViaApi();
            }, 500);
        });
    }
    CallbackFunctionForPagingComponents_PageIndex = (pageIndex = 0) => {
        this.setState({
            PageIndex: pageIndex,
        }, () => {
            setTimeout(() => {
                this.LoadList_ViaApi();
            }, 500);
        });
    }
    //#endregion === Paging Components
    //#endregion

    //#region === Profile - New/View/Edit
    ToggleEditProfileUiModal = async (index = -1, create = false) => {
        const _toggle = !this.state.EditProfileModal_Toggle;
        this.setState({
            EditProfileModal_Toggle: _toggle,
        });
        if (_toggle === false) {
            await Delay(500);
        }
        this.setState({
            // EditProfileModal_Toggle: _toggle,
            // TargetProfileIndex: CheckNullValue(index) === null ? -1 : index,
            TargetProfileIndex: index,
            ToggleRevealTargetPassword: false,
            ToggleRevealTargetEmailEdit: false,
            CreateNewStudentProfile: create,    //2024.09.27
        }, () => {
            if (_toggle) {
                this.SettingListToArray();
                this.InitEditProfileUiModal();
            }
            else {
                this.InitEditProfileUiModal();
                // setTimeout(() => {
                //     this.InitEditProfileUiModal();
                //     // this.setState({
                //     //     EditProfileModal_Toggle: !this.state.EditProfileModal_Toggle,
                //     // });
                // }, 500);
            }
        });
    }
    InitEditProfileUiModal = () => {
        const index = this.state.TargetProfileIndex;
        if (this.state.isDevMode)
            console.log('InitEditProfileUiModal', index);
        if (index === undefined || index === null || index < 0) {
            this.setState({
                TargetProfile: null,
                CachedTargetProfile: null,
                EditProfileState: CommonState.None,
                CommonStatus: CommonState.None,
                showSelectSchoolListOption: false,
                filteredByState_DistrictAreaList: [],
                ToggleRevealTargetPassword: false,
                ToggleRevealTargetEmailEdit: false,
            });
            SetTempTarget(null);
        } else {

            //2024.09.27
            let _editProfileState = CommonState.Edit;
            let _targetProfile = null;
            let _targetProfile_cache = null;
            if (this.state.CreateNewStudentProfile) {
                const profile_template = {
                    "email": "",
                    "name": "",
                    "rawPassword": "",
                    "gender": "",
                    "race": "",
                    "grade": "",
                    "classroom": "",
                    "guardian": "",
                    "contactNumber": "",
                };
                _targetProfile = JSON.parse(JSON.stringify(profile_template));
                _targetProfile_cache = JSON.parse(JSON.stringify(profile_template));
                _editProfileState = CommonState.New;
            }
            else {
                _targetProfile = JSON.parse(JSON.stringify(this.state.List[index]));
                _targetProfile_cache = JSON.parse(JSON.stringify(this.state.List[index]));
            }

            // const _targetProfile = JSON.parse(JSON.stringify(this.state.List[index]));
            this.setState({
                TargetProfile: _targetProfile,
                CachedTargetProfile: _targetProfile_cache,
                showSelectSchoolListOption: false,
                EditProfileState: _editProfileState,
                ToggleRevealTargetPassword: false,
                ToggleRevealTargetEmailEdit: false,
            }, () => {
                SetTempTarget(this.state.TargetProfile);
                this.CallbackSaveTarget(this.state.TargetProfile);
                this.FilterByState_DistrictAreaList();
                this.UpdateSchoolSelectHeight();
                if (this.state.isDevMode)
                    console.log('InitEditProfileUiModal', JSON.stringify(GetTempTarget()));
            });
        }
    }
    ResetProfileValue = (index = -1) => {
        // this.setState({
        //     TargetProfile: JSON.parse(JSON.stringify(this.state.CachedTargetProfile)),
        // }, () => {
        //     this.CallbackSaveTarget(this.state.TargetProfile);
        // });
        this.InitEditProfileUiModal(this.state.TargetProfileIndex);
    }
    UpdateSchoolSelectHeight = async () => {
        await Delay(500);
        const com = document.getElementById('r-select-school');
        if (com !== null) {
            let height = 0;
            const child_value_container = com.querySelector('.r-select__control .r-select__value-container');
            const child_placeholder = com.querySelector('.r-select__control .r-select__value-container .r-select__placeholder');
            // console.log(child_placeholder.innerHTML);            //value.
            // console.log(child_placeholder.clientHeight);         //component height.
            if (child_placeholder !== undefined && child_placeholder !== null && child_placeholder.classList.contains('r-select__placeholder'))
                height = Number(child_placeholder.clientHeight);
            if (height > 0)
                if (child_value_container !== undefined && child_value_container !== null && child_value_container.classList.contains('r-select__value-container'))
                    child_value_container.style.height = height + 'px';
        }
    }
    EditProfileComponents = () => {
        if (
            this.state.TargetProfile === null
            || this.state.EditProfileState === CommonState.None || this.state.EditProfileState === CommonState.Processing
            || this.state.EditProfileState === CommonState.Success || this.state.EditProfileState === CommonState.Failed
        ) {
            return null;
        }

        let components = [];
        const targetProfile = this.state.TargetProfile;

        //Email. dedicated ui component. with reveal/update email via rest api.
        components.push(<div key='profile-email' className="form-group">
            {
                this.state.CreateNewStudentProfile ?
                    <>
                        <label>{Locale("your-email", this.state.locale)}</label>
                        <input
                            name="Email"
                            className={"form-control"}
                            type="text"
                            onChange={(val) => {
                                let profile = targetProfile;
                                profile[ItemProperty.Email] = String(val.target.value);
                                SetTempTarget(profile);
                                this.CallbackSaveTarget(profile);
                            }}
                            value={CheckObjectStringEmpty(targetProfile, ItemProperty.Email)}
                            placeholder={CheckObjectStringEmpty(targetProfile, ItemProperty.Email, '(Email Address)')}
                            disabled={!this.state.PA_Create}
                        />
                    </>
                    :
                    <table>
                        <tbody>
                            {
                                this.state.ToggleRevealTargetEmailEdit ?
                                    <tr>
                                        <td width={270}>
                                            <label>{Locale("your-email", this.state.locale)}</label>
                                            <input
                                                name="Email"
                                                className={"form-control"}
                                                type="text"
                                                onChange={(val) => {
                                                    let profile = targetProfile;    //JSON.parse(JSON.stringify(targetProfile));
                                                    profile[ItemProperty.Email] = String(val.target.value);
                                                    // this.setState({ TargetProfile: profile });
                                                    SetTempTarget(profile);
                                                    this.CallbackSaveTarget(profile);
                                                }}
                                                value={CheckObjectStringEmpty(targetProfile, ItemProperty.Email)}
                                                placeholder={CheckObjectStringEmpty(targetProfile, ItemProperty.Email, '(Unknown)')}
                                                disabled={!this.state.PA_Update}
                                            />
                                        </td>
                                        <td valign='bottom'>
                                            &nbsp;&nbsp;
                                            <button
                                                type='button'
                                                className='btn btn-secondary'
                                                style={{ width: 85 }}
                                                onClick={() => {
                                                    let profile = targetProfile;
                                                    const cachedProfile = this.state.CachedTargetProfile;
                                                    profile[ItemProperty.Email] = cachedProfile[ItemProperty.Email];
                                                    profile[ItemProperty.RawPassword] = cachedProfile[ItemProperty.RawPassword];
                                                    this.setState({
                                                        TargetProfile: profile,
                                                        ToggleRevealTargetEmailEdit: false,
                                                        ToggleRevealTargetPassword: false,
                                                    });
                                                }}
                                            >Hide</button>
                                            &nbsp;&nbsp;
                                            <button
                                                type='button'
                                                className='btn btn-primary'
                                                style={{ width: 85 }}
                                                onClick={() => this.UpdateProfile()}
                                                disabled={!this.state.PA_Update || this.state.isLoading || (CheckObjectNullValue(targetProfile, ItemProperty.Email) === null ? true : false)}
                                            >Update</button>
                                        </td>
                                    </tr>
                                    :
                                    <tr>
                                        <td style={this.state.PA_Update ? { width: 270 } : { width: 'auto' }}>
                                            <label>{Locale("your-email", this.state.locale)}</label>
                                            <input
                                                name="Email"
                                                className={"form-control"}
                                                type="text"
                                                value={
                                                    this.state.CreateNewStudentProfile ? '' :
                                                        CheckObjectNullValue(this.state.List[this.state.TargetProfileIndex], ItemProperty.Email) === null ?
                                                            '' : CheckStringEmpty(this.state.List[this.state.TargetProfileIndex][ItemProperty.Email])
                                                }
                                                placeholder={
                                                    this.state.CreateNewStudentProfile ? '' :
                                                        CheckObjectNullValue(this.state.List[this.state.TargetProfileIndex], ItemProperty.Email) === null ?
                                                            '' : CheckStringEmpty(this.state.List[this.state.TargetProfileIndex][ItemProperty.Email])
                                                }
                                                disabled={true}
                                            />
                                        </td>
                                        <td valign='bottom' style={this.state.PA_Update ? { display: 'table-cell' } : { display: 'none' }}>
                                            &nbsp;&nbsp;
                                            <button
                                                type='button'
                                                className='btn btn-primary'
                                                style={{ width: 85 }}
                                                onClick={() => {
                                                    let profile = targetProfile;
                                                    const cachedProfile = this.state.CachedTargetProfile;
                                                    profile[ItemProperty.RawPassword] = cachedProfile[ItemProperty.RawPassword];
                                                    this.setState({
                                                        TargetProfile: profile,
                                                        ToggleRevealTargetEmailEdit: true,
                                                        ToggleRevealTargetPassword: false,
                                                    })
                                                }}
                                            >Edit</button>
                                        </td>
                                    </tr>
                            }
                        </tbody>
                    </table>
            }
            {/* {
                this.state.ToggleRevealTargetEmailEdit === false ? null :
                    <span style={{ color: 'gray', fontSize: 14, paddingLeft: 15 }}>({Locale("password-min-req", this.state.locale)})</span>
            } */}
        </div>);

        //Name.
        components.push(<div key='profile-name' className="form-group">
            {/* <label>{Locale("your-name", this.state.locale)} *</label> */}
            <label>Student Name</label>
            {GetInputComponent(InputType.Text, null, targetProfile, ItemProperty.Name, null, 'full-name', this.state.locale, this.CallbackSaveTarget, null, !this.state.PA_Update)}
        </div>);

        //Password.
        if (this.state.CreateNewStudentProfile === false) {
            //Password. dedicated ui component. with reveal/update/send password reset link.
            components.push(<div key='profile-password' className="form-group">
                {
                    this.state.CreateNewStudentProfile ?
                        <>
                            <label>{Locale("your-password", this.state.locale)}</label>
                            <input
                                name="Password"
                                className={"form-control"}
                                type="text"
                                onChange={(e) => {
                                    let profile = targetProfile;
                                    profile[ItemProperty.RawPassword] = String(e.target.value);
                                    SetTempTarget(profile);
                                    this.CallbackSaveTarget(profile);
                                }}
                                value={CheckObjectStringEmpty(targetProfile, ItemProperty.RawPassword)}
                                placeholder={CheckObjectStringEmpty(targetProfile, ItemProperty.RawPassword, '(Password, left blank to auto generate a random password during creation)')}
                                disabled={!this.state.PA_Create}
                            />
                        </>
                        :
                        <table>
                            <tbody>
                                {
                                    this.state.ToggleRevealTargetPassword ?
                                        <tr>
                                            <td width={270}>
                                                <label>{Locale("your-password", this.state.locale)}</label>
                                                <input
                                                    name="Password"
                                                    className={"form-control"}
                                                    type="text"
                                                    onChange={(e) => {
                                                        let profile = targetProfile;    //JSON.parse(JSON.stringify(targetProfile));
                                                        profile[ItemProperty.RawPassword] = String(e.target.value);
                                                        // this.setState({ TargetProfile: profile });
                                                        SetTempTarget(profile);
                                                        this.CallbackSaveTarget(profile);
                                                    }}
                                                    value={CheckObjectStringEmpty(targetProfile, ItemProperty.RawPassword)}
                                                    placeholder={CheckObjectStringEmpty(targetProfile, ItemProperty.RawPassword, '(Unknown)')}
                                                    disabled={!this.state.PA_Update}
                                                />
                                            </td>
                                            <td valign='bottom'>
                                                &nbsp;&nbsp;
                                                <div style={{ display: 'flex', flexDirection: 'row', gap: 10, paddingLeft: 10 }}>
                                                    <button
                                                        type='button'
                                                        className='btn btn-secondary'
                                                        style={{ width: 85 }}
                                                        onClick={() => {
                                                            let profile = targetProfile;
                                                            const cachedProfile = this.state.CachedTargetProfile;
                                                            profile[ItemProperty.RawPassword] = cachedProfile[ItemProperty.RawPassword];
                                                            profile[ItemProperty.Email] = cachedProfile[ItemProperty.Email];
                                                            this.setState({
                                                                TargetProfile: profile,
                                                                ToggleRevealTargetPassword: false,
                                                                ToggleRevealTargetEmailEdit: false,
                                                            });
                                                        }}
                                                    >Hide</button>
                                                    {
                                                        this.state.PA_Update === false ? null :
                                                            CheckObjectNullValue(targetProfile, ItemProperty.RawPassword) === null ?
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-primary'
                                                                    style={{ width: 85 }}
                                                                    onClick={() => this.SendPasswordResetEmail()}
                                                                >Reset</button>
                                                                :
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-primary'
                                                                    style={{ width: 85 }}
                                                                    onClick={() => this.UpdateProfile()}
                                                                    disabled={this.state.isLoading || (CheckObjectNullValue(targetProfile, ItemProperty.RawPassword) === null ? true : false)}
                                                                >Update</button>
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                        :
                                        <tr>
                                            <td style={this.state.PA_Update ? { width: 270 } : { width: 'auto' }}>
                                                <label>{Locale("your-password", this.state.locale)}</label>
                                                <input
                                                    name="Password"
                                                    className={"form-control"}
                                                    type="text"
                                                    value={'******'}
                                                    placeholder={'******'}
                                                    disabled={true}
                                                />
                                            </td>
                                            <td valign='bottom' style={this.state.PA_Update ? { display: 'table-cell' } : { display: 'none' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', gap: 10, paddingLeft: 10 }}>
                                                    <button
                                                        type='button'
                                                        className='btn btn-primary'
                                                        style={{ width: 85 }}
                                                        onClick={() => {
                                                            let profile = targetProfile;
                                                            const cachedProfile = this.state.CachedTargetProfile;
                                                            profile[ItemProperty.Email] = cachedProfile[ItemProperty.Email];
                                                            this.setState({
                                                                TargetProfile: profile,
                                                                ToggleRevealTargetPassword: true,
                                                                ToggleRevealTargetEmailEdit: false,
                                                            })
                                                        }}
                                                    >Reveal</button>
                                                    <button
                                                        type='button'
                                                        className='btn btn-warning'
                                                        // style={{ width: 85 }}
                                                        onClick={() => this.SendPasswordToStudentByEmailViaApi()}
                                                    >Email Password</button>
                                                </div>
                                            </td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                }
                {
                    this.state.ToggleRevealTargetPassword === false ? null :
                        <span style={{ color: 'gray', fontSize: 14, paddingLeft: 15 }}>({Locale("password-min-req", this.state.locale)})</span>
                }
            </div>);
        }

        //Custom Groups.
        if (CheckObjectNullValue(targetProfile, ItemProperty.CustomGroups) !== null) {
            if (Array.isArray(targetProfile[ItemProperty.CustomGroups]) && targetProfile[ItemProperty.CustomGroups].length > 0) {
                components.push(<div key='profile-cg' className="form-group">
                    <label>{Locale("label-custom-group", this.state.locale)}</label>
                    <div style={{ border: '1px solid lightgray', borderRadius: 5 }}>
                        {
                            targetProfile.CustomGroups.map((group, gkey) => {
                                return (<div key={'e-cg-i-' + gkey} className="div-hover" style={{ display: 'flex', gap: 10, padding: '10px 15px', alignItems: 'center' }}>
                                    {GetInputComponent(InputType.Checkbox, this.state.OrganizerCustomGroups, targetProfile, ItemProperty.CustomGroups, null, '', this.state.locale, this.CallbackSaveTarget, null, !this.state.PA_Update, editCustomGroupStyleObj, gkey)}
                                    <label style={{ margin: 0 }}>{CheckObjectStringEmpty(group, 'Name', '-')}</label>
                                </div>);
                            })
                        }
                    </div>
                    {/* <table className='table table-hover' style={{
                        width: '100%',
                        // borderColor: 'transparent !important',
                        border: '1px solid lightgray',
                        borderRadius: 15,
                        margin: 0,
                    }}>
                        <tbody>
                            {
                                targetProfile.CustomGroups.map((data, key) => {
                                    return (<tr key={'e-cg-i-' + key}>
                                        <td width={'15%'}></td>
                                        <td><label style={{ margin: 0 }}>{CheckObjectStringEmpty(data, 'Name', '-')}</label></td>
                                        <td>
                                            {GetInputComponent(InputType.Checkbox, null, targetProfile, ItemProperty.CustomGroups, null, '', this.state.locale, this.CallbackSaveTarget, null, !this.state.PA_Update, editCustomGroupStyleObj, key)}
                                        </td>
                                    </tr>);
                                })
                            }
                        </tbody>
                    </table> */}
                </div>);
            }
        }

        //2024.10.21 disabled by Zach request.
        // //Gender.
        // components.push(<div key='profile-gender' className="form-group">
        //     <label>{Locale("label-gender", this.state.locale)}</label>
        //     {GetInputComponent(InputType.Select, GenderOptions(this.state.locale), targetProfile, 'gender', null, 'not-specify-gender', this.state.locale, this.CallbackSaveTarget, null, !this.state.PA_Update)}
        // </div>);

        //2024.10.21 disabled by Zach request.
        // //Race.
        // components.push(<div key='profile-race' className="form-group">
        //     <label>{Locale("label-race", this.state.locale)}</label>
        //     {GetInputComponent(InputType.Select, RaceOptions(this.state.locale), targetProfile, 'race', null, 'not-specify-race', this.state.locale, this.CallbackSaveTarget, null, !this.state.PA_Update)}
        // </div>);

        //#region National State, District Area, School & Center = set in Organizer setting, OrganizerMapping not needed.
        /*
        
                //National State.
                components.push(<div className="form-group">
                    <label>{Locale("label-state", this.state.locale)}</label>
                    {GetInputComponent(InputType.Select, this.state.nationalStateListArray, targetProfile, 'nationalState', null, 'label-national-state', this.state.locale, this.CallbackSaveTarget, this.FilterByState_DistrictAreaList, !this.state.PA_Update)}
                </div>);
        
                //District Area.
                components.push(<div className="form-group">
                    <label>{Locale("label-district", this.state.locale)}</label>
                    {GetInputComponent(InputType.Select, this.state.filteredByState_DistrictAreaList, targetProfile, 'districtArea', 'nationalState', 'label-district-area', this.state.locale, this.CallbackSaveTarget, null, !this.state.PA_Update)}
                </div>);
        
                //School. dedicated ui component. with autocomplete on entered text.
                components.push(<div className="form-group">
                    <label>{Locale("label-school", this.state.locale)}</label>
                    <Select
                        id='r-select-school'
                        classNamePrefix={'r-select'}
                        className={CheckObjectNullValue(targetProfile, 'school') === null ? "select-highlight" : ""}
                        options={this.state.schoolListArray}
                        menuIsOpen={this.state.showSelectSchoolListOption}
                        onInputChange={(e) => {
                            let inputText = String(e);
                            this.setState({
                                showSelectSchoolListOption: inputText.length >= 2 ? true : false,
                            });
                        }}
                        onChange={(val) => {
                            let profile = { ...targetProfile };
                            profile['school'] = String(val.value);
                            this.setState({ TargetProfile: profile });
                        }}
                        value={CheckObjectNullValue(targetProfile, 'school')}
                        placeholder={
                            CheckObjectNullValue(targetProfile, 'school') === null ?
                                Locale("text-school-name", this.state.locale)
                                : CheckObjectNullValue(targetProfile, 'school')
                        }
                        disabled={!this.state.PA_Update}
                    />
                </div>);
        
                //Center.
                components.push(<div className="form-group">
                    <label>{Locale("your-center", this.state.locale)} ({Locale("optional", this.state.locale)})</label>
                    {GetInputComponent(InputType.Text, null, targetProfile, 'center', null, 'center-name', this.state.locale, this.CallbackSaveTarget, null, !this.state.PA_Update)}
                </div>);
        
        */
        //#endregion

        //Group.
        components.push(<div key='profile-grade' className="form-group">
            {/* <label>{Locale("your-grade", this.state.locale)} *</label> */}
            <label>Group</label>
            {/* {GetInputComponent(InputType.Select, GradeOptions(this.state.locale), targetProfile, ItemProperty.Group, null, ItemProperty.Group, this.state.locale, this.CallbackSaveTarget, null, !this.state.PA_Update)} */}
            {GetInputComponent(InputType.Select, useAppService.getState().groupOptions, targetProfile, ItemProperty.Group, null, ItemProperty.Group, this.state.locale, this.CallbackSaveTarget, null, !this.state.PA_Update)}
        </div >);

        //Classroom.
        components.push(<div key='profile-classroom' className="form-group">
            {/* <label>{Locale("your-classroom", this.state.locale)} ({Locale("optional", this.state.locale)})</label> */}
            {/* <label>{Locale("your-classroom", this.state.locale)} *</label> */}
            <label>Classroom</label>
            {GetInputComponent(InputType.Text, null, targetProfile, ItemProperty.Classroom, null, 'classroom-name', this.state.locale, this.CallbackSaveTarget, null, !this.state.PA_Update)}
        </div>);

        //2024.10.21 disabled by Zach request.
        // //Guardian.
        // components.push(<div key='profile-guardian' className="form-group">
        //     <label>{Locale("label-guardian-name", this.state.locale)} ({Locale("optional", this.state.locale)})</label>
        //     {GetInputComponent(InputType.Text, null, targetProfile, ItemProperty.Guardian, null, 'label-guardian-name', this.state.locale, this.CallbackSaveTarget, null, !this.state.PA_Update)}
        // </div>);

        //2024.10.21 disabled by Zach request.
        // //Contact Number.
        // components.push(<div key='profile-contact' className="form-group">
        //     <label>{Locale("contact-number", this.state.locale)} ({Locale("optional", this.state.locale)})</label>
        //     {GetInputComponent(InputType.Text, null, targetProfile, ItemProperty.ContactNumber, null, 'contact-number-sample', this.state.locale, this.CallbackSaveTarget, null, !this.state.PA_Update)}
        // </div>);

        return (components);
    }
    SettingListToArray = () => {

        if (this.state.schoolListArray.length === 0) {
            let _schoolListArray = [];
            SchoolList.map((data, key) => {
                return _schoolListArray.push({ value: data, label: data });
            });
            _schoolListArray = [...new Set(_schoolListArray)];  //remove duplicates.    //2020.12.05
            this.setState({
                schoolListArray: _schoolListArray,
            });
        }

        if (this.state.nationalStateListArray.length === 0) {
            let _nationalStateListArray = [];
            NationalState.map((data, key) => {
                return _nationalStateListArray.push({ value: data, label: data });
            });
            _nationalStateListArray = [...new Set(_nationalStateListArray)];  //remove duplicates.  //2020.12.05

            this.setState({
                nationalStateListArray: _nationalStateListArray,
            });
        }

        // navigator.clipboard.writeText(JSON.stringify(schoolListArray));
        // alert("SchoolList converted to JSON & copied to clipboard");
    }
    FilterByState_DistrictAreaList = (reset = false) => {
        if (this.state.TargetProfile !== null) {
            let targetProfile = this.state.TargetProfile;
            if (reset)
                targetProfile['districtArea'] = '';
            this.setState({
                filteredByState_DistrictAreaList: [],
                TargetProfile: targetProfile,
            }, () => {
                const nationalState = CheckObjectNullValue(targetProfile, 'nationalState') === null ? '' : String(targetProfile['nationalState']);
                if (nationalState !== '') {
                    let findIndex = District.findIndex(x => x.State === nationalState);
                    if (findIndex > -1) {
                        let districtArea = District[findIndex].Area;
                        let _areas = [];
                        districtArea.map((data, key) => {
                            return _areas.push({ value: data, label: data });
                        });
                        this.setState({
                            filteredByState_DistrictAreaList: _areas,
                        });
                        if (this.state.isDevMode)
                            console.log('FilterByState_DistrictAreaList', nationalState);
                    }
                }
            });
        }
    }
    CallbackSaveTarget = (tempTarget = null) => {
        if (tempTarget !== undefined && tempTarget !== null) {
            this.setState({
                TargetProfile: tempTarget,
            }, () => {
                this.UpdateSchoolSelectHeight();
            });
            if (this.state.isDevMode)
                console.log('CallbackSaveTarget \n' + JSON.stringify(tempTarget));
        }
    }
    // UpdateProfilePassword = () => {
    //     const targetProfile = GetTempTarget();
    //     const rawPassword = CheckObjectNullValue(targetProfile, ItemProperty.RawPassword) === null ? '' : String(targetProfile[ItemProperty.RawPassword]);
    //     this.UpdateProfileViaAPI(rawPassword);
    // }
    //2023.09.28
    UpdateProfile = () => {
        const sourceProfile = this.state.CreateNewStudentProfile ? null : this.state.List[this.state.TargetProfileIndex];
        const targetProfile = GetTempTarget();
        const rawPassword = CheckObjectNullValue(targetProfile, ItemProperty.RawPassword) === null
            // || CheckObjectNullValue(sourceProfile, ItemProperty.RawPassword) === null          //some profile has no rawPassword (not found in backup)
            ? '' :
            (CheckStringEmpty(sourceProfile[ItemProperty.RawPassword]) === CheckStringEmpty(targetProfile[ItemProperty.RawPassword]) ?
                '' : String(targetProfile[ItemProperty.RawPassword]));
        const newEmail = CheckObjectNullValue(targetProfile, ItemProperty.Email) === null
            || CheckObjectNullValue(sourceProfile, ItemProperty.Email) === null
            ? '' :
            (CheckStringEmpty(sourceProfile[ItemProperty.Email]) === CheckStringEmpty(targetProfile[ItemProperty.Email]) ?
                '' : CheckStringEmpty(targetProfile[ItemProperty.Email]));

        //2024.10.21
        const isValid = this.ValidateProfileInputs(rawPassword, newEmail);
        if (this.state.isDevMode)
            console.log(`UpdateProfile (${rawPassword}) (${newEmail}) (isValid: ${isValid})`);
        if (isValid === false)
            return null;

        this.UpdateProfileViaAPI(rawPassword, newEmail);
    }
    //2024.10.21
    ValidateProfileInputs = (rawPassword = '', newEmail = '') => {
        let isValid = true;
        let msg = [];
        const profile = GetTempTarget();
        if (CheckObjectStringEmpty(profile, 'Email') === '')
            msg.push('Please enter <b>Email address</b>.');
        if (CheckObjectStringEmpty(profile, 'Name') === '')
            msg.push('Please enter <b>Name</b>.');
        if (CheckObjectStringEmpty(profile, 'Grade') === '')
            msg.push('Please select <b>Grade</b>.');
        if (CheckObjectStringEmpty(profile, 'Classroom') === '')
            msg.push('Please enter <b>Classroom</b>.');
        if (msg.length > 0) {
            isValid = false;
            useAppService.getState().setModal('Invalid inputs', msg.join('<br />'));
        }
        return isValid;
    }
    UpdateProfileViaAPI = async (rawPassword = '', newEmail = '') => {

        // this.setState({ isLoading: true, });
        useAppService.getState().setModal('', 'saving profile...', null, AlertMode.Loading);
        window.scrollTo(0, 0);

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);

        //2024.09.27
        const url = GlobalSetting.ApiUrl + 'Api/LearningCentre/Organizer/UserProfile/'
            + (this.state.CreateNewStudentProfile ? 'Create' : 'Update');
        // const url = GlobalSetting.ApiUrl + 'Api/LearningCentre/Organizer/UserProfile/Update';

        if (this.state.isDevMode)
            console.log(url);

        if (CheckNullValue(rawPassword) === null)
            rawPassword = '';

        let done = false;
        let errorMessage = '';
        let success = false;
        let _profile = null;
        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    secret: this.state.SecretKey,
                    authorId: authorId,
                    organizerId: organizerId,
                    rawPassword: rawPassword,
                    newEmail: newEmail,
                    studentProfile: GetTempTarget(),    // this.state.List[this.state.TargetProfileIndex],
                })
            })
            .then(res => res.json())
            .then(data => {
                success = CheckObjectBoolean(data, 'success');
                if (this.state.isDevMode)
                    console.log((success ? 'Success' : 'Error'), `api - profile - update ${success ? '(success)' : '(failed)'}\n` + JSON.stringify(data));

                if (success) {
                    if (data.data !== undefined && data.data !== null)
                        _profile = data.data;
                }
                else {
                    errorMessage = data.message;
                    // if (this.state.isDevMode)
                    //     console.log('Error', 'api - profile - update (failed)\n' + JSON.stringify(data));
                }
                done = true;
            })
            .catch(error => {
                errorMessage = error.message;
                done = true;
                if (this.state.isDevMode)
                    console.log('Error', 'api - profile - update (error)\n' + error.message);
            });
        await DelayUntil(() => done === true);

        let statusText = 'update';     //2024.09.27

        //update result to table.
        let deactivated = false;
        if (_profile !== null) {

            if (this.state.CreateNewStudentProfile === false) {
                let _List = this.state.List;
                _List[this.state.TargetProfileIndex] = _profile;
                this.setState({
                    List: _List,
                });
            }
            //init field values.
            // await this.LoadStudentProfileList_ViaApi();
            // this.InitEditProfileUiModal();

            //2024.07.05
            deactivated = CheckObjectBoolean(_profile, ItemProperty.Deactivated);
            if (this.state.CreateNewStudentProfile)
                statusText = 'create';
            this.ToggleEditProfileUiModal();
            this.LoadList_ViaApi(true);
            // if (deactivated || this.state.CreateNewStudentProfile) {
            //     statusText = 'create';
            //     this.ToggleEditProfileUiModal();
            //     this.LoadList_ViaApi(true);
            // }
            // else {
            //     this.InitEditProfileUiModal();
            // }
        }

        //close loading.
        if (deactivated) {
            //2024.07.05
            useAppService.getState().setModal('Request Done', `<span>Student profile has been deactivated.</span><br /><b>${CheckObjectStringEmpty(_profile, ItemProperty.Name)}</b><br /><b>${CheckObjectStringEmpty(_profile, ItemProperty.Email)}</b>`);
        }
        else if (deactivated === false && this.state.DeactivatedItems_Toggle) {
            //2025.01.23
            useAppService.getState().setModal('Request Done', `<span>Student profile has been re-activated.</span><br /><b>${CheckObjectStringEmpty(_profile, ItemProperty.Name)}</b><br /><b>${CheckObjectStringEmpty(_profile, ItemProperty.Email)}</b>`);
        }
        else {
            if (success)
                useAppService.getState().setModal('Request Done', 'Student profile' + (CheckNullValue(rawPassword) === null ? ' ' : ' password ') + 'has been ' + statusText + 'd.');
            else
                useAppService.getState().setModal('Request Failed', 'Student profile has failed to ' + statusText + '.' + (CheckNullValue(errorMessage) === null ? '' : '<br /><br />Error:<br />' + errorMessage));
        }
    }
    SendPasswordResetEmail = async () => {

        useAppService.getState().setModal('', 'sending password reset email...', null, AlertMode.Loading);
        window.scrollTo(0, 0);

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);

        const url = GlobalSetting.ApiUrl + 'Api/LearningCentre/User/Profile/SendPasswordResetEmail';

        if (this.state.isDevMode)
            console.log(url);

        const profile = GetTempTarget();
        let emailSent = false;
        let errorMessage = '';
        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    secret: this.state.SecretKey,
                    authorId: authorId,
                    organizerId: organizerId,
                    rawPassword: '',
                    studentProfile: profile,    // this.state.List[this.state.TargetProfileIndex],
                })
            })
            .then(res => res.json())
            .then(data => {
                if (data.success) {
                    emailSent = true;
                }
                else {
                    errorMessage = data.message;
                    if (this.state.isDevMode)
                        console.log('Error', 'api - send password reset email (failed)\n' + JSON.stringify(data));
                }
            })
            .catch(error => {
                errorMessage = error.message;
                if (this.state.isDevMode)
                    console.log('Error', 'client - send password reset email (error)\n' + error.message);
            });

        //close loading.
        if (emailSent)
            useAppService.getState().setModal('', 'A password reset email has been sent' + (CheckNullValue(profile.email) === null ? '.' : ' to &#60;' + profile.email + '&#62;.'));
        else
            useAppService.getState().setModal('Failed', 'Failed to send password reset email.' + (CheckNullValue(errorMessage) === null ? '' : '<br /><br />Error:<br />' + errorMessage));
    }
    //2024.06.22
    DeactivateProfile = () => {
        const confirm = window.confirm('Proceed to deactivate current student ?');
        if (confirm) {
            let targetProfile = GetTempTarget();
            const deactivated = CheckObjectBoolean(targetProfile, ItemProperty.Deactivated);
            if (deactivated === false) {
                targetProfile[ItemProperty.Deactivated] = true;
                SetTempTarget(targetProfile);
            }
            this.UpdateProfile();
        }
    }
    //2025.01.23
    ActivateProfile = () => {
        const confirm = window.confirm('Proceed to re-activate current student ?');
        if (confirm) {
            let targetProfile = GetTempTarget();
            const deactivated = CheckObjectBoolean(targetProfile, ItemProperty.Deactivated);
            if (deactivated) {
                targetProfile[ItemProperty.Deactivated] = false;
                SetTempTarget(targetProfile);
            }
            this.UpdateProfile();
        }
    }
    //2025.02.03
    SendPasswordToStudentByEmailViaApi = async () => {

        useAppService.getState().setModal('', 'sending login credential via email...', null, AlertMode.Loading);

        let done = false;
        let errorMessage = '';
        let success = false;

        const targetProfile = this.state.TargetProfile;
        const email = CheckObjectStringEmpty(targetProfile, 'Email');
        if (targetProfile !== null && email !== '') {

            const { organizerSchoolCode } = GetPropIds(useGlobal.getState().user);
            const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Student/ForgotPassword/Request/${email}/${organizerSchoolCode}`;

            await fetch(url,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        // 'Content-Type': 'application/json',
                    },
                })
                .then(res => res.json())
                .then(data => {
                    if (this.state.isDevMode)
                        console.log('Response', 'api - student profile - email password \n' + JSON.stringify(data));

                    success = CheckObjectBoolean(data, 'success');
                    if (!success) {
                        errorMessage = CheckObjectStringEmpty(data, 'message');
                    }
                    done = true;
                })
                .catch(error => {
                    errorMessage = CheckObjectStringEmpty(error, 'message');
                    done = true;
                    if (this.state.isDevMode)
                        console.log('Error', 'api - profile - email password (error)\n' + errorMessage);
                });
            await DelayUntil(() => done === true);
        }
        if (success) {
            useAppService.getState().setModal('', 'Email has been sent successfully.');
        }
        else {
            useAppService.getState().setModal(`Unsuccessful`,
                `Failed to send login credential via email. ${CheckNullValue(errorMessage) === null ? '' : '<br /><br />Error:<br />' + errorMessage}`);
        }
    }
    //#endregion === Student Profile - New/View/Edit

    //#region === Table / Upload Student Profile Template File
    ToggleUploadProfileModal = () => {
        this.setState({
            UploadStudentProfileModal_Toggle: !this.state.UploadStudentProfileModal_Toggle
        }, () => {
            if (!this.state.UploadStudentProfileModal_Toggle) {
                //close.
                this.ResetUploadProfileModal();
            }
            else {
                //open.
                this.setState({
                    SendEmailAfterUpload: false,     //2024.09.27
                });
            }
        });
    }
    ResetUploadProfileModal = (action = '') => {
        this.setState({
            UploadStatus: UploadState.None,
            UploadStatusText: '',
            UniqueId: '',
            AttachedFile: null,
            UploadModal: null,
        }, () => {
            if (action !== '') {
                if (action === 'reset') {
                    if (this.state.UploadStudentProfileModal_Toggle) {
                        this.ToggleUploadProfileModal();
                        setTimeout(() => {
                            this.ToggleUploadProfileModal();
                        }, 500);
                    }
                }
                if (action === 'reload') {
                    this.LoadList_ViaApi(true);
                }
            }
        });
    }
    onUploadFileChange = (event) => {
        this.setState({ AttachedFile: event.target.files[0] });
    }
    HideComponent_UploadUiModal = () => {
        return this.state.UploadStatus === UploadState.ConvertFailed ||
            this.state.UploadStatus === UploadState.Success ||
            this.state.UploadStatus === UploadState.Failed ? false : true
    }
    ProcessUploadProfileFile = async () => {
        let processSuccess = false;
        let processErrorMessage = '';
        let done = false;
        // console.log('ProcessUploadProfileFile (enter)');

        this.setState({
            UploadStatus: UploadState.Validation,
            UploadStatusText: '',
        });

        let jsonData = null;
        try {
            let reader = new FileReader();
            reader.onload = (event) => {
                /* Parse data */
                let bstr = event.target.result;
                let wb = XLSX.read(bstr, { type: "binary" });
                let wsname = wb.SheetNames[0];
                // console.log(wsname);
                if (wsname === 'ProfileTemplate') {
                    let ws = wb.Sheets[wsname];
                    /* Convert array of arrays */
                    let jsonData_raw = XLSX.utils.sheet_to_json(ws);
                    let jsonStrings = JSON.stringify(jsonData_raw);
                    jsonStrings = jsonStrings.replaceAll('\\r\\n', '<br/>');
                    // if (this.state.isDevMode)
                    //     console.log('StudentProfile =\n' + jsonStrings);
                    jsonData = JSON.parse(jsonStrings, (key, value) => { return (CheckNullValue(value) === null ? '' : value); });
                    if (this.state.isDevMode) {
                        console.log('Total Profiles (init) = ' + (jsonData === null ? 0 : jsonData.length));
                        // console.log('jsonData (init) =\n' + JSON.stringify(jsonData));
                    }

                    //2023.09.19
                    if (Array.isArray(jsonData)) {
                        const customGroups = this.state.OrganizerCustomGroups;
                        let newArray = [];
                        for (let j = 0; j < jsonData.length; j++) {
                            // delete jsonData[j]['#'];
                            // const csr = CheckObjectNullValue(jsonData[j], 'CSR');
                            // const tuition = CheckObjectNullValue(jsonData[j], 'Tuition');
                            // const pemulihan = CheckObjectNullValue(jsonData[j], 'Pemulihan');
                            // jsonData[j].CSR = csr === null || csr === '-' ? false : true;
                            // jsonData[j].Tuition = tuition === null || tuition === '-' ? false : true;
                            // jsonData[j].Pemulihan = pemulihan === null || pemulihan === '-' ? false : true;

                            //2023.09.29
                            if (Number(jsonData[j].No) <= 500) {
                                if (CheckObjectNullValue(jsonData[j], 'Email') !== null) {
                                    if (customGroups !== null) {
                                        if (customGroups.length > 0) {
                                            for (let cg = 0; cg < customGroups.length; cg++) {
                                                const fieldName = String(customGroups[cg].name);
                                                const objValue = CheckObjectNullValue(jsonData[j], fieldName);
                                                jsonData[j][fieldName] = objValue === null || objValue === '-' ? false : true;
                                            }
                                        }
                                    }
                                    newArray.push(jsonData[j]);
                                    // console.log(j, JSON.stringify(jsonData[j]));
                                }
                            }
                            // console.log(j, JSON.stringify(jsonData[j]));
                        }
                        jsonData = newArray;
                        // if (this.state.isDevMode)
                        //     console.log('Total Profiles (500) = ' + (jsonData === null ? 0 : jsonData.length));
                    }
                    if (this.state.isDevMode) {
                        console.log('Total Profiles (final) = ' + (jsonData === null ? 0 : jsonData.length));
                        // console.log('jsonData (final) =\n' + JSON.stringify(jsonData));
                        // DownloadTxtFile(jsonData, 'Debug_StudentProfile_' + moment.utc().unix());
                    }
                    processSuccess = jsonData !== null;
                    done = true;
                }
            };
            reader.readAsArrayBuffer(this.state.AttachedFile);
        }
        catch (err) {
            processErrorMessage = err;
            done = true;
        };
        await DelayUntil(() => done === true);

        return { processSuccess, processErrorMessage, jsonData };
    }
    UploadProcessedProfile_ViaAPI = async (jsonData = null) => {

        let done = false;
        let uploadSuccess = false;
        let uploadErrorMessage = '';
        let profileEmails = [];

        if (jsonData === null) {
            uploadErrorMessage = 'invalid json data.';
            return { uploadSuccess, uploadErrorMessage, profileEmails };
        }

        this.setState({
            UploadStatus: UploadState.Processing,
            UploadStatusText: 'Please wait patiently...',
        });

        //api.
        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        // if (this.state.isDevMode)
        //     console.log('LoadStudentProfileList_ViaApi', centerUserId, authorId, authorRoleId, organizerId, organizerDiplayName, this.state.OrderBy, this.state.OrderType);

        const url = GlobalSetting.ApiUrl + 'Api/LearningCentre/User/Profile/CreateOrUpdate/TemplateFile/Upload';

        if (this.state.isDevMode)
            console.log('UploadProcessedProfile_ViaAPI', url);

        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    secret: this.state.SecretKey,
                    authorId: authorId,
                    organizerId: organizerId,
                    profiles: jsonData,
                    sendEmail: this.state.SendEmailAfterUpload,     //2024.09.27
                })
            })
            .then(res => res.json())
            .then(data => {
                if (data.success) {
                    uploadSuccess = true;
                    profileEmails = data.data;
                    if (this.state.isDevMode)
                        console.log('File has been uploaded & processed successfully.');
                }
                else {
                    uploadErrorMessage = data.message;
                    if (this.state.isDevMode)
                        console.log('Error', 'api - profile - upload file (failed)\n' + JSON.stringify(data));
                }
                done = true;
            })
            .catch(error => {
                done = true;
                uploadErrorMessage = error.message;
                if (this.state.isDevMode)
                    console.log('Error', 'api - profile - upload file (error)\n' + error.message);
            });
        await DelayUntil(() => done === true);

        // this.setState({
        //     UploadStatus: uploadSuccess ? UploadState.Success : UploadState.Failed,
        // });

        return { uploadSuccess, uploadErrorMessage, profileEmails };
    }
    TriggerUploadProfileFile = async () => {
        let success = false;
        let errorMessage = '';
        let profileUidsComponentHTML = '';

        if (this.state.PA_Upload === false)
            return null;

        if (this.state.AttachedFile === undefined || this.state.AttachedFile === null) {
            useAppService.getState().setModal('Upload Failed', 'Please select a template file before upload.');
            return null;
        }

        this.setState({ UploadResultModal: null, });

        //get organizer custom groups.
        const { fetchSuccess, fetchErrorMessage } = await this.GetOrganizerCustomGroups_ViaApi();
        if (fetchSuccess === false)
            errorMessage = fetchErrorMessage;

        //converting attached file to json.
        const { processSuccess, processErrorMessage, jsonData } = await this.ProcessUploadProfileFile();
        if (processSuccess === false)
            errorMessage = processErrorMessage;
        await Delay(1000);
        // console.log('ProcessUploadProfileFile', processSuccess, processErrorMessage);

        if (processSuccess) {
            //upload via api.
            const { uploadSuccess, uploadErrorMessage, profileEmails } = await this.UploadProcessedProfile_ViaAPI(jsonData);
            if (uploadSuccess) {
                success = true;
                profileUidsComponentHTML = this.PopulateUploadProfileTemplateResultComponentHTML(jsonData, profileEmails);
                this.ToggleUploadProfileModal();
            }
            else {
                errorMessage = uploadErrorMessage;
            }
            await Delay(2000);
            // console.log('UploadProcessedProfile_ViaAPI', uploadSuccess, uploadErrorMessage);
        }

        this.setState({
            UploadStatus: success ? UploadState.Success : UploadState.Failed,
            UploadStatusText: success ? 'File has been uploaded & processed.' + CheckStringEmpty(profileUidsComponentHTML) : errorMessage,
        });
    }
    //2023.12.09
    PopulateUploadProfileTemplateResultComponentHTML = (jsonData = [], profileEmails = []) => {
        if (jsonData.length > 0 && profileEmails.length > 0) {
            let uploadResultModal = [];
            let html = '<br /><br /><table id="uploadTableResult" width="100%" border="1" cellpadding="5" style="font-size:12px;">';
            let html2 = '<table id="uploadTableResult_hidden" hidden="hidden" width="100%" border="1" cellpadding="5" style="font-size:12px;">';
            html += '<thead><th>No</th><th>Name</th><th width="170">Email</th><th align="center">Status</th></thead><tbody>';
            html2 += '<thead><th>No</th><th>Name</th><th width="170">Email</th><th align="center">Status</th></thead><tbody>';

            for (let p = 0; p < jsonData.length; p++) {
                const email = CheckObjectStringEmpty(jsonData[p], 'Email');
                let result = '<i class="fa fa-check" style="font-size:24px;color:green"></i>';
                const notFound = profileEmails.findIndex(x => x === email) < 0;
                if (notFound)
                    result = '<i class="fa fa-remove" style="font-size:24px;color:green"></i>';

                html += '<tr ' + (notFound ? 'style="background-color:lightgray;"' : '') + '>';
                html += '<td align="center">' + (p + 1) + '</td>';
                html += '<td>' + CheckObjectStringEmpty(jsonData[p], 'Name') + '</td>';
                html += '<td>' + email + '</td>';
                html += '<td align="center">' + result + '</td>';
                html += '</tr>';

                html2 += '<tr ' + (notFound ? 'style="background-color:lightgray;"' : '') + '>';
                html2 += '<td align="center">' + (p + 1) + '</td>';
                html2 += '<td>' + CheckObjectStringEmpty(jsonData[p], 'Name') + '</td>';
                html2 += '<td>' + email + '</td>';
                html2 += '<td align="center">' + (notFound ? 'Failed' : 'Success') + '</td>';
                html2 += '</tr>';

                uploadResultModal.push({
                    No: (p + 1),
                    Name: CheckObjectStringEmpty(jsonData[p], 'Name'),
                    Email: email,
                    Status: notFound ? 'Failed' : 'Success',
                })
            }
            html += '</tbody></table>';
            html2 += '</tbody></table>';
            this.setState({ UploadResultModal: uploadResultModal, });

            let content = html + html2;
            // content += html + '<br /><br />';
            // content += '<input type="button" onclick="window.open(<html><head></head><body>' + html + '</body></html>, _blank)" >Open table in new tab</input>';
            return CheckNullValue(content);
        }
    }
    DownloadTableAsXLSX = (tableId = '', tableName = '') => {
        if (tableId === '')
            return null;

        const table = document.getElementById(tableId);
        if (table !== null) {
            let wb = XLSX.utils.table_to_book(table, { raw: true });
            XLSX.writeFile(wb, tableName + ".xlsx");
        }
    }
    //2023.09.29
    ShowTemplateDownloadAlertBox = () => {
        let itemCom = [];
        for (let k = 1; k <= 6; k++) {
            itemCom.push(<tr key={'tp-grade-' + k}><td>{'Standard ' + k}</td><td><button className="link-button" onClick={() => this.GetProfileTemplate(k)}>download</button></td></tr>);
        }
        let tableCom = [];
        tableCom.push(<table key='tp-grade' cellPadding="5" width="100%" border="1" style={{ textAlign: 'center' }}><thead><tr><th>Grade</th><th>Spreadsheet Template</th></tr></thead><tbody>{itemCom}</tbody></table>);
        tableCom.push(<br key='br' />);
        const sampleLink = 'https://ikeynew.blob.core.windows.net/ikeykidz/quizbank/TEMPLATE_STUDENT_PROFILE_SAMPLE.xlsx';
        tableCom.push(<table key='tp-sample' cellPadding="5" width="100%" border="1" style={{ textAlign: 'center' }}><thead><tr><th>Spreadsheet Implementation Sample</th></tr></thead><tbody>
            <tr><td><button className="link-button" onClick={() => window.open(sampleLink, '_new')}>download sample file</button></td></tr>
        </tbody></table>);
        useAppService.getState().setModal('Student Profile Upload Template', tableCom);
    }
    //2023.09.29
    GetProfileTemplate = async (stdId = '') => {

        useAppService.getState().setModal('', 'requesting template file...', null, AlertMode.Loading);

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        if (this.state.isDevMode)
            console.log('GetProfileTemplate', authorId, organizerId);

        const url = GlobalSetting.ApiUrl + 'Api/LearningCentre/Organizer/Download/Template/UserProfile/'
            + organizerId + '/' + authorId + '/' + encodeURI('Standard ' + stdId);

        await fetch(url,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (data.success) {
                    if (this.state.isDevMode)
                        console.log('GetProfileTemplate (data)\n', JSON.stringify(data.data));
                    if (CheckObjectNullValue(data, 'data') === null) {
                        useAppService.getState().setModal('Error', 'Failed to receive template file link.');
                    }
                    else {
                        if (CheckObjectNullValue(data.data, 'url') === null)
                            useAppService.getState().setModal('Error', 'Failed to download template file.');
                        else {
                            window.open(String(data.data.url));
                            this.ShowTemplateDownloadAlertBox();
                        }
                    }
                }
                else {
                    useAppService.getState().setModal('Error', 'Failed to receive template file.<br /><br />Error:<br />' + data.message);
                    if (this.state.isDevMode)
                        console.log('Error', 'api - receive template file link (failed)\n' + JSON.stringify(data));
                }
            })
            .catch(error => {
                useAppService.getState().setModal('Request Error', 'Failed to request file.<br /><br />Error:<br />' + error.message);
                if (this.state.isDevMode)
                    console.log('Error', 'api - request template file (error)\n' + error.message);
            });
    }
    //#endregion === Table / Upload Student Profile Template File

    //#region === Search Student by Condition ===
    //2023.10.02
    SearchStudentByCondition_ViaAPI = async () => {
        this.setState({ SearchByCondition_Processing: true, SearchByConditionModal_Toggle: false, });
        await this.LoadList_ViaApi();
        await Delay(500);
        // this.ResetSearchStudentParams();
        // await Delay(500);
        this.setState({ SearchByCondition_Processing: false, });
    }
    //2024.01.13
    ResetSearchStudentParams = (toggleOn = false, search = SearchCondition.Name) => {
        this.setState({
            SearchUserByName: '',
            SearchUserByEmail: '',
            SearchUserByGroup: '',
            SearchUserByClassroom: '',
            SearchUserBySchoolName: '',
            SearchUserByCondition: search,
            SearchByConditionModal_Toggle: toggleOn,
        });
    }
    //2024.01.13
    GetSearchInputPlaceholder = () => {
        const defaultText = "(enter student's " + this.state.SearchUserByCondition.toLowerCase() + " here)";
        switch (this.state.SearchUserByCondition) {
            case SearchCondition.Name: return CheckStringEmpty(this.state.SearchUserByName, defaultText);
            case SearchCondition.Email: return CheckStringEmpty(this.state.SearchUserByEmail, defaultText);
            case SearchCondition.Group: return CheckStringEmpty(this.state.SearchUserByGroup, defaultText);
            case SearchCondition.Classroom: return CheckStringEmpty(this.state.SearchUserByClassroom, defaultText);
            case SearchCondition.SchoolName: return CheckStringEmpty(this.state.SearchUserBySchoolName, defaultText);
            default: return defaultText;
        }
    }
    //#endregion === Search Student by Condition ===

    //#region Manage Custom Group - 2023.10.25
    //2025.02.10
    PopupateCustomGroupOptions = () => {
        let options = [];
        const organizerCustomGroups = JSON.parse(JSON.stringify(this.state.OrganizerCustomGroups));
        if (Array.isArray(organizerCustomGroups)) {
            organizerCustomGroups.map((data, key) => {
                if (CheckObjectBoolean(data, ItemProperty.Active)) {
                    options.push({
                        id: CheckObjectNumber(data, ItemProperty.Id),
                        label: CheckObjectStringEmpty(data, ItemProperty.Label),
                        value: CheckObjectNumber(data, ItemProperty.Id),
                    });
                }
                return null;
            });
        }
        this.setState({ CustomGroupOptions: options, });
    }
    //2023.09.29
    GetOrganizerCustomGroups_ViaApi = async (active = true) => {

        let done = false;
        let fetchSuccess = false;
        let fetchErrorMessage = '';
        this.setState({ OrganizerCustomGroups: [] });

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const url = GlobalSetting.ApiUrl + 'Api/LearningCentre/Organizer/CustomGroups/List/' + organizerId + '/' + authorId + '/' + active;

        await fetch(url,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('GetOrganizerCustomGroups_ViaApi \n', JSON.stringify(data));

                if (data.success) {
                    fetchSuccess = true;
                    this.setState({ OrganizerCustomGroups: CapitalizeJsonKeys(data.data) });
                }
                else {
                    fetchErrorMessage = data.message;
                }
                done = true;
            })
            .catch(error => {
                fetchErrorMessage = error.message;
                done = true;
                if (this.state.isDevMode)
                    console.log('Error', 'api - GetOrganizerCustomGroups_ViaApi (error)\n' + error.message);
            });
        await DelayUntil(() => done === true);

        return { fetchSuccess, fetchErrorMessage };
    }
    ManageCustomGroup_ToggleModal = () => {
        this.setState({
            ManageCustomGroupModal_Toggle: !this.state.ManageCustomGroupModal_Toggle,
        }, () => {
            if (this.state.ManageCustomGroupModal_Toggle) {
                //open.
                this.LoadCustomGroupList();
            }
            else {
                //close.
                this.ManageCustomGroup_ResetValues();
            }
        });
    }
    ManageCustomGroup_ResetValues = () => {
        SetTempTarget(null);
        this.setState({
            EditCustomGroup_Target: null,
        });
    }
    LoadCustomGroupList = async () => {
        this.setState({
            ManageCustomGroupModal_Loading: true,
        });
        const { fetchSuccess, fetchErrorMessage } = await this.GetOrganizerCustomGroups_ViaApi(null);
        if (fetchSuccess === false) {
            //failed.
            useAppService.getState().setModal('Failed to retrieve Custom Groups', fetchErrorMessage);
            this.ManageCustomGroup_ToggleModal();
        }
        else {
            //success.
        }
        this.setState({
            ManageCustomGroupModal_Loading: false,
        });
    }
    ManageCustomGroup_BodyComponent = () => {

        if (this.state.ManageCustomGroupModal_Loading)
            return (<ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10, width: '100%' }} />);

        // if (this.state.OrganizerCustomGroups === null || this.state.OrganizerCustomGroups.length <= 0)
        //     return null;

        // const gv = useGlobal.getState();
        let components = [];
        let tableBody = [];

        if (this.state.OrganizerCustomGroups === null || this.state.OrganizerCustomGroups.length <= 0) {
            tableBody.push(<tr key='tb-cgi-empty'><td colSpan={6} align='center'>- list is empty -</td></tr>);
        }
        else {
            this.state.OrganizerCustomGroups.map((data, key) => {
                tableBody.push(<tr key={'tb-cgi-' + key}>
                    <td align='center'>{key + 1}</td>
                    <td>{CheckObjectStringEmpty(data, ItemProperty.Name)}</td>
                    <td align='center'>{CheckObjectBoolean(data, ItemProperty.AlwaysOnTop) ? '✔' : '-'}</td>
                    <td align='center'>{CheckObjectNumber(data, ItemProperty.DisplayOrder)}</td>
                    <td align='center'>{CheckObjectBoolean(data, ItemProperty.Active) ? '✔' : '-'}</td>
                    <td align='center'>
                        <button type='button' className='btn btn-primary'
                            onClick={() => this.EditCustomGroup_ToggleModal(CheckObjectNumber(data, ItemProperty.Id))}
                            disabled={!PermissionAccess(LayoutScreen.ManageCustomGroup, PermissionAccessType.Update)}
                        >Edit</button>
                    </td>
                </tr>);
                return null;
            });
        }

        // components.push(<button type='button' className='btn btn-primary' onClick={() => this.EditCustomGroup_ToggleModal(0, true)} >Add New Custom Group</button>);
        components.push(<div key='cg-btns' style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            {
                PermissionAccess(LayoutScreen.ManageCustomGroup, PermissionAccessType.Download) ?
                    <button type='button' className='btn btn-info' onClick={() => this.RequestDownload_BulkEditTemplateFile_ViaApi()} title='Download Template for Bulk Assign/Unassign Custom Group(s) for Student(s)'>Download Template</button>
                    : null
            }
            {
                PermissionAccess(LayoutScreen.ManageCustomGroup, PermissionAccessType.Upload) ?
                    <button type='button' className='btn btn-danger' onClick={() => this.ToggleModal_UploadBulkEditTemplateUi()} title='Upload Edited Template for Bulk Assign/Unassign Custom Group(s) for Student(s)'>Upload Edited Template</button>
                    : null
            }
            {
                PermissionAccess(LayoutScreen.ManageCustomGroup, PermissionAccessType.Create) ?
                    <button type='button' className='btn btn-primary' onClick={() => this.EditCustomGroup_ToggleModal(0, true)} title='Add New Custom Group'>Add New Custom Group</button>
                    : null
            }
        </div>);

        components.push(<table key='tb-cg' className='table table-hover table-bordered tbStyle' cellPadding='10' cellSpacing='10' style={{ fontSize: 14, textAlign: 'left', marginTop: 20, marginBottom: 0 }}>
            <thead>
                <tr>
                    <th align='center' width={45}>No</th>
                    <th>Name</th>
                    <th align='center' width={122}>Always On Top</th>
                    <th align='center' width={115}>Display Order</th>
                    <th align='center' width={65}>Active</th>
                    <th align='center' width={75}>Action</th>
                </tr>
            </thead>
            <tbody>
                {tableBody}
            </tbody>
        </table>);

        return (components);
    }
    EditCustomGroup_ToggleModal = (id = 0, newCustomGroup = false) => {
        if (id <= 0) {
            if (newCustomGroup === false) {
                //close.
                this.setState({
                    EditCustomGroup_Toggle: false,
                    EditCustomGroup_isNew: false,
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            EditCustomGroup_Target: null,
                        });
                    }, 200);
                });
            }
            else {
                //new custom group.
                this.setState({
                    EditCustomGroup_Target: {
                        Name: '',
                        OrganizerId: 0,
                        Id: 0,
                        AlwaysOnTop: false,
                        DisplayOrder: 0,
                        Active: false,
                    },
                    EditCustomGroup_isNew: true,
                }, () => {
                    SetTempTarget(this.state.EditCustomGroup_Target);
                    this.setState({
                        EditCustomGroup_Toggle: true,
                    });
                });
            }
        }
        else {
            //edit.
            let target = null;
            const findIndex = this.state.OrganizerCustomGroups.findIndex(x => Number(x.Id) === id);
            if (findIndex > -1)
                target = this.state.OrganizerCustomGroups[findIndex];

            this.setState({
                // EditCustomGroup_Target: target,
                // EditCustomGroup_Toggle: true,
                EditCustomGroup_Target: {
                    name: CheckObjectStringEmpty(target, ItemProperty.Name),
                    organizerId: CheckObjectNumber(target, 'organizerId'),
                    id: CheckObjectNumber(target, ItemProperty.Id),
                    alwaysOnTop: CheckObjectBoolean(target, ItemProperty.AlwaysOnTop),
                    displayOrder: CheckObjectNumber(target, ItemProperty.DisplayOrder),
                    active: CheckObjectBoolean(target, ItemProperty.Active),
                },
            }, () => {
                SetTempTarget(this.state.EditCustomGroup_Target);
                this.setState({
                    EditCustomGroup_Toggle: true,
                });
            });
        }
    }
    EditCustomGroup_BodyComponent = () => {

        if (this.state.EditCustomGroup_Processing)
            return (<ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10, width: '100%' }} />);

        if (this.state.EditCustomGroup_Target === null)
            return null;

        let components = [];

        const target = this.state.EditCustomGroup_Target;

        if (this.state.isDevMode)
            if (CheckObjectNumber(target, ItemProperty.Id) > 0)
                components.push(<div className="form-group" >(Dev) <label>Id</label>&nbsp;:&nbsp;{CheckObjectNumber(target, ItemProperty.Id)}</div>);

        components.push(<div key='edit-cg-name' className="form-group">
            <label>Name *</label>
            {GetInputComponent(InputType.Text, null, target, ItemProperty.Name, null, 'placeholder-custom-group-name', this.state.locale, this.CallbackSaveTarget_CustomGroup, null, this.state.EditCustomGroup_Processing)}
        </div>);

        components.push(<div key='edit-cg-aot' className="form-group">
            <label>Always On Top</label>
            {GetInputComponent(InputType.Checkbox, null, target, ItemProperty.AlwaysOnTop, null, 'placeholder-custom-group-alwaysOnTop', this.state.locale, this.CallbackSaveTarget_CustomGroup, null, this.state.EditCustomGroup_Processing)}
        </div>);

        components.push(<div key='edit-cg-do' className="form-group">
            <label>Display Order</label>
            {GetInputComponent(InputType.Number, null, target, ItemProperty.DisplayOrder, null, 'placeholder-custom-group-displayOrder', this.state.locale, this.CallbackSaveTarget_CustomGroup, null, this.state.EditCustomGroup_Processing)}
        </div>);

        components.push(<div key='edit-cg-ac' className="form-group">
            <label>Active</label>
            {GetInputComponent(InputType.Checkbox, null, target, ItemProperty.Active, null, 'placeholder-custom-group-active', this.state.locale, this.CallbackSaveTarget_CustomGroup, null, this.state.EditCustomGroup_Processing)}
        </div>);

        return (components);
    }
    CallbackSaveTarget_CustomGroup = (tempTarget = null) => {
        if (tempTarget !== undefined && tempTarget !== null) {
            this.setState({
                EditCustomGroup_Target: tempTarget,
            });
            console.log('CallbackSaveTarget_CustomGroup =\n' + JSON.stringify(tempTarget));
            // this.EditCustomGroup_ToggleModal(this.state.EditCustomGroup_Target.id, this.state.EditCustomGroup_isNew);
        }
    }
    SaveCustomGroup_ViaApi = async (remove = false) => {

        if (remove) {
            let confirm = window.confirm('Are you sure you want to remove this custom group ?');
            if (!confirm)
                return null;
        }

        this.setState({
            EditCustomGroup_Processing: true,
        });
        let errorMessage = '';

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);

        let url = GlobalSetting.ApiUrl + 'Api/LearningCentre/Organizer/CustomGroups/';

        const target = this.state.EditCustomGroup_Target;

        if (remove) {
            url += 'Delete';
        }
        else {
            if (CheckObjectNumber(target, ItemProperty.Id) > 0)
                url += 'Update';
            else
                url += 'Create';
        }

        let done = false;
        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    authorId: authorId,
                    organizerId: organizerId,
                    id: CheckObjectNumber(target, ItemProperty.Id),
                    name: CheckObjectStringEmpty(target, ItemProperty.Name),
                    alwaysOnTop: CheckObjectBoolean(target, ItemProperty.AlwaysOnTop),
                    displayOrder: CheckObjectNumber(target, ItemProperty.DisplayOrder),
                    active: CheckObjectBoolean(target, ItemProperty.Active),
                    remove: remove,
                }),
            })
            .then(res => res.json())
            .then(data => {
                if (data.success) {
                }
                else {
                    if (this.state.isDevMode)
                        console.log('Error', 'api - custom group - save (failed)\n' + JSON.stringify(data));
                }
                done = true;
            })
            .catch(error => {
                done = true;
                if (this.state.isDevMode)
                    console.log('Error', 'api - custom group - save (error)\n' + error.message);
            });
        await DelayUntil(() => done === true);

        this.setState({
            EditCustomGroup_Processing: false,
        }, () => {
            if (CheckStringEmpty(errorMessage) !== '') {
                useAppService.getState().setModal('Failed to save custom group', errorMessage);
            }
            else {
                useAppService.getState().setModal('Success', 'Custom group has been saved.');
                this.EditCustomGroup_ToggleModal();
                this.LoadCustomGroupList();
                this.LoadList_ViaApi(true);
            }
        });
    }
    setTimeoutWithCheck = (callback, delay) => {
        const start = Date.now();
        const intervalId = setInterval(() => {
            const elapsed = Date.now() - start;
            if (elapsed >= delay) {
                clearInterval(intervalId);
                callback();
            }
        }, 1000);
    }
    //2023.11.29
    RequestDownload_BulkEditTemplateFile_ViaApi = async () => {

        const reqText = 'requesting file...<br /><br />this may take more than a minute to process, please wait patiently...';
        useAppService.getState().setModal('', reqText, null, AlertMode.Loading);
        // useAppService.getState().setModal('', 'requesting file...', false);

        let timeouts = [];
        for (let t = 1; t <= 3; t++) {
            timeouts.push(setTimeout(() => {
                useAppService.getState().setModal('', reqText + '<br /><br /><b>' + (t * 15) + '</b> seconds had passed.', null, AlertMode.Loading);
            }, t * 15000));
        }
        timeouts.push(setTimeout(() => {
            useAppService.getState().setModal('', 'A minute had passed.<br /><br />Looks like the download request need more time than expected to process, which is most probably due to the massive amount of students recorded.<br /><br />Please wait patiently and check whether the file download has been trigger or not at a later time.<br /><br />A download message will be show when the file is ready to be downloaded to your device.', null, AlertMode.Loading);
        }, 60000));

        let done = false;
        let success = false;
        let errorMessage = '';

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const url = GlobalSetting.ApiUrl + 'Api/LearningCentre/Organizer/CustomGroups/BulkEditTemplate/Download/' + organizerId + '/' + authorId;

        await fetch(url,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('RequestDownload_BulkEditTemplateFile_ViaApi \n', JSON.stringify(data));

                if (data.success) {
                    if (CheckObjectNullValue(data, 'data') !== null) {
                        const detail = data.data;
                        const url = CheckObjectStringEmpty(detail, 'url');
                        const fileName = CheckObjectStringEmpty(detail, 'fileName');
                        const fileExt = CheckObjectStringEmpty(detail, 'fileExt');
                        if (url !== '' && fileName !== '' && fileExt !== '') {
                            for (let t = 0; t < timeouts.length; t++) {
                                if (timeouts[t] !== null)
                                    clearTimeout(timeouts[t]);
                            }
                            success = true;
                            TriggerDownloadFile(url, fileName, fileExt, this.state.locale);
                        }
                        else {
                            errorMessage = 'Invalid Url, FileName or File Extension.';
                        }
                    }
                    else {
                        errorMessage = 'Invalid File Data.';
                    }
                }
                else {
                    errorMessage = data.message;
                    if (this.state.isDevMode)
                        console.log('Error', 'api - RequestDownload_BulkEditTemplateFile_ViaApi (failed)\n' + data.message);
                }
                done = true;
            })
            .catch(error => {
                done = true;
                errorMessage = error.message;
                if (this.state.isDevMode)
                    console.log('Error', 'api - RequestDownload_BulkEditTemplateFile_ViaApi (error)\n' + error.message);
            });
        await DelayUntil(() => done === true);

        if (!success) {
            useAppService.getState().setModal('', 'Failed to download file.' + (CheckNullValue(errorMessage) === null ? '' : '<br /><br />Error:<br />' + errorMessage));
            for (let t = 0; t < timeouts.length; t++) {
                if (timeouts[t] !== null)
                    clearTimeout(timeouts[t]);
            }
        }
    }
    //2023.11.29
    ToggleModal_UploadBulkEditTemplateUi = () => {
        this.setState({
            UploadBulkEditTemplateUi_Toggle: !this.state.UploadBulkEditTemplateUi_Toggle,
            CustomGroups_BulkEditTemplateUploadFile: null,
            BulkEditTemplate_Processing: false,
            BulkEditTemplate_UploadState: null,
        });
    }
    //2023.11.29
    PopulateFileContensForBulkEditCustomGroupsTemplate = async () => {

        let done = false;
        let populate_success = false;
        let populate_errorMessage = '';
        let jsonData = null;

        try {
            let reader = new FileReader();
            reader.onload = (event) => {
                /* Parse data */
                let bstr = event.target.result;
                let wb = XLSX.read(bstr, { type: "binary" });
                let wsname = wb.SheetNames[0];
                // console.log(wsname);
                if (wsname === 'BulkEditCustomGroupsTemplate') {
                    let ws = wb.Sheets[wsname];
                    /* Convert array of arrays */
                    let jsonData_raw = XLSX.utils.sheet_to_json(ws);
                    let jsonStrings = JSON.stringify(jsonData_raw);
                    jsonStrings = jsonStrings.replaceAll('\\r\\n', '<br/>');
                    // if (this.state.isDevMode)
                    //     console.log('BulkEditCustomGroupsTemplate =\n' + jsonStrings);
                    jsonData = JSON.parse(jsonStrings, (key, value) => { return (CheckNullValue(value) === null ? '' : value); });

                    if (this.state.isDevMode) {
                        console.log('jsonData =\n' + JSON.stringify(jsonData));
                        // DownloadTxtFile(jsonData, 'Debug_BulkEditCustomGroups_' + moment.utc().unix());
                    }
                    populate_success = true;
                    done = true;
                }
            };
            reader.readAsArrayBuffer(this.state.CustomGroups_BulkEditTemplateUploadFile);
        }
        catch (err) {
            populate_errorMessage = err;
            done = true;
        };
        await DelayUntil(() => done === true);

        return { jsonData, populate_success, populate_errorMessage };
    }
    //2023.11.29
    Upload_BulkEditTemplateFile_ViaApi = async () => {

        this.setState({
            BulkEditTemplate_Processing: true,
        });
        useAppService.getState().setModal('', 'uploading file & processing...', null, AlertMode.Loading);

        //populating file's content.
        const { jsonData, populate_success, populate_errorMessage } = await this.PopulateFileContensForBulkEditCustomGroupsTemplate();
        if (populate_success === false) {
            useAppService.getState().setModal('', 'Failed to upload file.<br /><br />Error:<br />' + populate_errorMessage);
            return null;
        }

        //init.
        let done = false;
        let success = false;
        let errorMessage = '';

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const url = GlobalSetting.ApiUrl + 'Api/LearningCentre/Organizer/CustomGroups/BulkEditTemplate/Upload';

        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    OrganizerId: organizerId,
                    AuthorId: authorId,
                    Profiles: jsonData,
                }),
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('Upload_BulkEditTemplateFile_ViaApi \n', JSON.stringify(data));

                success = data.success;
                if (data.success) {
                    this.ToggleModal_UploadBulkEditTemplateUi();
                    this.ManageCustomGroup_ToggleModal();
                    this.LoadList_ViaApi(true);
                }
                else {
                    errorMessage = data.message;
                    if (this.state.isDevMode)
                        console.log('Error', 'api - Upload_BulkEditTemplateFile_ViaApi (failed)\n' + data.message);
                }
                done = true;
            })
            .catch(error => {
                done = true;
                errorMessage = error.message;
                if (this.state.isDevMode)
                    console.log('Error', 'api - Upload_BulkEditTemplateFile_ViaApi (error)\n' + error.message);
            });
        await DelayUntil(() => done === true);

        this.setState({
            BulkEditTemplate_Processing: false,
        });

        if (success)
            useAppService.getState().setModal('File has been uploaded successully', 'All students have been updated with their Custom Groups assignation.');
        else
            useAppService.getState().setModal('', 'Failed to upload file.' + (CheckNullValue(errorMessage) === null ? '' : '<br /><br />Error:<br />' + errorMessage));
    }
    //2023.11.29
    onUploadFileChange_CustomGroups_BulkEditTemplate = (event) => {
        this.setState({ CustomGroups_BulkEditTemplateUploadFile: event.target.files[0] });
    };
    //#endregion

    //#region === bulk edit ===
    //2025.01.20
    BulkEdit_ToggleEditSettingModal = () => {
        if (this.state.PA_Update === false) {
            useAppService.getState().setModal(`Bulk Edit ${settingTitle}(s)`, 'Invalid permission.');
            return null;
        }
        const toggle = !this.state.BulkEdit_Toggle_EditSettingModal;
        this.setState({
            BulkEdit_Toggle_EditSettingModal: toggle,
        });
        this.BulkEdit_ResetSetting();
    }
    BulkEdit_SettingModalComponent = () => {
        let components = [];
        const setting = this.state.BulkEdit_Setting;
        const setting_checked = this.state.BulkEdit_Setting_checked;

        //Group.
        const groups_setting_index = Object.keys(BulkSetting).indexOf(BulkSetting.Group);
        const groups_setting_checked = setting_checked[groups_setting_index];
        const groupOptions = useAppService.getState().groupOptions;
        components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (groups_setting_checked ? 'bg-lightskyblue' : '')}`}>
            <div className="select-setting form-group width-max pd-7-10">
                <label>Group</label>
                <ReactSelect
                    className="basic-multi-select"
                    classNamePrefix='select'
                    // isMulti
                    // closeMenuOnSelect={false}
                    options={groupOptions}
                    placeholder='Select Group...'
                    onChange={(option) => this.BulkEdit_SetSetting(BulkSetting.Group, option)}
                    theme={theme => ({
                        ...theme,
                        width: 'max-content',
                        colors: {
                            ...theme.colors,
                            neutral50: 'gray',  // placeholder color
                        }
                    })}
                    value={setting[BulkSetting.Group]}
                />
            </div>
            <div className="select-checkbox">
                <div className="form-check" onChange={() => this.BulkEdit_SetSetting(ItemProperty.CheckedItem, groups_setting_index)}>
                    <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : groups_setting_checked} readOnly={true} />
                </div>
            </div>
        </div>);

        // //Subjects.
        // const subjects_setting_index = Object.keys(BulkSetting).indexOf(BulkSetting.Subjects);
        // const subjects_setting_checked = setting_checked[subjects_setting_index];
        // const subjectOptions = useAppService.getState().subjectOptions;
        // components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (subjects_setting_checked ? 'bg-lightskyblue' : '')}`}>
        //     <div className="select-setting form-group width-max pd-7-10">
        //         <label>Subject(s)</label>
        //         <ReactSelect
        //             className="basic-multi-select"
        //             classNamePrefix='select'
        //             isMulti
        //             closeMenuOnSelect={false}
        //             options={subjectOptions}
        //             placeholder='Select Subject(s)...'
        //             onChange={(option) => this.BulkEdit_SetSetting(BulkSetting.Subjects, option)}
        //             theme={theme => ({
        //                 ...theme,
        //                 width: 'max-content',
        //                 colors: {
        //                     ...theme.colors,
        //                     neutral50: 'gray',  // placeholder color
        //                 }
        //             })}
        //         />
        //     </div>
        //     <div className="select-checkbox">
        //         <div className="form-check" onChange={() => this.BulkEdit_SetSetting(ItemProperty.CheckedItem, subjects_setting_index)}>
        //             <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : subjects_setting_checked} readOnly={true} />
        //         </div>
        //     </div>
        // </div>);

        //Classrooms.
        const classrooms_setting_index = Object.keys(BulkSetting).indexOf(BulkSetting.Classrooms);
        const classrooms_setting_checked = setting_checked[classrooms_setting_index];
        const classroomOptions = useAppService.getState().classroomOptions;
        components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (classrooms_setting_checked ? 'bg-lightskyblue' : '')}`}>
            <div className="select-setting form-group width-max pd-7-10">
                <label>Classroom(s)</label>
                <ReactSelect
                    className="basic-multi-select"
                    classNamePrefix='select'
                    isMulti
                    closeMenuOnSelect={false}
                    options={classroomOptions}
                    placeholder='Select Classroom(s)...'
                    onChange={(option) => this.BulkEdit_SetSetting(BulkSetting.Classrooms, option)}
                    theme={theme => ({
                        ...theme,
                        width: 'max-content',
                        colors: {
                            ...theme.colors,
                            neutral50: 'gray',  // placeholder color
                        }
                    })}
                    value={setting[BulkSetting.Classrooms]}
                />
            </div>
            <div className="select-checkbox">
                <div className="form-check" onChange={() => this.BulkEdit_SetSetting(ItemProperty.CheckedItem, classrooms_setting_index)}>
                    <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : classrooms_setting_checked} readOnly={true} />
                </div>
            </div>
        </div>);

        //Custom Groups.
        const customGroups_Options = this.state.CustomGroupOptions;
        if (Array.isArray(customGroups_Options) && customGroups_Options.length > 0) {
            const customGroups_setting_index = Object.keys(BulkSetting).indexOf(BulkSetting.CustomGroups);
            const customGroups_setting_checked = setting_checked[customGroups_setting_index];
            // const customGroups_ValueIndex = customGroups_Options === undefined ? -1 : customGroups_Options.findIndex(x => String(x.value) === CheckObjectStringEmpty(setting[customGroups_setting_index], 'value'));
            components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (customGroups_setting_checked ? 'bg-lightskyblue' : '')}`}>
                <div className="select-setting form-group width-max pd-7-10">
                    <label>Custom Group(s)</label>
                    <ReactSelect
                        // options={customGroups_Options}
                        // placeholder={customGroups_ValueIndex < 0 ? '(Select Custom Group...)' : customGroups_Options[customGroups_ValueIndex].label}
                        // value={customGroups_ValueIndex < 0 ? 0 : customGroups_Options[customGroups_ValueIndex].value}
                        // onChange={(option) => this.BulkEdit_SetSetting(BulkSetting.CustomGroups, option)}

                        className="basic-multi-select"
                        classNamePrefix='select'
                        isMulti
                        closeMenuOnSelect={false}
                        options={customGroups_Options}
                        placeholder='Select Custom Group(s)...'
                        onChange={(option) => this.BulkEdit_SetSetting(BulkSetting.CustomGroups, option)}
                        theme={theme => ({
                            ...theme,
                            width: 'max-content',
                            colors: {
                                ...theme.colors,
                                neutral50: 'gray',  // placeholder color
                            }
                        })}
                        value={setting[BulkSetting.CustomGroups]}
                    />
                </div>
                <div className="select-checkbox">
                    <div className="form-check" onChange={() => this.BulkEdit_SetSetting(ItemProperty.CheckedItem, customGroups_setting_index)}>
                        <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : customGroups_setting_checked} readOnly={true} />
                    </div>
                </div>
            </div>);
        }

        // //Display Order.
        // const displayOrder_setting_index = Object.values(BulkSetting).indexOf(BulkSetting.DisplayOrder);
        // const displayOrder_setting_checked = setting_checked[displayOrder_setting_index];
        // components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (displayOrder_setting_checked ? 'bg-lightskyblue' : '')}`}>
        //     <div className="form-group width-max">
        //         <label>Display Order</label>
        //         <input type="number" className="form-control" style={{ width: '100%' }}
        //             value={CheckNumber(setting[displayOrder_setting_index].value)}
        //             onChange={(e) => this.BulkEdit_SetSetting(BulkSetting.DisplayOrder, e.target.value)}
        //         ></input>
        //     </div>
        //     <div className="select-checkbox">
        //         <div className="form-check" onChange={() => this.BulkEdit_SetSetting(BulkSetting.CheckedItem, displayOrder_setting_index)}>
        //             <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : displayOrder_setting_checked} readOnly={true} />
        //         </div>
        //     </div>
        // </div>);

        // //Active.
        // const active_setting_index = Object.values(BulkSetting).indexOf(BulkSetting.Active);
        // const active_setting_checked = setting_checked[active_setting_index];
        // components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (active_setting_checked ? 'bg-lightskyblue' : '')}`}>
        //     <div className="form-group width-max">
        //         <label>Active</label>
        //         <input type="checkbox" className="form-check form-check-input"
        //             onClick={(e) => this.BulkEdit_SetSetting(BulkSetting.Active, e.currentTarget.checked)}
        //             checked={CheckBoolean(setting[active_setting_index].value)}
        //             readOnly={true}
        //         ></input>
        //     </div>
        //     <div className="select-checkbox">
        //         <div className="form-check" onChange={() => this.BulkEdit_SetSetting(BulkSetting.CheckedItem, active_setting_index)}>
        //             <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : active_setting_checked} readOnly={true} />
        //         </div>
        //     </div>
        // </div>);

        // //Effective Date Start.
        // const effectiveDateStart_setting_index = Object.values(BulkSetting).indexOf(BulkSetting.EffectiveDateStart);
        // const effectiveDateStart_setting_checked = setting_checked[effectiveDateStart_setting_index];
        // components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (effectiveDateStart_setting_checked ? 'bg-lightskyblue' : '')}`}>
        //     <div className="form-group width-max">
        //         <label>Effective Date Start</label>
        //         <input type="datetime-local" className="form-control" style={{ width: '100%' }}
        //             onChange={(e) => this.BulkEdit_SetSetting(BulkSetting.EffectiveDateStart, e.target.value)}
        //             value={moment.utc(setting[effectiveDateStart_setting_index].value).local().format('YYYY-MM-DD HH:mm')}
        //         ></input>
        //     </div>
        //     <div className="select-checkbox">
        //         <div className="form-check" onChange={() => this.BulkEdit_SetSetting(BulkSetting.CheckedItem, effectiveDateStart_setting_index)}>
        //             <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : effectiveDateStart_setting_checked} readOnly={true} />
        //         </div>
        //     </div>
        // </div>);

        // //Effective Date End.
        // const effectiveDateEnd_setting_index = Object.values(BulkSetting).indexOf(BulkSetting.EffectiveDateEnd);
        // const effectiveDateEnd_setting_checked = setting_checked[effectiveDateEnd_setting_index];
        // components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (effectiveDateEnd_setting_checked ? 'bg-lightskyblue' : '')}`}>
        //     <div className="form-group width-max">
        //         <label>Effective Date End</label>
        //         <input type="datetime-local" className="form-control" style={{ width: '100%' }}
        //             onChange={(e) => this.BulkEdit_SetSetting(BulkSetting.EffectiveDateEnd, e.target.value)}
        //             value={moment.utc(setting[effectiveDateEnd_setting_index].value).local().format('YYYY-MM-DD HH:mm')}
        //         ></input>
        //     </div>
        //     <div className="select-checkbox">
        //         <div className="form-check" onChange={() => this.BulkEdit_SetSetting(BulkSetting.CheckedItem, effectiveDateEnd_setting_index)}>
        //             <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : effectiveDateEnd_setting_checked} readOnly={true} />
        //         </div>
        //     </div>
        // </div>);

        // //Remark.
        // const remark_setting_index = Object.values(BulkSetting).indexOf(BulkSetting.Remark);
        // const remark_setting_checked = setting_checked[remark_setting_index];
        // components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (remark_setting_checked ? 'bg-lightskyblue' : '')}`}>
        //     <div className="form-group width-max">
        //         <label>Remark</label>
        //         <input type="text" className="form-control" style={{ width: '100%' }}
        //             value={CheckStringEmpty(setting[remark_setting_index].value)}
        //             onChange={(e) => this.BulkEdit_SetSetting(BulkSetting.Remark, e.target.value)}
        //         ></input>
        //     </div>
        //     <div className="select-checkbox">
        //         <div className="form-check" onChange={() => this.BulkEdit_SetSetting(BulkSetting.CheckedItem, remark_setting_index)}>
        //             <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : remark_setting_checked} readOnly={true} />
        //         </div>
        //     </div>
        // </div>);

        // //Display.
        // const display_setting_index = Object.values(BulkSetting).indexOf(BulkSetting.Display);
        // const display_setting_checked = setting_checked[display_setting_index];
        // components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (display_setting_checked ? 'bg-lightskyblue' : '')}`}>
        //     <div className="form-group width-max">
        //         <label>Display</label>
        //         <input type="checkbox" className="form-check form-check-input"
        //             onClick={(e) => this.BulkEdit_SetSetting(BulkSetting.Display, e.currentTarget.checked)}
        //             checked={CheckBoolean(setting[display_setting_index].value)}
        //             readOnly={true}
        //         ></input>
        //     </div>
        //     <div className="select-checkbox">
        //         <div className="form-check" onChange={() => this.BulkEdit_SetSetting(BulkSetting.CheckedItem, display_setting_index)}>
        //             <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : display_setting_checked} readOnly={true} />
        //         </div>
        //     </div>
        // </div>);

        return (components);
    }
    BulkEdit_SetSetting = (property = ItemProperty.None, value = null) => {
        let setting = this.state.BulkEdit_Setting;
        let setting_checked = this.state.BulkEdit_Setting_checked;
        const setting_index = property === ItemProperty.CheckedItem ? 999 : Object.keys(BulkSetting).indexOf(property);
        if (this.state.isDevMode)
            console.log(`BulkEdit_SetSetting (setting_index = ${setting_index}) | (property = ${property}) | (value = ${JSON.stringify(value)})`);
        if (property === ItemProperty.None || setting === null || value === null || setting_index < 0)
            return null;

        switch (property) {
            // case ItemProperty.Groups: setting[setting_index].value = Array.isArray(value) ? value : []; break;
            // case ItemProperty.Subjects: setting[setting_index].value = Array.isArray(value) ? value : []; break;
            case ItemProperty.Group: setting[setting_index].value = value; break;
            case ItemProperty.Classrooms: setting[setting_index].value = Array.isArray(value) ? value : []; break;
            case ItemProperty.CustomGroups: setting[setting_index].value = Array.isArray(value) ? value : []; break;

            case ItemProperty.CheckedItem:
                setting_checked[value] = !setting_checked[value];
                if (this.state.isDevMode)
                    console.log(`BulkEdit_SetSetting (checkedItem) = ` + JSON.stringify(setting_checked));
                break;
            default: break;
        }
        this.setState({
            BulkEdit_Setting: setting,
            BulkEdit_Setting_checked: setting_checked,
        }, () => {
            if (this.state.isDevMode && property !== ItemProperty.CheckedItem) {
                console.log(`BulkEdit_SetSetting (${property}) = ` + JSON.stringify(value));
                console.log(`BulkEdit_SetSetting (setting) = ` + JSON.stringify(setting));
            }
        });
    }
    BulkEdit_ResetSetting = () => {
        this.setState({
            BulkEdit_Setting: [
                // { key: BulkSetting.Groups, value: [] },
                // { key: BulkSetting.Subjects, value: [] },
                { key: BulkSetting.Group, value: null },
                { key: BulkSetting.Classroom, value: '' },
                { key: BulkSetting.Classrooms, value: [] },
                { key: BulkSetting.CustomGroups, value: [] },
            ],
            BulkEdit_Setting_checked: Object.keys(BulkSetting).map(() => { return false; }),
        });
    }
    BulkEdit_ToggleRemoveSettingModal = () => {
        this.setState({
            BulkEdit_Toggle_RemoveSettingModal: !this.state.BulkEdit_Toggle_RemoveSettingModal,
        });
    }
    BulkEdit_CUD_Setting_ViaApi = async (remove = false) => {

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const { textTitle, textBody, text, urlParam } = GetPostParams({ id: 999 }, remove);
        this.setState({
            BulkEdit_IsUpdating: true,
        });
        useAppService.getState().setModal('', `${textTitle} setting...`, null, AlertMode.Loading);

        const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Organizer/Student/BulkEdit/${urlParam}`;
        // Api/LearningCentre/Organizer/Student/BulkEdit/{Update|Remove}

        let setting_params = [];
        const setting_keys = Object.values(BulkSetting);
        if (remove === false) {
            for (let i = 0; i < this.state.BulkEdit_Setting_checked.length; i++) {
                if (this.state.BulkEdit_Setting_checked[i])
                    setting_params.push({ key: setting_keys[i], value: this.state.BulkEdit_Setting[i].value });
                else
                    setting_params.push({ key: setting_keys[i], value: null });
            }
        }

        let profileIds = [];
        const list = this.state.List;
        const checkedItems = this.state.BulkEdit_CheckedItems;
        for (let n = 0; n < list.length; n++) {
            if (checkedItems[n])
                profileIds.push(CheckObjectNumber(list[n], ItemProperty.Id));
        }

        const json = JSON.stringify({
            OrganizerId: organizerId,
            AuthorId: authorId,

            BulkProfileIds: profileIds.join(','),
            // Subjects: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.Subjects)].value,
            Group: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.Group)].value,
            Classrooms: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.Classrooms)].value,
            CustomGroups: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.CustomGroups)].value,

            Remove: remove,
        });
        if (this.state.isDevMode)
            console.log(`BulkEdit_CUD_Setting_ViaApi (${text}) (postData) =\n` + json);

        // this.setState({ BulkEdit_IsUpdating: false });
        // useAppService.getState().setModal();
        // return null;

        // let data = null;
        let success = false;
        let msg = '';
        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: json,
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('BulkEdit_CUD_Setting_ViaApi =\n' + JSON.stringify(data));

                success = CheckObjectBoolean(data, 'success');
                // data = CheckObjectNullValue(data, 'data');

                if (!success)
                    msg = CheckObjectStringEmpty(data, 'message');
            })
            .catch(error => {
                msg = error.message;
                if (this.state.isDevMode)
                    console.log('Error', `api - ${text} (error)\n` + error.message);
            });

        if (success) {
            this.LoadList_ViaApi();
            this.BulkEdit_ToggleEditSettingModal();
            await Delay(500);
            useAppService.getState().setModal();
            await Delay(500);
            useAppService.getState().setModal('', `${settingTitle}(s) have been ${textBody}.`);
        }
        else {
            useAppService.getState().setModal('', `Failed to ${text} ${settingTitle.toLowerCase()}(s).<br /><br />` + msg);
        }
        this.setState({
            BulkEdit_IsUpdating: false,
        });
    }
    //#endregion === bulk edit ===

    //#region === Send Password To ALL or SELECTED Students By Email ===
    //2025.02.12
    SendPasswordTo_ALL_OR_SELECTED_Profiles_ByEmailViaApi = async () => {

        const list = this.state.List;
        const checkedItems = this.state.BulkEdit_CheckedItems;

        let checkedItemsInfo_html = '\n\n';
        for (let n = 0; n < list.length; n++) {
            if (checkedItems[n])
                checkedItemsInfo_html += `(${n + 1}) ${CheckObjectStringEmpty(list[n], ItemProperty.Name)} \n        (${CheckObjectStringEmpty(list[n], ItemProperty.Email)})\n`;
        }

        const confirm = window.confirm(`Continue to send login credential email to ${this.state.BulkEdit_CheckedItems.includes(true) ? 'selected' : 'all'} Students ? ${checkedItemsInfo_html}`);
        if (confirm === false)
            return null;

        useAppService.getState().setModal('', 'sending login credentials via email...', null, AlertMode.Loading);
        this.setState({
            isProcessing: true,
        });

        let groupIds = '0';
        let classrooms = '0';

        //#region userIds
        let profileIds = '0';
        let itemIds = [];
        for (let n = 0; n < list.length; n++) {
            if (checkedItems[n])
                itemIds.push(CheckObjectNumber(list[n], ItemProperty.Id));
        }
        if (itemIds.length > 0)
            profileIds = itemIds.join(',');
        //#endregion

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Organizer/Student/Profile/SendEmail/Password/Multiple/${organizerId}/${authorId}/${groupIds}/${classrooms}/${profileIds}`;

        // console.log('SendPasswordTo_ALL_OR_SELECTED_Profiles_ByEmailViaApi = \n' + url);
        // useAppService.getState().setModal();
        // this.setState({ isProcessing: false, });
        // return null;

        let done = false;
        let errorMessage = '';
        let success = false;
        let responseData = null;

        await fetch(url,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('Response', 'api - all student - send email password \n' + JSON.stringify(data));

                responseData = data.data;
                success = CheckObjectBoolean(data, 'success');
                if (!success) {
                    errorMessage = CheckObjectStringEmpty(data, 'message');
                }
                done = true;
            })
            .catch(error => {
                errorMessage = CheckObjectStringEmpty(error, 'message');
                done = true;
                if (this.state.isDevMode)
                    console.log('Error', 'api - all student - send email password (error)\n' + errorMessage);
            });
        await DelayUntil(() => done === true);

        // //debug.
        // success = true;
        // responseData = [{ Email: 'email_1', Name: 'name_1', Sent: true }, { Email: 'email_2', Name: 'name_2', Sent: false }];

        //responseData.
        if (responseData !== null && responseData !== undefined && Array.isArray(responseData)) {
            let htmlText = '';
            responseData.map((data, key) => {
                return htmlText += `<tr><td>${key + 1}</td>`
                    + `<td class="left">${CheckObjectStringEmpty(data, 'Name', '-')}</td>`
                    + `<td class="left">${CheckObjectStringEmpty(data, 'Email', '-')}</td>`
                    + `<td class="icon-color"><i class="fa ${CheckObjectBoolean(data, 'Sent') ? 'fa-check-circle blue' : 'fa-times-circle red'} fs20"></i></td></tr>`;
            });
            if (htmlText === '')
                responseData = '';
            else
                responseData = `<br /><br /><table class="table tbStyle" border="1"><thead><tr><th>#</th><th class="left">Name</th><th class="left">Email</th><th>Sent</th></tr></thead><tbody>${htmlText}</tbody></table>`;
        }
        else {
            responseData = '';
        }

        this.setState({
            isProcessing: false,
        });
        if (success) {
            useAppService.getState().setModal('Email Sent', 'All emails have been sent successfully.' + responseData);
        }
        else {
            useAppService.getState().setModal(`Unsuccessful`,
                `Failed to send login credentials via email. ${errorMessage === '' ? '' : '<br /><br />Error:<br />' + errorMessage}` + responseData);
        }
    }
    //#endregion === Send Password To ALL Students By Email ===

    render = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        return (<div className="">
            <table className="table page-header">
                <tbody>
                    <tr>
                        <td className="left" style={{ flex: 2 }}>
                            <h5>Student Profile</h5>
                            <div className="form-check">
                                <input
                                    id='formCheck_DeactivatedItems_Toggle'
                                    className='form-check-input cursor-pointer'
                                    type='checkbox'
                                    defaultChecked={this.state.DeactivatedItems_Toggle}
                                    readOnly={true}
                                    onClick={() => this.setState({
                                        DeactivatedItems_Toggle: !this.state.DeactivatedItems_Toggle
                                    }, () => {
                                        this.LoadList_ViaApi(true);
                                    })}
                                ></input>
                                <label className='form-check-label cursor-pointer' htmlFor='formCheck_DeactivatedItems_Toggle'
                                    style={{ color: 'gray', fontSize: 'small', userSelect: 'none', }}> show deactivated students</label>
                            </div>
                            {
                                PermissionAccess(LayoutScreen.ManageCustomGroup, PermissionAccessType.View) ?
                                    <button type="button" className="btn btn-outline-primary"
                                        onClick={() => this.ManageCustomGroup_ToggleModal()}
                                        disabled={this.state.ManageCustomGroupModal_Toggle}
                                    >Custom Group</button>
                                    : null
                            }
                            <Button
                                variant='outline-primary'
                                onClick={() => this.SendPasswordTo_ALL_OR_SELECTED_Profiles_ByEmailViaApi()}
                                disabled={this.state.isProcessing || this.state.List.length === 0}
                            >Send login credential email to {this.state.BulkEdit_CheckedItems.includes(true) ? 'selected' : 'all'} Students</Button>
                            <Button variant="primary"
                                onClick={() => this.BulkEdit_ToggleEditSettingModal()}
                                disabled={this.state.BulkEdit_CheckedItems.length === 0 ? true : (this.state.BulkEdit_CheckedItems.includes(true) ? false : true)}
                            >Bulk Edit</Button>
                            <button
                                type="button"
                                className="btn-link"
                                onClick={() => this.LoadList_ViaApi(true)}
                                title="Refresh Student Profile list"
                            ><i className="fa fa-refresh" title="Refresh Student Profile list"></i></button>
                        </td>
                        {/* <td className="center"></td> */}
                        <td className="right">
                            {
                                this.state.PA_Search === false ? null :
                                    <Button
                                        variant='outline-primary'
                                        onClick={() => this.ResetSearchStudentParams(true)}
                                        disabled={this.state.SearchByConditionModal_Toggle}
                                    >Search Student</Button>
                            }
                            {
                                this.state.PA_Upload === false ? null :
                                    <Button
                                        variant='outline-primary'
                                        onClick={() => this.ToggleUploadProfileModal()}
                                        disabled={this.state.UploadStudentProfileModal_Toggle}
                                    >Upload Student</Button>
                            }
                            {
                                this.state.PA_Create === false ? null :
                                    <Button
                                        variant='outline-primary'
                                        onClick={() => this.ToggleEditProfileUiModal(999, true)}
                                        disabled={this.state.UploadStudentProfileModal_Toggle}
                                    >Add Student</Button>
                            }
                            {/* <Button
                                variant='outline-primary'
                                onClick={() => this.setState({ redirectLink: getMenuLink(LayoutScreen.Dashboard), redirect: true, })}
                            >Back to Dashboard</Button> */}
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className='table table-hover table-bordered tbStyle' cellPadding='10' cellSpacing='10' style={{ fontSize: 14 }}>
                <thead>
                    <tr>
                        <th width='50' className="pointer" onClick={() => this.state.isLoading || this.state.List.length === 0 ? DoNothing() : this.ToggleItemChecked(-1, this.state.BulkEdit_CheckedItems.findIndex(x => x === false) < 0)}>
                            <input type='checkbox' className={this.state.isLoading || this.state.List.length === 0 ? '' : 'pointer'}
                                checked={this.state.isLoading || this.state.List.length === 0 ? false : !(this.state.BulkEdit_CheckedItems.findIndex(x => x === false) > -1)}
                                readOnly={true} disabled={this.state.isLoading || this.state.List.length === 0}></input>
                        </th>
                        {/* <th width='50'>#</th> */}
                        <th className="left">Name</th>
                        <th width='135'>Custom Group</th>
                        <th width='95'>Classroom</th>
                        <th width='100'>Group</th>
                        <th width='225'>Email</th>
                        {
                            this.state.DeactivatedItems_Toggle ?
                                <th width='225'>Deactivated by</th>
                                : null
                        }
                        <th width='75'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.isLoading && !this.state.IsListLoaded ?
                            // <tr><td colSpan='15' align='center'><LoadingIndicator /></td></tr>
                            <tr><td colSpan='15' height={63}><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10 }} /></td></tr>
                            : this.state.List.length > 0 ?
                                this.ListComponents()
                                : <tr><td colSpan='15' align='center'>list is empty</td></tr>
                    }
                    {
                        this.state.List.length === 0 ? null :
                            PagingComponents(15, this.state.TotalRows, this.state.PageIndex, this.state.PageSize, this.CallbackFunctionForPagingComponents_PageSize, this.CallbackFunctionForPagingComponents_PageIndex)
                    }
                </tbody>
            </table>

            {/* Profile - Search Student by Condition - Modal */}
            <Modal show={this.state.SearchByConditionModal_Toggle}
                onHide={() => this.state.SearchByCondition_Processing ? DoNothing() : this.ResetSearchStudentParams()}
                centered>
                <Modal.Header closeButton={this.state.SearchByCondition_Processing === false}>
                    <Modal.Title>{
                        this.state.SearchByCondition_Processing ? 'Searching...' : 'Search Student by ' + this.state.SearchUserByCondition
                    }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        this.state.SearchByCondition_Processing ?
                            <ProgressBar animated now={100} className='progressbar1' />
                            :
                            <table cellPadding={5} cellSpacing={0} width='100%'>
                                <tbody>
                                    <tr>
                                        {/* <td align='right'><span>Search by {this.state.SearchUserByCondition}</span></td> */}
                                        <td>
                                            <span>Search Student by</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                {
                                                    Object.keys(SearchCondition).map((name, key) => {
                                                        const option = SearchCondition[name];
                                                        return (<div className='form-control' style={{ border: 0, cursor: 'pointer', }}
                                                            key={'k-search-by-' + name}
                                                            onClick={() => this.setState({
                                                                SearchUserByCondition: option,
                                                                SearchUserByName: name === SearchCondition.Name ? '' : this.state.SearchUserByName,
                                                                SearchUserByEmail: name === SearchCondition.Email ? '' : this.state.SearchUserByEmail,
                                                                SearchUserByGroup: name === SearchCondition.Group ? '' : this.state.SearchUserByGroup,
                                                                SearchUserByClassroom: name === SearchCondition.Classroom ? '' : this.state.SearchUserByClassroom,
                                                                SearchUserBySchoolName: name === SearchCondition.SchoolName ? '' : this.state.SearchUserBySchoolName,
                                                            })}
                                                            disabled={this.state.isLoading}
                                                        >
                                                            <input type='radio' name='searchBy' readOnly={true}
                                                                checked={this.state.SearchUserByCondition === option}
                                                            />&nbsp;&nbsp;{option}
                                                        </div>);
                                                    })
                                                }
                                                {/* <div className='form-control' style={{ border: 0, cursor: 'pointer', }}
                                                    onClick={() => this.setState({ SearchUserByCondition: SearchCondition.Name, SearchUserByName: '', })}
                                                    disabled={this.state.isLoading}
                                                >
                                                    <input type='radio' name='searchBy' readOnly={true}
                                                        checked={this.state.SearchUserByCondition === SearchCondition.Name}
                                                    />&nbsp;&nbsp;{SearchCondition.Name}
                                                </div>
                                                <div className='form-control' style={{ border: 0, cursor: 'not-allowed', color: 'darkgray', }}
                                                    // onClick={() => this.setState({ SearchUserByCondition: SearchCondition.StudentEmail, SearchUserByEmail: '', })}
                                                    disabled={this.state.isLoading}
                                                >
                                                    <input type='radio' name='searchBy' readOnly={true}
                                                        checked={this.state.SearchUserByCondition === SearchCondition.Email}
                                                    // disabled={true}
                                                    />&nbsp;&nbsp;{SearchCondition.Email}
                                                </div>
                                                <div className='form-control' style={{ border: 0, cursor: 'not-allowed', color: 'darkgray', }}
                                                    // onClick={() => this.setState({ SearchUserByCondition: SearchCondition.Classroom, SearchUserByClassroom: '', })}
                                                    disabled={this.state.isLoading}
                                                >
                                                    <input type='radio' name='searchBy' readOnly={true}
                                                        checked={this.state.SearchUserByCondition === SearchCondition.Classroom}
                                                    // disabled={true}
                                                    />&nbsp;&nbsp;{SearchCondition.Classroom}
                                                </div>
                                                <div className='form-control' style={{ border: 0, cursor: 'not-allowed', color: 'darkgray', }}
                                                    // onClick={() => this.setState({ SearchUserByCondition: SearchCondition.Grade, SearchUserByGroup: '', })}
                                                    disabled={this.state.isLoading}
                                                >
                                                    <input type='radio' name='searchBy' readOnly={true}
                                                        checked={this.state.SearchUserByCondition === SearchCondition.Grade}
                                                    // disabled={true}
                                                    />&nbsp;&nbsp;{SearchCondition.Grade}
                                                </div> */}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <input className='form-control' type="text" style={{ width: '100%' }}
                                                // value={
                                                //     this.state.SearchUserByCondition === SearchCondition.SchoolName ? this.state.SearchUserBySchoolName :
                                                //         this.state.SearchUserByCondition === SearchCondition.StudentName ? this.state.SearchUserByName :
                                                //             this.state.SearchUserByCondition === SearchCondition.StudentEmail ? this.state.SearchUserByEmail :
                                                //                 ''
                                                // }
                                                placeholder={this.GetSearchInputPlaceholder()}
                                                // placeholder={'(enter ' + this.state.SearchUserByCondition.toLowerCase() + ' here)'}
                                                onChange={(e) => {
                                                    switch (this.state.SearchUserByCondition) {
                                                        case SearchCondition.Name: this.setState({ SearchUserByName: String(e.target.value) }); break;
                                                        case SearchCondition.Email: this.setState({ SearchUserByEmail: String(e.target.value) }); break;
                                                        case SearchCondition.Group: this.setState({ SearchUserByGroup: String(e.target.value) }); break;
                                                        case SearchCondition.Classroom: this.setState({ SearchUserByClassroom: String(e.target.value) }); break;
                                                        case SearchCondition.SchoolName: this.setState({ SearchUserBySchoolName: String(e.target.value) }); break;
                                                        default: break;
                                                    }
                                                }}
                                                disabled={this.state.SearchByCondition_Processing}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                    }
                </Modal.Body>
                {
                    !this.state.SearchByCondition_Processing ?
                        <Modal.Footer>
                            <Button variant="secondary"
                                onClick={() => this.ResetSearchStudentParams()}
                            >Cancel</Button>
                            {/* <Button variant="secondary"
                                        onClick={() => this.setState({
                                            SearchUserBySchoolName: '',
                                            SearchUserByName: '',
                                            SearchUserByEmail: '',
                                            SearchUserByCondition: SearchCondition.StudentName
                                        })}
                                    >Reset</Button> */}
                            <Button variant="primary"
                                onClick={() => this.SearchStudentByCondition_ViaAPI()}
                                disabled={
                                    this.state.SearchUserByCondition === SearchCondition.SchoolName ?
                                        CheckNullValue(this.state.SearchUserBySchoolName) === null
                                        :
                                        this.state.SearchUserByCondition === SearchCondition.Name ?
                                            CheckNullValue(this.state.SearchUserByName) === null
                                            :
                                            this.state.SearchUserByCondition === SearchCondition.Email ?
                                                CheckNullValue(this.state.SearchUserByEmail) === null
                                                :
                                                this.state.SearchUserByCondition === SearchCondition.Classroom ?
                                                    CheckNullValue(this.state.SearchUserByClassroom) === null
                                                    :
                                                    this.state.SearchUserByCondition === SearchCondition.Group ?
                                                        CheckNullValue(this.state.SearchUserByGroup) === null
                                                        : true
                                }
                            >Search</Button>
                        </Modal.Footer>
                        : null
                }
            </Modal>

            {/* StudentProfile - Upload - Modal */}
            <Modal show={this.state.UploadStudentProfileModal_Toggle}
                onHide={this.ToggleUploadProfileModal}
                centered
                size='lg'
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>Upload Student Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table width='100%' cellPadding='5'>
                        <tbody>
                            {/* <tr><td colSpan='3'><hr /></td></tr> */}
                            <tr>
                                <td colSpan='3'>
                                    <button
                                        className='link-button'
                                        onClick={() => {
                                            // const baseFront = "window.open('https://ikeynew.blob.core.windows.net/ikeykidz/quizbank/TEMPLATE_STUDENT_PROFILE_";
                                            // const baseRear = ".xlsx', '_new')";
                                            // const link500std1 = baseFront + '500_Std1' + baseRear;
                                            // const link500std2 = baseFront + '500_Std2' + baseRear;
                                            // const link500std3 = baseFront + '500_Std3' + baseRear;
                                            // const link500std4 = baseFront + '500_Std4' + baseRear;
                                            // const link500std5 = baseFront + '500_Std5' + baseRear;
                                            // const link500std6 = baseFront + '500_Std6' + baseRear;
                                            // const linkSample = baseFront + 'SAMPLE' + baseRear;
                                            // let htmlText = '<table cellpadding="5" width="100%" border="1" style="text-align:center;"><thead><tr><th>Grade</th><th>Spreadsheet Template</th></tr></thead><tbody>';
                                            // htmlText += '<tr><td>Standard 1</td><td><button class="link-button" onClick="' + link500std1 + '">download</button></td></tr>';
                                            // htmlText += '<tr><td>Standard 2</td><td><button class="link-button" onClick="' + link500std2 + '">download</button></td></tr>';
                                            // htmlText += '<tr><td>Standard 3</td><td><button class="link-button" onClick="' + link500std3 + '">download</button></td></tr>';
                                            // htmlText += '<tr><td>Standard 4</td><td><button class="link-button" onClick="' + link500std4 + '">download</button></td></tr>';
                                            // htmlText += '<tr><td>Standard 5</td><td><button class="link-button" onClick="' + link500std5 + '">download</button></td></tr>';
                                            // htmlText += '<tr><td>Standard 6</td><td><button class="link-button" onClick="' + link500std6 + '">download</button></td></tr>';
                                            // htmlText += '</tbody></table><br />';
                                            // htmlText += '<table cellpadding="5" width="100%" border="1" style="text-align:center;"><thead><tr><th>Spreadsheet Implementation Sample</th></tr></thead><tbody>';
                                            // htmlText += '<tr><td><button class="link-button" onClick="' + linkSample + '">download sample file</button></td></tr>';
                                            // htmlText += '</tbody></table>';
                                            // htmlText += '<br /><ul style="list-style-type: circle"><li>to enter more students, first download either one of provided file template, unprotect the spreadsheet, increase required rows by dragging a whole row of data, then fill-in the remaining details.</li></ul>'
                                            // useAppService.getState().setModal('Student Profile Upload Template', htmlText);

                                            //2023.09.29
                                            this.ShowTemplateDownloadAlertBox();
                                        }}
                                    >Download & use the provided spreadsheet template file</button> for upload purpose.
                                    <p>Using other files with different column name or format will cause errors during data validation.</p>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <input type="file" onChange={this.onUploadFileChange} style={{ width: '100%' }} />*
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>&nbsp;
                                    <span>Continue to upload this file ?</span>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td colSpan={3}>
                                    <div className="form-check setting-checkbox framed-checkbox"
                                        onChange={() => this.setState({ SendEmailAfterUpload: !this.state.SendEmailAfterUpload, })}
                                        style={{ height: 32 }}
                                    >
                                        <input className="form-check-input" type="checkbox" id="checkbox-send-email-after-upload"
                                            readOnly={true}
                                            checked={this.state.SendEmailAfterUpload}
                                        />
                                        <label className="form-check-label" htmlFor="checkbox-send-email-after-upload" style={{ cursor: 'pointer' }}
                                        >Send Email after Upload.</label>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.ToggleUploadProfileModal()}>Cancel</Button>
                    &nbsp;&nbsp;
                    <Button variant="secondary" onClick={() => this.ResetUploadProfileModal('reset')}>Reset</Button>
                    &nbsp;&nbsp;
                    <Button
                        variant="primary"
                        onClick={() => this.TriggerUploadProfileFile()}
                        disabled={this.state.UploadStatus !== UploadState.None}
                    >Upload</Button>
                </Modal.Footer>
            </Modal>

            {/* Student Profile - Process Upload - Modal */}
            <Modal show={this.state.UploadStatus !== UploadState.None}
                // onHide={this.HideComponent_UploadUiModal() ? this.ResetUploadProfileModal : DoNothing()}
                onHide={DoNothing}
                centered
                dialogClassName='alert-dialog-bordered'
            >
                <Modal.Header>
                    <Modal.Title style={{ fontSize: 20 }}>{this.state.UploadStatus}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ display: 'grid' }}>
                    {UploadStatusMessage(this.state.UploadStatus, this.state.UploadStatusText, this.state.UniqueId)}
                    {this.state.UploadResultModal === null ? null : <>
                        <br />
                        <button type='button' className='btn btn-primary' onClick={() => this.DownloadTableAsXLSX('uploadTableResult_hidden', 'Processed-Profile-Template_' + moment().format('YYYY-MM-DD_HH-mm-ss'))}>Download table as XLSX</button>
                    </>}
                </Modal.Body>
                {
                    this.HideComponent_UploadUiModal() ? null :
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.ResetUploadProfileModal('reload')}>Close</Button>
                        </Modal.Footer>
                }
            </Modal>

            {/* Student Profile - Edit / Create - Modal */}
            <Modal show={this.state.EditProfileModal_Toggle}
                onHide={() => this.ToggleEditProfileUiModal()}
                centered
                dialogClassName='alert-dialog-bordered'
                size='lg'
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title style={{ fontSize: 20 }}>{this.state.EditProfileState} Student {
                        this.state.CreateNewStudentProfile ? '' :
                            !Array.isArray(this.state.List) || this.state.List.length === 0 ? '' :
                                this.state.TargetProfileIndex < 0 ? null :
                                    '(' + CheckObjectStringEmpty(this.state.List[this.state.TargetProfileIndex], ItemProperty.Email) + ')'
                    }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.EditProfileComponents()}
                    {CommonStatusMessage(this.state.EditProfileState, this.state.CommonStatus)}
                </Modal.Body>
                {
                    this.state.EditProfileState === CommonState.Processing ? null :
                        <Modal.Footer>
                            {
                                this.state.PA_Update === false ? null :
                                    <>
                                        {
                                            this.state.CreateNewStudentProfile || this.state.TargetProfileIndex < 0 ? null :
                                                CheckObjectBoolean(this.state.List[this.state.TargetProfileIndex], ItemProperty.Deactivated) ?
                                                    <Button variant="primary" className="pull-left" onClick={() => this.ActivateProfile()}>Activate</Button>
                                                    :
                                                    <Button variant="danger" className="pull-left" onClick={() => this.DeactivateProfile()}>Deactivate</Button>
                                        }
                                        <Button variant="secondary" onClick={() => this.ResetProfileValue()}>Reset</Button>
                                        <Button variant="primary" onClick={() => this.UpdateProfile()}>{this.state.CreateNewStudentProfile ? 'Create' : 'Save'}</Button>
                                    </>
                            }
                            <Button variant="secondary" onClick={() => this.ToggleEditProfileUiModal()}>Close</Button>
                        </Modal.Footer>
                }
            </Modal>

            {/* Custom Group - Manage Custom Group - Modal */}
            <Modal show={this.state.ManageCustomGroupModal_Toggle}
                onHide={this.ManageCustomGroup_ToggleModal}
                centered
                size='lg'
            // dialogClassName='alert-dialog-bordered'
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title style={{ fontSize: 20 }}>Manage Custom Group</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ textAlign: 'center' }}>
                    {this.ManageCustomGroup_BodyComponent()}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.ManageCustomGroup_ToggleModal()}>Close</Button>
                </Modal.Footer>
            </Modal>

            {/* Custom Group - New / Edit Custom Group - Modal */}
            <Modal show={this.state.EditCustomGroup_Toggle}
                onHide={() => this.EditCustomGroup_ToggleModal()}
                centered
                dialogClassName='alert-dialog-bordered'
            // zIndex={-1}
            // backdrop='static'
            // keyboard='false'
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title style={{ fontSize: 20 }}>{CheckObjectNumber(this.state.EditCustomGroup_Target, ItemProperty.Id) > 0 ? 'Edit' : 'New'} Custom Group</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.EditCustomGroup_BodyComponent()}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" className='' onClick={() => this.SaveCustomGroup_ViaApi(true)} disabled={this.state.EditCustomGroup_Processing} style={{ position: 'absolute', left: 0, marginLeft: 14 }}>Remove</Button>
                    <Button variant="secondary" onClick={() => this.EditCustomGroup_ToggleModal()} disabled={this.state.EditCustomGroup_Processing}>Close</Button>
                    <Button variant="primary" onClick={() => this.SaveCustomGroup_ViaApi()} disabled={this.state.EditCustomGroup_Processing}>Save</Button>
                </Modal.Footer>
            </Modal>

            {/* Custom Group - Upload Bulk Template - Modal */}
            <Modal show={this.state.UploadBulkEditTemplateUi_Toggle}
                onHide={this.ToggleModal_UploadBulkEditTemplateUi}
                centered
            // size='lg'
            // dialogClassName='alert-dialog-bordered'
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title style={{ fontSize: 20 }}>Upload Template for Bulk Edit Custom Groups</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>Select a file to upload:</span>
                    <br />
                    <input type="file" onChange={this.onUploadFileChange_CustomGroups_BulkEditTemplate}
                        style={{ width: '100%', marginTop: 20 }}
                        disabled={this.state.BulkEditTemplate_Processing} />*
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.ToggleModal_UploadBulkEditTemplateUi()}>Cancel</Button>
                    <Button variant="primary"
                        onClick={() => this.Upload_BulkEditTemplateFile_ViaApi()}
                        disabled={this.state.CustomGroups_BulkEditTemplateUploadFile === null || this.state.BulkEditTemplate_Processing}
                    >Upload</Button>
                </Modal.Footer>
            </Modal>

            {/* Setting - (BULK) Edit / Update - Modal */}
            <Modal show={this.state.BulkEdit_Toggle_EditSettingModal}
                onHide={() => this.BulkEdit_ToggleEditSettingModal()}
                centered
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>Bulk Edit</Modal.Title>
                </Modal.Header>
                <Modal.Body className="setting-bulk-parent">
                    {this.BulkEdit_SettingModalComponent()}
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="danger"
                                    onClick={() => this.BulkEdit_ToggleRemoveSettingModal()}
                                    style={{ position: "absolute", left: 0, marginLeft: 15 }}
                                    disabled={this.state.BulkEdit_IsUpdating || (this.state.isSuperAdmin ? false : this.state.PA_Delete === false)}
                                >Bulk Remove</Button> */}
                    <Button variant="secondary" onClick={() => this.BulkEdit_ToggleEditSettingModal()} disabled={this.state.BulkEdit_IsUpdating}>Cancel</Button>
                    <Button variant="secondary" onClick={async () => {
                        this.BulkEdit_ToggleEditSettingModal(); //close.
                        await Delay(200);
                        this.BulkEdit_ToggleEditSettingModal(); //open again.
                    }} disabled={this.state.BulkEdit_IsUpdating}>Reset</Button>
                    <Button variant="primary" onClick={() => this.BulkEdit_CUD_Setting_ViaApi()} disabled={this.state.BulkEdit_IsUpdating || this.state.BulkEdit_Setting_checked.indexOf(true) < 0}>Bulk Update</Button>
                </Modal.Footer>
            </Modal >
        </div >);
    }
}