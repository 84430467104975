import React from 'react';
import {
    // osVersion, osName, browserName, fullBrowserVersion
    browserName
} from "react-device-detect";
import { Dropdown, ProgressBar, SplitButton } from 'react-bootstrap';
import { CommonState, UploadState, InputType, PermissionAccessType, AdminRolePermissions, TeacherRolePermissions, SuperAdminRolePermissions, ViewerRolePermissions, LayoutScreen, MasterRolePermissions, Menu, Access } from './GlobalSetting';
import Select from 'react-select';
import { Locale, Lang } from './localization/CustomLocalization';
import moment from 'moment';
import { useGlobal } from './GlobalVariables';
import { useAppService } from '../services/AppService';

const isDevMode = window.location.href.includes('localhost');

let tempTarget = null;

export function DoNothing() { }

export function Delay(ms) { return new Promise(res => setTimeout(res, ms)); }

export function DelayUntil(conditionFunction) {
    const poll = resolve => {
        if (conditionFunction()) resolve();
        else setTimeout(_ => poll(resolve), 500);
    }
    return new Promise(poll);
}

export function ScrollToElement(_elementName, delay = 300) {
    const elementName = CheckStringEmpty(_elementName);
    if (elementName === '')
        return null;
    const element = document.getElementById(elementName);
    if (element !== null) {
        setTimeout(() => {
            // element.parentNode.scrollTop = element.offsetTop;
            // window.scrollY(element.target.outerHeight);
            if (browserName.toLowerCase().includes('chrome'))
                element.scrollIntoView({ behavior: "smooth" });
            else
                element.scrollIntoView();
        }, delay);
    }
}

export function CheckNullValue(val, def = null) {
    // if (val === undefined || val === null || String(val).trim() === '' || String(val) === 'null' || String(val) === 'undefined')
    //     return null;

    // if (val === undefined || val === null)
    //     if (def === null)
    //         return null;
    //     else
    //         return def;
    // if (typeof (val) !== 'function') {
    //     if (String(val).trim() === '' || String(val) === 'null' || String(val) === 'undefined')
    //         if (def === null)
    //             return null;
    //         else
    //             return def;
    // }
    // return val;

    //2024.12.18
    switch (typeof (val)) {
        case 'string':
            const val_string = String(val);
            return val_string.trim() === '' || val_string === 'null' || val_string === 'undefined' ?
                (def === null ? null : String(def)) : val_string.trim();
        case 'number':
            const val_number = Number(val);
            return isNaN(val_number) || val_number === 0 ?
                (def === null ? null : Number(def)) : val_number;
        case 'boolean':
            const val_boolean = String(val).toLowerCase();
            return val_boolean === 'true' ? true : (val_boolean === 'false' ? false : (def === null ? null : def));
        // case 'function': return val === null || val === undefined ? (def === null ? null : def) : val;
        default: return val === null || val === undefined ? (def === null ? null : def) : val;
    }
}

export function CheckObjectNullValue(obj, field, def = null) {
    if (CheckNullValue(obj) === null || CheckNullValue(field) === null) {
        if (def === null)
            return null;
        else
            return def;
    }
    const tmp_field = String(field).charAt(0).toLowerCase() + String(field).slice(1);
    const val = obj[field];
    if (CheckNullValue(val) === null && CheckNullValue(obj[tmp_field]) === null) {
        if (def === null)
            return null;
        else
            return def;
    }
    return val;
}

export function CheckValueNA(val) {
    return CheckNullValue(val) === null ? '-n/a-' : val;
}

export function CheckStringEmpty(val, def = null) {
    return CheckNullValue(val) === null ? (def === null ? '' : String(def).trim()) : String(val).trim();
}

export function CheckBoolean(val) {
    if (CheckNullValue(val) === null)
        return false;
    return CheckStringEmpty(val).trim().toLowerCase() === 'true' ? true : false;
}

// function CheckField = (obj = null, field = '', def = null) => {
//     let val = obj[field];
//     if (CheckNullValue(val) === null) {
//         const _field = CheckStringEmpty(field);
//         if (_field.length > 0) {
//             field = _field.charAt(0).toLowerCase() + _field.slice(1);
//             val = obj[field];
//             return CheckObjectNullValue(obj, field) === null ? def : CheckStringEmpty(val, def);
//         }
//     }
//     return val;
// }

export function CheckObjectStringEmpty(obj, field = '', def = null) {
    field = String(field).trim();
    let tmp = CheckObjectNullValue(obj, field) === null ? '' : CheckStringEmpty(obj[field]);
    if (CheckNullValue(tmp) === null) {
        const tmp_field = String(field).charAt(0).toLowerCase() + String(field).slice(1);
        tmp = CheckObjectNullValue(obj, tmp_field) === null ? '' : CheckStringEmpty(obj[tmp_field]);
    }
    if (CheckNullValue(tmp) === null)
        tmp = def === null ? '' : String(def).trim();
    return tmp;
    // const defaultText = def === null ? '' : String(def).trim();
    // return CheckObjectNullValue(obj, field) === null ? defaultText : CheckStringEmpty(obj[field], defaultText);
}

export function CheckObjectBoolean(obj, field, def = null) {
    // const value = CheckObjectStringEmpty(obj, val);
    return CheckObjectStringEmpty(obj, field).trim().toLowerCase() === 'true' ? true : (def === null ? false : CheckBoolean(def));
}

export function CheckObjectNumber(obj, field, def = null) {
    const value = CheckObjectStringEmpty(obj, field).trim();
    // if (Number(value).toString() === 'NaN')
    if (CheckNullValue(value) === null)
        return def === null ? 0 : Number(def);
    return Number(value);
}

//2025.01.02
export function CheckObjectNumberDecimal(obj, field, decimals = 0, def = null) {
    return CheckObjectNumber(obj, field, def).toFixed(decimals);
}

export function CheckNumber(val, def = null) {
    if (CheckNullValue(val) === null)
        return def === null ? 0 : Number(def);
    return Number(CheckStringEmpty(val).trim());
}

//if not equal/not same = return true, if equal/same = return false.
export function CompareObjectValue_NotEqual(objA, objB, field) {
    // const _objA = CheckObjectNullValue(objA, val) === null ? '' :
    //     (typeof (objA[val]) === 'number' ? CheckObjectNumber(objA, val) :
    //         (typeof (objA[val]) === 'boolean' ? CheckObjectBoolean(objA, val) :
    //             CheckObjectStringEmpty(objA, val)));
    // const _objB = CheckObjectNullValue(objB, val) === null ? '' :
    //     (typeof (objB[val]) === 'number' ? CheckObjectNumber(objB, val) :
    //         (typeof (objB[val]) === 'boolean' ? CheckObjectBoolean(objB, val) :
    //             CheckObjectStringEmpty(objB, val)));
    let _objA = null;
    if (CheckObjectNullValue(objA, field) !== null) {
        switch (typeof (objA[field])) {
            case 'number': _objA = CheckObjectNumber(objA, field); break;
            case 'boolean': _objA = CheckObjectBoolean(objA, field); break;
            default: _objA = CheckObjectStringEmpty(objA, field); break;
        }
    }
    let _objB = null;
    if (CheckObjectNullValue(objB, field) !== null) {
        switch (typeof (objB[field])) {
            case 'number': _objB = CheckObjectNumber(objB, field); break;
            case 'boolean': _objB = CheckObjectBoolean(objB, field); break;
            default: _objB = CheckObjectStringEmpty(objB, field); break;
        }
    }
    if (_objA === null && _objB === null)
        return false;
    // console.log('CompareObjectValue_NotEqual', typeof (objA[val]), typeof (objB[val]), _objA, _objB, _objA !== _objB);
    return _objA !== _objB;
}

export function CompareValue_NotEqual(valA, valB) {
    const _valA = CheckNullValue(valA) === null ? '' :
        (typeof (valA) === 'number' ? Number(valA) :
            (typeof (valA) === 'boolean' ? (String(valA).toLowerCase() === 'true' ? true : false) :
                CheckStringEmpty(valA)));
    const _valB = CheckNullValue(valB) === null ? '' :
        (typeof (valB) === 'number' ? Number(valB) :
            (typeof (valB) === 'boolean' ? (String(valB).toLowerCase() === 'true' ? true : false) :
                CheckStringEmpty(valB)));
    return _valA !== _valB;
}

export function CheckUpdateValueDiffer(updateObj, editedObj, cachedObj, field) {
    // console.log('CheckUpdateValueDiffer ', val, editedObj[val], cachedObj[val], CompareObjectValue_NotEqual(editedObj, cachedObj, val));
    if (CompareObjectValue_NotEqual(editedObj, cachedObj, field)) {
        //value differ.
        if (typeof (editedObj[field]) === 'number')
            updateObj[field] = CheckObjectNumber(editedObj, field);
        else if (typeof (editedObj[field]) === 'boolean')
            updateObj[field] = CheckObjectBoolean(editedObj, field);
        else if (typeof (editedObj[field]) === 'string')
            updateObj[field] = CheckObjectStringEmpty(editedObj, field);
        else
            updateObj[field] = editedObj[field];
    }
    else {
        //same value.
        if (CheckObjectNullValue(cachedObj, field) !== null)
            updateObj[field] = cachedObj[field];
    }
    // console.log('CheckUpdateValueDiffer ', updateObj[val], val, editedObj[val], cachedObj[val], CompareObjectValue_NotEqual(editedObj, cachedObj, val));
    return updateObj;
}

export function GetPropIds(user) {

    const uid = CheckObjectStringEmpty(user, 'uid');
    const centerUserId = CheckObjectNumber(user, 'CenterUserId');
    const authorId = CheckObjectNumber(user, 'AuthorId');
    const authorRoleId = CheckObjectNumber(user, 'AuthorRoleId');

    let organizerInfo = CheckObjectNullValue(user, 'OrganizerInfo');
    if (organizerInfo === null) {
        const organizerList = CheckObjectNullValue(user, 'OrganizerInfoList', []);
        organizerInfo = organizerList.length > 0 ? organizerList[0] : null;
    }
    const organizerId = CheckObjectNumber(organizerInfo, 'OrganizerId');
    const organizerDiplayName = CheckObjectStringEmpty(organizerInfo, 'DisplayName');
    const organizerIdentity = CheckObjectStringEmpty(organizerInfo, 'Identity');
    const organizerName = CheckObjectStringEmpty(organizerInfo, 'Name');
    const organizerSchoolCode = CheckObjectStringEmpty(organizerInfo, 'SchoolCode', '0');    //2025.02.03

    return { uid, centerUserId, authorId, authorRoleId, organizerId, organizerDiplayName, organizerIdentity, organizerName, organizerSchoolCode };
}

export function GetValueString(data, propertyName, def = null) {
    if (CheckObjectNullValue(data, propertyName) !== null)
        return CheckObjectStringEmpty(data, propertyName);
    return CheckStringEmpty(def);
}

//Panel Menu.
// export function PermissionAccess(section = '', action = PermissionAccessType.None) {

//     //return true = block access.
//     //return false = allow access.

//     // const props = useGlobal.getState();

//     if (CheckNullValue(gv) === null || CheckNullValue(gv.user) === null)
//         return true;

//     // const viewerAccess = !CheckBoolean(props.user.IsViewer) || props.OrganizerInfo === null;

//     // const organizerAccess = !CheckBoolean(props.user.IsOrganizer) || props.OrganizerInfo === null;

//     const isSuperAdmin = CheckNullValue(gv.isSuperAdmin) === null ? false : CheckBoolean(gv.isSuperAdmin);
//     if (isSuperAdmin)
//         return false;   //not blocking.

//     const isAdmin = CheckNullValue(gv.isAdmin) === null ? false : CheckBoolean(gv.isAdmin);
//     if (isAdmin)
//         return false;   //not blocking.

//     //2024.07.18
//     const { CustomPermissions } = GetOrganizerInfo();
//     // const CustomPermissions = JSON.parse(CheckObjectStringEmpty(useGlobal.getState().organizerInfo, 'CustomPermissions'));
//     let permissions = '';
//     if (CheckNullValue(CustomPermissions) !== null)
//         permissions = CustomPermissions;
//     else if (CheckNullValue(gv.user.CustomPermissions) !== null)
//         permissions = gv.user.CustomPermissions;

//     // const permissions = CheckNullValue(gv.user.CustomPermissions) === null ? null
//     //     : gv.user.CustomPermissions;

//     if (CheckNullValue(permissions) === null || !Array.isArray(permissions) || CheckNullValue(section) === null || CheckNullValue(action) === null)
//         return true;    //blocked.

//     const entries = Object.values(permissions);
//     let keyIndex = -1;
//     for (let i = 0; i < entries.length; i++) {
//         const _section = Object.keys(entries[i])[0];
//         if (_section === section) {
//             keyIndex = i;
//             break;
//         }
//     }
//     if (keyIndex > -1) {
//         if (CheckNullValue(permissions[keyIndex]) === null)
//             return true;
//         if (CheckNullValue(permissions[keyIndex][section]) === null)
//             return true;
//         if (CheckNullValue(permissions[keyIndex][section][action]) === null)
//             return true;
//         return !CheckBoolean(permissions[keyIndex][section][action]);
//     }
//     return true;
// }

export function UploadStatusMessage(uploadState = UploadState.None, uploadStatus = '', uniqueId = '') {
    if (uploadState !== UploadState.None) {
        switch (uploadState) {
            // case UploadState.Converting:
            //     return (<ProgressBar animated now={100} className='progressbar1' />);
            case UploadState.Validation:
                return (<>
                    <span>Processing File Conversion & Validation...</span>
                    <br /><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 7 }} />
                </>);
            case UploadState.Processing:
                return (<>
                    {CheckNullValue(uploadStatus) === null ? null : <div dangerouslySetInnerHTML={{ __html: uploadStatus }} />}
                    <ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 7 }} />
                </>);
            case UploadState.Saving:
                return (<>
                    <span>Now Saving...</span>
                    <br /><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 7 }} />
                </>);
            case UploadState.ConvertFailed:
                return (<>
                    <span>File Conversion & Validation Failed.</span>
                    {CheckNullValue(uploadStatus) === null ? null : <div dangerouslySetInnerHTML={{ __html: uploadStatus }} />}
                </>);
            case UploadState.Success:
                return (<>
                    {CheckNullValue(uniqueId) === null ? null : <><span style={{ fontSize: 12, color: 'gray' }}>{uniqueId}</span><br /></>}
                    {/* <span>File Upload Success.</span> */}
                    {CheckNullValue(uploadStatus) === null ? null : <div dangerouslySetInnerHTML={{ __html: uploadStatus }} />}
                </>);
            case UploadState.Failed:
                return (<>
                    <span>File Upload Failed.</span>
                    {CheckNullValue(uploadStatus) === null ? null : <div dangerouslySetInnerHTML={{ __html: uploadStatus }} />}
                </>);
            default:
                return null;
        }
    }
    return null;
}

export function CommonStatusMessage(commonState = CommonState.None, commonStatus = null) {
    if (commonState !== CommonState.None) {
        switch (commonState) {

            case UploadState.Processing:
                if (typeof (commonStatus) === 'object')
                    return (commonStatus);
                else if (typeof (commonStatus) === 'string')
                    return (<div dangerouslySetInnerHTML={{ __html: commonStatus }} />);
                else
                    return (<>{'Processing...'}</>);

            case UploadState.Success:
                if (typeof (commonStatus) === 'object')
                    return (commonStatus);
                else if (typeof (commonStatus) === 'string')
                    return (<div dangerouslySetInnerHTML={{ __html: commonStatus }} />);
                else
                    return (<>{'Done.'}</>);

            case UploadState.Failed:
                if (typeof (commonStatus) === 'object')
                    return (commonStatus);
                else if (typeof (commonStatus) === 'string')
                    return (<div dangerouslySetInnerHTML={{ __html: commonStatus }} />);
                else
                    return (<>{'Failed.'}</>);

            default:
                return null;
        }
    }
    return null;
}

//#region Data Input.
export function GenderOptions(locale) {
    if (CheckNullValue(locale) === null)
        return null;

    return [
        { value: Locale("label-gender-male", Lang.English), label: Locale("label-gender-male", locale) },
        { value: Locale("label-gender-female", Lang.English), label: Locale("label-gender-female", locale) },
        { value: Locale("label-gender-other", Lang.English), label: Locale("label-gender-other", locale) },
    ]
}
export function RaceOptions(locale) {
    if (CheckNullValue(locale) === null)
        return null;

    return [
        { value: Locale("label-race-1", Lang.English), label: Locale("label-race-1", locale) },
        { value: Locale("label-race-2", Lang.English), label: Locale("label-race-2", locale) },
        { value: Locale("label-race-3", Lang.English), label: Locale("label-race-3", locale) },
        { value: Locale("label-race-0", Lang.English), label: Locale("label-race-0", locale) },
    ]
}
export function GradeOptions(locale) {
    if (CheckNullValue(locale) === null)
        locale = Lang.English;

    const GetStandard = (idx) => {
        let text = "";
        if (locale === Lang.Chinese)
            text = idx + " " + Locale("standard", locale);
        else
            text = Locale("standard", locale) + " " + idx;
        return text;
    }
    const GetForm = (idx) => {
        let text = "";
        if (locale === Lang.Chinese) {
            if (idx > 0 && idx < 4)
                text = Locale("form-123", locale) + " " + idx + " (" + Locale("form", locale) + idx + ")";
            else if (idx > 3 && idx < 6)
                text = Locale("form-45", locale) + " " + (idx - 3) + " (" + Locale("form", locale) + idx + ")";
            else if (idx === 6)
                text = Locale("form-6", locale) + " (" + Locale("form", locale) + idx + ")";
        }
        else {
            text = Locale("form", locale) + " " + idx;
        }
        return text;
    }

    let gradeOptions = [];

    [1, 2, 3, 4, 5, 6].map((stdIdx) => (
        gradeOptions.push({ value: 'Standard ' + stdIdx, label: GetStandard(stdIdx), id: stdIdx, })
    ));

    [1, 2, 3, 4, 5, 6].map((stdIdx) => (
        gradeOptions.push({ value: 'Form ' + stdIdx, label: GetForm(stdIdx), id: stdIdx + 10, })
    ));

    gradeOptions.push({ value: 'Other', label: Locale("other", locale), id: 17, });

    gradeOptions.push({ value: 'Pre-School', label: Locale("pre-school", locale), id: 31, });

    //2022.05.12
    [1, 2, 3].map((stdIdx) => (
        gradeOptions.push({ value: 'KD ' + (stdIdx + 3), label: Locale("kindergarden", locale) + ' ' + (stdIdx + 3) + ' (' + (stdIdx + 3) + Locale("years-old", locale) + ')', id: stdIdx + 27, })
    ));

    return gradeOptions;
}
export function GetOptionsLabel(options, id) {
    let label = '';
    if (Array.isArray(options) && options.length > 0 && CheckNumber(id) > 0) {
        let index = options.findIndex(x => x.id === id);
        if (index > -1)
            label = options[index].label;
        // console.log('index : ' + index + '\nlabel : ' + label + '\nvalue : ' + value + '\n' + JSON.stringify(options[index]));
    }
    return label;
}
export function GetTempTarget(field = null) {
    if (tempTarget !== null && field !== undefined && field !== null)
        return CheckNullValue(tempTarget[field]);
    return tempTarget;
}
export function SetTempTarget(target) {
    tempTarget = target;
}
export function GetInputComponent(
    inputType = InputType.None,
    options = [],
    target = null,
    fieldName = '',
    preConditionField = '',
    defaultPlaceholderLocale = '',
    locale = Lang.English,
    callbackSaveTarget = null,
    otherCallback = null,
    readOnly = false,
    styleObj = null,
    nestedKey = -1
) {
    if (inputType === InputType.None || CheckNullValue(target) === null || CheckNullValue(fieldName) === null || CheckNullValue(locale) === null)
        return null;

    switch (inputType) {
        case InputType.Text:
            const value_Text = readOnly ? CheckObjectNullValue(target, fieldName) : CheckObjectNullValue(tempTarget, fieldName);
            return (<input
                name={fieldName}
                className={"form-control" + (value_Text === null ? " highlight" : "")}
                type="text"
                onChange={(val) => {
                    target[fieldName] = String(val.target.value);
                    tempTarget = target;
                    // console.log('Text', fieldName, tempTarget[fieldName]);
                    if (callbackSaveTarget !== undefined && callbackSaveTarget !== null)
                        callbackSaveTarget(tempTarget);
                    // if (otherCallback !== undefined && otherCallback !== null)
                    //     otherCallback();
                }}
                value={value_Text === null ? '' : value_Text}
                placeholder={value_Text === null ? Locale(defaultPlaceholderLocale, locale) : value_Text}
                disabled={readOnly}
                style={styleObj === null ? {} : styleObj}
            />);

        case InputType.Number:
            const value_Number = readOnly ? CheckObjectNumber(target, fieldName) : CheckObjectNumber(tempTarget, fieldName);
            return (<input
                name={fieldName}
                className={"form-control" + (value_Number === null ? " highlight" : "")}
                type="number"
                onChange={(val) => {
                    target[fieldName] = Number(val.target.value);
                    tempTarget = target;
                    // console.log('Text', fieldName, tempTarget[fieldName]);
                    if (callbackSaveTarget !== undefined && callbackSaveTarget !== null)
                        callbackSaveTarget(tempTarget);
                }}
                value={value_Number}
                // value={value_Number === 0 ? 0 : value_Number}
                // placeholder={value_Number === 0 ? Locale(defaultPlaceholderLocale, locale) : value_Number}
                disabled={readOnly}
                style={styleObj === null ? {} : styleObj}
            />);

        case InputType.Select:
            const value_Select = readOnly ? CheckObjectNullValue(target, fieldName) : CheckObjectNullValue(tempTarget, fieldName);
            return (<Select
                classNamePrefix={'r-select'}
                className={value_Select === null ? "select-highlight" : ""}
                options={options}
                onChange={(val) => {
                    target[fieldName] = String(val.value);
                    tempTarget = target;
                    // console.log('Select', fieldName, tempTarget[fieldName]);
                    if (callbackSaveTarget !== undefined && callbackSaveTarget !== null)
                        callbackSaveTarget(tempTarget);
                    if (otherCallback !== undefined && otherCallback !== null)
                        if (fieldName === 'nationalState')
                            otherCallback(false);
                }}
                value={value_Select === null ? '' : value_Select}
                placeholder={value_Select === null ? Locale(defaultPlaceholderLocale, locale) : value_Select}
                isDisabled={readOnly || (CheckNullValue(preConditionField) === null ? false : CheckObjectNullValue(tempTarget, preConditionField) === null)}
                style={styleObj === null ? {} : styleObj}
            />);

        case InputType.Checkbox:
            let value_Checkbox_Text = null;
            if (nestedKey < 0)
                value_Checkbox_Text = readOnly ? CheckObjectNullValue(target, fieldName) : CheckObjectNullValue(tempTarget, fieldName);
            else
                value_Checkbox_Text = readOnly ?
                    (CheckObjectNullValue(target, fieldName) === null ? 'false' : CheckNullValue(target[fieldName][nestedKey]))
                    :
                    (CheckObjectNullValue(tempTarget, fieldName) === null ? 'false' : CheckNullValue(tempTarget[fieldName][nestedKey]));

            let value_Checkbox = false;
            if (value_Checkbox_Text !== null || CheckObjectNullValue(value_Checkbox_Text, 'Selected') !== null) {
                if (CheckObjectNullValue(value_Checkbox_Text, 'Selected') !== null)
                    value_Checkbox = CheckObjectBoolean(value_Checkbox_Text, 'Selected');
                else
                    value_Checkbox = String(value_Checkbox_Text).toLowerCase() === 'true' ? true : false;
            }

            return (<input
                name={fieldName}
                className={"form-check form-check-input" + (value_Checkbox === null ? " highlight" : "")}
                type="checkbox"
                onClick={(val) => {
                    if (readOnly === false) {

                        if (nestedKey < 0)
                            target[fieldName] = val.currentTarget.checked;
                        else
                            target[fieldName][nestedKey]['Selected'] = val.currentTarget.checked;

                        tempTarget = target;
                        if (isDevMode)
                            console.log('Checkbox', fieldName, JSON.stringify(tempTarget[fieldName]));

                        if (callbackSaveTarget !== undefined && callbackSaveTarget !== null)
                            callbackSaveTarget(tempTarget);
                    }
                }}
                checked={
                    // CheckObjectBoolean(tempTarget, fieldName)
                    value_Checkbox
                    // nestedKey < 0 ?
                    //     CheckObjectNullValue(tempTarget, fieldName) === null ? false : (String(tempTarget[fieldName]).toLowerCase() === 'true' ? true : false)
                    //     :
                    //     CheckObjectNullValue(tempTarget, fieldName) === null ? false
                    //         : CheckNullValue(tempTarget[fieldName][nestedKey].selected) === null ? false
                    //             : CheckBoolean(tempTarget[fieldName][nestedKey].selected)
                }
                readOnly={true}
                style={styleObj === null ? { width: 20, height: 20 } : styleObj}
                disabled={readOnly}
            />);

        default: return null;
    }
}
//#endregion

// function RemoveUnwantedProperties(data) {

//     delete data.ContactNumber;
//     delete data.contactNumber;

//     delete data.CSR;
//     delete data.cSR;

//     delete data.Pemulihan;
//     delete data.pemulihan;

//     delete data.Tuition;
//     delete data.tuition;

//     delete data.RawPassword;
//     delete data.rawPassword;

//     delete data.EventCode;
//     delete data.eventCode;

//     delete data.CreatedDate;
//     delete data.createdDate;

//     delete data.IsOrganizerStudent;
//     delete data.isOrganizerStudent;

//     delete data.CustomGroup;
//     delete data.customGroup;

//     delete data.FirebaseUserId;
//     delete data.firebaseUserId;

//     //2023.12.06
//     delete data.MarkAsDeleted;
//     delete data.CreatedOnUtc;
//     delete data.CreatedByUserId;
//     delete data.LastUpdatedOnUtc;
//     delete data.UpdatedByUserId;
//     delete data.DeletedOnUtc;
//     delete data.DeletedByUserId;

//     // if (CheckObjectNullValue(data, 'CSR') !== null)
//     //     delete data.CSR;
//     // if (CheckObjectNullValue(data, 'Pemulihan') !== null)
//     //     delete data.Pemulihan;
//     // if (CheckObjectNullValue(data, 'Tuition') !== null)
//     //     delete data.Tuition;
//     // if (CheckObjectNullValue(data, 'RawPassword') !== null)
//     //     delete data.RawPassword;
//     // if (CheckObjectNullValue(data, 'EventCode') !== null)
//     //     delete data.EventCode;
//     // if (CheckObjectNullValue(data, 'CreatedDate') !== null)
//     //     delete data.CreatedDate;
//     // if (CheckObjectNullValue(data, 'IsOrganizerStudent') !== null)
//     //     delete data.IsOrganizerStudent;
//     // if (CheckObjectNullValue(data, 'RawPassword') !== null)
//     //     delete data.RawPassword;
//     // if (CheckObjectNullValue(data, 'CustomGroup') !== null)
//     //     delete data.CustomGroup;
//     // if (CheckObjectNullValue(data, 'FirebaseUserId') !== null)
//     //     delete data.FirebaseUserId;

//     return data;
// }

export function CapitalizeJsonKeys(obj) {
    // for (var key in obj) {
    //     delete Object.assign(obj, { [key.charAt(0).toUpperCase() + key.slice(1)]: obj[key] })[key];
    //     // if (obj.hasOwnProperty(key)) {
    //     //     obj[key.charAt(0).toUpperCase() + key.slice(1)] = obj[key];
    //     //     delete obj[key];
    //     // }
    // }

    //2023.12.06
    if (Array.isArray(obj)) {
        return obj.map(CapitalizeJsonKeys);
    } else if (typeof obj === 'object') {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                obj[key.charAt(0).toUpperCase() + key.slice(1)] = CapitalizeJsonKeys(obj[key]);
                if (key.charAt(0).toUpperCase() + key.slice(1) !== key) {
                    delete obj[key];
                }
            }
        }
    }
    return obj;
}

export function DecapitalizeJsonKeys(obj) {
    if (Array.isArray(obj)) {
        return obj.map(DecapitalizeJsonKeys);
    } else if (typeof obj === 'object') {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                obj[key.charAt(0).toLowerCase() + key.slice(1)] = DecapitalizeJsonKeys(obj[key]);
                if (key.charAt(0).toLowerCase() + key.slice(1) !== key) {
                    delete obj[key];
                }
            }
        }
    }
    return obj;
}

export function FormatList_QuestionSet(_List = [], groupOptions = [], subjectOptions = []) {
    if (CheckNullValue(_List) === null)
        return null;

    if (_List.length > 0) {
        _List.map((data, key) => {
            if (CheckNullValue(data) !== null) {

                //OrganizerId.
                if (CheckObjectNumber(data, 'OrganizerId') === 0) {
                    const { organizerId } = GetPropIds(useGlobal.getState().user);
                    data['OrganizerId'] = organizerId;
                }

                //Subjects.
                if (Array.isArray(subjectOptions) && subjectOptions.length > 0) {
                    let subjectOption = subjectOptions[0];
                    if (data.hasOwnProperty('SubjectId')) {
                        const findIndex = subjectOptions.findIndex(x => Number(x.id) === CheckObjectNumber(data, 'SubjectId'));
                        if (findIndex > -1)
                            subjectOption = subjectOptions[findIndex];     //replace default.
                    }
                    else if (data.hasOwnProperty('SubjectName') || (data.hasOwnProperty('Subject') && typeof (data.Subject) === 'string')) {
                        const name = CheckObjectStringEmpty(data, 'SubjectName', CheckObjectStringEmpty(data, 'Subject'));
                        const findIndex = subjectOptions.findIndex(x => String(x.value) === name);
                        if (findIndex > -1)
                            subjectOption = subjectOptions[findIndex];     //replace default.
                    }
                    subjectOption = { Id: CheckObjectNumber(subjectOption, 'id'), Name: CheckObjectStringEmpty(subjectOption, 'value'), Label: CheckObjectStringEmpty(subjectOption, 'label') };     //default
                    data['Subject'] = subjectOption;
                    data['SubjectId'] = subjectOption.Id;
                    data['SubjectName'] = subjectOption.Name;
                }

                //Groups.
                if (Array.isArray(groupOptions) && groupOptions.length > 0) {
                    let groupOption = groupOptions[0];
                    if (data.hasOwnProperty('GroupId')) {
                        const findIndex = groupOptions.findIndex(x => Number(x.id) === CheckObjectNumber(data, 'GroupId'));
                        if (findIndex > -1)
                            groupOption = { Id: groupOptions[findIndex].id, Name: groupOptions[findIndex].value };     //default
                    }
                    else if (data.hasOwnProperty('GroupName') || (data.hasOwnProperty('Group') && typeof (data.Group) === 'string')) {
                        const name = CheckObjectStringEmpty(data, 'GroupName', CheckObjectStringEmpty(data, 'Group'));
                        const findIndex = groupOptions.findIndex(x => String(x.value) === name);
                        if (findIndex > -1)
                            groupOption = { Id: groupOptions[findIndex].id, Name: groupOptions[findIndex].value };     //default
                    }
                    data['Group'] = groupOption;
                    data['GroupId'] = groupOption.Id;
                    data['GroupName'] = groupOption.Name;
                }
            }
            return data;
        });
    }
    return _List;
}

export async function TriggerDownloadFile(url = '', fileName = '', fileExt = '', locale = null) {
    //download file.
    let a = document.createElement('a');
    a.href = url;
    a.download = fileName + fileExt;
    // a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    await Delay(200);
    //reset.
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
    //alert.
    useAppService.getState().setModal(
        Locale("label-file-download-success", CheckStringEmpty(locale, 'en')),
        Locale("notice-file-download-success", CheckStringEmpty(locale, 'en'))
    );
}

export function DownloadTxtFile(dataSet, filename) {
    let element = document.createElement("a");
    const file = new Blob([JSON.stringify(dataSet)], {
        type: "text/plain"
    });
    element.href = URL.createObjectURL(file);
    element.download = filename + '.txt';
    document.body.appendChild(element);
    element.click();
    element = null;
}

//#region === Paging Components
function NavigatePage(totalRows = 0, pageIndex = 0, pageSize = 0, action = '', cbFnPageIndex = null) {
    if (action === 'prev') {
        pageIndex = pageIndex - pageSize;
        if (pageIndex < 0)
            pageIndex = 0;
    }
    else if (action === 'next') {
        pageIndex = pageIndex + pageSize;
        if (pageIndex >= totalRows)
            pageIndex = Math.round(totalRows / pageSize) * pageSize;
    }
    else if (action === 'first') {
        pageIndex = 0;
    }
    else if (action === 'last') {
        pageIndex = Number((totalRows / pageSize).toFixed(0)) * pageSize;
        if (pageIndex >= totalRows)
            pageIndex = (Number((totalRows / pageSize).toFixed(0)) - 1) * pageSize;
    }
    else {
        pageIndex = Number(action);
    }
    if (isDevMode)
        console.log('NavigatePage', pageIndex);
    if (cbFnPageIndex !== null)
        cbFnPageIndex(pageIndex);
}
function PagingNavigationComponents(totalRows = 0, pageIndex = 0, pageSize = 0, cbFnPageIndex = null) {
    let components = [];
    let temp = [];

    let totalPage = Number((totalRows / pageSize).toFixed(0));
    const maxSize = totalPage * pageSize;
    let currentPage = 1;
    if (totalRows > maxSize || totalPage === 0)
        totalPage += 1;
    for (let i = 0; i < totalPage; i++) {
        const _pageIndex = i * pageSize;
        temp.push({ page: (i + 1), pageIndex: _pageIndex });
        if (pageIndex === _pageIndex)
            currentPage = (i + 1);
    }
    const findCurrentPageIndex = temp.findIndex(x => x.page === currentPage);
    let page_StartIndex = 0;
    let page_EndtIndex = pageSize;
    if (findCurrentPageIndex > -1) {
        const page = temp[findCurrentPageIndex].page;
        page_StartIndex = page - 5;
        page_EndtIndex = page + 5;
        if (page_StartIndex < 0) {
            page_StartIndex = 0;
            page_EndtIndex = 10;
        }
        if (page_EndtIndex > totalPage) {
            page_EndtIndex = totalPage;
            page_StartIndex = totalPage - 10;
        }
    }
    // console.log(totalPage, currentPage, page_StartIndex, page_EndtIndex);
    if (totalPage === 1) {
        currentPage = 0;
        page_StartIndex = 0;
        page_EndtIndex = 1;
    }
    // console.log(totalPage, currentPage, page_StartIndex, page_EndtIndex);

    if (totalPage > 1) {
        components.push(<button type='button' className='btn-link' key='k_f' onClick={() => NavigatePage(totalRows, pageIndex, pageSize, 'first', cbFnPageIndex)}><i className='fa fa-fast-backward'></i> First</button>);
        components.push(<>&nbsp;&nbsp;</>);
        components.push(<button type='button' className='btn-link' key='k_p' onClick={() => NavigatePage(totalRows, pageIndex, pageSize, 'prev', cbFnPageIndex)}>Prev <i className='fa fa-angle-double-left'></i></button>);
    }
    for (let k = page_StartIndex; k < page_EndtIndex; k++) {
        if (CheckNullValue(temp[k]) !== null) {
            const firstFigure = temp[k].pageIndex + 1;
            let lastFigure = temp[k].page * pageSize;
            if (lastFigure > totalRows)
                lastFigure = totalRows;

            if (temp[k].page === currentPage) {
                components.push(<span className='btn-link' key={'k_' + k}
                    style={{ width: 35, display: 'inline-block', fontWeight: 'bold' }}
                    title={firstFigure + '~' + lastFigure + ' (' + (lastFigure - firstFigure + 1) + ')'}><u>{k + 1}</u></span>);
            }
            else {
                components.push(<button type='button' className='btn-link' key={'k_' + k}
                    style={{ width: 35 }}
                    title={firstFigure + '~' + lastFigure + ' (' + (lastFigure - firstFigure + 1) + ')'}
                    onClick={() => NavigatePage(totalRows, pageIndex, pageSize, temp[k].pageIndex, cbFnPageIndex)}>{k + 1}</button>);
            }
        }
    }
    if (totalPage > 1) {
        components.push(<button type='button' className='btn-link' key='k_n' onClick={() => NavigatePage(totalRows, pageIndex, pageSize, 'next', cbFnPageIndex)}><i className='fa fa-angle-double-right'></i> Next</button>);
        components.push(<>&nbsp;&nbsp;</>);
        components.push(<button type='button' className='btn-link' key='k_l' onClick={() => NavigatePage(totalRows, pageIndex, pageSize, 'last', cbFnPageIndex)}>Last <i className='fa fa-fast-forward'></i></button>);
    }

    return (components);
}
export function PagingComponents(colspan = 0, totalRows = 0, pageIndex = 0, pageSize = 0, cbFnPageSize = null, cbFnPageIndex = null) {
    return (<tr key='page-com-footer'>
        <td colSpan={colspan} align='center' style={{ height: 57, padding: 0 }}>
            <div style={{ display: 'inline-flex', alignItems: 'center', width: '15%', paddingLeft: 20 }}>
                Showing {pageIndex + 1}~{pageIndex + pageSize > totalRows ? totalRows : pageIndex + pageSize} of {totalRows}.
            </div>
            <div style={{ height: '100%', width: '70%', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>{PagingNavigationComponents(totalRows, pageIndex, pageSize, cbFnPageIndex)}</div>
            <div style={{ display: 'inline-flex', alignItems: 'center', width: '15%', justifyContent: 'end', paddingRight: 20 }}>
                {/* Page Size&nbsp; */}
                {/* <input type='number'
                    placeholder={pageSize}
                    onBlur={e => {
                        let _pageSize = Number(e.currentTarget.value);
                        _pageSize = _pageSize < 5 ? 5 : _pageSize;
                        if (cbFnPageSize !== null && cbFnPageSize !== undefined)
                            cbFnPageSize(_pageSize);
                    }}
                    style={{ height: 38, width: 60, textAlign: 'center', fontSize: 20, }}
                ></input> */}
                <SplitButton
                    className="btn-dropdown-ps"
                    title={pageSize}
                    align={{ lg: 'center' }}
                    drop='up-centered'
                >
                    {
                        [10, 15, 20, 25, 30, 50, 75, 100].map((data, key) => {
                            return <Dropdown.Item
                                key={'menu-di-ps-' + key}
                                eventKey={'menu-di-ps-' + key}
                                onClick={() => cbFnPageSize(data)}
                                disabled={pageSize === data}
                            >{data}</Dropdown.Item>;
                        })
                    }
                </SplitButton>
            </div>
        </td>
    </tr>);
}
//#endregion === Paging Components

//2024.10.21
export function UTCtoLocalTime(_utc) {
    if (CheckNullValue(_utc) === null)
        return '-';
    return moment.utc(_utc).local().format('YYYY-MM-DD hh:mm A');
}

//format = 'YYYY-MM-DD hh:mm A'
export function FormatTime(_time) {
    let time = '';
    if (String(_time).includes(' ') || String(_time).length > 8)
        time = moment(_time).format('hh:mm A');
    else
        time = moment(moment().format('YYYY-MM-DD ') + _time).format('hh:mm A');
    return time;
}

//format = 'MMM D, YYYY'
export function RoomFormatDate(_data) {
    if (CheckNullValue(_data) === null)
        return null;
    if (_data.hasOwnProperty('DateStart')) {
        if (_data.DateStart === _data.DateEnd)
            return (<>{moment(_data.DateStart).format('ll')}</>);
        else
            return (<>{moment(_data.DateStart).format('ll')}<br />~<br />{moment(_data.DateEnd).format('ll')}</>);
    }
    else {
        return (<>{moment(_data.Date).format('ll')}</>);
    }
}

export function UTCtoLocalDateTimeString(_utc) {
    _utc = CheckStringEmpty(_utc);
    if (_utc === '')
        return '';
    return moment.utc(_utc).local().format('lll');
}

export function RandomId() {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < 5; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}

//populate data fetched from list.
export async function PopulateRoomData(data = null, questionSet = null) {

    if (data === null)
        return null;

    const _groupId = CheckObjectNumber(data, 'GroupId');
    const _group = CheckObjectNullValue(data, 'Group');
    const { group, groupId } = GetGroupData(_group, _groupId);

    const _subjectId = CheckObjectNumber(data, 'SubjectId');
    const _subject = CheckObjectNullValue(data, 'Subject');
    const { subject, subjectId } = GetSubjectData(_subject, _subjectId);

    const { centerUserId, authorId, organizerId, organizerDiplayName, organizerIdentity } = GetPropIds(useGlobal.getState().user);

    let _Duration = CheckObjectNumber(data, 'Duration');
    let _DurationPerQuestion = CheckObjectNumber(data, 'DurationPerQuestion');
    let _QnQty = CheckObjectNumber(data, 'QnQty');
    if (CheckNullValue(questionSet) !== null && _QnQty === 0) {
        _QnQty = CheckObjectNumber(questionSet, 'TotalQuestion');
        if (_QnQty > 0 && _Duration > 0)
            _DurationPerQuestion = Math.round(_Duration / _QnQty);
    }

    let roomData = {
        Id: CheckObjectNumber(data, 'Id'),
        AuthorId: CheckObjectNumber(data, 'AuthorId', authorId),
        CenterUserId: CheckObjectNumber(data, 'CenterUserId', centerUserId),

        Organizer: CheckObjectStringEmpty(data, 'Organizer', organizerDiplayName),
        OrganizerId: CheckObjectNumber(data, 'OrganizerId', organizerId),
        OrganizerIdentity: CheckObjectStringEmpty(data, 'OrganizerIdentity', organizerIdentity),
        EventCode: CheckObjectStringEmpty(data, 'EventCode'),

        RoomCode: CheckObjectStringEmpty(data, 'RoomCode'),
        RoomId: CheckObjectStringEmpty(data, 'RoomId'),
        RoomTitle: CheckObjectStringEmpty(data, 'RoomTitle'),
        RoomType: CheckObjectNumber(data, 'RoomType'),        //0 = basic, 1 = document

        Date: CheckObjectStringEmpty(data, 'Date'),
        DateStart: CheckObjectStringEmpty(data, 'DateStart'),
        DateEnd: CheckObjectStringEmpty(data, 'DateEnd'),
        TimeStart: CheckObjectStringEmpty(data, 'TimeStart', '00:00:00'),
        TimeEnd: CheckObjectStringEmpty(data, 'TimeEnd', '23:59:00'),

        Duration: _Duration,
        DurationPerQuestion: _DurationPerQuestion,

        Subject: CheckObjectStringEmpty(subject, 'value'),
        SubjectId: subjectId,
        SubjectName: CheckObjectStringEmpty(subject, 'value'),

        Group: group,
        GroupId: groupId,
        Grade: groupId,
        GroupName: CheckObjectStringEmpty(group, 'value'),

        QuestionSetUniqueId: CheckObjectStringEmpty(data, 'QuestionSetUniqueId'),
        QnQty: _QnQty,
        SupportedDocExt: CheckObjectNumber(data, 'RoomType') === 0 ? [] : ['.txt', '.rtf', '.doc', '.docx'],
        Remark: CheckObjectStringEmpty(data, 'Remark'),
        ExtraUrl: CheckObjectStringEmpty(data, 'ExtraUrl'),   //2021.12.10 for Flipbook

        RandomQuestionMode: CheckObjectBoolean(data, 'RandomQuestionMode'),
        RestrictAccessToTimeRangeOnly: CheckObjectBoolean(data, 'RestrictAccessToTimeRangeOnly'),
        ForceRetrictedAccess: CheckObjectBoolean(data, 'ForceRetrictedAccess'),
        EnableStatisticReport: CheckObjectBoolean(data, 'EnableStatisticReport'),
        ExcludedFromStatisticReport: CheckObjectBoolean(data, 'ExcludedFromStatisticReport'),
        // QuizEnded: CheckObjectBoolean(data, 'QuizEnded') || moment.utc() > moment(CheckObjectStringEmpty(data, 'DateEnd') + ' ' + CheckObjectStringEmpty(data, 'TimeEnd', '23:59:00')).utc(),
        QuizEnded: moment.utc() > moment(CheckObjectStringEmpty(data, 'DateEnd') + ' ' + CheckObjectStringEmpty(data, 'TimeEnd', '23:59:00')).utc(),

        //2024.06.05
        LastSyncDone: '',
        // EnableSyncResult: CheckObjectBoolean(data, 'EnableSyncResult', true),

        Classrooms: CheckObjectStringEmpty(data, 'Classrooms'),   //2024.07.26
        // ClassroomIds: Array.isArray(data['ClassroomIds']) ? data['ClassroomIds'] : [],     //2025.01.27

        QuestionSet: questionSet,   //2025.01.16

        AuthorUser: CheckObjectNullValue(data, 'AuthorUser', { Id: 0, UserId: 0, Name: '', Email: '' }),       //2025.01.21
    };

    //2025.01.24
    roomData['ClassroomIds'] = [];
    if (roomData['Classrooms'] !== '') {
        let t_classrooms_id = [];
        const classrooms = roomData['Classrooms'].split(',').filter(x => CheckNullValue(x) !== null);
        const classroomOptions = useAppService.getState().classroomOptions;
        if (Array.isArray(classrooms) && Array.isArray(classroomOptions)) {
            classrooms.map((data, key) => {
                const findIndex = classroomOptions.findIndex(x => String(x.value) === String(data));
                if (findIndex > -1)
                    t_classrooms_id.push(Number(classroomOptions[findIndex].id));
                return null;
            });
            t_classrooms_id.sort((a, b) => a - b);
        }
        roomData['ClassroomIds'] = t_classrooms_id;
        // console.log(JSON.stringify(t_classrooms_id));
    }

    //2024.06.05
    const lastSyncDoneOnUtc = CheckObjectStringEmpty(data, 'LastSyncDoneOnUtc')
    if (lastSyncDoneOnUtc !== '') {
        roomData.LastSyncDone = moment(lastSyncDoneOnUtc).local().format('lll');
    }

    //RoomType (0 = normal, 1 = upload file)
    switch (roomData.RoomType) {
        case 1: roomData.QuestionSetUniqueId = ''; break;
        default: break;
    }

    await Delay(200);
    return roomData;
}

//2024.03.14 - new added.
//populate data to be post on Firebase or Api.
export async function PopulateRoomDataModal(roomData = null, _currentRoomId = '') {

    if (roomData === null || CheckNullValue(_currentRoomId) === null)
        return null;

    const roomCode = CheckObjectStringEmpty(roomData, 'RoomCode');
    const { centerUserId, authorId, organizerId, organizerIdentity, organizerDiplayName } = GetPropIds(useGlobal.getState().user);

    //room DateStart.
    const m_date = moment(CheckObjectStringEmpty(roomData, 'DateStart', moment().format('YYYY-MM-DD')) + ' 00:00:00');
    // const ref_date = m_date.format('YYYYMMDD');
    const r_date = m_date.format('YYYY-MM-DD');
    const dateStart = CheckObjectStringEmpty(roomData, 'DateStart', r_date);
    const dateEnd = CheckObjectStringEmpty(roomData, 'DateEnd', r_date);
    const timeStart = CheckObjectStringEmpty(roomData, 'TimeStart', '00:00:00');
    const timeEnd = CheckObjectStringEmpty(roomData, 'TimeEnd', '23:59:00');

    // //RTDB ref.
    // const roomDetailRef = child(ref(dbLiveQuiz), 'pkquiz/' + ref_date + '/pkquiz-room-detail/' + _currentRoomId);
    // const roomCodeRef = child(ref(dbLiveQuiz), 'pkquiz/' + ref_date + '/pkquiz-room-code/' + roomCode);
    // const roomStateRef = child(ref(dbLiveQuiz), 'pkquiz/' + ref_date + '/pkquiz-live/' + _currentRoomId);

    //Values.
    const questionSet_obj = CheckObjectNullValue(roomData, 'QuestionSet');
    const duration = CheckObjectNumber(roomData, 'Duration');
    const roomType = CheckObjectNumber(roomData, 'RoomType', 0);

    const _groupId = CheckObjectNumber(roomData, 'GroupId');
    const _group = CheckObjectNullValue(roomData, 'Group');
    const { group, groupId } = GetGroupData(_group, _groupId);

    const _subjectId = CheckObjectNumber(roomData, 'SubjectId');
    const _subject = CheckObjectNullValue(roomData, 'Subject');
    const { subject, subjectId } = GetSubjectData(_subject, _subjectId);

    //Room Details.
    let modal = {

        Id: CheckObjectNumber(roomData, 'Id'),
        AuthorId: authorId,
        CenterUserId: centerUserId,

        Organizer: organizerDiplayName,
        OrganizerId: organizerId,
        OrganizerIdentity: organizerIdentity,
        EventCode: '',

        RoomCode: roomCode,
        RoomId: Number(_currentRoomId),
        RoomType: roomType,
        RoomTitle: CheckObjectStringEmpty(roomData, 'RoomTitle'),

        Date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),   //Created Date.
        DateStart: dateStart,
        DateEnd: dateEnd,
        TimeStart: timeStart,
        TimeEnd: timeEnd,

        Duration: duration,
        DurationPerQuestion:
            roomType === 0 ?
                Math.round((duration / CheckObjectNumber(questionSet_obj, 'TotalQuestion', 1)))
                : duration,

        Subject: CheckObjectStringEmpty(subject, 'value'), // subject,
        SubjectId: subjectId,

        Group: group,
        GroupId: groupId,
        Grade: roomType === 0 ? CheckObjectNumber(questionSet_obj, 'GroupId', groupId) : groupId,
        //std 1~6, N2, K1, K2 = ok, others = no dedicated qs set category.

        QuestionSetUniqueId: roomType === 0 ? CheckObjectStringEmpty(questionSet_obj, 'UniqueId', CheckObjectStringEmpty(roomData, 'QuestionSetUniqueId')) : '',
        QnQty: roomType === 0 ? CheckObjectNumber(questionSet_obj, 'TotalQuestion', 1) : 1,
        SupportedDocExt: CheckObjectNullValue(roomData, 'SupportedDocExt', []),
        Remark: CheckObjectStringEmpty(roomData, 'Remark'),
        ExtraUrl: CheckObjectStringEmpty(roomData, 'ExtraUrl'),

        RandomQuestionMode: CheckObjectBoolean(roomData, 'RandomQuestionMode'),
        RestrictAccessToTimeRangeOnly: CheckObjectBoolean(roomData, 'RestrictAccessToTimeRangeOnly'),
        ForceRetrictedAccess: CheckObjectBoolean(roomData, 'ForceRetrictedAccess'),
        // QuizEnded: CheckObjectBoolean(roomData, 'QuizEnded'),
        // QuizEnded: CheckObjectBoolean(roomData, 'QuizEnded') || moment() > moment(CheckObjectStringEmpty(roomData, 'DateEnd') + ' ' + CheckObjectStringEmpty(roomData, 'TimeEnd', '23:59:00')),
        // QuizEnded: CheckObjectBoolean(roomData, 'QuizEnded') || moment.utc() > moment(CheckObjectStringEmpty(roomData, 'DateEnd') + ' ' + CheckObjectStringEmpty(roomData, 'TimeEnd', '23:59:00')).utc(),
        QuizEnded: moment.utc() > moment(CheckObjectStringEmpty(roomData, 'DateEnd') + ' ' + CheckObjectStringEmpty(roomData, 'TimeEnd', '23:59:00')).utc(),
        EnableStatisticReport: CheckObjectBoolean(roomData, 'EnableStatisticReport'),
        ExcludedFromStatisticReport: CheckObjectBoolean(roomData, 'ExcludedFromStatisticReport'),

        Classrooms: CheckObjectStringEmpty(roomData, 'Classrooms'),   //2024.07.26
    };
    //RoomType (0 = normal, 1 = upload file)
    switch (modal.RoomType) {
        case 1: modal.QuestionSetUniqueId = ''; modal.QnQty = 0; break;
        default: break;
    }
    return modal;
}

export function GetGroupData(group = null, groupId = 0) {
    // let groupId = CheckObjectNumber(roomData, 'GroupId');
    // let group = CheckObjectNullValue(roomData, 'Group');
    if (group !== null && groupId <= 0) {
        groupId = CheckObjectNumber(group, 'id');
    }
    if (group === null && groupId > 0) {
        const groupOptions = useAppService.getState().groupOptions;
        if (Array.isArray(groupOptions) && groupOptions.length > 0) {
            const groupIndex = groupOptions.findIndex(x => x.id === groupId);
            if (groupIndex > -1)
                group = groupOptions[groupIndex];
        }
    }
    return { group, groupId };
}

export function GetSubjectData(subject = null, subjectId = 0) {
    // let subjectId = CheckObjectNumber(roomData, 'SubjectId');
    // let subject = CheckObjectNullValue(roomData, 'Subject');
    if (subject !== null && subjectId <= 0) {
        subjectId = CheckObjectNumber(subject, 'id');
    }
    if (subject === null && subjectId > 0) {
        const subjectOptions = useAppService.getState().subjectOptions;
        if (Array.isArray(subjectOptions) && subjectOptions.length > 0) {
            const subjectIndex = subjectOptions.findIndex(x => x.id === subjectId);
            if (subjectIndex > -1)
                subject = subjectOptions[subjectIndex];
        }
    }
    return { subject, subjectId };
}

export function GetDurationText(_totalSeconds = 0, fullformat = false) {
    var hours = Number((_totalSeconds / 3600).toFixed(3).split('.')[0]);
    var minutes = Number((_totalSeconds / 60).toFixed(3).split('.')[0]) - (hours * 60);
    var seconds = (_totalSeconds % 60);
    // return hours > 0 ?
    //     hours + ' hr ' + (minutes - (hours * 60)) + ' min ' + seconds + ' sec'
    //     : minutes + ' min ' + seconds + ' sec';
    if (fullformat) {
        return hours + ' hr' + (hours > 1 ? 's ' : ' ')
            + minutes + ' min' + (minutes > 1 ? 's ' : ' ')
            + seconds + ' sec' + (seconds > 1 ? 's' : '');
    }
    return (hours > 0 ? hours + ' hr' + (hours > 1 ? 's ' : ' ') : '')
        + (minutes > 0 ? minutes + ' min' + (minutes > 1 ? 's ' : ' ') : '')
        + (seconds > 0 ? seconds.toFixed(2) + ' sec' + (seconds > 1 ? 's' : '') : '');
}

export function GetGroup(id = 0) {
    if (id <= 0)
        return null;
    const options = useAppService.getState().groupOptions;
    let findIndex = options.findIndex(x => Number(x.id) === id);
    if (findIndex > -1)
        return options[findIndex];
    return null;
}

export function GetSubject(id = 0) {
    if (id <= 0)
        return null;
    const options = useAppService.getState().subjectOptions;
    let findIndex = options.findIndex(x => Number(x.id) === id);
    if (findIndex > -1)
        return options[findIndex];
    return null;
}

export function AdjustSectionRightDiv() {
    const com = document.getElementById('section-right-div');
    if (com != null) {
        const width = com.getBoundingClientRect().width;
        const innerWidth = Math.floor(window.innerWidth * 0.99);
        // console.log(com.classList.toString() + ' / ' + innerWidth + ' / ' + width);
        com.style.left = (innerWidth - width + 0) + 'px';
    }
}

export async function ToBase64(file = null) {
    if (file === null)
        return null;
    try {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result.replace("data:", "").replace(/^.+,/, ""));
            reader.onerror = reject;
        });
    } catch (error) {
        if (isDevMode)
            console.log('File Conversion Error (File:' + file.name + ')\nError:' + error);
        return null;
    }
}

export function TextSpacing(txt = '') {
    if (CheckNullValue(txt) === null)
        return '';
    let txts = [];
    for (let i = 0; i < txt.length; i++) {
        if (i - 1 < 0) {
            txts.push(txt.charAt(i));
        }
        else {
            if (i + 1 <= txt.length && txt.charAt(i + 1).match(/[A-Z]/))
                txts.push(txt.charAt(i) + ' ');
            else
                txts.push(txt.charAt(i));
        }
    }
    return txts.join('');
}

//2024.07.22 - revamped.
export function PermissionAccess(section = LayoutScreen.None, action = PermissionAccessType.None, defaultAccess = false) {

    const user = useGlobal.getState().user;
    if (user === null || section === LayoutScreen.None || action === PermissionAccessType.None)
        return defaultAccess;
    // console.log('checkStage (1) = ' + section + ' ' + action);

    let permissions = null;
    if (CheckObjectBoolean(user, 'IsSuperAdmin')) {
        permissions = SuperAdminRolePermissions;
    }
    else {
        const organizerInfo = useGlobal.getState().organizerInfo;
        if (organizerInfo !== null && CheckObjectStringEmpty(organizerInfo, 'CustomPermissions') !== '') {
            if (typeof organizerInfo['CustomPermissions'] === 'string')
                permissions = JSON.parse(organizerInfo['CustomPermissions']);
            else if (Array.isArray(organizerInfo['CustomPermissions']))
                permissions = organizerInfo['CustomPermissions'];
        }
        else {
            if (CheckObjectBoolean(user, 'IsTeacher'))
                permissions = TeacherRolePermissions;
            else if (CheckObjectBoolean(user, 'IsAdmin'))
                permissions = AdminRolePermissions;
            else if (CheckObjectBoolean(user, 'IsMasterAdmin'))
                permissions = MasterRolePermissions;
            else if (CheckObjectBoolean(user, 'IsViewer'))
                permissions = ViewerRolePermissions;
        }
    }
    if (permissions === null)
        return defaultAccess;
    // console.log('checkStage (2) \n' + JSON.stringify(permissions));

    const findIndex_section = permissions.findIndex(x => Object.keys(x).findIndex(y => String(y) === String(section)) > -1);
    if (findIndex_section < 0)
        return defaultAccess;
    // console.log(`checkStage (3) (${findIndex_section}) \n ${JSON.stringify(permissions[findIndex_section][section])}`);

    const findIndex_action = Object.keys(permissions[findIndex_section][section]).findIndex(x => String(x) === String(action));
    if (findIndex_action < 0)
        return defaultAccess;

    // console.log(`checkStage (last) (${findIndex_section}) (${findIndex_action}) \n ${JSON.stringify(permissions[findIndex_section][section][action])}`);
    return CheckBoolean(permissions[findIndex_section][section][action]);
}

//2024.07.24
export function ConsoleLog(value = null) {
    if (value === null || isDevMode === false)
        return null;
    console.log(value);
}

//2024.07.30
export function ShowOrHideMenu(menuItem = Menu['home']) {

    if (menuItem === Menu['home'])
        return false;

    if (menuItem.ShowInMenu === false || menuItem.Show === Access.None)
        return false;

    if (menuItem.Show === Access.All || useGlobal.getState().isSuperAdmin)
        return true;

    if (menuItem.Show === Access.MasterAdminOnly && useGlobal.getState().isMasterAdmin)
        return true;

    if (menuItem.Show === Access.AdminOnly && useGlobal.getState().isAdmin)
        return true;

    if (menuItem.Show === Access.TeacherOnly && useGlobal.getState().isTeacher)
        return true;

    if (menuItem.Show === Access.ByPermission)
        return PermissionAccess(CheckObjectStringEmpty(menuItem, 'Screen'), PermissionAccessType.View);    //2025.02.13

    // // if (menuItem.Show === Access.All) {
    // switch (CheckObjectStringEmpty(menuItem, 'Screen')) {
    //     // case LayoutScreen.Dashboard: return !useGlobal.getState().isTeacher;
    //     case LayoutScreen.Dashboard:    //2024.09.13
    //         if (CheckObjectBoolean(useGlobal.getState().user, 'HideDashboard'))
    //             return false;
    //         return !useGlobal.getState().isTeacher;
    //     case LayoutScreen.ManageRoom: return PermissionAccess(LayoutScreen.ManageRoom, PermissionAccessType.View);
    //     case LayoutScreen.ManageRoomPreview: return PermissionAccess(LayoutScreen.ManageRoomPreview, PermissionAccessType.View);
    //     case LayoutScreen.ManageQuestionSet: return PermissionAccess(LayoutScreen.ManageQuestionSet, PermissionAccessType.View);
    //     case LayoutScreen.ManageStudentProfile: return PermissionAccess(LayoutScreen.ManageStudentProfile, PermissionAccessType.View);
    //     case LayoutScreen.ManageTeacherProfile: return PermissionAccess(LayoutScreen.ManageTeacherProfile, PermissionAccessType.View);
    //     case LayoutScreen.ManageSetting: return PermissionAccess(LayoutScreen.ManageSetting, PermissionAccessType.View);
    //     case LayoutScreen.ManageReport: return PermissionAccess(LayoutScreen.ManageReport, PermissionAccessType.View);
    //     case LayoutScreen.ManageEvent: return PermissionAccess(LayoutScreen.ManageEvent, PermissionAccessType.View);
    //     case LayoutScreen.ManageOrganizer: return PermissionAccess(LayoutScreen.ManageOrganizer, PermissionAccessType.View);
    //     case LayoutScreen.ManageAuthor: return PermissionAccess(LayoutScreen.ManageAuthor, PermissionAccessType.View);
    //     default: break;
    // }
    // // }

    return false;
}

//2024.08.09
export function byteConverter(bytes, decimals, only) {
    const K_UNIT = 1024;
    const SIZES = ["Bytes", "KB", "MB", "GB", "TB", "PB"];

    if (bytes === 0) return "0 Byte";

    if (only === "MB") return (bytes / (K_UNIT * K_UNIT)).toFixed(decimals) + " MB";

    let i = Math.floor(Math.log(bytes) / Math.log(K_UNIT));
    let resp = parseFloat((bytes / Math.pow(K_UNIT, i)).toFixed(decimals)) + " " + SIZES[i];

    return resp;
}

//2024.09.28
export function GetPostParams(postData = null, remove = false) {
    let textTitle = '';
    let textBody = '';
    let text = '';
    let urlParam = '';
    if (postData !== null) {
        if (remove) {
            textTitle = 'Removing';
            textBody = 'removed';
            text = 'remove';
            urlParam = 'Remove';
        }
        else {
            if (CheckObjectNumber(postData, 'id') <= 0) {
                textTitle = 'Creating';
                textBody = 'created';
                text = 'create';
                urlParam = 'Create';
            }
            else {
                textTitle = 'Upating';
                textBody = 'updated';
                text = 'update';
                urlParam = 'Update';
            }
        }
    }
    return { textTitle, textBody, text, urlParam };
}